import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Loader } from '../'
import './style.scss'

class Navi extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { items } = this.props
    return(
      <div className='navi'>
        {
          items.map((item, index) => {
            return (
              <Fragment  key={index} >
                {
                  index === 0
                  ? <div className="backButton"></div>
                  : <div className="separator"></div>
                }
                <Link className={`item ${index === items.length - 1 ? 'last' : null} ${item.isRequesting ? 'requesting' : ''}`} to={item.to}>{item.title}</Link>
              </Fragment>
            )
          })
        }
        <div className="moreVert"></div>
      </div>
    )
  }
}

export default Navi
