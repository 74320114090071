import {Button, Typography} from "@material-ui/core";
import React from "react";
import {diff2name, theme2name} from "../../utils/translate";

const Course = ({ course, onAccessStatsClick, onInvitesStatsClick, onLinksClick }) => (
    <div className={`course${course.withAccess ? " accessed" : ""}`}>
        <img src={course.cover.url} />
        <div className='textBlock'>
            <Typography className="rewardPoints"><div className="symbol">☆</div>{course.rewardPoints} баллов</Typography>
            <Typography className="title">{course.title}</Typography>
            <Typography className="desc">{course.description}</Typography>
            <Typography variant='body2'>Тематика:</Typography>
            <Typography variant='body1'>{theme2name(course.theme)}</Typography>
            <Typography variant='body2'>Сложность:</Typography>
            <Typography variant='body1'>{diff2name(course.difficulty)}</Typography>
            <div className='textRow'>
                <Typography>Слайдов:  </Typography>
                <Typography variant='body1'>{course.count}</Typography>
            </div>
        </div>
        <div className="buttonRow">
            <Button variant='button'onClick={onAccessStatsClick}>Статистика по доступам</Button>
            <Button variant='button'onClick={onInvitesStatsClick}>Статистика по неиспользованным доступам</Button>
        </div>
        <Button variant='button' className='big-button' onClick={onLinksClick}>Ссылки</Button>
    </div>
)

export default Course;