import React, { Component } from 'react';
import Paper from "@material-ui/core/Paper";
import { Loader, List } from '../component'
import "./Pharmacies.scss";
import { YMaps, Map } from "react-yandex-maps";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";


import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button/Button";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";

import PharmaciesTableToolbar from "../component/toolbar/PharmaciesTableToolbar";



const pharmTypes = {
  'clinic': 'Клиника',
  'pharmacy': 'Аптека',
  'cosmetology_clinic': 'Косметологическая клиника',
}

const Header = ({ current, onTabClick, toggleFilter }) => (
  <div className='header'>
    <div className='title'></div>
    <div className='tabs'>
      <div onClick={() => onTabClick()} className={`tab ${current === 0 ? 'active' : null}`}>список</div>
      <div onClick={() => onTabClick()} className={`tab ${current === 1 ? 'active' : null}`}>карта</div>
    </div>
    <div className='buttons'>
      <div className="tooltip">
        <Tooltip title="Filter list" classes={{ tooltip: 'tipFonSize' }} >
          <IconButton onClick={toggleFilter} aria-label="Filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  </div>
)

class Pharmacies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
      view: 0,
      isFilterOpened: false,
      itemsOnPage: 20,
      pageNumber: 1
    }
  }
  componentDidMount = () => {
    const { pharmaciesActions: { getPharmacies }, metaActions: { updateNavi } } = this.props
    updateNavi([{ title: 'Медучереждения', to: '/pharmacies' }])
    getPharmacies({
      'pagination[offset]': this.state.itemsOnPage * (this.state.pageNumber - 1),
      'pagination[count]': this.state.itemsOnPage,
    })
  }
  componentDidUpdate = (_, prevState) => {
    const { pharmaciesActions: { getPharmacies } } = this.props
    if ((prevState.itemsOnPage !== this.state.itemsOnPage) || (prevState.pageNumber !== this.state.pageNumber)) {
      getPharmacies({
        'pagination[offset]': this.state.itemsOnPage * (this.state.pageNumber - 1),
        'pagination[count]': this.state.itemsOnPage,
      })
    }
  }
  handleTabClick = value => this.setState({ view: value })
  // handleTabClick = () => (console.log(this.state))

  handleFilterChange = filter => {
    this.props.contractActions.changeFilter({ filter });
  };
  handleToggleFilter = () => this.setState({ isFilterOpened: !this.state.isFilterOpened })

  handleTableControlClick = data => this.setState({ itemsOnPage: data.itemsOnPage, pageNumber: data.pageNumber })

  handleToggleFilter = () =>
    this.setState({ isFilterOpened: !this.state.isFilterOpened });

  renderList = () => {
    const { pharmacies: { items, isRequesting } } = this.props

    return <List
            onItemClick={(item) => this.props.history.push(this.props.match.url + '/' + item.id)}
            isRequesting={isRequesting}
            onTableControlChange={this.handleTableControlClick}
            items={items.map(item => ({...item, address: item.address, group: item.company.name, type: pharmTypes[item.type]}))}
            keys={['title', 'address', 'type', 'group']}
            headers={['Название', 'Адрес', 'Тип', 'Сеть']}/>

  }
  renderMap = () => {
    return (
      <div className='content map'> 
        <YMaps>
          <Map
            width={"100%"}
            height={"100%"}
            defaultState={{ center: [55.75, 37.57], zoom: 9 }}
            />
        </YMaps>
      </div>
    )
  }
  render() {
    const { view } = this.state;
    return (
      <Paper
        style={{}}
        className='pharmacies'
        >
        <PharmaciesTableToolbar
          toggleFilter={this.handleToggleFilter}
          onTabClick={this.handleTabClick}
          withFilter={this.state.isFilterOpened}
          current={this.state.view}
          history={this.props.history}
          url={this.props.match.url}
        />
        <FilterSideBar
          items={Array.from(new Set([].map(item => item.status))).map(
            item => projectStatuses[item]
          )}
          nameKey={false}
          opened={this.state.isFilterOpened}
          onChange={() => null}
          filterName={"Статус"}
        />

        {
          view === 0
            ? this.renderList()
            : this.renderMap()
        }
      </Paper>
    )
  }
}

export default Pharmacies
