import { pharmaciesTypes, invitesTypes } from '../types'
import { getList, getOne, post, serialize, get, currentApiUrl, patch } from '../api'
import { filter2url, excel2json, calcPercentage } from '../utils'
import { formatPharmacy } from '../utils/formating.js'

export const getPharmacies = (filter) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: pharmaciesTypes.GET_PHARMACIES__REQUEST }))())
    const resourses = getState().meta.resourses
    getList(filter ? filter2url(resourses['medical-institutions'], filter) : resourses['medical-institutions'])
    .then(data => {
      const uniqueCompanies = Array.from(new Set(data.items.map(item => item.company)))
      Promise.all(uniqueCompanies.map(item => getOne(resourses['companies'], { id: item.split('/').pop() }))).then(companies => {
        const companiesHash = ((arr, hash) => arr.map(item => hash[item.self] = item) && hash)(companies, {})
        dispatch((() => ({
          type: pharmaciesTypes.GET_PHARMACIES__SUCCESS,
          payload: { items: data.items.map(item => ({ ...item, company: companiesHash[item.company] })) }
        }))())
      })
    })
  }

export const createPharmacy = form =>
  (dispatch, getState) => {
    form.company = `/v1/companies/${form.company}`
    form.isRelatedToVesna = form.isRelatedToVesna ? 1 : 0
    dispatch((() => ({ type: pharmaciesTypes.CREATE_PHARMACY__REQUEST }))())
    const resourse = getState().meta.resourses['medical-institutions']
    post(resourse, { ...form }).then(pharm => {
      console.log(pharm);
    })
  }

export const getPharm = pharm =>
  (dispatch) => {
    dispatch((() => ({ type: pharmaciesTypes.GET_PHARM__REQUEST }))())
    get(`${currentApiUrl}/v1/medical-institutions/${pharm}`).then(_pharm => {
      get(`${currentApiUrl}${_pharm.courseInvites}`).then(_invites => {
        Promise.all(Array.from(new Set(_invites.items.map(_invite => _invite.course))).map(_link => get(`${currentApiUrl}${_link}`))).then(_courses => {
          dispatch((() => ({
            type: pharmaciesTypes.GET_PHARM__SUCCESS,
            payload: {
              pharm: {
                ..._pharm,
                courses: _courses
              }
            }
          }))())
        })
      })
    })
  }

export const updatePharm = (form, id) =>
  (dispatch, getState) => {
    form.company = `/v1/companies/${form.company}`
    dispatch((() => ({ type: pharmaciesTypes.UPDATE_PHARM__REQUEST }))())
    patch(`${currentApiUrl}/v1/medical-institutions/${id}`, form).then(data => {
      console.log(data);
    })
  }


export const getInvitesForPharm = ({ courseId, pharmId }, pagination) =>
  dispatch => {
    dispatch((() => ({ type: invitesTypes.GET_INVITES_FOR_PHARM__REQUEST }))())
    const _url = `${currentApiUrl}/v1/course-invites?course=${courseId}&medicalInstitution=${pharmId}&pagination[offset]=${pagination.offset}&pagination[count]=${pagination.count}`

    get(_url).then(_invites => {
      const uniqueCourses = Array.from(new Set(_invites.items.map(_invite => _invite.course)))
      Promise.all(uniqueCourses.map(_course => get(`${currentApiUrl}${_course}`))).then(_courses => {
        const _coursesByKeys = ((_arr, _obj) => _arr.map(_course => {_obj[_course.self] = _course}) && _obj)(_courses, {})
        Promise.all(_invites.items.map(_invite => _invite.courseAccess ? get(`${currentApiUrl}${_invite.courseAccess}`) : null)).then(_accesses => {
          Promise.all(_accesses.map(_access => _access ? (_access.courseResults ? get(`${currentApiUrl}${_access.courseResults}`) : null) : null)).then(_results => {
            Promise.all(_accesses.map(_access => _access ? (_access.user ? get(`${currentApiUrl}${_access.user}`) : null ) : null)).then(_users => {
              const withResultsAndUsers = _accesses.map((_access, key) => _access ? ({ ..._access, courseResults: _results[key], user: _users[key] }) : null)
              dispatch((() => ({
                type: invitesTypes.GET_INVITES_FOR_PHARM__SUCCESS,
                payload: { items: _invites.items.map((_invite, key) => ({ ..._invite, courseAccess: withResultsAndUsers[key], course: _coursesByKeys[_invite.course], test: 123 })) }
              }))())
            })
          })
        })
      })
    })
  }

export const loadPharmaciesFromFile = file => 
  dispatch => {
    console.log(file)
    dispatch((() => ({ type: pharmaciesTypes.LOAD_FROM_FILE__REQUEST}))())
    excel2json(file).then(_parsed => {
      // const _updated = _parsed[0].map()
      dispatch((() => ({
        type: pharmaciesTypes.LOAD_FROM_FILE__SUCCESS,
        payload: { items: _parsed[0], itemsMeta: Array.from(_parsed[0], () => ({ isError: false, isImported: false})) }
      }))())
    })
  }

export const importPharmacies = pharmacies => 
  (dispatch, getState) => {
    dispatch((() => ({ type: pharmaciesTypes.IMPORT_PHARMACIES__REQUEST}))())
    const recursivePromise = (_items, _counter) => {
      if (_items[_counter]) {
        post(
          `${currentApiUrl}/v1/medical-institutions`,
          formatPharmacy(_items[_counter])
        )
        .then(_results => {
          const _itemsMeta = getState().pharmaciesImport.itemsMeta
          _itemsMeta[_counter] = {..._itemsMeta[_counter], isImported: true }
          dispatch((() => ({
            type: pharmaciesTypes.IMPORT_PHARMACIES__IN_PROGRESS,
            payload: { itemsMeta: _itemsMeta, progress: calcPercentage(_items.length, _counter) }
          }))())
          recursivePromise(_items, _counter + 1)
        })
        .catch(err => err.then(_err => {
          const _itemsMeta = getState().pharmaciesImport.itemsMeta
          _itemsMeta[_counter] = {
            ..._itemsMeta[_counter], 
            isError: true, 
            errors: _err.violations.map(_violation => {
              if (_violation.pointer.includes('phones')) {
                return _violation.pointer.split('/')[1]
              }
              return _violation.pointer.split('/').pop()
            })
          }
          dispatch((() => ({
            type: pharmaciesTypes.IMPORT_PHARMACIES__IN_PROGRESS,
            payload: { itemsMeta: _itemsMeta, progress: calcPercentage(_items.length, _counter) }
          }))())
          recursivePromise(_items, _counter + 1)
        }))
      } else {
        dispatch((() => ({ type: pharmaciesTypes.IMPORT_PHARMACIES__SUCCESS }))())
      }
    } 
    recursivePromise(pharmacies, 0)
  }










