import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { TextField, Typography, MenuItem } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment';
import { companiesActions,
  pharmaciesActions,
  pharmaciesGroupsActions,
  usersActions,
  drugsActions,
  courseActions } from "../../actions";
import './style.scss'
import { Loader  } from '../../component'


// TODO: rewrite from ONLY PHARMS FILTERING ON BACKEND to ALL

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
      _isSuggestionsOpened: false,
      _isStartEditing: false
    }
  }
  componentDidMount = () => {
    this.setState({ value: this.props.value })
    // if (this.props.value) {
    //   switch (this.props.data) {
    //     case 'users': this.props.
    //
    //       break;
    //     default:
    //
    //   }
    // }
    switch (this.props.data) {
      case 'drugs': {
        this.props.drugsActions.getDrugs()
        break;
      }
      // case 'companies': {
      //   this.props.companiesActions.getCompanies()
      //   break;
      // }
      case 'pharmaciesGroups': {
        this.props.pharmaciesGroupsActions.getPharmaciesGroups()
        break;
      }
      case 'pharmacies': {
        // this.props.pharmaciesActions.getPharmacies()
        break;
      }
      case 'users': {
        this.props.usersActions.getUsers()
        break;
      }
      case 'courses': {
        this.props.courseActions.getCourses()
        break;
      }
      default: break;

    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data === 'pharmacies') {
      if (prevState.value !== this.state.value) {
        this.state.value !== '' && this.props.pharmaciesActions.getPharmacies({title: this.state.value})
      }
    }
  }
  handleChange = ({target: { value }}) => {
    this.setState({ value, _isStartEditing: true })
  }
  handleClick = item => this.setState(
    state => ({ value: item[this.props.dataKey], _isSuggestionsOpened: false }),
    () => {
      this.props.onSelect(item)
    }
  )

  toDataKey = (id) => {
    console.log(this.props);
    console.log(id);
    return this.props[this.props.data] && this.props[this.props.data].items.find(item => item.self === id)
    ? this.props[this.props.data].items.find(item => item.self === id)[this.props.dataKey]
    : ''
  }

  handleFocus = () => this.setState({ _isSuggestionsOpened: true })
  render () {
    return (
      <div className='searchInput'>
        <TextField
          className='input'
          value={
            (this.props[this.props.data] && this.props[this.props.data].isRequesting && !this.state._isStartEditing)
            ? ''
            : this.state._isStartEditing
              ? this.state.value
              : this.toDataKey(this.props.value)
          }
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          placeholder={this.props[this.props.data] && this.props[this.props.data].isRequesting ? '' : this.props.placeholder}
          fullWidth={true}
          label={this.props.label}
          type="text"
          name="from"
          margin="normal"
          variant="outlined"
          InputProps = {{
            classes: {focused: "focusedColor" },
            startAdornment: this.props[this.props.data] && this.props[this.props.data].isRequesting
            && (
              <InputAdornment position="start">
                <Loader small />
              </InputAdornment>
            ),
          }}
          />
        <div className={`suggestions ${this.state._isSuggestionsOpened ? 'visible' : ''}`}>
          {
            this.state.value !== ''
            && (this.props.data === 'pharmacies'
            ? this.props[this.props.data].isRequesting
              ? <Loader small />
              : this.props[this.props.data].items
                .map((item, key) =>
                <div key={key} className='suggestion' onClick={() => this.handleClick(item)} >
                  <Typography>
                    {item[this.props.dataKey]}
                  </Typography>
                  <Typography className='small'>
                    {item.address}
                  </Typography>
                </div>)
            : this.props[this.props.data] && this.props[this.props.data].items
              .filter(item => item[this.props.dataKey].toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0)
              .map((item, key) =>
              <MenuItem key={key} onClick={() => this.handleClick(item)} >
                  {item[this.props.dataKey]}
              </MenuItem>))
          }
        </div>
      </div>
    )
  }
}

const state2props = state => ({
  users: state.users,
  pharmacies: state.pharmacies,
  companies: state.companies,
  courses: state.courses,
  pharmaciesGroups: state.pharmaciesGroups,
  drugs: state.drugs
})
const dispatch2props = dispatch => ({
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch),
  companiesActions: bindActionCreators(companiesActions, dispatch),
  courseActions: bindActionCreators(courseActions, dispatch),
  pharmaciesGroupsActions: bindActionCreators(pharmaciesGroupsActions, dispatch),
  drugsActions: bindActionCreators(drugsActions, dispatch),
})

const container = connect(state2props, dispatch2props)(SearchInput)

export { container as SearchInput }
