import React from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";

//styles
import "./chip.scss";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  chip: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing.unit,
    maxWidth: "200px"
  }
});

const SimpleChip = ({ classes, icon, deleteIcon, label }) => (
  <Chip
    avatar={<Avatar>{icon}</Avatar>}
    label={label}
    color="secondary"
    className={'chip'}
    deleteIcon={deleteIcon}
  />
);

export default SimpleChip;
