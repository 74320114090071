import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { usersActions, metaActions } from '../actions'
import { Users } from "../pages";

const mapStateToProps = state => ({
    users: state.users,
    meta: state.meta,
})

const mapDispatchToProps = dispatch => ({
    metaActions: bindActionCreators(metaActions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users)
