import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActions, metaActions } from "../actions";
import { Projects } from "../pages";
import { changeStatusProject } from "../actions/project/changeStatusProject";

class AdminProjectsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: true,
    };
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([{ title: "Проекты", to: "/projects" }]);
  };

  render() {
    return <Projects {...this.props} isAdmin={this.state.isAdmin}/>;
  }
}

const mapStateToProps = state => ({
  projects: state.projects
});
const mapDispatchToProps = dispatch => ({
  projectActions: bindActionCreators(projectActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  changeStatusProject: bindActionCreators(changeStatusProject, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProjectsContainer);
