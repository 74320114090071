import { Toolbar, Typography, Tooltip, Tabs, Tab } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton/IconButton";
import ImportIcon from "@material-ui/icons/Publish";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

//styles
import "./Toolbar.scss"
import "../../styles/variables.scss"

class FilesTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      tab: 0
    };
  }

  handleTabClick = value => this.setState(
    () => ({ tab: value }),
    () => this.props.onTabChange(this.state.tab)
  )

  render() {
    const { toggleFilter, withFilter } = this.props,
          { tab } = this.state
    return (
      <Toolbar className={`toolbar ${withFilter ? "withFilter" : null}`}>
        <button className={"button"}>
          <ImportIcon style={{ marginRight: 10, marginLeft: 10 }}/>
          <Typography style={{ whiteSpace: 'nowrap', color: '#00796B' }} variant="body1" id="tableTitle" onClick={this.props.handleOpenModal}>
            Загрузить файл
          </Typography>
        </button>
        <Tabs value={tab} onChange={(_, tab) => this.handleTabClick(tab)}>
          <Tab label="Мои акции" />
          <Tab label="Акции для меня" />
        </Tabs>
        <div className={"actions"}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Filter list"  style={{fontSize:"50px !important"}}>
              <IconButton onClick={toggleFilter} aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="More action">
              <IconButton aria-label="More action">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

export default FilesTableToolbar;
