import React, { Component } from "react";
import SimpleChip from "../../component/chip/chip";
import Healing from "@material-ui/icons/Healing";
import BestSearchInput from "../../component/inputs/bestSearchInput2";
// import DrugsService from "../state/api/Drugs/DrugsService";
import { Field } from "react-final-form";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import ApiService from "../../api/apiService";
import { DRUGS } from "../../constants/entities";

export class DrugsSupply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.input.value.selected || [],
      countValue: this.props.input.value.countValue || 0
    };
    this.onChange = _.debounce(this.onChange, 150, { maxWait: 300 });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    return prevState !== this.state ? this.onChange(this.state) : null;
  }

  onChange = state => {
    let {
      input: { onChange }
    } = this.props;
    onChange(this.state);
  };

  handleChangeSearch = value => {
    this.setState(state => ({ selected: value }));
  };

  handleChangeCount = e => {
    let value = e.target.value;

    this.setState(state => ({ countValue: value }));
  };
  handleRemove = id => {
    const { selected } = this.state;
    let updater = state => ({
      selected: selected.filter(item => id !== item.id)
    });
    this.setState(updater);
  };

  render() {
    //
    let { selected, countValue } = this.state;
    return (
      <div className="drugs">
        <div
          className="row"
          style={{ flexGrow: 1, display: "flex", flexDirection: "row" }}
        >
          <SimpleChip
            icon={<Healing />}
            deleteIcom={<Healing />}
            label={"Выбран тип отчета: Лекарства"}
          />
        </div>
        <Field
          component={BestSearchInput}
          onChange={this.handleChangeSearch}
          name={`${name}.assignedTo`}
          className="field"
          label={"Добавить лекарства"}
          api={new ApiService(DRUGS, "title").search}
          InputLabelProps={{ shrink: true }}
          suggestionChipName={"name"}
          // selecter={true}
          // variant={"outlined"}
        />
        {selected.map((item, key) => {
          return (
            <div>
              <Chip
                key={key}
                // icon={<Healing />}
                onDelete={() => this.handleRemove(item.id)}
                label={item.render.label}
              />
              <TextField
                type="number"
                value={countValue}
                onChange={e => this.handleChangeCount(e, item.id)}
              />
            </div>
          );
        })}
        <div className="column" />
      </div>
    );
  }
}

//
