import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActions, metaActions } from "../actions";
import { Dashboard } from "../pages";
import { changeStatusProject } from "../actions/project/changeStatusProject";

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([{ title: "Дэшборд", to: "/dashboard" }]);
  };

  render() {
    return <Dashboard {...this.props} />;
  }
}

const mapStateToProps = state => ({
  projects: state.projects
});
const mapDispatchToProps = dispatch => ({
  projectActions: bindActionCreators(projectActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  changeStatusProject: bindActionCreators(changeStatusProject, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
