import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  profileActions,
  metaActions,
  positionsActions,
  internalCompaniesActions,
  modalActions, pharmaciesActions, workPlacesActions,
} from "../actions";
import Profile from "../pages/Users/Profile";

class UserContainer extends Component {
  componentDidMount = () => {
    const {
      metaActions: { updateNavi },
      internalCompaniesActions: { getCompanies },
        profileActions: { getProfile },
      match: {
        params: { userId }
      }
    } = this.props;

    getCompanies();
    getProfile(userId);
    updateNavi([
      { title: "Сотрудники", to: "/users" },
      { title: "Сотрудник", to: `${userId}`, isRequesting: true }
    ]);
  };

  render() {
    return <Profile {...this.props} />;
  }
}

const mapStateToProps = state => ({
  internalCompanies: state.internalCompanies,
  profile: state.profile,
  positions: state.positions,
  isOpenModal: state.modal.isOpenModal,
  pharmacies: state.pharmacies,
  employees: state.workPlaces
});
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  positionsActions: bindActionCreators(positionsActions, dispatch),
  internalCompaniesActions: bindActionCreators(
    internalCompaniesActions,
    dispatch
  ),
  modalActions: bindActionCreators(modalActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  workPlacesActions: bindActionCreators(workPlacesActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContainer);
