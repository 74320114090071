import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import SimpleChip from "../../component/chip/chip";
import PhotoIcon from "@material-ui/icons/Photo";
import TextField from "@material-ui/core/TextField/TextField";
import React, { Fragment } from "react";
import DoneIcon from "@material-ui/icons/Done";
import ListItem from "@material-ui/core/ListItem";

export let SubtaskPhotos = ({
  input: { name, onChange, value, ...restInput },
  // meta,
  // label,
  // classes,
  // placeholder,
  ...rest
}) => {
  return (
    <Fragment>
      <SimpleChip
        icon={<PhotoIcon />}
        deleteIcom={<DoneIcon />}
        label={"Выбран тип отчета: Фотографии"}
      />
      <TextField
        type={"number"}
        name={`minimumAmount`}
        label={"Введите количество фотографий в отчете"}
        onChange={onChange}
        value={value}
        style={{ margin: "0 0 0 20px" }}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
    </Fragment>
  );
};
