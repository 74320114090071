import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import MessageIcon from "@material-ui/icons/Message";
import './style.scss'

class Message extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'message'
    }
  }
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Сообщение</Typography>
        <div className='container'>
          <div className='icon'>
            <MessageIcon color='primary'/>
          </div>
          <div className='content'>
          </div>
        </div>
      </div>
    )
  }
}

export default Message
