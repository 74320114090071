import React, { Component } from 'react'
import { SearchInput } from '../../inputs'
import { USERS } from "../../../constants/entities"
import { Api } from '../../../api' // todo: move it to actions
import { Button } from '@material-ui/core'

import './EditProject.scss'

class EditProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: ''
    }
  }
  handleUserSelect = users => {
    users[0] && this.setState({ user: users[0].item.id })
  }
  handleProjectsSave = () => {
    this.props.onSubmit({ id: this.state.user })
  }
  render() {
    return this.props.opened ? (
      <div className='edit_modal_wrapper' onClick={this.props.onClose}>
        <div className='modal' onClick={e => e.stopPropagation()}>
          <h6>Редактирование проектов:</h6>
          <div className={'projects'}>
            {
              this.props.projects.map((project, index) => <div key={index}>{index + 1}.{project}</div>)
            }
          </div>
          <p>Медицинский представитель</p>
          <SearchInput api={new Api(USERS, "query").search} onChange={this.handleUserSelect} />
          <Button onClick={this.handleProjectsSave}>Сохранить</Button>
        </div>
      </div>
    ) : null
  }
}

export default EditProject
