import React, { Component, Fragment } from "react";
import { axios } from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@material-ui/icons/Done"
import apiService from "../../api/apiService";

export const isFileType = (
  {
    input: { name, onChange, value, ...restInput },
    meta,
    label,
    classes,
    placeholder,
    ...rest
  } = this.props
) => {
  let setData = file => {
    return new apiService(
      "static-files",
    ).postFile(file, "contract_document")
  };

  let selectFile = async e => {
    let file = e.target.files[0];
    let newFileID = await setData(file);
    onChange(newFileID.id);
  };

  let errorLabel =
    meta.error && meta.touched ? `⚠️- Проверьте, пожалуйста, поле ` : label;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <Fragment>
      <input
        type="file"
        id="contained-button-file"
        style={{ display: "none" }}
        onChange={e => selectFile(e)}
      />

      <TextField
        {...rest}
        name={name}
        placeholder={placeholder}
        label={errorLabel}
        error={showError}
        inputProps={restInput}
        value={value}
        classes={{
          inputAdornedStart: { paddingLeft: "20px !important" }
        }}
        disabled
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <label htmlFor="contained-button-file">
                <Button color="primary" variant="contained" component="span">
                  {value ? "Загружен" : "Загрузить"}
                  {value ? <DoneIcon/> : <CloudUploadIcon />}
                </Button>
              </label>
            </InputAdornment>
          )
        }}
      />
    </Fragment>
  );
};
{
  /*<fieldset*/
}
{
  /*style={{*/
}
{
  /*height: "30px",*/
}
{
  /*borderRadius: "4px",*/
}
{
  /*borderWidth: "0.5px",*/
}
{
  /*// marginLeft: "15px",*/
}
{
  /*marginTop: "-6.8px"*/
}
{
  /*}}*/
}
{
  /*>*/
}
{
  /*<legend*/
}
{
  /*style={{*/
}
{
  /*color: "rgba(0, 0, 0, 0.54)",*/
}
{
  /*padding: "0",*/
}
{
  /*fontSize: "0.8rem",*/
}
{
  /*fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',*/
}
{
  /*lineHeight: "1"*/
}
{
  /*}}*/
}
{
  /*>*/
}
{
  /*Файл договора*/
}
{
  /*</legend>*/
}

{
  /*<input type="file" onChange={(e , {onChange} = input ) => selectFile(e, onChange)} />*/
}
{
  /*</fieldset>*/
}
