import React, { Component } from 'react'
import { Typography } from '@material-ui/core'
import StreetViewIcon from "@material-ui/icons/Streetview";
import './style.scss'

class Location extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'geolocation'
    }
  }
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Проверить геолокацию</Typography>
        <div className='container'>
          <div className='icon'>
            <StreetViewIcon color='primary'/>
          </div>
          <div className='content'></div>
        </div>
      </div>
    )
  }
}

export default Location
