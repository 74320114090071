import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'
import { getShortName } from '../../utils/nameTransform'
import './File.scss'

const FilePreview = props => (
  <div className='file-preview'>
    <img className={`icon ${props.type}`} src={props.type === 'image' ? props.file.url : null} />
    <div className='info'>
      <div className='meta'>
        <a href={props.file.url} target='_blank'><Typography className='title'>{props.title}</Typography></a>
        <Typography className='meta'>{new Date(props.uploadedAt).toLocaleDateString()}</Typography>
      </div>
      <div className='author'>
        <Typography className='name'>{props.owner.fullName}</Typography>
        <Typography className='description'>{props.description}</Typography>
      </div>
      <div className='shares'>
        {
          props.users.map((user, index) =>
            <div key={index} className='sharePreview' onClick={() => props.onShareClick(props.shares.items[index].id)}>
              <div className='shared'>
                <div className='avatar'></div>
                <Typography className='name'>{getShortName(user.fullName)}</Typography>
              </div>
              <div className={`commentsCount ${props.shares.items[index].comments.items.length === 0 && 'no-comments'}`}>
                {
                  props.shares.items[index].comments.items.length > 0 &&
                  <Fragment>
                    <div className='commentIcon'></div>
                    <Typography>{ props.shares.items[index].comments.items.length }</Typography>
                  </Fragment>
                }
              </div>
            </div>)
        }
      </div>
    </div>
  </div>
)

export default FilePreview
