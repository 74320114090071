import React, { Component, Fragment } from "react";
import { Typography } from "@material-ui/core";
import { UserList } from "./Drugstore/UserList";
import { Link } from "../static/imgs";
import "./UserStatistics.scss";
import {copyTextToClipboard} from "../utils";

class UserStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedLink: null
    };
  }

  componentDidMount() {
    const { metaActions: { updateNavi }, pharmacy: { item: { title } }, user: { item: { fullName } } } = this.props;
    updateNavi([
      { title: 'Мои аптеки', to: `/drugstore`, isRequesting: false },
      { title: title, to: `/drugstore/${this.props.pharmId}`, isRequesting: false },
      { title: fullName, to: `/drugstore/${this.props.pharmId}/user/${this.props.userId}`, isRequesting: false },
    ])
  }
  handleCopyLinkClick = (invite, index) => {
    this.setState({ copiedLink: index })
    copyTextToClipboard(`${process.env.BASE_TRAINING_URL}/invites?id=${invite}&type=with_registration`)
  }



  render() {
    const _user = this.props.user.item,
          { copiedLink } = this.state

    return  (
        <div className='user_statistics'>

          <div className='courseInfo__row'>
            <div className='info__block'>
              <Typography>Общее число пройденных курсов</Typography>
              <Typography className='value'>{_user.successfullyFinishedCourseAccessesAmount}</Typography>
            </div>
            <div className='info__block'>
              <Typography>Число пройденных курсов с первого раза</Typography>
              <Typography className='value'>{_user.successfullyFinishedWithFirstTryCourseAccessesAmount}</Typography>
            </div>
            <div className='info__block'>
              <Typography>Количество проваленных курсов</Typography>
              <Typography className='value'>{_user.unsuccessfullyFinishedCourseAccessesAmount} </Typography>
            </div>
          </div>

          {
            _user.courseAccesses.items.length > 0
            ? _user.courseAccesses.items.filter(_access => _access.invite).map((_access, _key) => console.log(_access) || (
              <div className='course__row' key={_key}>
                <div className='block link' onClick={() => this.handleCopyLinkClick(_access.invite.split('/').pop(), _key)}>
                  <div className='linkIcon'></div>
                  <div className='withIcon'>
                    <Typography>{ _access.invite.split('/').pop().cut(6) }</Typography>
                    <div className={`copyIcon${copiedLink === _key ? ' copied' : ''}`}></div>
                  </div>
                </div>
                <Typography>{_access.course.title}</Typography>
                <Typography>Создана {new Date(_access.assignedAt).toLocaleDateString('ru-RU', {year: 'numeric', month: 'long', day: 'numeric'})}</Typography>
                <div className='block'>
                  <a target="_blank" href={_access.course.certificate && _access.course.certificate.url}>
                    <div onClick={() => null} className="certificate" />
                  </a>
                </div>

              </div>))
            : <Typography>Нет назначенных курсов</Typography>
          }
        </div>
    );
  }
}

export default UserStatistics