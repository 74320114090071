import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import getMuiTheme from "material-ui/styles/getMuiTheme";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/es/styles";

const styles = theme => ({
  root: {
    width: "50%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: theme.palette.secondary.main
    }
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: theme.palette.secondary.main
    }
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100]
    }
  },
  connectorLine: {
    transition: theme.transitions.create("border-color")
  }
});

function getSteps() {
  return ["", ""];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "Select campaign settings...";
    case 1:
      return "What is an ad group anyways?";
    // case 2:
    //   return "This is the bit I really care about!";
    default:
      return "Unknown step";
  }
}

class StepperModalCreateProject extends React.Component {
  render() {
    const { classes, activeStep } = this.props;
    const steps = getSteps();
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine
        }}
      />
    );
    const muiTheme = createMuiTheme({
      palette: {
        primary: {
          light: "#339388",
          main: "#00796B",
          dark: "#00544a",
          contrastText: "#fff"
        }
      }
    });
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} connector={connector}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel style={{ color: "#00796B" }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(StepperModalCreateProject);
