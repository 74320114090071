import React, { Component } from "react";
import { Paper, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import SupportTableToolbar from "../component/toolbar/SupportTableToolbar";
import { Loader, List } from "../component";
import './Support.scss'
import '../component/comments/Comment.scss'
import { tickets as ticketsConstants } from "../constants";

@withRouter
class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      newTicket: {
        title: '',
        body: ''
      }
    };
  }
  componentDidMount = () => {
    const { ticketsActions: { getTickets }, auth: { user: { roles } } } = this.props
    const isAdmin = roles.includes('ROLE_MANAGER') || roles.includes('ROLE_DEVELOPER')

    if (isAdmin) {
      getTickets()
    } else {
      getTickets({ author: `/v1/users/${localStorage.getItem('user_id')}` })
    }
  }
  handleTicketClick = ticket => {
    this.props.history.push(`/support/${ticket.id}`)
  }
  handleNewTicketClick = () => this.setState({ isNewModalOpened: true })
  handleCloseNewModal = () => this.setState({ isNewModalOpened: false })
  handleCreateTicketClick = () => {
      this.setState({
          isNewModalOpened: false
      }, () => this.props.ticketsActions.createTicket(this.state.newTicket))
  }
  render() {
    const { tickets, auth: { user: { roles } } } = this.props

    const isAdmin = roles.includes('ROLE_MANAGER') || roles.includes('ROLE_DEVELOPER')

    return tickets.isRequesting ? <Loader /> : (
      <Paper className="">
        <SupportTableToolbar isAdmin={isAdmin} onNewTicketClick={this.handleNewTicketClick}/>
        <div className='content__wrapper'>
          {
            this.state.isNewModalOpened && (
              <div className="modal-wrapper" onClick={this.handleCloseNewModal}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                  <div className='close' onClick={this.handleCloseNewModal}></div>
                  <Typography>Новое обращение</Typography>
                  <input value={this.state.title} onChange={({ target: { value }}) => this.setState(state => ({ newTicket: { ...state.newTicket, title: value} }))} placeholder='Тема обращения' /><br />
                  <textarea lines={7} value={this.state.body} onChange={({ target: { value }}) => this.setState(state => ({ newTicket: { ...state.newTicket, body: value} }))} placeholder='Опишите проблему' /><br />
                  <button className={tickets.isRequestingCreation ? 'loading' : ''} onClick={this.handleCreateTicketClick}>Создать</button>
                </div>
              </div>
            )
          }
          <List
            keys={['theme', 'status', 'date', 'comments']}
            headers={['Тема запроса', 'Статус', 'Дата создания обращения', 'Комментарии']}
            onItemClick={this.handleTicketClick}
            items={tickets.items.map((_ticket, _index) => ({
              theme: _ticket.title,
              status: ticketsConstants.translateStatus[_ticket.status.toUpperCase()],
              date: new Date(_ticket.createdAt).toLocaleDateString('ru-RU', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
              comments: _ticket.comments.items.length,
              index: _index,
              id: _ticket.id
            }))}
            />
        </div>
      </Paper>
    );
  }
}

export default Support;
