import React, { Component } from "react";
import {Typography, IconButton, CircularProgress, withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { AttachFile, SendIcon, MenuIcon } from "../../static/imgs";
import "./Ticket.scss";
import "../../component/comments/Comment.scss";
import { tickets } from "../../constants";
import ModalTicketChangeStatus from "./ModalTicketChangeStatus";
import TicketComment from "../../component/comments/TicketComment";
import FullScreenImage from "../../component/image/FullScreenImage";
import Attachments from "../../component/files/Attachments";

@withRouter
class Ticket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      files: [],
      isOpenModal: false,
      _isOpen: false,
      isPreviewOpened: false,
      previewImage: null
    };
  }
  componentDidMount = () => {
    const lastChatElem = document.querySelector(".lastChatElem");
    lastChatElem.scrollIntoView({ block: "center", behavior: "smooth" });
  };
  componentWillUpdate = nextProps => {
    if (
      nextProps.ticket.isRequestingTicketStatus === false &&
      this.props.ticket.isRequestingTicketStatus === true
    ) {
      this.setState({ isOpenModal: false });
    }
  };
  handleCommentChange = ({ target: { value } }) =>
    this.setState({ text: value });

  handleCommentSend = () => {
    const {
      ticketsActions: { sendMessageForTicket },
      ticket
    } = this.props;
    const { text, files } = this.state;
    text !== "" &&
      sendMessageForTicket({ text: text, ticket: ticket.item.self }, files);
  };

  handleFileChange = ({ target: { files: attachments } }) => {
    const { files } = this.state;
    if (files.length + attachments.length > 10)
      alert("Можно прикрепить максимум 10 вложений");
    else this.setState({ files: [...files, ...attachments] });
  };

  handleSubmitChangeStatus = (status, message) => {
    const {
      ticketsActions: { changeTicketStatus }
    } = this.props;
    changeTicketStatus(status, message, this.props.ticket.item.self);
  };
  openModal = () => {
    this.setState({
      isOpenModal: true
    });
  };
  closeModal = () => {
    this.setState({
      isOpenModal: false
    });
  };
  handleImageClick = image =>
    this.setState({
      isPreviewOpened: true,
      previewImage: image
    });
  handleImageClose = () => this.setState({ isPreviewOpened: false });

  handleDeleteFile = index => {
    this.setState(({ files }) => ({
      files: files.filter((_, fileIndex) => fileIndex !== index)
    }));
  };

  render() {
    const { ticket, user } = this.props;
    const { isOpenModal, files, isPreviewOpened, previewImage } = this.state;
    const {
      item: {
        createdAt,
        title,
        status,
        comments: { items: _comments }
      },
      isRequestingTicketStatus,
      isRequestingTicketComment
    } = ticket;
    return !ticket ? null : (
      <div className={`ticket opened`}>
        <div className={`ticket__wrapper opened`}>
          <div className="ticketInfo">
            <div>
              <Typography variant="body2">
                Тема сообщения: <b>{title}</b>
              </Typography>
              <Typography variant="body2">
                {new Date(createdAt).toLocaleDateString("ru-RU", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                })}
              </Typography>
            </div>
            <Typography style={{ marginLeft: "auto" }} variant="body1">
              Статус:
              <span className="ticketInfo__status" onClick={this.openModal}>
                {tickets.translateStatus(status).toLowerCase()}
              </span>
            </Typography>
            <MenuIcon style={{ marginLeft: "20px" }} />
          </div>
          <div className="cards__wrapper">
            {_comments.map(_comment => (
              <TicketComment
                key={_comment.id}
                comment={_comment}
                user={user}
                onImageClick={this.handleImageClick}
              />
            ))}
            <div className="lastChatElem" />
          </div>
          <div
            className="enterComment"
            onKeyDown={e => e.key === "Enter" && this.handleCommentSend()}
          >
            <input
              id="outlined-name"
              label="Добавить сообщение в чат"
              autoFocus={true}
              className={"message-input"}
              onChange={this.handleCommentChange}
              value={this.state.text}
              margin="normal"
              variant="outlined"
              placeholder="Добавить комментарий"
            />
            <input
              onChange={this.handleFileChange}
              className="fileInput"
              type="file"
              accept="image/*"
              id="file"
              multiple
            />
            <label htmlFor="file" className={files.length && "withFile"}>
              <AttachFile />
            </label>
            <div className={`sendIcon ${isRequestingTicketComment ? 'sendIcon_progress' : ''}`}>
              {isRequestingTicketComment ? (
                <CircularProgress size={25} color="secondary" />
              ) : (
                <IconButton onClick={this.handleCommentSend}>
                  <SendIcon />
                </IconButton>
              )}
            </div>
            <Attachments attachments={files} onDelete={this.handleDeleteFile} />
          </div>
        </div>
        <ModalTicketChangeStatus
          isOpenModal={isOpenModal}
          status={status}
          onSubmitChangeStatus={this.handleSubmitChangeStatus}
          closeModal={this.closeModal}
          isRequesting={isRequestingTicketStatus}
        />
        <FullScreenImage
          open={isPreviewOpened}
          image={previewImage}
          onClose={this.handleImageClose}
        />
      </div>
    );
  }
}

export default Ticket;
