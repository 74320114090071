import React, { Component, Fragment } from "react";
import {
  Redirect,
  Route,
  // Link
} from "react-router-dom";


import {
    AdminContractsContainer,
    AdminProjectsContainer,
    ContractsContainer,
    ContractContainer,
    CreateManufacturerContainer,
    EditManufacturerContainer,
    ProjectsContainer,
    DashboardContainer,
    ProjectContainer,
    PharmaciesContainer,
    PharmacyContainer,
    DrugstoreContainer,
    MyPharmsContainer,
    DrugsContainer,
    ManufacturersContainer,
    MaterialsContainer,
    SupportContainer,
    TaskContainer,
    FilesContainer,
    UsersContainer,
    ProfileContainer,
    CurrentManufacturerContainer,
    TicketContainer,
    UserStatisticsContainer,
    ImportPharmaciesContainer,
    UserContainer
} from '../containers'


export default class ComponentRouter extends Component {
  render() {
    return (
      <Fragment>
        <Route exact path="/admin_contracts" component={AdminContractsContainer} />
        <Route exact path="/admin_projects" component={AdminProjectsContainer} />
        <Route exact path="/contracts" component={ContractsContainer} />
        <Route exact path="/contracts/:id" component={ContractContainer} />
        <Route exact path="/create-manufacturer" component={CreateManufacturerContainer} />
        <Route exact path="/create_pharmacy" component={PharmacyContainer} />
        <Route exact path="/dashboard" component={DashboardContainer} />
        <Route exact path="/drugs" component={DrugsContainer} />
        <Route exact path="/drugstore" component={MyPharmsContainer} />
        <Route exact path="/drugstore/:pharmId" component={DrugstoreContainer} />
        <Route exact path="/drugstore/:pharmId/courses/:courseId" component={DrugstoreContainer} />
        <Route exact path="/drugstore/:pharmId/users/:userId" component={UserStatisticsContainer} />
        <Route exact path="/files" component={FilesContainer} />
        <Route exact path="/files/:shareId" component={FilesContainer} />
        <Route exact path="/files/upload" component={FilesContainer} />
        <Route exact path="/import_from_excel" component={ImportPharmaciesContainer} />
        <Route exact path="/manufacturers" component={ManufacturersContainer} />
        <Route exact path="/manufacturers/:manufacturerId" component={EditManufacturerContainer} />
        <Route exact path="/materials" component={MaterialsContainer} />
        <Route exact path="/materials/:id" component={MaterialsContainer} />
        <Route exact path="/pharmacies" component={PharmaciesContainer} />
        <Route exact path="/pharmacies/:pharmId" component={PharmacyContainer} />
        <Route exact path="/profile" component={ProfileContainer} />
        <Route exact path="/profile/documents" component={ProfileContainer} />
        <Route exact path="/profile/new-document" component={ProfileContainer} />
        <Route exact path="/profile/documents/:documentId" component={ProfileContainer} />
        <Route exact path="/projects" component={ProjectsContainer} />
        <Route exact path="/projects/:id" component={ProjectContainer} />
        <Route exact path="/projects/:id/new-task" component={TaskContainer} />
        <Route exact path="/projects/:id/tasks/:taskId" component={TaskContainer} />
        <Route exact path="/support" component={SupportContainer} />
        <Route exact path="/profile/manufacturers" component={ProfileContainer} />
        <Route exact path="/profile/manufacturers/:id" component={CurrentManufacturerContainer} />
        <Route exact path="/support/:ticketId" component={TicketContainer} />
        <Route exact path="/upload" component={FilesContainer} />
        <Route exact path="/users" component={UsersContainer} />
        <Route exact path="/users/:userId" component={UserContainer} />
        {/*<Route exact path="/contract/create" component={Contract} />
        <Route exact path="/contract/create/project" component={Contract} />
        <Route exact path="/project/edit/:id" component={ProjectLayout} />
        <Route exact path="/dashboard/projects" component={ProjectLayout} />*/}
      </Fragment>
    );
  }
}

//       /auth ----------------- авторизация
//
//       /contracts ------------ список контрактов
//       /contracts/:id -------- редактирование контракта
//       /contracts/new -------- создание контракта
//
//       /projects ------------- дэшборд проектов
//       /projects/:id --------- редактирование проекта
//       /projects/new --------- создание проекта
