import { usersTypes, internalCompaniesTypes } from '../types'

const initialState = {
  item: {},
  isRequesting: true,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case usersTypes.GET_USER__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case usersTypes.GET_USER__SUCCESS: return {
      ...state,
      isRequesting: false,
      item: action.payload.user
    }
    case internalCompaniesTypes.CREATE_EMPLOYEE__SUCCESS: return {
      ...state,
      item: {
        ...state.item,
        internalCompanyEmployment: action.payload.employment
      }
    }
    case internalCompaniesTypes.DELETE_EMPLOYEE__SUCCESS: return {
      ...state,
      item: {
        ...state.item,
        internalCompanyEmployment: null
      }
    }
    default:
      return state
  }
}
