import { filesTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case filesTypes.GET_FILES__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case filesTypes.GET_FILES__SUCCESS: return {
      ...state,
      isRequesting: false,
      isError: false,
      items: action.payload.items
    }
    case filesTypes.GET_FILES__FAILURE: return {
      ...state,
      isError: true,
      isRequesting: false
    }
    default:
      return state
  }
}
