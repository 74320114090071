import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { projectActions, metaActions, pharmaciesActions, tasksActions } from '../actions'
import { Project } from '../pages'
import { changeStatusProject } from "../actions/project/changeStatusProject";
import {toggleModalCreateTask} from "../actions/modal/toggleModal";

class ProjectContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {id: null}
  }

  componentDidMount = () => {
    const { metaActions: { updateNavi }, match: { params: { id } } } = this.props
    this.setState({id})
    updateNavi([{ title: 'Проекты', to: '/projects' }, { title: 'Имя проекта', to: `/projects/${id}` }])
  }

  render() {
    return this.state.id !== null && <Project {...this.props} id={this.state.id} />
  }
}

const mapStateToProps = state => ({
  project: state.project,
  task: state.task,
  meta: state.meta,
})
const mapDispatchToProps = dispatch => ({
  projectActions: bindActionCreators(projectActions, dispatch),
  // pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  tasksActions: bindActionCreators(tasksActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  toggleModalCreateTask: bindActionCreators(toggleModalCreateTask , dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectContainer)
