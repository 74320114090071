import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { modalContract } from "../../constants/form";

import classes from "./ModalSourceEditor.scss";
import FormCreate from "../form/AltFormCreate";
import FieldsCreate from "../form/FieldsCreate";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { submitContractsActions } from "../../actions/modal/createContract";
import Paper from "@material-ui/core/es/Paper/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { toggleModalCreateContract } from "../../actions/modal/toggleModal";
import Typography from "@material-ui/core/Typography";
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, OutlinedInput } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#D72638' }
  }
});

class ModalSourceEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      cover: "",
    };
  }

  handleFileChange = ({ target: { files } }) => this.setState({ cover: files[0] })
  loadPreview = () => {
    return (this.state.cover !== '' && this.state.cover != undefined) ? URL.createObjectURL(this.state.cover) : false
  }

  render() {
    let { isSubmiting, isOpenModalCreateContract } = this.props;



    return (
      <Modal className="modal_wrapper" open={this.props.opened}>
        <div className="modal_sourceEditor">
          <Typography variant="h6" className='title'>{`Редактирование источника`}</Typography>
          <div className='inputsRow'>
            <div className='inputColumn'>
              <TextField
                id="outlined-name"
                label="Ссылка на страницу в источнике"
                // className={classes.textField}
                // value={this.state.name}
                // onChange={this.handleChange('name')}
                margin="normal"
                variant="outlined"
                style={{width: '100%'}}
                InputProps={{ classes: {root: 'inputBorderColor'} }}
              />
              <TextField
                id="outlined-name"
                label="Ссылка на страницу в источнике"
                // className={classes.textField}
                // value={this.state.name}
                // onChange={this.handleChange('name')}
                margin="normal"
                variant="outlined"
                style={{width: '100%'}}
                InputProps={{ classes: {focused: 'inputBorderColor'} }}
              />
            </div>
            <div className='photoInput__wrapper'>
              {
                this.state.cover === '' || this.state.cover === undefined
                ? <div>
                        <input
                          className="photoInput"
                          type="file"
                          name="cover"
                          onChange={this.handleFileChange}
                          accept="image/*"
                          id='fileModal'
                        />
                        <label className='emptyField' htmlFor="fileModal">
                          <div className='icon photoInputIcon'></div>
                          <p>*</p>
                        </label>
                  </div>
                : <div>
                        <input
                          className="photoInput"
                          type="file"
                          name="cover"
                          onChange={this.handleFileChange}
                          accept="image/*"
                          id='fileModal'
                          multiple={true}
                        />
                        {
                          <label  htmlFor="fileModal">
                            <img htmlFor="fileModal" src={this.loadPreview()} />
                          </label>
                        }
                      </div>
              }
            </div>
          </div>
            <Button color="secondary" style={{ whiteSpace: 'nowrap', textTransform: 'none', justifyContent: 'space-around' }}>
              <Typography variant="h5" color='secondary'>+</Typography>Добавить синоним
            </Button>
            <Typography variant="body2">{`Синоним - это альтернативный адрес источника, например http://bbc.com и http://bbc.com.uk`}</Typography>
            <Typography variant="h6">{`Объединить с другим источником`}</Typography>
            <div className='sourceRow'>
              <FormControl style={{width: '100%', padding: '0px'}} variant="outlined">
                <InputLabel style={{padding: '0px'}}>Источник</InputLabel>
                <Select
                  value={this.state.imgSource}
                  onChange={this.handleChange}
                  inputProps={{name: 'imgSource'}}
                  style={{width: '100%'}}
                  input={<OutlinedInput/>}
                >
                    <MenuItem value={'Commonmark.org'}>Commonmark.org</MenuItem>
                    <MenuItem value={'Mocommnark.gor'}>Mocommnark.gor</MenuItem>
                    <MenuItem value={'Kocrammnom.rog'}>Kocrammnom.rog</MenuItem>
                </Select>
              </FormControl>
              <Button variant="outlined" color="secondary">
                Объединить
              </Button>
          </div>
            <div className='buttonRow'>
              <MuiThemeProvider theme={theme}>
                <Button style={{color: '#D72638', borderColor: '#D72638'}} variant="outlined" onClick={this.props.onCloseModal}>
                  Отмена
                </Button>
              </MuiThemeProvider>
              <Button variant="contained" color="secondary" style={{whiteSpace: 'nowrap'}}>
                Далее
              </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitContractsActions,
      toggleModalCreateContract
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    isSubmiting: state.modal.isSubmiting,
    isError: state.modal.isError,
    isOpenModalCreateContract: state.modal.isOpenModalCreateContract
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSourceEditor);
