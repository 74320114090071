import { pharmaciesTypes } from '../types'

const initialState = {
  items: [],
  itemsMeta: [],
  progress: 0,
  isRequesting: false,
  isRequestingImport: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case pharmaciesTypes.LOAD_FROM_FILE__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case pharmaciesTypes.LOAD_FROM_FILE__SUCCESS: return {
      ...state,
      items: action.payload.items,
      itemsMeta: action.payload.itemsMeta,
      isRequesting: false
    }
    case pharmaciesTypes.IMPORT_PHARMACIES__REQUEST: return {
      ...state,
      progress: 0,
      isSuccessfullyImport: false,
      isRequestingImport: true
    }
    case pharmaciesTypes.IMPORT_PHARMACIES__IN_PROGRESS: return {
      ...state,
      itemsMeta: action.payload.itemsMeta,
      progress: action.payload.progress
    }
    case pharmaciesTypes.IMPORT_PHARMACIES__SUCCESS: return {
      ...state,
      progress: 100,
      isRequestingImport: false,
      isSuccessfullyImport: true
    }
    // case pharmaciesTypes.FILTER: return {
    //   ...state,
    //   filter: action.payload.filter
    // }
    default: return state
  }
}
