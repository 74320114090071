import React, { Component, Fragment } from "react";
import "./ManufacturerPage.scss";
import { Typography, Link } from "@material-ui/core";
import { DateIcon, DiplomatV2Icon } from "../../static/imgs"
import { Loader, Course } from '../../component'

class ManufacturerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: 'courses',
        };
    }
    handleChangeTab = (tab) => () => {
        switch (tab) {
          case 'courses':
            this.setState({component: 'courses'})
            break;
        case 'contracts':
            this.setState({component: 'contracts'})
            break;
          default:
            break;
        }
    }
    render() {
        const manufacturer = this.props.currentManufacturer;
        const addedAt = new Date(manufacturer.addedAt)
        return  (
            <Fragment>
                { manufacturer.isRequesting
                ? <Loader/>
                : <div className='manufacturerPage__wrapper'>
                    <div className='manufacturerInfo'>
                        <div className='row'>
                            <DiplomatV2Icon/>
                            <Typography variant="body1">{ manufacturer.title }</Typography>
                            <Typography style={{marginLeft: '10px'}}>(ИНН: { manufacturer && manufacturer.company && manufacturer.company.itn })</Typography>
                        </div>
                        <Typography style={{marginLeft: '40px'}}>{ manufacturer && manufacturer.company && manufacturer.company.name }</Typography>
                        <div className='row'>
                            <DateIcon/>
                            <Typography variant="body1">
                                <font style={{color: '#2632389c'}}>Производитель добавлен </font>
                                { addedAt.toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric' }) }
                            </Typography>
                        </div>
                        <div className='row' style={{marginTop: '30px'}}>
                            <Link href={`mailto:${manufacturer.email}`}
                                  color='secondary'
                                  style={{marginLeft: '40px', fontSize: '18px'}}
                                  >
                                    { manufacturer.email }
                            </Link>
                            <Link href={`tel:${manufacturer.phone}`}
                                  color='secondary'
                                  style={{marginLeft: '24px', fontSize: '18px'}}
                            >
                                    { manufacturer.phone }
                            </Link>
                        </div>
                    </div>
                    <div className='tabs'>
                        <Typography className={this.state.component === 'courses' ? 'currentTab' : ''}
                                    onClick={this.handleChangeTab('courses')}
                        >
                        Курсы
                        </Typography>
                        <Typography className={this.state.component === 'contracts' ? 'currentTab' : ''}
                                    onClick={this.handleChangeTab('contracts')}
                        >
                        Контракты
                        </Typography>
                    </div>
                    <div>{
                        this.state.component === 'courses'
                        ? <div style={{display: 'flex', marginTop: '30px'}}>
                            {manufacturer && manufacturer.manufacturerCourses && manufacturer.manufacturerCourses.items.length > 0
                            ? manufacturer.manufacturerCourses.items.map(item => <Course {...item}/>)
                            : <Typography>Нет доступных курсов</Typography>
                            }
                        </div>

                        : <div>
                            <div className='table__head'>
                                <Typography variant='body1'>Номер контракта</Typography>
                                <Typography variant='body1'>Дата начала</Typography>
                                <Typography variant='body1'>Дата окончания</Typography>
                                <Typography variant='body1'>Название контракта</Typography>
                            </div>
                            {manufacturer.manufacturerContracts.items.map(item =>
                                {
                                    const signDate = new Date(item.signDate)
                                    const expirationDate = new Date(item.expirationDate)
                                    return (
                                    <div className='contract__row'>
                                        <Typography>{item.contractNumber}</Typography>
                                        <Typography>{signDate.toLocaleDateString('ru-RU')}</Typography>
                                        <Typography>{expirationDate.toLocaleDateString('ru-RU')}</Typography>
                                        <Typography>{item.title}</Typography>
                                    </div>)
                                }
                            )}
                        </div>
                        }</div>
                </div>
                }
            </Fragment>
        );
    }
  }

  export default ManufacturerPage;
