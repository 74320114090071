import { lighten } from "@material-ui/core/styles/colorManipulator";
import classNames from "classnames";
import { IconButton, Tabs, Tab, Tooltip, Typography, Toolbar } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

//styles
import "./Toolbar.scss"


class SupportTableToolbar extends React.Component {
  render() {
    const {
        handleOpenModal,
        onNewTicketClick,
      withFilter
    } = this.props;

    return (
      <Toolbar className={`toolbar ${withFilter ? "withFilter" : null}`}>
        {
            <button className={"button"} onClick={onNewTicketClick}>
              <AddIcon />
              <Typography style={{ width: 170 }} variant="body1" id="tableTitle" onClick={handleOpenModal}>
                Новое обращение
              </Typography>
            </button>
        }
        <div className={"spacer"} />
        <Tabs
            style={{flexGrow: 1, marginLeft: '100px'}}
        >
            <Tab label="Актуальные" />
            <Tab label="Архив" />
        </Tabs>
      </Toolbar>
    );
  }
}

export default SupportTableToolbar;
