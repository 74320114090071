import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { manufacturersActions, metaActions } from "../actions";
import { Manufacturer, Manufacturers } from "../pages";

class ManufacturersContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const {
      manufacturersActions: { getManufacturers },
      metaActions: { updateNavi }
    } = this.props;

    updateNavi([{ title: "Производители", to: "/manufacturers" }]);
    getManufacturers();
  };

  render() {
    return <Manufacturers {...this.props} />;
  }
}

const mapStateToProps = state => ({
  manufacturers: state.manufacturers,
  meta: state.meta
});

const mapDispatchToProps = dispatch => ({
  manufacturersActions: bindActionCreators(manufacturersActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManufacturersContainer);
