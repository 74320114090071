import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import ContractTableToolbar from "../../toolbar/ContractTableToolbar";
import EnhancedTableHead from "../head/EnhancedTableHead";
import { dataTable } from "../../../constants/string";
import { createData } from "../../../utils/sort";
import EnhancedTableBody from "../body/EnhancedTableBody";
import FilterSideBar from "../../sidebar/filter/FilterSideBar";
import moment from "moment";

import "./Table.scss"

class EnhancedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50
    };
  }

  render() {
    const { toggleFilter, sidebarOpen, data, onItemClick } = this.props;
    const menuToggle = false
    const { rowsPerPage, page } = this.state;

    const contracts = data.map(contract => {
      return {
        contractNumber: contract.contractNumber,
        expirationDateFormat: moment(contract.expirationDate).format("DD MMMM YY"),
        id: contract.id,
        signDateFormat: moment(contract.signDate).format("DD MMMM YY"),
        title: contract.title,
      };
    });

    return (
      <Paper
        className={`tablePaperWrapper ${menuToggle ? 'opened' : null}`}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={'tableWrapper'} style={{ flexGrow: 1 }}>
            <Table className={'table'} aria-labelledby="tableTitle">
              <EnhancedTableHead contractsData={contracts} rowCount={data.length} />
              <EnhancedTableBody
              page={page}
              rowsPerPage={rowsPerPage}
              contractsData={contracts}
              onClick={onItemClick}
              />
            </Table>
          </div>
        </div>
        {/*<TablePagination*/}
        {/*component="div"*/}
        {/*count={data.length - 1}*/}
        {/*labelDisplayedRows={labelDisplayedRows}*/}
        {/*labelRowsPerPage={"Строк на страницу:"}*/}
        {/*rowsPerPage={rowsPerPage}*/}
        {/*rowsPerPageOptions={[50, 100]}*/}
        {/*page={page}*/}
        {/*backIconButtonProps={{ "aria-label": "Previous Page" }}*/}
        {/*nextIconButtonProps={{ "aria-label": "Next Page" }}*/}
        {/*onChangePage={this.handleChangePage}*/}
        {/*onChangeRowsPerPage={this.handleChangeRowsPerPage}*/}
        {/*/>*/}
      </Paper>
    );
  }
}

export default EnhancedTable;
