import React, {Component} from "react";
import { Button, Typography } from "@material-ui/core";
import './style.scss'
import * as PropTypes from "prop-types";

class Filter extends Component {
    state = {
        isOpen: false
    }

    toggleButton = () => {
        this.setState(({ isOpen }) => ({
            isOpen: !isOpen
        }))
    }

    render() {
        const { isOpen } = this.state;
        const {values, value, onClick} = this.props;
        return (
            <div className={`filters ${isOpen ? "opened" : ""} ${value ? "active" : ""}`}>
                <Button onClick={this.toggleButton}>
                    <i/>
                    Фильтры
                </Button>
                <div className="list">
                    {Object.keys(values).map((_filter, _index) => (
                        <div
                            key={_index}
                            className={value === _filter ? "selected" : ""}
                            onClick={value === _filter ? () => onClick() : () => onClick(_filter)}
                        >
                            <div className={_filter}>&nbsp;</div>
                            <Typography>{values[_filter]}</Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

Filter.propTypes = {
    isOpen: PropTypes.any,
    values: PropTypes.any,
    value: PropTypes.any,
    onClick: PropTypes.any,
    toggleButton: PropTypes.any,
    isActive: PropTypes.any
}

export default Filter;
