import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ShopIcon from "@material-ui/icons/Shop";
import CreatedIcon from "@material-ui/icons/OfflineBolt";
import ApprovedIcon from "@material-ui/icons/OfflinePin";
import StartedIcon from "@material-ui/icons/PlayCircleFilled";
import ProblemIcon from "@material-ui/icons/Error";
import FailedIcon from "@material-ui/icons/Cancel";
import CanceledIcon from "@material-ui/icons/Remove";
import CompletedIcon from "@material-ui/icons/CheckCircle";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import moment from "moment";
import * as projectStatuses from "../../constants/projectStatuses";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Checkbox } from "@material-ui/core";
import "./card.scss";
import Select from "@material-ui/core/Select";

/**
 * @type {{
 * manager: string,
 * contract: string,
 * warning: string,
 * availability:
 *              {
 *              till: string,
 *              from: string
 *              },
 * id: string,
 * title: string,
 * status: string}}
 */

let flexRow = {
  display: "flex",
  flexDirection: "row"
};

const ProjectStatus = ({ status, handleClick }) => {
  let buttonClass = classNames("button", status);
  switch (status) {
    case "created":
      return (
        <div style={flexRow} className={buttonClass}>
          <CreatedIcon />
          <div className="label">{projectStatuses[status]}</div>
        </div>
      );
    case "approved":
      return (
        <div style={flexRow} className={buttonClass}>
          <ApprovedIcon />
          <div className="label">{projectStatuses[status]}</div>
        </div>
      );
    case "started":
      return (
        <div style={flexRow} className={buttonClass}>
          <StartedIcon />
          <div className="label">{projectStatuses[status]}</div>
        </div>
      );
    case "problem":
      return (
        <div style={flexRow} className={buttonClass}>
          <ProblemIcon />
          <div className="label">{projectStatuses[status]}</div>
        </div>
      );
    case "failed":
      return (
        <div style={flexRow} className={buttonClass}>
          <FailedIcon />
          <div className="label">Провален</div>
        </div>
      );
    case "canceled":
      return (
        <div style={flexRow} className={buttonClass}>
          <CanceledIcon />
          <div className="label">{projectStatuses[status]}</div>
        </div>
      );
    case "completed":
      return (
        <div style={flexRow} className={buttonClass}>
          <CompletedIcon />
          {projectStatuses[status]}
        </div>
      );
    default:
      return (
        <div style={flexRow} onClick={handleClick} className={buttonClass}>
          <FailedIcon />
          <div>Провален</div>
        </div>
      );
  }
};

const workFlow = status => {
  switch (status) {
    case "active":
      return ["canceled", "problem", "completed", "failed"];
    case "failed":
      return ["active"];
    case "problem":
      return ["active"];
    case "created":
      return ["approved", "canceled"];
    case "approved":
      return ["created", "active"];
  }
};

const Header = ({
  manager: { fullName },
  availability: { from },
  handleClose,
  handleClick,
  handleCheck,
  anchorEl,
  fromProjects
}) => {
  moment.locale("ru");
  let formatDate = moment(new Date(from)).format("DD  MMM  YYYY");
  // let formatFullName = getShortName(fullName);
  return (
    <div className="header">
      <div className="projectInfo">
        <Typography variant={"overline"} className="fullName">
        </Typography>
        <Typography variant={"overline"} className="date">
          {formatDate}
        </Typography>
      </div>
      <div className="projectCardButtons">
        <IconButton
          color="inherit"
          onClick={handleClick}
          className="headerMoreIcon"
        >
          <MoreIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem button onClick={handleClose}>
            скачать акт
          </MenuItem>
        </Menu>
        { !fromProjects ? <Checkbox onClick={handleCheck} /> : null }
      </div>
    </div>
  );
};

const Warning = ({ warning }) => {
  return <div className="warning">{warning}</div>;
};

const Title = ({ title }) => {
  return (
    <div className="title">
      <Typography variant={"h5"} component={"h5"}>
        {title}
      </Typography>
    </div>
  );
};

const Contract = ({ contract }) => {
  return (
    <div className="project_contract">
      <ShopIcon disabled />
      <Typography variant="body1">{contract.title}</Typography>
    </div>
  );
};

const Status = ({ statusOpen, status, anchorStatus, selectStatus }) => {
  let workFlowStatus = workFlow(status) || [];
  return (
    <div className="status">
      {/*<ProjectStatus status={status} handleClick={handleClick} />*/}
      <Select
        inputProps={{
          name: "age",
          id: "age-simple"
        }}
        value={status}
        renderValue={value => <ProjectStatus status={status} />}
        onChange={selectStatus}
      >
        <MenuItem value="">
          <ProjectStatus status={status} />
        </MenuItem>
        {workFlowStatus.map((status, key) => (
          <MenuItem key={key} value={status}>
            <ProjectStatus status={status} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorHeader: null,
      anchorStatus: null,
      projectStatus: this.props.project.status,
      statusOpen: false
    };
  }

  handleClick = name => event => {
    this.setState({ [name]: event.currentTarget });
  };

  handleClose = name => event => {
    this.setState({ [name]: null });
  };

  // openStatus = () => {
  //   this.setState(state => ({ statusOpen: !this.state.statusOpen }));
  // };
  //
  // closeStatus = () => {
  //   this.setState(state => ({ statusOpen: false }));
  // };

  selectStatus = status => {
    let changeStatus = status.target.value;
    let id = this.props.project.id
    this.props.changeStatus(id, changeStatus, this.props.contractID || ""); // this.closeStatus()
  };

  render() {
    const { project, fromProjects } = this.props;

    // let controlStyleCard =
    //   projecttest.warning !== undefined
    //     ? classNames("project_card__warning", "project_card")
    //     : classNames("project_card__normal", "project_card");

    return (
      <Card className="project_card__normal project_card">
        {/*<div onClick={e => e.stopPropagation()}>*/}
        {/*{projecttest.warning && <Warning warning={projecttest.warning} />}*/}
        <Header
          manager={project.manager}
          availability={project.availability}
          handleClick={this.handleClick("anchorHeader")}
          handleClose={this.handleClose("anchorHeader")}
          handleCheck={e =>
            this.props.onCheck({
              id: project.id,
              checked: e.target.checked
            })
          }
          fromProjects={fromProjects}
          anchorEl={this.state.anchorHeader}
        />
        <Title title={project.title} />
        <CardContent className="body">
          {!fromProjects && <Contract contract={this.props.contractID} />}
          <Status
            status={this.state.projectStatus}
            selectStatus={this.selectStatus}
            anchorStatus={this.state.anchorStatus}
            statusOpen={this.state.statusOpen}
          />
        </CardContent>
        <div className="control-btn">
          <Button
            fullWidth
            variant="outlined"
            onClick={() => this.props.onClick(project.id)}
            className="openBtn"
          >
            Открыть
          </Button>
        </div>
        <CardMedia />
        {/*</div>*/}
      </Card>
    );
  }
}

export default ProjectCard;

//
// // let { project } = this.props;
// //TODO : remove this obj/ its just test data
