import React, { Component, Fragment } from "react";
import "./Manufacturers.scss";
import { Typography } from "@material-ui/core";

class Manufacturers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            documentPage: false,
        };
    }
    handleClick = (id) => () => {
        this.props.history.push(`/profile/manufacturers/${id}`)
    }
    render() {
        return  (
            <Fragment>
                {
                    this.props.profile.manufacturers.items.length > 0
                    ? <div className='manufacturers__wrapper'>
                        <div className='table__head'>
                            <Typography variant='body1'>Название</Typography>
                            <Typography variant='body1'>Компания</Typography>
                            <Typography variant='body1'>ИНН</Typography>
                        </div>
                        <div className='table__body'>
                        {
                            this.props.profile.manufacturers.items.map(item =>
                                <div className='manufacturer__row' onClick={this.handleClick(item.id)}>
                                    <Typography>{item.title}</Typography>
                                    <Typography>{item.company.name ? item.company.name : 'Нет данных'}</Typography>
                                    <Typography>{item.company.itn}</Typography>
                                </div>
                            )
                        }
                        </div>
                    </div>
                    : <Typography>Нет данных</Typography>
                }
            </Fragment>
        );
    }
  }

  export default Manufacturers;
