import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { infoBarActions } from "../actions";
import { ImportPharmacies } from "../component/info";
import { withRouter } from "react-router"

@withRouter
class InfoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handlePharmaciesImportClick = () => {
    this.props.history.push('/import_from_excel')
    if (this.props.info.isEnd) {
      this.props.infoBarActions.closeBar()
    }
  }

  render() {
    const { pharmaciesImport: { isRequestingImport }, info } = this.props
    return info.isOpen 
    ? <ImportPharmacies pharmaciesImport={this.props.pharmaciesImport} info={this.props.info} closeBar={this.props.infoBarActions.closeBar} onPanelClick={this.handlePharmaciesImportClick} /> 
    : null
  }
}

const mapStateToProps = state => ({
  pharmaciesImport: state.pharmaciesImport,
  info: state.info
})
const mapDispatchToProps = dispatch => ({
  infoBarActions: bindActionCreators(infoBarActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoContainer)
