import { metaTypes } from '../types'

export const updateNavi = (path) =>
  (dispatch, getState) => {
    // const next = isConcat
    // ? [...getState().meta.navi, { title, to }]
    // : [{ title, to }]
    dispatch((() => ({
      type: metaTypes.UPDATE_NAVI,
      payload: { navi: path }
    }))())
  }

export const provideTitle = title =>
  (dispatch, getState) => {
    let prev = getState().meta.navi
    prev[prev.length - 1].title = title
    dispatch((() => ({
      type: metaTypes.UPDATE_NAVI,
      payload: { navi: prev }
    }))())
    dispatch((() => ({
      type: metaTypes.PROVIDE_TITLE,
      payload: { navi: title }
    }))())
  }
