import React from "react";
import FormCreateProject from "../form/FormCreateProject";
import withRouter from "react-router/es/withRouter";

import "./Contract.scss";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { submitWizardActions } from "../../actions/modal/wizard";
import LinearProgress from "../../component/modal/ModalCreateContract";
import { toggleModalWizzard } from "../../actions/modal/toggleModal";
import Typography from "@material-ui/core/es/Typography/Typography";
import { projectForm } from "../../constants/form";
import FormCreate from "../../component/form/FormCreate";
import FieldsCreate from "../../component/form/FieldsCreate";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import Epic from "./ModalCreateTask";
import Button from "@material-ui/core/Button";

@withRouter
class ModalWizard extends React.Component {
  handleOnSubmit = data => {
    let { contractId, submitWizardActions } = this.props;
    // this.props.handleOpenCreateProjectWizard();
    submitWizardActions(contractId, data);
    // this.props.handleOpenCreateProjectWizard();
  };

  render() {
    let { isSubmiting, isOpenModalWizzard } = this.props;
    return (
      <Modal className="modal_wrapper" open={isOpenModalWizzard}>
        <div className="modal">
          <div className="title">
            <IconButton className="close" aria-label="Delete">
              <CloseIcon
                fontSize="small"
                onClick={this.props.handleOpenCreateProjectWizard}
              />
            </IconButton>
            <Typography variant="h6">{`Создание проекта `}</Typography>
          </div>
          {isSubmiting && <LinearProgress />}
          <Form
            onSubmit={this.handleOnSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={{ tasks: [{}] }}
            render={({
              handleSubmit,
              mutators: { push, pop },
              pristine,
              reset,
              submitting,
              values
            }) => {
              return (
                <form onSubmit={handleSubmit} className="form">
                  <FieldsCreate fields={projectForm} />
                  <div className="buttons">
                    <div className="left">
                      <Button
                        className="button"
                        color="secondary"
                        variant="outlined"
                        type="submit"
                        disabled={submitting || pristine}
                      >
                        Отправить
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitWizardActions,
      toggleModalWizzard
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    isSubmiting: state.modal.isSubmiting,
    isError: state.modal.isError,
    isOpenModalWizzard: state.modal.isOpenModalWizzard
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalWizard);
