import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ticketsActions, metaActions } from '../actions'
import { Support } from '../pages'
import {toggleModalCreateContract} from "../actions/modal/toggleModal";
import { Loader } from '../component'

class SupportContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { ticketsActions: { getTickets }, metaActions: { updateNavi } } = this.props
    updateNavi([{ title: 'Мои обращения', to: '/support' }])
  }

  render() {
    return this.props.auth.isRequesting ? <Loader /> : <Support {...this.props} />
  }
}

const mapStateToProps = state => ({
  tickets: state.tickets,
  auth: state.auth
})
const mapDispatchToProps = dispatch => ({
  ticketsActions: bindActionCreators(ticketsActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportContainer)
