import React, { Component } from 'react'
import { Loader } from '../'
import { Typography } from '@material-ui/core'
import { DebounceInput } from 'react-debounce-input'

import './style.scss'

/**
 *  component props:
 *  
 *  disabled: boolean,
 *  classNameInput: string,
 *  className: string,
 *  value: string,
 *  placeholder: string,
 *  isRequesting: boolean,
 *  onChange: Function,
 *  onSelect: Function,
 *  suggestions: Array,
 */

class AutocompleteInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: false,
      value: ''
    }
  }
  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleOutsideClick);
    this.setState({ value: this.props.value })
  }

  componentWillUpdate = nextProps => {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = ({ target }) => {
    if (!this.node.contains(target))
      this.setState(() => ({
        isOpened: false
      }));
  };

  handleValueChange = value => {
    this.setState({ value }, () => this.props.onChange(this.state.value))
  }

  handleSelectSuggestion = item => {
    this.setState({ isOpened: false }, () => this.props.onSelect(item))
  }

  render() {
    return (
      <div className={`autocomplete-input ${this.props.className || ''} ${this.state.isOpened ? 'opened' : ''}`} ref={node => (this.node = node)}>
        <DebounceInput
          className={this.props.classNameInput}
          type='text'
          placeholder={this.props.placeholder} 
          value={this.state.value}
          debounceTimeout={400}
          disabled={this.props.disabled || false}
          onChange={({ target: { value } }) => this.handleValueChange(value)}
          onFocus={() => this.setState({ isOpened: true })}
        />
        {
          !this.props.disabled
          && (this.state.isOpened
                    && (
                      <div className={`suggestions ${this.props.isRequesting ? 'requesting' : ''}`}>
                        {
                          this.props.isRequesting
                          ? <Loader small />
                          : this.props.suggestions.map((_item, _key) => (
                                <div key={_key} className={`suggestion ${this.props.classNameSuggestion || ''}`} onClick={() => this.handleSelectSuggestion(_item)}><Typography>{_item[this.props.suggestionsKey]}</Typography></div>
                            ))
                        }
                      </div>
                    ))
        }
      </div>
    )
  }
}

export default AutocompleteInput
