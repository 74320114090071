import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { MyPharms } from '../pages'
import { metaActions, pharmaciesActions } from '../actions'

class MyPharmsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <MyPharms {...this.props} />
  }
}

const mapStateToProps = state => ({
  isRequesting: state.auth.isRequesting,
  pharms: state.auth.user.pharms,
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyPharmsContainer)
