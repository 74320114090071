import React, { Component } from 'react'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  List,
  ListSubheader,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Collapse
} from "@material-ui/core/";
import { ExpandLess, Add, Save } from "@material-ui/icons/";
import {
  DrugsSupply,
  SubtaskGeolocation,
  SubtaskMessage,
  SubtaskPhotos,
  SubtaskPoll
} from '../card'
import './Tasks.scss'

const selectSubtask = (item, name, handler) => {
  switch (item.type) {
    case "GEOLOCATION": return <SubtaskGeolocation
        input={{
          onChange: data => handler(data, 'geolocation'),
          name: 'geolocation',
          value: ''
        }}
        name={`geolocation`}
        defaultValue={true}
        />;
    case "MESSAGE": return <SubtaskMessage
        input={{
          onChange: data => handler(data, 'message'),
          name: 'message',
          value: ''
        }}
        name={`message`}
        defaultValue={true}
        />;
    case "PHOTOS": return <SubtaskPhotos
        input={{
          onChange: data => handler(data, 'photos'),
          name: 'photos',
          value: ''
        }}
        name={`minimumAmount`}
        />;
    case "POLL": return <SubtaskPoll
        input={{
          onChange: data => handler(data, 'poll'),
          name: 'poll',
          value: ''
        }}
        name={`poll`}
        />;
    case "DRUGS_SUPPLY": return <DrugsSupply
        input={{
          onChange: data => handler(data, 'drugSupply'),
          name: 'drugSupply',
          value: ''
        }}
        name={`drugSupply`}
        />;
    default: return null;
  }
};
const dropDownList = [
  {
    id: "56219157-8b52-44b9-8ecb-c9fce9691920",
    type: "GEOLOCATION",
    placeholder: "Добавить геолокацию"
  },
  {
    id: "56219157-8b52-44b9-8ecb-c943e9691920",
    type: "MESSAGE",
    placeholder: "Добавить репорт"
  },
  {
    id: "56219157-8b52-44b9-8ecb-c9fce9691430",
    type: "PHOTOS",
    placeholder: "Добавить фото"
  },
  {
    id: "56219157-8322-44b9-8ecb-c9fce9691920",
    type: "DRUGS_SUPPLY",
    placeholder: "Добавить лекарства"
  },
  {
    id: "56219157-8b52-44b9-8ecb-c9fce9665920",
    type: "POLL",
    placeholder: "Добавть вопрос"
  }
];

class Tasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openedSubtasksList: false,
      subtasks: [],
      name: '',
      description: '',
      geolocation: null,
      message: null,
      photos: null,
      poll: null,
      drugSupply: null,
      anchor: null
    }
  }
  handleBGClick = () => this.props.onClose()
  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value })
  handleOpenList = () => {
    this.setState(state => ({ openedSubtasksList: !state.openedSubtasksList }))
  }
  handleOpen = event => this.setState({ anchor: event.currentTarget })
  handleClose = (id, type) => {
    this.setState({ anchor: null });
    id !== null && this.handleCreateSubTask(id, type)
  };
  handleCreateSubTask = (id, type) => {
    const subtaskArray = this.state.subtasks;
    this.setState(() => ({ openedSubtasksList: true }));
    Array.isArray(subtaskArray)
    && this.setState({
      subtasks: [...subtaskArray, { id, type }]
    })
  };
  handleSubtaskChange = (data, name) => {
    this.setState({
      [name]: data
    })
  }
  handleCreateTaskClick = () => {
  }
  render() {
    const { openedSubtasksList, subtasks, anchor } = this.state

    const filtered = dropDownList.filter(
      item => !subtasks.map(item => item.id).includes(item.id)
    );


    return (
      <div onClick={this.handleBGClick} className={`tasksWrapper ${this.props.opened ? '' : 'hidden'}`}>
        <div onClick={e => e.stopPropagation()}>
          <Card className="tasks">
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                name='name'
                placeholder={'Имя'}
                onChange={this.handleChange}
              />
              <TextField
                name='description'
                placeholder={'Описание'}
                onChange={this.handleChange}
              />
              <Card className="subtask-list">
                <List
                  component="nav"
                  subheader={
                    <div className="control">
                      <ListSubheader component="div">
                        Список типов отчетов
                      </ListSubheader>
                      <IconButton>
                        <ExpandLess onClick={this.handleOpenList} />
                      </IconButton>
                    </div>
                  }
                >
                  {subtasks.map((item, index) => {
                    return (
                      <Collapse
                        key={index}
                        in={openedSubtasksList}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ListItem className="list">
                          {selectSubtask(item, name, this.handleSubtaskChange)}
                        </ListItem>
                      </Collapse>
                    );
                  })}
                </List>
              </Card>
            </CardContent>
            <CardActions>
              {filtered.length === 0 ? null : (
                <Button
                  className="epicButton"
                  aria-owns={anchor ? "simple-menu" : undefined}
                  color="secondary"
                  onClick={this.handleOpen}
                >
                  <Add /> Добавить задачу
                </Button>
              )}
              <Menu
                id="simple-menu"
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={() => this.handleClose(null)}
              >
                {filtered.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => this.handleClose(item.id, item.type)}
                    >
                      {item.placeholder}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Button
                color="secondary"
                className="epicButton"
                onClick={() => this.handleCreateTaskClick()}
                >
                <Save /> Создать задачу
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    )
  }
}

export default Tasks
