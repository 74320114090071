import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/core/SvgIcon/SvgIcon";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import Button from "@material-ui/core/Button/Button";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";

//styles
import "./Toolbar.scss"

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    button: {
        fontWeight: "100",
        border: 0,
        textDecoration: "none",
        backgroundColor: "#fff",
        cursor: "pointer",
        marginLeft: "28px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        ...theme.typography.button_outline
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    },
    h6: {
        ...theme.typography.h6
    }
});

let ProjectDashboardToolbar = props => {
    const {
        numSelected,
        classes,
        toggleFilter,
        toggleModalCreateContract,
        withFilter
    } = props;

    return (
        <Toolbar
            style={{maxHeight:"53px", }}
            className={classNames('toolbar', {
                ['highlight']: numSelected > 0
            }, withFilter ? 'withFilter' : null)}
        >
            <div className={'title'}>
                {numSelected > 0 ? (
                    <Typography
	                    variant="h6"
                        className={'h6'}
                        color="inherit"

                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography className={'h6'} variant="h6" id="tableTitle">
                            Проекты
                        </Typography>
                    </div>
                )}
            </div>
            <Tabs
                style={{flexGrow:1}}
                value={0}
                // onChange={this.handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                centered
            >
                <Tab label="Активные" />
                <Tab label="Архивные" />
            </Tabs>

            <div className={'actions'}>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Tooltip title="Filter list">
                            <IconButton onClick={toggleFilter} aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="More action">
                            <IconButton aria-label="More action">
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
            </div>
        </Toolbar>
    );
};

export default ProjectDashboardToolbar;
