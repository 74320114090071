import { contractTypes, commentsTypes } from '../types'
import { contractApi, getList, getOne, serialize } from '../api'
import { metaActions } from './'
import { formatComments } from '../utils/formating'

export const getLogs = ({ projectId }) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: contractTypes.GET_LOGS__REQUEST }))())
    Promise.all(
      getState().projectHalReducer._embedded.items
        .map(project =>
          project._links.tasks.map(task => task.href))
        .filter(arr => arr.length > 0)
        .flat()
        .map(task => contractApi.getTask({ taskId: task.split('/')[task.split('/').length - 1] })))
      .then(tasks => dispatch(getPersonsForTasks(tasks)))
  }
const getPersonsForTasks = tasks =>
  dispatch =>
    Promise.all(
      tasks.map(task => contractApi.getPerson({ personUrl: task._links.assignedTo.href }))
    ).then(data => {
      dispatch(getSubtasksForTasks(tasks.map((task, key) =>
        ({
          ...task,
          assign: `${data[key].fullName.lastName} ${data[key].fullName.firstName[0]}.${data[key].fullName.middleName[0]}.`,
        })
      )))
    })
const getSubtasksForTasks = tasks =>
  dispatch =>
      Promise.all(
        tasks
        .filter(task => task.isCompleted)
        .map(task => task._embedded.subtasks.map(subtask =>
            ({ report: subtask._links.report.href, taskId: task.id })))
        .flat()
        .map(subtask => contractApi.getReport({ reportLink: subtask.report, taskId: subtask.taskId }))
      ).then(
        data => {
          const hashedReports = (obj => {
            data.map(item =>
              obj[item.taskId]
              ? Object.defineProperty(obj[item.taskId], item.report.type, { value: item.report, enumerable: true })
              : Object.defineProperty(obj, item.taskId, { value: {}, enumerable: true })
                && Object.defineProperty(obj[item.taskId], item.report.type, { value: item.report, enumerable: true }))
            return obj
          })({})
          const withReports = tasks.map((task) => {
            return {
              date: new Date(task.createdAt),
              title: task.title,
              description: task.description,
              isCompleted: task.isCompleted,
              assign: task.assign,
              reports: hashedReports[task.id] ? Object.values(hashedReports[task.id]).map(item => item) : null
            }
          })
          return dispatch(getLogs__success(withReports))
        })

const getLogs__success = logs => {
  return {
    type: contractTypes.GET_LOGS__SUCCESS,
    payload: { logs }
  }
}

export const getContracts = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: contractTypes.GET_CONTRACTS__REQUEST }))())
    const resourses = getState().meta.resourses
    getList(resourses.contracts)
    .then(data =>
      dispatch((() => ({
        type: contractTypes.GET_CONTRACTS__SUCCESS,
        payload: { items: data.items }
      }))()))}

export const getContract = ({ id }) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: contractTypes.GET_CONTRACT__REQUEST }))())
    dispatch((() => ({ type: commentsTypes.GET_COMMENTS__REQUEST }))())
    const resourses = getState().meta.resourses
    getOne(resourses.contracts, { id })
    .then(_contract => {
      dispatch(metaActions.provideTitle(_contract.title))
      dispatch((() => ({ type: commentsTypes.GET_COMMENTS__SUCCESS, payload: { items: formatComments(_contract.comments.items) } }))())
      getOne(resourses.users, { id: _contract.manager.split('/')[_contract.manager.split('/').length - 1] }).then(_manager => {
        dispatch((() => ({
          type: contractTypes.GET_CONTRACT__SUCCESS,
          payload: {
            ..._contract,
            projects: _contract.projects.items,
            manager: _manager
          }
        }))())
      })
    })
    .catch(() => dispatch((() => ({ type: contractTypes.GET_CONTRACT__FAILURE }))()))
  }

  export const changeFilter = ({ filter }) => ({
    type: contractTypes.FILTER,
    payload: { filter }
  })
