import React, { Component, Fragment } from "react";

import { Form, Field, FormSpy } from "react-final-form";
import createDecorator from "final-form-focus";

import "./Form.scss";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Button from "@material-ui/core/Button";

class FormCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initValues: {}
    };
  }

  handleSubmit = values => {
    this.props.handleOnSubmit(values)
  };

  render() {
    let { initValues } = this.state;
    let { children } = this.props;
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initValues}
        // subscription={{ submitting: true }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values
        }) => (
          <Fragment>
            <form onSubmit={handleSubmit} className="form">
              {children}
            </form>
            <Button
              style={{ margin: " 20px 20px 20px auto " }}
              color={"secondary"}
              variant="contained"
              type="submit"
              // disabled={props.disabled}
            >
              Отправить
            </Button>
          </Fragment>
        )}
      />
    );
  }
}

export default FormCreate;
