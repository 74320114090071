import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contractActions } from "../actions";
import { Logs } from "../pages";

class LogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      contractActions: { getLogs }
    } = this.props;
    getLogs({}); // TODO: add projectId
  };

  render() {
    const { logs } = this.props;
    return <Logs logs={logs} />;
  }
}

const mapStateToProps = state => ({
  logs: state.logs
});
const mapDispatchToProps = dispatch => ({
  contractActions: bindActionCreators(contractActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogsContainer);
