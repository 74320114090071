import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

//styles
import "./Toolbar.scss"

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  button: {
    fontWeight: "100",
    border: 0,
    textDecoration: "none",
    backgroundColor: "#fff",
    cursor: "pointer",
    marginLeft: "28px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...theme.typography.button_outline
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  h6: {
    ...theme.typography.h6
  }
});

class ContractTableToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    };
  }


  render() {
    const {
      classes,
      toggleFilter,
      openContractCreateModal,
      withFilter
    } = this.props;

    return (
      <Toolbar className={`toolbar ${withFilter ? "withFilter" : null}`}>
        <button className={"button"}>
          <AddIcon />
          <Typography style={{ width: 170 }} variant="body1" id="tableTitle" onClick={this.props.handleOpenModal}>
            Добавить контракт
          </Typography>
        </button>
        <div className={"spacer"} />

        <div className={"actions"}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Filter list"  style={{fontSize:"50px !important"}}>
              <IconButton onClick={toggleFilter} aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="More action">
              <IconButton aria-label="More action">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

export default ContractTableToolbar;
