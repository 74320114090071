import { filesTypes, commentsTypes } from '../types'
import { get, getList, getOne, post, currentApiUrl } from '../api'
import { STATIC_FILES } from "../constants/entities";
import { getFileType } from '../utils'
import apiService from "../api/apiService";

export const getFiles = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: filesTypes.GET_FILES__REQUEST }))())

    const resourses = getState().meta.resourses
    getList(resourses['personal-files'])
    .then(data => {
      const withTypes = data.items.map(file => ({
        ...file,
        type: getFileType(file.title)
      }))
      Promise.all(withTypes.map(file => get(`${currentApiUrl}${file.shares}`))).then(shares => {
        const withShares = withTypes.map((file, index) => ({ ...file, shares: shares[index] }))
        Promise.all(Array
          .from(new Set(withShares.map(item => [item.shares.items.map(share => share.sharedWith), item.owner]).flat(2)))
          .map(link => getOne(resourses.users, { id: link.split('/').pop() }))
        ).then(users => {
          const withUsers = withShares.map(file => ({
            ...file,
            owner: users.find(user => file.owner === user.self),
            users: file.shares.items.map(link => users.find(user => user.self === link.sharedWith))
          }))
          dispatch((() => ({
            type: filesTypes.GET_FILES__SUCCESS,
            payload: { items: withUsers }
          }))())
        })
      })
    }).catch(e => dispatch((() => ({ type: filesTypes.GET_FILES__FAILURE }))()))
  }

export const getShare = shareId =>
  (dispatch, getState) => {
    dispatch((() => ({ type: filesTypes.GET_FILE__REQUEST }))())
    const resourses = getState().meta.resourses

    getOne(resourses['personal-file-shares'], { id: shareId })
    .then(share => getOne(resourses['personal-files'], { id: share.personalFile.split('/').pop() })
    .then(file => {
      dispatch((()=> ({
        type: commentsTypes.GET_COMMENTS__SUCCESS,
        payload: { items: share.comments.items }
      }))())
      dispatch((() => ({
        type: filesTypes.GET_FILE__SUCCESS,
        payload: { shared: {
          file: {
            ...file
          },
          ...share
        }}
      }))())
    }))
  }

export const uploadFile = data =>
  (dispatch, getState) => {
    dispatch((() => ({ type: filesTypes.UPLOAD_FILE__REQUEST }))())

    new apiService(STATIC_FILES).postFile(
      data.file,
      "personal_file"
    ).then(file => post(`${process.env.BASE_API_URL}/v1/personal-file-creation-requests`, {
      description: data.description,
      title: data.title,
      file: file.self
    }).then(personal => Promise.all(data.users.map(link => post(`${process.env.BASE_API_URL}/v1/personal-file-sharing-requests`, {
      personalFile: personal.personalFile,
      sharedWith: link
    }))).then(() => dispatch((() => ({ type: filesTypes.UPLOAD_FILE__SUCCESS }))()))))

    const arr = {
      file: 'url',
      title: 'file name',
      description: 'vaev',
      sharedWith: 'user url',
      medicalInstitution: 'url for pharmacy, nullable'
    }
  }
