import { ticketsTypes } from "../types";
import { get, currentApiUrl, post, postFile } from "../api";
import {
  OPEN,
  CLOSED,
  IN_WORK,
  NEEDS_ADDITIONAL_INFO
} from "../constants/tickets";
import { createComment } from "./comments";

export const getTickets = filter => dispatch => {
  dispatch((() => ({ type: ticketsTypes.GET_TICKETS__REQUEST }))());
  get(
    filter
      ? `${currentApiUrl}/v1/support-tickets?author=${filter.author}`
      : `${currentApiUrl}/v1/support-tickets`
  ).then(_tickets => {
    Promise.all(
      _tickets.items.map(_ticket => get(`${currentApiUrl}${_ticket.author}`))
    ).then(_authors => {
      const _withAuthors = _tickets.items.map((_ticket, _key) => ({
        ..._ticket,
        author: _authors[_key]
      }));
      dispatch(
        (() => ({
          type: ticketsTypes.GET_TICKETS__SUCCESS,
          payload: { items: _withAuthors }
        }))()
      );
    });
  });
};

export const createTicket = ticket => dispatch => {
  dispatch((() => ({ type: ticketsTypes.CREATE_TICKET__REQUEST }))());
  post(`${currentApiUrl}/v1/support-ticket-creation-requests`, ticket).then(
    _ticket => {
      dispatch((() => ({ type: ticketsTypes.CREATE_TICKET__SUCCESS }))());
      dispatch(getTickets());
    }
  );
};

export const sendMessageForTicket = (
  { ticket, text },
  attachments
) => dispatch => {
  dispatch((() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT }))());
  if (attachments.length) {
    Promise.all(
      attachments.map(attachment =>
        postFile(`${currentApiUrl}/v1/static-files`, {
          file: attachment,
          type: "comment_attachment"
        })
      )
    ).then(files => {
      createComment({
        text,
        type: "support_ticket",
        attachments: {
        	items: files.map(({ self }) => self)
		},
        supportTicket: ticket
      }).then(_responce => {
	  	dispatch((() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT_SUCCESS }))());
        dispatch(getTicket(ticket.split("/").pop()));
      });
    });
  } else {
    createComment({
      text,
      type: "support_ticket",
      supportTicket: ticket
    }).then(_responce => {
		dispatch((() => ({ type: ticketsTypes.CREATE_TICKET_COMMENT_SUCCESS }))());
      dispatch(getTicket(ticket.split("/").pop()));
    });
  }
};

export const getTicket = ticketId => dispatch => {
  dispatch((() => ({ type: ticketsTypes.GET_TICKET__REQUEST }))());
  get(`${currentApiUrl}/v1/support-tickets/${ticketId}`).then(_ticket => {
    get(`${currentApiUrl}${_ticket.author}`).then(_author => {
      dispatch(
        (() => ({
          type: ticketsTypes.GET_TICKET__SUCCESS,
          payload: {
            ticket: {
              ..._ticket,
              author: _author,
              comments: {
                ..._ticket.comments,
                items: _ticket.comments.items
                  .map(_comment => ({
                    ..._comment,
                    attachment: _comment.attachment
                      ? { ..._comment.attachment, mimeType: "image/jpeg" }
                      : null
                  }))
                  .reverse()
              }
            }
          }
        }))()
      );
    });
  });
};

export const changeTicketStatus = (status, message, ticket) => dispatch => {
  dispatch((() => ({ type: ticketsTypes.CHANGE_TICKET_STATUS__REQUEST }))());
  const status2path = _status => {
    switch (_status) {
      case OPEN:
        return "/v1/support-ticket-reopen-requests";
      case CLOSED:
        return "/v1/support-ticket-close-requests";
      case NEEDS_ADDITIONAL_INFO:
        return "/v1/support-ticket-request-additional-info-requests";
      case IN_WORK:
        return "/v1/support-ticket-in-work-requests";
      default:
        return "";
    }
  };
  post(`${currentApiUrl}${status2path(status)}`, {
    supportTicket: ticket,
    message
  }).then(_response => {
    dispatch(
      (() => ({
        type: ticketsTypes.CHANGE_TICKET_STATUS__SUCCESS,
        payload: {}
      }))()
    );
    dispatch(getTicket(_response.supportTicket.split("/").pop()));
  });
};
