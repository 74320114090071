export const GET_USERS__REQUEST = 'USER_TYPE__GET_USERS__REQUEST'
export const GET_USERS__SUCCESS = 'USER_TYPE__GET_USERS__SUCCESS'
export const GET_USERS__FAILURE = 'USER_TYPE__GET_USERS__FAILURE'
export const GET_USER__REQUEST = 'USER_TYPE__GET_USER__REQUEST'
export const GET_USER__SUCCESS = 'USER_TYPE__GET_USER__SUCCESS'
export const GET_USER__FAILURE = 'USER_TYPE__GET_USER__FAILURE'

export const BAN_USER_REQUEST = 'BAN_USER__REQUEST';
export const BAN_USER_SUCCESS = 'BAN_USER_SUCCESS';
export const BAN_USER__FAILURE = 'BAN_USER__FAILURE';

export const UNBAN_USER_REQUEST = 'UNBAN_USER_REQUEST';
export const UNBAN_USER_SUCCESS = 'UNBAN_USER_SUCCESS';
export const UNBAN_USER__FAILURE = 'UNBAN_USER__FAILURE';
