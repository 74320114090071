import { filesTypes, commentsTypes } from "../types";

const initialState = {
  item: { file: {} },
  isRequesting: true,
  isError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case commentsTypes.SEND_COMMENT__REQUEST:
    case filesTypes.GET_FILE__REQUEST:
      return {
        ...state,
        isError: false,
        isRequesting: true,
        item: { file: '' }
      };
    case filesTypes.GET_FILE__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        item: {
          ...action.payload.shared
        }
      };
    default:
      return state;
  }
};
