import React, { Component } from "react";
import Epic from "../../component/card/Epic";
import Wizard from "../../component/wizard/createWizard";
import { FieldArray } from "react-final-form-arrays";
import { projectForm } from "../../constants/form";
import FieldsCreate from "../../component/form/FieldsCreate";

class FormCreateProject extends Component {
  render() {
    let { handleOnSubmit } = this.props;
    return (
      <Wizard onSubmit={handleOnSubmit} handleClose={this.props.handleOpenCreateProjectWizard}>
        <Wizard.Page>
          <div className="form">
            <FieldsCreate fields={projectForm} />
          </div>
        </Wizard.Page>
        <Wizard.Page>
          <div className="tasks">
          <FieldArray name="tasks">
            {({ fields, values }) => {
              return fields.map((name, index) => (
                <Epic
                  fields={fields}
                  values={values}
                  key={name}
                  name={name}
                  idEpic={index}
                />
              ));
            }}
          </FieldArray>
          </div>
        </Wizard.Page>
      </Wizard>
    );
  }
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ createProjectActions }, dispatch);
//
// const mapStateToProps = state => ({});

export default FormCreateProject;
