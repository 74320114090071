import { ticketsTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: true,
  isRequestingCreation: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ticketsTypes.CREATE_TICKET__REQUEST: return {
      ...state,
      isRequestingCreation: true
    }
    case ticketsTypes.CREATE_TICKET__SUCCESS: return {
      ...state,
      isRequestingCreation: false
    }
    case ticketsTypes.GET_TICKETS__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case ticketsTypes.GET_TICKETS__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.items
    }
    default:
      return state
  }
}
