import { profileTypes, workPlacesTypes } from "../types";
import { getList, get, post, currentApiUrl, remove } from "../api";
import { setQueryString } from "../utils";
import { getProfile } from "./profile";

export const getWorkPlaces = (filter, options) => (dispatch, getState) => {
  dispatch((() => ({ type: workPlacesTypes.GET_WORK_PLACES }))());
  getList(`${getState().meta.resourses["employees"]}${setQueryString(filter)}`)
    .then(data => {
      if (options && options.withUsers)
        return Promise.all(
          data.items.map(workPlace =>
            get(`${process.env.BASE_API_URL}${workPlace.user}`).then(user => ({
              ...workPlace,
              user
            }))
          )
        );
      return data.items;
    })
    .then(data =>
      dispatch(
        (() => ({
          type: workPlacesTypes.GET_WORK_PLACES_SUCCESS,
          payload: { items: data }
        }))()
      )
    )
    .catch(() =>
      dispatch(
        (() => ({
          type: workPlacesTypes.GET_WORK_PLACES_FAIL
        }))()
      )
    );
};

export const createEmployee = data => (dispatch, getState) => {
  dispatch((() => ({ type: workPlacesTypes.CREATE_EMPLOYEE }))());
  return new Promise((res, rej) =>
    post(`${currentApiUrl}/v1/employee-creation-requests`, data)
      .then(data => {
        dispatch(
          (() => ({
            type: workPlacesTypes.CREATE_EMPLOYEE_SUCCESS
          }))()
        );
        res(data);
      })
      .catch(error => {
        dispatch((() => ({ type: workPlacesTypes.CREATE_EMPLOYEE_FAIL }))());
        rej(error);
      })
  );
};

export const deleteEmployee = id => (dispatch, getState) => {
  dispatch((() => ({ type: workPlacesTypes.DELETE_EMPLOYEE }))());
  return new Promise((res, rej) =>
    remove(`${currentApiUrl}/v1/employees/${id}`)
      .then(data => {
        dispatch(
          (() => ({
            type: workPlacesTypes.DELETE_EMPLOYEE_SUCCESS
          }))()
        );
        res();
      })
      .catch(error => {
        dispatch((() => ({ type: workPlacesTypes.DELETE_EMPLOYEE_FAIL }))());
        rej();
      })
  );
};
