import { submitProject, submitTasks } from "../../utils/submiting";
import { toggleModalWizzard } from "../../actions/modal/toggleModal";
import { getProject } from "../../actions/project";
import { getContract } from "../../actions/contract";

export function submitWizardActions(id, values) {
  return (dispatch, getState) => {
    (async () => {
      dispatch({ type: "SUBMITING_WIZARD" });


      let createdProject = await submitProject(id, values);

      dispatch({ type: "SUBMITED_WIZARD" });
      dispatch(toggleModalWizzard());
      dispatch(getContract({ id }));
    })();
  };
}
