import * as contractApi from './contract'
import * as geoApi from './geo'
import * as authApi from './auth'
import serialize from './_serializer'
import ApiService from './apiService'

const currentApiUrl = process.env.BASE_API_URL;
const currentClientUrl = process.env.BASE_TRAINING_URL

const generateParams = (data, type) => data
? ({
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
  },
  mode: 'cors',
  method: type || 'POST',
  body: JSON.stringify(data)
})
: ({
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
  },
  mode: 'cors'
})
const generateFileParams = (data) => {
  if (data) {
    return {
      headers: {
        Accept: null,
        Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
      },
      mode: 'cors',
      method: 'POST',
      body: data
    }
  }
}
const generateParams__patch = data => ({
  headers: {
    // Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
  },
  mode: 'cors',
  method: 'PATCH',
  body: JSON.stringify(data)
})
const generateParams__delete = () => ({
  headers: {
    // Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
  },
  mode: 'cors',
  method: 'DELETE'
})
const generateParams__get = () => ({
  headers: {
    // Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem('bearer_token')}`
  },
  mode: 'cors',
  method: 'GET'
})

export const get = resource => {
  return new Promise((resolve, reject) =>
    fetch(resource, generateParams__get())
    .then
(data => {
      if (!data.ok) {
        reject(new Error())
      }
      resolve(data.json())
    }))
}

export const remove = resource =>
    new Promise((resolve, reject) =>
        fetch(resource, generateParams__delete())
        .then(
          response => {
            if (!response.ok) { reject() }
            resolve()
          }
        ))

const post = (resource, data) =>
  new Promise((resolve, reject) =>
    fetch(resource, generateParams(data))
    .then(data => {
      if (data.ok) {
        resolve(data.json())
      } else {
        return data
      }
    })
    .then(data => {
      console.log(data)
      if (data === undefined) {
        return;
      }
      if (data.status === 400) {
        reject(data.json())
      }
      reject(true)
    })
  )

const postWithoutResponse = (resource, data) =>
    new Promise((resolve, reject) =>
        fetch(resource, generateParams(data))
            .then(data => {
                if (data.ok) {
                    resolve(data)
                } else {
                    return data
                }
            })
            .then(data => {
                if (data === undefined) {
                    return;
                }
                if (data.status === 400) {
                    reject(data.json())
                }
                reject(true)
            })
    )

export const patch = (resource, data) =>
  new Promise((resolve, reject) =>
    fetch(resource, generateParams__patch(data))
    .then(
      response => {
        if (!response.ok) { reject() }
        resolve()
      }
    ))

export const getList = (url) =>
  new Promise((resolve, reject) =>
    fetch(url, generateParams())
    .then((data, error) => {
      if (!data.ok) {
        reject('error')
      } else {
        resolve(data.json())
      }
    })
    )

export const getOne = (url, { id }) =>
  new Promise((resolve, reject) =>
    fetch(`${url}/${id}`, generateParams())
    .then(data =>
      resolve(data.json()))
    .catch(err =>
      reject(err)))

const postFile = (resource, data) => {
  let form = new FormData()
  Object.keys(data).map(key => form.append(key, data[key]))
  return new Promise((resolve, reject) =>
    fetch(resource, generateFileParams(form, true))
    .then(data => {
      if (!data.ok) {
        reject()
      } else {
        resolve(data.json())
      }
    }))
}

export const update = (url, { id, data }) =>
  new Promise((resolve, reject) => {
    fetch(`${url}/${id}`, generateParams(data))
    .then(_ => resolve(true))
  })

export {
  geoApi,
  contractApi,
  authApi,
  serialize,
  post,
  postWithoutResponse,
  postFile,
  ApiService as Api,
  currentApiUrl,
  currentClientUrl,
}
