import { drugsTypes } from '../types'
import { getList, get, currentApiUrl } from '../api'

export const getDrugs = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: drugsTypes.GET_DRUGS__REQUEST }))())

    const resourses = getState().meta.resourses

    getList(resourses['drugs'])
    .then(data => dispatch((() => ({
      type: drugsTypes.GET_DRUGS__SUCCESS,
      payload: { items: data.items }
    }))()))
  }

export const getDrugsByQuery = query => 
	dispatch => {
		dispatch((() => ({ type: drugsTypes.GET_DRUGS__REQUEST }))())
		get(`${currentApiUrl}/v1/drugs?title=${query}`).then(_drugs => {
			dispatch((() => ({
				type: drugsTypes.GET_DRUGS__SUCCESS,
  			payload: { items: _drugs.items }
			}))())
		})
	}