import { companiesTypes } from '../types'
import { getList } from '../api'

export const getCompanies = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: companiesTypes.GET_COMPANIES__REQUEST }))())
    getList(getState().meta.resourses.companies).then(data => dispatch((() => ({
      type: companiesTypes.GET_COMPANIES__SUCCESS,
      payload: { items: data.items }
    }))()))
  }
