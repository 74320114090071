import React, { Component } from "react";
import {
  MenuItem,
  withStyles,
  InputLabel,
  Select,
  IconButton
} from "@material-ui/core";
import { statuses, translateStatus } from "../../constants/tickets";
import CloseIcon from "@material-ui/icons/Close";
import "./Ticket.scss";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ModalMaterial from "../../component/modal/ModalMaterial";
import './Ticket.scss';

const StyledSelect = withStyles({
  root: {
    fontSize: "16px",
    color: "#263238",
    width: "400px",
    zIndex: 9999999
  },
  select: {
    paddingTop: "3px",
    paddingBottom: "3px"
  }
})(Select);

const StyledMenuItem = withStyles({
  root: {
    fontSize: "16px",
    color: "#263238"
  }
})(MenuItem);

const SelectLabel = withStyles({
  root: {
    fontSize: "16px",
    color: "#808080",
    wordBreak: "keep-all",
    whiteSpace: "nowrap",
    marginRight: "20px"
  }
})(InputLabel);

class ModalTicketChangeStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: statuses[0],
      message: ""
    };
  }
  componentDidMount() {
    this.setState({ status: this.props.status });
  }
  handleChangeStatus = ({ target: { value } }) =>
    this.setState({ status: value });
  handleMessageChange = ({ target: { value } }) =>
    this.setState({ message: value });
  render() {
    const { status, message } = this.state;
    const {
      closeModal,
      isRequesting,
      onSubmitChangeStatus,
      isOpenModal
    } = this.props;
    return (
        <ModalMaterial open={isOpenModal} onClose={closeModal}>
          <div className="modal-status__title">
            <div>Изменить статус обращения</div>
            <IconButton
                className="close"
                aria-label="Delete"
                onClick={closeModal}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="modal-status__select">
            <SelectLabel htmlFor="outlined-age-simple">
              Выберите статус
            </SelectLabel>
            <StyledSelect
                value={status}
                onChange={this.handleChangeStatus}
                fullWidth
                input={
                  <OutlinedInput
                      labelWidth={status}
                      name="age"
                      id="outlined-age-simple"
                  />
                }
            >
              {statuses.map(_status => (
                  <StyledMenuItem key={_status} value={_status}>
                    {translateStatus(_status)}
                  </StyledMenuItem>
              ))}
            </StyledSelect>
          </div>
          <textarea
              lines={7}
              multiline
              value={message}
              onChange={this.handleMessageChange}
              placeholder="Опишите причину смены статуса"
              className="modal-status__message"
          />
          <div className="modal-status__group">
            <button
                className={`modal-status__btn modal-status__btn_cancel`}
                onClick={() => closeModal()}
            >
              Отменить
            </button>
            <button
                className={`modal-status__btn modal-status__btn_confirm ${
                    isRequesting ? "modal-status__btn_loading" : ""
                }`}
                onClick={() => onSubmitChangeStatus(status, message)}
            >
              Сохранить
            </button>
          </div>
        </ModalMaterial>
    );
  }
}

export default ModalTicketChangeStatus;
