import React, { Component, Fragment } from "react";
import "./DocumentList.scss";
import { AttachFileV2, FullDesc, CancelIconV2, VariantsIcon, DeleteIcon, EditIcon, SendIconV2 } from '../../static/imgs'
import { documentKinds } from '../../constants/documentKinds'
import { Typography } from "@material-ui/core";
var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

class DocumentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            documentPage: false,
        };
    }
    deleteDocument = document => this.props.externalDocumentsActions.deleteDocument(document)
    render() {
        const documents = this.props.profile.item.externalDocuments.items
        const deleting = this.props.profile.deletingDocument
        return  (
            <Fragment>
                <div className='documentList__wrapper'>
                    <div className='table__head'>
                        <Typography variant='body1'>Комментарий</Typography>
                        <Typography variant='body1'>Тип документа</Typography>
                        <Typography variant='body1'>Дата добавления</Typography>
                        <Typography variant='body1'>Действия</Typography>
                    </div>
                    <div className='table_body'>
                    {
                      documents.map((_document, _key) => {
                        const createdAt = new Date(_document.createdAt)
                        const option = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        }
                        return <div key={_key} className={`document ${_document.self === deleting ? 'deleting' : ''}`}>
                          <Typography>{ _document.description }</Typography>
                          <Typography>{ documentKinds[_document.kind] }</Typography>
                          <Typography className='date'>{ createdAt.toLocaleString( "ru", option ) }</Typography>
                          <div className='actions'>
                            <EditIcon onClick={() => this.props.history.push(`/profile/documents/${_document.id}`)} className='action edit' />
                            <SendIconV2 className='action send' />
                            <DeleteIcon onClick={() => this.deleteDocument(_document.self)}className='action delete' />
                          </div>
                        </div>
                      })
                    }
                    </div>
                </div>
            </Fragment>
        );
    }
  }

  export default DocumentList;
