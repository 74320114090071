import React, { Component, Fragment } from 'react'
import './Modal.scss'

class Modal extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div className='modal__wrapper'>
        <div className={`modal ${this.props.className || ''}`}>
          {/*<div onClick={this.props.onClose} className='close'></div>*/}
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Modal
