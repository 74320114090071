export const PROJECT = "PROJECT";
export const MEDICAL_FACILITIES_SIDEBAR = {
  action: "pharmacies",
  label: "Медучреждения"
};
export const PHARMACIES_SIDEBAR = { action: "PHARMACIES", label: "Медучереждения" };
export const DRUGSTORE_SIDEBAR = { action: "DRUGSTORE", label: "Аптеки" };
export const CONTRACTS_SIDEBAR = { action: "CONTRACTS", label: "Мои контракты" };
export const ADMIN_CONTRACTS_SIDEBAR = { action: "ADMIN_CONTRACTS", label: "Все контракты" };
export const PROJECTS_SIDEBAR = { action: "PROJECTS", label: "Мои проекты" };
export const ADMIN_PROJECTS_SIDEBAR = { action: "ADMIN_PROJECTS", label: "Все проекты" };
export const DRUGS_SIDEBAR = { action: "DRUGS", label: "Лекарства" };
export const MATERIALS_SIDEBAR = { action: "MATERIALS", label: "Материалы" };
export const FILES_SIDEBAR = { action: "FILES", label: "Акции" };
export const SUPPORT_SIDEBAR = { action: "SUPPORT", label: "Техподдержка" };
export const USERS_SIDEBAR = { action: "USERS", label: "Сотрудники" };
export const TRAINING_SIDEBAR = { action: 'TRAINING', label: "Мое обучение" };
export const COURSE_LIST_SIDEBAR = { action: "COURSE_LIST", label: "Список курсов" };
export const MANUFACTURERS = { action: "MANUFACTURERS", label: "Производители" };


// table
export const CONTRACT_NUMBER = {
  id: "contractNumber",
  numeric: false,
  disablePadding: false,
  label: "Номер контракта"
};
export const EXPIRATION_DATE = {
  id: "expirationDate",
  numeric: false,
  disablePadding: false,
  label: "Дата окончания контракта"
};

export const INTERNAL_COMPANY = {
  id: "internalCompany",
  numeric: false,
  disablePadding: false,
  label: "Внутренняя компания"
};
export const SIGN_DATE = {
  id: "signDate",
  numeric: false,
  disablePadding: false,
  label: "Дата начала контракта"
};
export const TITLE = {
  id: "title",
  numeric: false,
  disablePadding: false,
  label: "Название"
};
export const SUBSIDIARY = {
  id: "subsidiary",
  numeric: false,
  disablePadding: false,
  label: "Внешняя компания"
};

export const dataTable = [
  [
    "21.01.2018",
    "Закупка Кальций Д3 Никомед",
    "Денис Фарм",
    "Никомед",
    "Левин Б. А."
  ],
  ["25.05.2017", "Контракт обучения gsk", "Весна", "gsk", "Черноус В. Н."]
];

// dropList input / searchListInput / dataInput / dateInput / dateInputWithCheckBox/

export const CREATED_PROJECT = { label: "Активен", type: "active" };
export const APPROVED_PROJECT = { label: "Утвержден", type: "done" };
export const ERROR_PROJECT = { label: "Отменен", type: "canceled" };
export const START_PROJECT = { label: "Запущен", type: "awaiting" };
