import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pharmaciesActions, metaActions, geoActions } from '../actions'
import { Pharmacy } from '../pages'
import { Loader } from '../component'

class PharmacyContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { pharmaciesActions: { getPharm }, match: { path, params: { pharmId } } } = this.props
    console.log(this.props.match)
    if (path === '/pharmacies/:pharmId' && pharmId !== 'new') {
      getPharm(pharmId)
    }
  }

  render() {
    const { pharmacy: { isRequesting }, match: { path } } = this.props
    return isRequesting ? <Loader /> : <Pharmacy {...this.props} isEditing={path === '/pharmacies/:pharmId'} />
  }
}

const mapStateToProps = state => ({
  pharmacy: state.pharmacy,
  meta: state.meta,
  geo: state.geo
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  geoActions: bindActionCreators(geoActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PharmacyContainer)
