import React, { Component } from "react";
import { Input, InputAdornment, IconButton } from "@material-ui/core";

import PhotoIcon from "@material-ui/icons/Photo";
import PlayListAddIcon from "@material-ui/icons/PlaylistAdd";
import DoneIcon from "@material-ui/icons/Done";
import SimpleChip from "../../component/chip/chip";
import TextField from "@material-ui/core/TextField/TextField";
import Chip from "@material-ui/core/Chip";



export class SubtaskPoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: this.props.input.question || "",
      answer:  this.props.input.answer || "",
      pollAnswer:  this.props.input.pollAnswer || []
    };
    this.onChange = _.debounce(this.onChange, 150, { maxWait: 300 });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    return prevState !== this.state ? this.onChange(this.state) : null;
  }

  onChange = state => {
    let {
      input: { onChange }
    } = this.props;

    onChange(this.state);
  };

  handleChange = name => event => {
    let updater = (name, value) => ({ [name]: value });

    this.setState(updater(name, event.target.value));
  };

  handleAddChip = () => {
    let { answer } = this.state;

    let updater = () => ({
      pollAnswer: [...this.state.pollAnswer, answer],
      answer: ""
    });

    this.setState(updater);
  };

  handleDelete = data => async () => {
    await this.setState(state => {
      const pollAnswer = [...state.pollAnswer];
      const chipToDelete = pollAnswer.indexOf(data);
      pollAnswer.splice(chipToDelete, 1);
      return { pollAnswer };
    });
  };

  render() {
    // let {
    //   input: { name, onChange, value, ...restInput },
    //   meta,
    //   label,
    //   classes,
    //   placeholder,
    //   ...rest
    // } = this.props;
    // // console.log(value);
    return (
      <div className="poll">
        <TextField
          className="question input"
          value={this.state.question}
          onChange={this.handleChange("question")}
          placeholder="Напишите вопрос"
          fullWidth
        />
        <div className="row">
          <SimpleChip
            icon={<PhotoIcon />}
            deleteIcom={<DoneIcon />}
            label={"Выбран тип отчета: Опрос"}
          />

          <Input
            className="answer input"
            id="adornment-password"
            type={"text"}
            value={this.state.answer}
            onChange={this.handleChange("answer")}
            fullWidth
            disabled={this.state.pollAnswer.length > 5}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleAddChip}
                >
                  <PlayListAddIcon />
                </IconButton>
              </InputAdornment>
            }
            style={{ margin: "0 0 0 20px" }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
        <div className="chips">
          {this.state.pollAnswer.map((name, index) => (
            <div className="chip">
              <div className="label">{name}</div>
              <Chip className="button" onDelete={this.handleDelete(name)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
