import React, { Component } from "react";
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";
import moment from "moment";
import TablePagination from "../component/table/";
import { withRouter } from "react-router";
import ProjectDashboardToolbar from "../component/toolbar/ProjectDashboardToolbar";
import ProjectCard from "../component/card/ProjectCard";
import { Loader, List } from '../component'
import * as projectStatuses from "../constants/projectStatuses";
import ModalCreateContract from "../component/modal/ModalCreateContract";
import DrugsTableToolbar from "../component/toolbar/DrugsTableToolbar";


//styles
import "../component/dashboard/Dashboard.scss";
// import "./Projects.scss";

@withRouter
class Drugs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isFilterOpened: false
    };
  }
  render() {
    const { selected, rowsPerPage } = this.state;
    const { drugs: { isRequesting, items } } = this.props

    console.log(items)

    return isRequesting ? (
      <Loader />
    ) : (
      <Paper className="drugs">
        <DrugsTableToolbar
          toggleFilter={this.handleToggleFilter}
          withFilter={this.state.isFilterOpened}
          handleOpenModal={this.props.toggleModalCreateContract}
        />
        {
          isRequesting
          ? <Loader />
          : <List
              items={items.map(_item => ({..._item, manufacturer: _item.manufacturer.title}))}
              keys={['brand', 'title', 'manufacturer']}
              headers={['Название', 'Описание', 'Производитель']}
              />
         }

      </Paper>
    );
  }
}

export default Drugs;
