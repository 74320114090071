import React, { Component } from "react";
import { Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { Loader, List } from '../component'
import './MyPharms.scss'

const PharmCard = ({ pharm, onClick }) => (
  <div className='pharm-card' onClick={onClick}>
    <Typography>Название</Typography>
    <Typography className='value'>{ pharm.title }</Typography>
    <Typography>Адрес</Typography>
    <Typography className='value'>{ pharm.address }</Typography>
  </div>
)

//styles
import "../component/dashboard/Dashboard.scss";
// import "./Projects.scss";

@withRouter
class MyPharms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isFilterOpened: false
    };
  }
  componentDidMount = () => {
    const { metaActions: { updateNavi } } = this.props
    updateNavi([{ title: 'Мои аптеки', to: '/drugstore' }])

  }
  handlePharmClick = id => {
    this.props.history.push(`/drugstore/${id}`)
  }
  render() {
    const { isRequesting, pharms } = this.props

    return isRequesting ? (
      <Loader />
    ) : (
      <Paper className="drugs">
        {
          pharms.length === 0
          ? <Typography>К вашему аккаунту не прикреплено ни одной аптеки</Typography>
          : (
            <div className='mypharms'>
              {
                pharms.map((pharm, key) =>
                  <PharmCard
                    onClick={() => this.handlePharmClick(pharm.id)}
                    pharm={pharm}
                    key={key}
                    />)
              }
            </div>
          )
        }
      </Paper>
    );
  }
}

export default MyPharms;
