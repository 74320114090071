import React, { Component } from "react";
import { Loader } from "../../component";
import Info from "../Profile/Info";
import "../Profile/Profile.scss";

class Profile extends Component {
    render() {
        return (
            <div className="profile__wrapper">
                {this.props.profile.isRequesting ? (
                    <Loader />
                ) : (
                    <Info employee {...this.props} />
                )}
            </div>
        );
    }
}

export default Profile;
