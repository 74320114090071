// import { contractTypes } from '../types'

import { modalTypes } from "../../types";

const initialState = {
  isSubmiting: false,
  isError: false,
  isOpenModal: false,
  isOpenModalCreateContract: false,
  isOpenModalCreateTask: false,
  isOpenModalWizzard: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case modalTypes.OPEN_MODAL:
      return {
        ...state,
        isOpenModal: true
      };
    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        isOpenModal: false
      };
    case "SUBMITING_FORM":
      return {
        ...state,
        isSubmiting: true
      };
    case "SUBMITED_FORM":
      return {
        ...state,
        isSubmiting: false
      };
    case "TOGGLE_MODAL_CREATE_CONTRACT":
      return {
        ...state,
        isOpenModalCreateContract: !state.isOpenModalCreateContract
      };
    case "TOGGLE_MODAL_CREATE_TASK":
      return {
        ...state,
        isOpenModalCreateTask: !state.isOpenModalCreateTask
      };
    case "TOGGLE_MODAL_MODAL_WIZARD":
      return {
        ...state,
        isOpenModalWizzard: !state.isOpenModalWizzard
      };
    default:
      return state;
  }
};
