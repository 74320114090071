import * as contractActions from "./contract";
import * as projectActions from "./project";
import * as geoActions from "./geo";
import * as authActions from "./auth";
import * as metaActions from "./meta";
import * as filesActions from "./files";
import * as drugsActions from "./drugs";
import * as companiesActions from "./companies";
import * as manufacturersActions from "./manufacturers";
import * as materialsActions from "./materials";
import * as tasksActions from "./tasks";
import * as pharmaciesActions from "./pharmacies";
import * as pharmaciesGroupsActions from "./pharmaciesGroups";
import * as usersActions from "./users";
import * as internalCompaniesActions from "./internalCompanies";
import * as commentsActions from "./comments";
import * as courseActions from "./course";
import * as positionsActions from "./positions";
import * as profileActions from "./profile";
import * as ticketsActions from "./tickets";
import * as infoBarActions from "./infoBar";
import * as externalDocumentsActions from "./externalDocuments";
import * as modalActions from "./modal/modal";
import * as currentManufacturerActions from './currentManufacturer'
import * as workPlacesActions from "./workPlaces";
import { rootTypes } from "../types";

const url =  process.env.BASE_API_URL

const getRootResourse = () =>
  dispatch => {
    dispatch((() => ({ type: rootTypes.GET_RESOURSES__REQUEST }))())
    new Promise(resolve =>
      fetch(
        `${url}/v1`,
        {
          headers: {
            Accept: "application/json",
          },
          mode: 'cors'
        },
      ).then(data =>
        resolve(data.json())))
        .then(data =>
          dispatch((() => ({
            type: rootTypes.GET_RESOURSES__SUCCESS,
            payload: {
              resourses: ((resourses, obj) =>
                resourses.map(r => obj[r.type] = `${url}${r.resource}`) && obj)(data.resourceListings, {})
            }
          }))()))
  }

export {
  contractActions,
  externalDocumentsActions,
  projectActions,
  filesActions,
  internalCompaniesActions,
  infoBarActions,
  drugsActions,
  manufacturersActions,
  materialsActions,
  tasksActions,
  companiesActions,
  ticketsActions,
  metaActions,
  geoActions,
  usersActions,
  courseActions,
  authActions,
  pharmaciesGroupsActions,
  positionsActions,
  pharmaciesActions,
  getRootResourse,
  commentsActions,
  profileActions,
  currentManufacturerActions,
  workPlacesActions,
    modalActions
};
