import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { commentsActions } from '../actions'
import { Comments } from '../component'
import { withRouter } from 'react-router'

@withRouter
class CommentsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null
    }
  }

  handleCommentSend = comment => {
    const { commentsActions: { sendComment } } = this.props
    sendComment(comment)
  }

  render() {
    return <Comments {...this.props} onSend={this.handleCommentSend} />
  }
}

const mapStateToProps = state => ({
  comments: state.comments
})
const mapDispatchToProps = dispatch => ({
  commentsActions: bindActionCreators(commentsActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsContainer)
