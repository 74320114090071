export let createError = err => ({
  type: `[ERROR_API]`,
  payload: {
    errorMessage: `${err.status} - ${err.title.toUpperCase()}`
  }
});

export let createAction = (action, payload) => ({
  type: `[API] ${action}`,
  payload
});

const UUID = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/g;

// let checkNull = item => (item === null ? "" : `/${item}`);
export const getID = item =>
  item === "" ? "" : item.match(UUID) ? `/${item.match(UUID)}` : `/${item}`;

//
