import React, { Component } from 'react'
import { LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import './style.scss'

const PharmsLinearProgress = withStyles({
	root: {
	    backgroundColor: 'rgba(0, 121, 107, 0.2)',
	}, 
	bar: {
	    backgroundColor: 'rgba(0, 121, 107, 0.8)',
	}
})(LinearProgress)

export class ImportPharmacies extends Component {
	constructor(props) { super(props) }
	render() {
		const errors = this.props.pharmaciesImport.itemsMeta.filter(item => item.isError)
		return (
			<div className={`info__wrapper import_pharmacies`} onClick={this.props.onPanelClick}>
				<div className="text">
					<p>{this.props.pharmaciesImport.isSuccessfullyImport ? 'Импорт аптек завершен' : 'Импорт аптек'}</p>
					{
						errors.length > 0 && <p className="errors">Ошибок: {errors.length}</p>
					}
				</div>
				<PharmsLinearProgress variant="determinate" value={this.props.pharmaciesImport.progress} />
			</div>
			)
	}
}