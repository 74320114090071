import { tasksTypes } from '../types'
import { currentApiUrl, post, postFile, getOne, patch, get } from '../api'
import { history } from '../'

export const saveTask = (task, subtasks, { path, push }) =>
  dispatch => {
    dispatch((() => ({ type: tasksTypes.CREATE_TASK__REQUEST }))())
    const _photos = Array.from(task.preview)
    Promise.all(_photos.map(_photo => postFile(`${currentApiUrl}/v1/static-files`, { file: _photo, type: 'task_preview' }))).then(_preview => {
      post(`${currentApiUrl}/v1/project-tasks`, {
        ...task,
        coordinates: {
          title: task.coordinates.title,
          coordinates: { lat: task.coordinates.lat, lon: task.coordinates.lon }
        },
        preview: _preview[0].self
      }).then(_task => {
        const _serializedSubtasks = subtasks.map(_subtask => {
          if (_subtask.type === 'drugs_supply') {
            return {
              ..._subtask,
              drug: _subtask.drug.self
            }
          } else if (_subtask.type === 'personal_training') {
            return {
              ..._subtask,
              course: _subtask.course.self,
              trainee: _subtask.trainee.self,
            }
          } else return _subtask
        })
        Promise.all(_serializedSubtasks.map(subtask => post(`${currentApiUrl}/v1/subtask-creation-requests`, { ...subtask, task: _task.self }))).then(data => {
          dispatch((() => ({ type: tasksTypes.CREATE_TASK__SUCCESS }))())
          push(path.url.replace('new-task', `tasks/${_task.id}`))
        })
      })
    })
  }

export const updateTask = (task, subtasks, { path, push }) =>
  dispatch => {
    dispatch((() => ({ type: tasksTypes.UPDATE_TASK__REQUEST }))())
    patch(`${currentApiUrl}/v1/project-tasks/${task.id}`, { title: task.title, description: task.description }).then(_task => {
      const serializedSubtasks = subtasks.map(_subtask => {
          if (_subtask.type === 'drugs_supply') {
            return {
              ..._subtask,
              drug: _subtask.drug.self
            }
          } else if (_subtask.type === 'personal_training') {
            return {
              ..._subtask,
              course: _subtask.course.self,
              trainee: _subtask.trainee.self,
            }
          } else return _subtask
        })
      const newSubtasks = serializedSubtasks.filter(_subtask => _subtask.isNew)
      for (let _subtask in newSubtasks) {
        delete newSubtasks[_subtask].isNew
      }
      const modyfiedSubtasks = serializedSubtasks.filter(_subtask => !_subtask.isNew)
      Promise.all(newSubtasks.map(_subtask => post(`${currentApiUrl}/v1/subtask-creation-requests`, { ..._subtask, task: task.self }))).then(data => {
        // Promise.all(modyfiedSubtasks.map(_subtask => patch()))
        dispatch((() => ({ type: tasksTypes.UPDATE_TASK__SUCCESS }))())
        push(path.url.replace('new-task', `tasks/${task.id}`))
        // location.reload()
      })
    })
  }

export const getTask = id =>
  (dispatch, getState) => {
    dispatch((() => ({ type: tasksTypes.GET_TASK__REQUEST }))())
    getOne(`${currentApiUrl}/v1/project-tasks`, { id }).then(_task => {
      if (_task.medicalInstitution) {
        get(`${currentApiUrl}${_task.medicalInstitution}`).then(_pharmacy => {
          get(`${currentApiUrl}${_task.assignedTo}`).then(_user => {
            get(`${currentApiUrl}${_task.subtasks}`).then(_subtasks => {
              Promise.all(_subtasks.items.map((_subtask, _index) => {
                if (_subtask.type === 'drugs_supply') {
                  return get(`${currentApiUrl}${_subtask.drug}`).then(_drug => 
                    ({ ..._subtask, drug: _drug })
                  )
                } else if (_subtask.type === 'personal_training') {
                  return get(`${currentApiUrl}${_subtask.courseAccess}`).then(_access => 
                    get(`${currentApiUrl}${_access.course}`).then(_course => 
                      get(`${currentApiUrl}${_subtask.trainee}`).then(_trainee => 
                        ({ ..._subtask, course: _course, trainee: _trainee })
                      )
                    )
                  )
                } else {
                  return _subtask
                }
              })).then(_extendedSubtasks => {
                const _withSubtasksAndPharmacy = { ..._task, assignedTo: _user, subtasks: {items: _extendedSubtasks}, medicalInstitution: _pharmacy }
                dispatch((() => ({
                  type: tasksTypes.GET_TASK__SUCCESS,
                  payload: { item: { ..._withSubtasksAndPharmacy, coordinates: _withSubtasksAndPharmacy.medicalInstitution ? _withSubtasksAndPharmacy.medicalInstitution.coordinates : _withSubtasksAndPharmacy.coordinates } }
                }))())
              })
            })
          })
        })
      } else {
        get(`${currentApiUrl}${_task.assignedTo}`).then(_user => {
          get(`${currentApiUrl}${_task.subtasks}`).then(_subtasks => {
            Promise.all(_subtasks.items.map((_subtask, _index) => {
              if (_subtask.type === 'drugs_supply') {
                return get(`${currentApiUrl}${_subtask.drug}`).then(_drug => 
                  ({ ..._subtask, drug: _drug })
                )
              } else if (_subtask.type === 'personal_training') {
                return get(`${currentApiUrl}${_subtask.courseAccess}`).then(_access => 
                  get(`${currentApiUrl}${_access.course}`).then(_course => 
                    get(`${currentApiUrl}${_subtask.trainee}`).then(_trainee => 
                      ({ ..._subtask, course: _course, trainee: _trainee })
                    )
                  )
                )
              } else {
                return _subtask
              }
            })).then(_extendedSubtasks => {
              const _withSubtasks = { ..._task, assignedTo: _user, subtasks: {items: _extendedSubtasks} }
              dispatch((() => ({
                type: tasksTypes.GET_TASK__SUCCESS,
                payload: { item: { ..._withSubtasks, coordinates: _withSubtasks.medicalInstitution ? _withSubtasks.medicalInstitution.coordinates : _withSubtasks.coordinates } }
              }))())
            })
          })
        })
      }
    })
  }

export const clearTask = () => ({ type: tasksTypes.CLEAR_TASK })
