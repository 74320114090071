import React, { Component } from "react";
import "./GeneralContract.scss";
import {
  Modal,
  Tabs,
  Tab,
  InputBase,
  Typography,
  Badge,
  Fab
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

import SwipeableViews from "react-swipeable-views";

import { Log } from "../../component/logsType/ComponentLog";
import { ComponentDocumentLog } from "../../component/logsType/ComponentDocumentLog";


let styleTabsNames = {
  flexContainer: "flexContainer",
  centered: "centered",
  scroller: "scroller",
  fixed: "fixed",
  scrollable: "scrollable",
  scrollButtons: "scrollButtons",
  scrollButtonsAuto: "scrollButtonsAuto",
  indicator: "indicator"
};

let styleTabNames = {
  labelIcon: "labelIcon",
  textColorInherit: "textColorInherit",
  textColorPrimary: "textColorPrimary",
  textColorSecondary: "textColorSecondary",
  selected: "selected",
  disabled: "disabled",
  fullWidth: "fullWidth",
  wrapper: "wrapper",
  labelContainer: "labelContainer",
  label: "label",
  labelWrapped: "labelWrapped"
};

const TabContainer = ({ children, dir }) => <div>{children}</div>;

const DocumentContentControl = () => {
  return (
    <div className="contentControl">
      <div className="column">
        <Badge className="badge" badgeContent={1} max={999} color="primary">
          <Typography variant={"h6"}>Внутрение</Typography>
        </Badge>
      </div>
      <div className="actions">
      </div>
      <div className="column">
        <Typography variant={"h6"}>Внешние</Typography>
      </div>
    </div>
  );
};

const LinkContractTitle = () => {
  return (
    <div className="linkContractContainer">
      <Typography className="linkContractTitle" variant="h6">
        Управляющий контракт
      </Typography>
      <div className="logs">
        <Log />
      </div>
    </div>
  );
};

const LinkContractControl = () => {
  return (
    <div className="contentControl">
      <div className="column">
        <Badge className="badge" badgeContent={1} max={999} color="primary">
          <Typography variant={"h6"}>Паритетные контракты</Typography>
        </Badge>
      </div>
      <div className="actions">
        {/*<Fab className="button" variant="extended" aria-label="Delete">*/}
          {/*<AddIcon />*/}
          {/*Добавить*/}
        {/*</Fab>*/}
      </div>
      <div className="column">
        <Typography variant={"h6"}>Подчиненные контракты</Typography>
      </div>
    </div>
  );
};

class GeneralContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    let { open } = this.props;

    let contracts = {
      _links: {
        document: {
          href: "/v1/static-files/00000000-0000-0000-0000-000000000000"
        },
        internalCompany: {
          href: {
            address: {
              full: "г Москва, Академический р-н, ул Вавилова, д 19",
              short: "г Москва, ул Вавилова, д 19"
            },
            itn: "3664069397",
            name: "string"
          }
        },
        manager: {
          email: "admin@gmail.com",
          fullName: "Maksim Yalagin Dubaevich",
          gender: "female",
          id: "00000000-0000-0000-0000-000000000000",
          phone: "+79115534343"
        },
        manufacturer: {
          address: {
            full: "г Москва, Академический р-н, ул Вавилова, д 19",
            short: "г Москва, ул Вавилова, д 19"
          },
          itn: "3664069397",
          name: "string"
        },
        projects: [
          {
            availability: {
              from: "2015-05-05T23:59:59+00:00",
              till: "2015-05-05T23:59:59+00:00"
            },
            id: "00000000-0000-0000-0000-000000000000",
            status: "active",
            title: "string"
          }
        ]
      },
      contractNumber: "566-im",
      expirationDate: "2015-05-05T23:59:59+00:00",
      id: "00000000-0000-0000-0000-000000000000",
      signDate: "2015-05-05T23:59:59+00:00",
      summaryPayment: {
        amount: 15000000,
        currency: "rub"
      },
      title: `Поставка 10 000 упаковок Лоратодина gsk`,
      type: "with_manufacturer"
    };

    return (
      <Modal className="modal_wrapper" open={open}>
        <div className="modal">
          <div className="title">
            <IconButton className="close" aria-label="Delete">
              <CloseIcon fontSize="small" onClick={this.props.handleOpenModal}/>
            </IconButton>
            <div>{`Контракт ${contracts.title}`}</div>
          </div>
          <div className="tabControl">
            <Tabs
              className="tabs"
              centered
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              classes={styleTabsNames}
            >
              <Tab className="tab" classes={styleTabNames} label="Документы" />
              <Tab className="tab" classes={styleTabNames} label="Логи" />
              <Tab
                className="tab"
                classes={styleTabNames}
                label="Связанные контракты"
              />
              {/*<Tab className="tab" classes={styleTabNames} label="Проекты" />*/}
            </Tabs>
          </div>
          <div className="_search">
            <SearchIcon />
            <InputBase
              placeholder="Search…"
              classes={{
                root: "inputRoot",
                input: "inputInput"
              }}
            />
          </div>
          {this.state.value === 0 ? <DocumentContentControl /> : null}
          {this.state.value === 2 ? <LinkContractTitle /> : null}
          {this.state.value === 2 ? <LinkContractControl /> : null}

          <div className="content">
            <SwipeableViews
              axis={"x"}
              index={this.state.value}
              style={{ height: "100%", overflowY: "hidden" }}
              onChangeIndex={this.handleChangeIndex}
            >

              <div className="document">
                <div className="column">
                  <ComponentDocumentLog />
                </div>
                <div className="column">
                  <ComponentDocumentLog />
                </div>
              </div>
              <div className="logs">
                <div className="column">
                  <div className="row">
                    <div className="row_title">На этой недели</div>
                    <Log />
                  </div>
                  <div className="row">
                    <div className="row_title">На прошлой недели</div>
                    <Log />
                  </div>
                </div>
              </div>
              <div className="link-contract">
                <div className="column">
                  <ComponentDocumentLog />
                </div>
                <div className="column">
                  <ComponentDocumentLog />
                </div>
              </div>
              {/*<div className="project">project</div>*/}
            </SwipeableViews>
          </div>
        </div>
      </Modal>
    );
  }
}

export default GeneralContract;
