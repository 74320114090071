import { authTypes } from "../types";
import { requestSms, generateToken } from "../api/auth";
import { getOne, get, currentApiUrl } from "../api";

export const sendPhone = ({ tel }) => (dispatch, getState) => {
  // const resourses = getState().meta.resourses
  // console.log(resourses)
  dispatch((() => ({ type: authTypes.SEND_NUMBER__REQUEST }))());
  requestSms({ tel })
    .then(data =>
      dispatch((() => ({ type: authTypes.SEND_NUMBER__SUCCESS }))())
    )
    .catch(error =>
      dispatch((() => ({ type: authTypes.SEND_NUMBER__FAILURE }))())
    );
};

export const sendCode = ({ tel, code }) => (dispatch, getState) => {
  dispatch((() => ({ type: authTypes.SEND_CODE__REQUEST }))());
  generateToken({ tel, code })
    .then(data => {
      localStorage.setItem("bearer_token", data.accessToken.token);
      localStorage.setItem(
        "user_id",
        data.accessToken.user.split("/").splice(-1)[0]
      );
      location.reload();
      dispatch((() => ({ type: authTypes.SEND_CODE__SUCCESS }))());
    })
    .catch(err => dispatch((() => ({ type: authTypes.SEND_CODE__FAILURE }))()));
};

export const getCurrentUser = () => (dispatch, getState) => {
  dispatch((() => ({ type: authTypes.GET_USER__REQUEST }))());
  const resourses = getState().meta.resourses;
  getOne(resourses.users, { id: localStorage.getItem("user_id") }).then(_user => {
    get(`${currentApiUrl}${_user.managedMedicalInstitutions}`).then(_pharms => {
      dispatch((() => ({
        type: authTypes.GET_USER__SUCCESS,
        payload: { user: {..._user, managedMedicalInstitutions: _pharms.items} }
      }))())
    })
  });
};

export const signout = () => {
  localStorage.clear();
  location.reload();
  return {
    type: "signout"
  };
};
