import React, { Component } from "react";
import { createPortal } from "react-dom";
import Modal from "../../../component/modal/Modal";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./styles.scss";
import { MaskedInput } from "../../index";
import * as authSteps from "../../../constants/authSteps";
import TextField from "@material-ui/core/TextField";
import { Loader } from "../../../component";

class ChangePhoneNumberModal extends Component {
  constructor(props) {
    super(props);
    let { phone } = props;
    const plusIndex = phone.indexOf("+");
    if (plusIndex !== -1) {
      phone = phone.split("");
      phone.splice(plusIndex, 1);
      phone = phone.join("");
    }
    this.state = {
      phone,
      code: ""
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleConfirm = () => {
    const { step, requestSmsCode, confirmSmsCode } = this.props;
    const { phone, code } = this.state;
    switch (step) {
      case authSteps.SEND_PHONE:
        return () => requestSmsCode({ phone: `+${phone}` });
      case authSteps.SEND_CODE:
        return () =>
          confirmSmsCode({ phone: `+${phone}`, smsConfirmationCode: code });
    }
  };

  render() {
    const { phone, code } = this.state;
    const {
      closeModal,
      isErrorConfirmCode,
      step,
      isRequestingChangeNumber,
        isErrorRequestCode,
        backToChangePhone
    } = this.props;
    return createPortal(
      <Modal className="modal-number">
        <div className="modal-number__title">
          <div>Смена номер телефона</div>
          <IconButton
            className="close"
            aria-label="Delete"
            onClick={closeModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="modal-number__phone">
          <MaskedInput
            onChange={this.handleChange}
            label="Номер телефона"
            name="phone"
            margin="normal"
            validate
            variant="outlined"
            value={phone}
            error={isErrorRequestCode}
            disabled={step === authSteps.SEND_CODE}
            fullWidth
          />
        </div>
          <TextField
            id="password"
            label="Kод из СМС"
            value={code}
            name="code"
            onChange={this.handleChange}
            margin="normal"
            variant="outlined"
            type="number"
            error={isErrorConfirmCode}
            disabled={step === authSteps.SEND_PHONE}
          />
        <div className="modal-number__group">
          {step === authSteps.SEND_CODE && (
            <button
              className={`modal-number__btn modal-number__btn_cancel`}
              onClick={backToChangePhone}
            >
              Изменить номер телефона
            </button>
          )}
          <button
            className={`modal-number__btn modal-number__btn_confirm`}
            onClick={this.handleConfirm()}
          >
            {isRequestingChangeNumber ? <Loader small /> : step === authSteps.SEND_PHONE
              ? "Подтвердить"
              : "Отправить код"}
          </button>
        </div>
      </Modal>,
      document.querySelector("#modal-dom")
    );
  }
}

export default ChangePhoneNumberModal;
