import React, { Component, Fragment } from 'react'
import { Modal, Loader, Autocomplete } from '../component'
import { Button, Input, RadioGroup, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { Geo, Course, Poll, Photos, Message, Location, Drugs, Survey } from './subtasks'
import { SearchInput } from '../component/search'
import { roundToPrecision } from '../utils'
import { YMaps, Map, Placemark } from "react-yandex-maps";

import { UploadIcon, GeoIcon, PhotoIcon, MessageIcon, PollIcon, StatPollIcon, CourseIcon,
  PillIcon, CancelIcon, DeleteBucketIcon } from '../static/imgs'

import { GeoSubtask, PhotoSubtask, MessageSubtask, PollSubtask, StatPollSubtask, CourseSubtask,
  DrugsSubtask  } from '../component/subtask/index.jsx'

import './Task.scss'

const renderInitialDataForType = type => {
  switch (type) {
    case 'geolocation':
    case 'message': return {}
    case 'poll': return {
      questionText: '',
      expectedAnswer: 1,
      answers: ['', '']
    }
    case 'statistical_survey': return {
      questionText: '',
      expectedAmount: 0
    }
    case 'photos': return {
      minimumAmount: 0
    }
    case 'drugs_supply': return {
      expectedAmount: 0,
      drug: ''
    }
    case 'personal_training': return {
      course: '',
      trainee: ''
    }
    default: break;
  }
}

const CreateSubtask = ({ ...props }) => (
  <div className='addSubtask'>
    <div className='type'>
      <div className='icon'>{props.icon}</div>
      <p className='title'>{props.title}</p>
    </div>
    <div className='counter'>{props.count ? props.count : 0}</div>
    <div className='plus' onClick={() => props.onAddSubtaskClick(props.type)}>+</div>
  </div>
)

const subtasksTypes = [
  {
    type: 'geolocation',
    icon: <GeoIcon />,
    title: 'Геопозиция'
  }, {
    type: 'photos',
    icon: <PhotoIcon/>,
    title: 'Фотографии'
  }, {
    type: 'message',
    icon: <MessageIcon/>,
    title: 'Сообщения'
  }, {
    type: 'poll',
    icon: <PollIcon/>,
    title: 'Опрос'
  }, {
    type: 'statistical_survey',
    icon: <StatPollIcon/>,
    title: 'Стат. опрос'
  }, {
    type: 'personal_training',
    icon: <CourseIcon/>,
    title: 'Курсы'
  }, {
    type: 'drugs_supply',
    icon: <PillIcon/>,
    title: 'Препарат'
  }
]
const randTask = length => Array.from({ length }, () => ({ type: subtasksTypes[Math.floor(Math.random() * 7)].type }))

class Task extends Component {
  constructor (props) {
    super(props)
    this.state = {
      _isMenuOpened: true,
      _modyfiedFields: {
        title: false,
        description: false,
        assignedTo: false,
        coordinates: false,
        subtasks: []
      },
      subtasks: [],
      preview: [],
      title: '',
      description: '',
      coordinates: { lat: 59.9, lon: 30.3, title: '' },
      assignedTo: '',
    }
  }
  componentDidMount = () => {
    const { task } = this.props
    if (!this.props.isNew) {
      const _coords = task.item.coordinates.coordinates || task.item.coordinates
      this.props.geoActions.getPlace({coords: [_coords.lat, _coords.lon]})
      this.setState(state => ({
              title: task.item.title,
              description: task.item.description,
              subtasks: task.item.subtasks.items,
              assignedTo: task.item.assignedTo,
              preview: [task.item.preview],
              _modyfiedFields: {
                ...state._modyfiedFields,
                subtasks: task.item.subtasks.items.map(() => false)
              }
            }))
    } else {
      this.setState({
        _isMenuOpened: true,
        subtasks: [],
        preview: [],
        title: '',
        description: '',
        coordinates: { lat: 59.9, lon: 30.3, title: '' },
        assignedTo: '',
      })
    }
  }
  toggleMenu = () => {
    this.setState(
      () => ({_isMenuOpened: !this.state._isMenuOpened}),
    )
  }
  onAddSubtaskClick = type => {
    this.setState(
      state => ({ subtasks: [...state.subtasks, { type, ...renderInitialDataForType(type), isNew: true } ] })
    )
  }

  handleDrugChange = value => value !== '' && this.props.drugsActions.getDrugsByQuery(value)
  handleUserChange = value => value !== '' && this.props.usersActions.getUsersByQuery(value)
  handleCourseChange = value => value !== '' && this.props.courseActions.getCoursesByQuery(value)

  renderSubtask = (item, index) => {
    const { _modyfiedFields: { subtasks } } = this.state
    switch (item.type) {
      case 'geolocation': return <GeoSubtask isNew={item.isNew} isModyfied={subtasks[index]} key={index} item={item} onChange={_subtask => this.handleChangeSubtask(_subtask, index)} onDelete={() => this.onDeleteSubtaskClick(index)} />;
      case 'photos': return <PhotoSubtask isNew={item.isNew} isModyfied={subtasks[index]} key={index} item={item} onChange={_subtask => this.handleChangeSubtask(_subtask, index)} onDelete={() => this.onDeleteSubtaskClick(index)} />;
      case 'poll': return <PollSubtask isNew={item.isNew} isModyfied={subtasks[index]} key={index} item={item} onChange={_subtask => this.handleChangeSubtask(_subtask, index)} onDelete={() => this.onDeleteSubtaskClick(index)} />;
      case 'statistical_survey': return <StatPollSubtask isNew={item.isNew} isModyfied={subtasks[index]} key={index} item={item} onChange={_subtask => this.handleChangeSubtask(_subtask, index)} onDelete={() => this.onDeleteSubtaskClick(index)} />;
      case 'message': return <MessageSubtask isNew={item.isNew} isModyfied={subtasks[index]} key={index} item={item} onChange={_subtask => this.handleChangeSubtask(_subtask, index)} onDelete={() => this.onDeleteSubtaskClick(index)} />;
      case 'drugs_supply': 
        return <DrugsSubtask
                isNew={item.isNew} 
                isModyfied={subtasks[index]} 
                key={index} 
                drugs={this.props.drugs} 
                item={item} 
                onChange={_subtask => this.handleChangeSubtask(_subtask, index)} 
                onDelete={() => this.onDeleteSubtaskClick(index)} 
                onDrugChange={this.handleDrugChange}
                />;
      case 'personal_training': 
        return <CourseSubtask 
                isNew={item.isNew} 
                isModyfied={subtasks[index]} 
                key={index} 
                item={item} 
                onChange={_subtask => this.handleChangeSubtask(_subtask, index)} 
                onDelete={() => this.onDeleteSubtaskClick(index)} 
                courses={this.props.courses}
                users={this.props.users}
                onCourseChange={this.handleCourseChange}
                onUserChange={this.handleUserChange}
                />;
      default: break;
    }
  }
  onDeleteSubtaskClick = index => {
    this.setState(state => ({subtasks: state.subtasks.filter((_, _index) => index !== _index)}))
  }
  handleDeletePhoto = index => this.setState(state => ({
    preview: Array.from(state.preview).filter((_, _index) => _index !== index)
  }))
  handleFileChange = ({ target: { files } }) => {
    this.setState(
      { preview: files },
      // { preview: this.state.preview.length === 0 ? files : [...files, ...this.state.preview] },
    )
  }
  handleChangeSubtask = (subtask, index) => {
    this.setState(
      state => ({ subtasks: state.subtasks.map((_subtask, _index) => index === _index ? subtask : _subtask) }),
      this.props.isNew
      ? () => null
      : () => this.setState(state => ({
        _modyfiedFields: {
          ...state._modyfiedFields,
          subtasks: state._modyfiedFields.subtasks.map((_subtask, _key) => _key === index ? true : _subtask)
        }
      }))
    )
  }
  handleFieldChange = (field, value) => this.setState(
    () => ({ [field]: value }),
    this.props.isNew
    ? () => null
    : () => this.setState({ _modyfiedFields: { ...this.state._modyfiedFields, [field]: this.state[field] !== this.props.task.item[field] } })
  ) 
  loadPreview = photo => photo.url || URL.createObjectURL(photo)

  handleMapClick = coords => {
    this.props.geoActions.getPlace({coords})
    this.setState(
      () => ({ coordinates: { lat: roundToPrecision(coords[0], 6), lon: roundToPrecision(coords[1], 6) } }),
      this.props.isNew
      ? () => null
      : () => {
        this.setState(state => ({
          ...state,
          _modyfiedFields: {
            ...state._modyfiedFields,
            coordinates: state.coordinates.lat !== this.props.task.item.coordinates.lat && state.coordinates.lon !== this.props.task.item.coordinates.lon
          }
        }))
      }
    )
  }

  handleSaveTask = () => {
    this.props.tasksActions.saveTask({
      title: this.state.title,
      description: this.state.description,
      project: `/v1/projects/${this.props.match.params.id}`,
      coordinates: { ...this.state.coordinates, title: this.props.geo.title },
      assignedTo: this.state.assignedTo.self,
      preview: this.state.preview,
    }, this.state.subtasks, { push: this.props.history.push, path: this.props.match })
  }
  handleUpdateTask = () => {
    const { _modyfiedFields, subtasks } = this.state
    let _modyfied = {}
    for (let key in _modyfiedFields) {
      if (_modyfiedFields[key] === true) {
        _modyfied[key] = this.state[key]
      }
    }
    const _newAndModyfiedSubtasks = [...subtasks.filter((_subtask, _index) => _modyfiedFields.subtasks[_index] || _subtask.isNew)]
    this.props.tasksActions.updateTask(
      { ..._modyfied, id: this.props.task.item.id, self: this.props.task.item.self }, 
      _newAndModyfiedSubtasks,
      { push: this.props.history.push, path: this.props.match }
      )
  }
  handleChangeAssigned = value => {
    value !== '' && this.props.usersActions.getUsersByQuery(value)
  }
  handleSelectAssigned = user => {
    this.setState(
      () => ({ assignedTo: user }),
      this.props.isNew
      ? () => null
      : () => this.setState(state => ({
              _modyfiedFields: {
                ...state._modyfiedFields,
                assignedTo: this.state.assignedTo.self !== this.props.task.item.assignedTo.self
              }
            }))
    )
  }

  render () {
    const { subtasks, _modyfiedFields } = this.state
    const _task = this.state
    const { task } = this.props
    const preview = Array.from(this.state.preview)
    console.log(this.props);
    console.log(this.state);

    const __subtasksSummary = (
      (__summary, __subtasks) => {
        __subtasks.map(subtask => {
          __summary[subtask.type] = __summary[subtask.type]
          ? __summary[subtask.type] + 1
          : 1
        })
        return __summary
      }
    )({}, subtasks)

    return (
      <div className='taskCreate__wrapper'>
        <div className='photoInput__wrapper'>
          {
            preview.map((photo, index) => photo &&
              <div className='previewContainer'>
                <CancelIcon onClick={() => this.handleDeletePhoto(index)}/>
                <img key={index} src={this.loadPreview(photo)}/>
              </div>
            )
          }
          <input
            onChange={this.handleFileChange}
            className='photoInput'
            type='file'
            accept="image/*"
            id='file'
            multiple={false}
            />
          <label htmlFor="file"><div>+</div></label>
          <div className='photoInputPreview'></div>
        </div>
        <div className='task__menu'>
          <div className='title'>
            <input className={_modyfiedFields.title ? 'modyfied' : ''} type='text' placeholder='Введите название' value={_task.title} onChange={({target: { value }}) => this.handleFieldChange('title', value)} />
          </div>
          <div className={`map__wrapper ${_modyfiedFields.coordinates ? 'modyfied' : ''}`}>
            <div className={`content map ${this.props.isNew ? '' : 'disabled'}`}>
              <YMaps>
                <Map
                  width={"100%"}
                  height={"100%"}
                  defaultState={{ center: [this.state.coordinates.lat, this.state.coordinates.lon], zoom: 13 }}
                  onClick={this.props.isNew ? e => this.handleMapClick(e.get('coords')) : () => null}
                  >
                  <Placemark geometry={[this.state.coordinates.lat, this.state.coordinates.lon]} />
                </Map>
              </YMaps>
            </div>
            <input disabled={!this.props.isNew} type='text' placeholder='Адрес' value={this.props.geo.title}/>
          </div>
          <div className='description'>
            <textarea
              className={_modyfiedFields.description ? 'modyfied' : ''}
              type='text'
              placeholder='Введите описание'
              value={_task.description}
              onChange={({target: { value }}) => this.handleFieldChange('description', value)}
              />
          </div>
          <Autocomplete
            className={`seniorStaff ${_modyfiedFields.assignedTo ? 'modyfied' : ''}`}
            placeholder='Назначить медицинского представителя'
            value={_task.assignedTo.fullName}
            isRequesting={this.props.users.isRequesting}
            onChange={this.handleChangeAssigned}
            onSelect={this.handleSelectAssigned}
            suggestions={this.props.users.items}
            disabled={!this.props.isNew}
            suggestionsKey={'fullName'}
            />
        </div>
        <div className={`subtask__menu ${this.state._isMenuOpened ? 'opened' : 'hidden'}`}>
          <div className='toggleOpenMenu' onClick={this.toggleMenu}>
            <div className='icon'>{this.state._isMenuOpened ? '-' : '+'}</div>
            <p>{this.state._isMenuOpened ? 'Скрыть' : 'Показать'} меню подзадач</p>
          </div>
          <div className={`subtasks__row ${this.state._isMenuOpened ? 'opened' : 'disabled'}`}>
            {
              subtasksTypes.map((subtask, key) =>
              < CreateSubtask
                onAddSubtaskClick={this.onAddSubtaskClick}
                type={subtask.type}
                key={key}
                icon={subtask.icon}
                title={subtask.title}
                count={__subtasksSummary[subtask.type]}
                />
              )
            }
          </div>
        </div>
        <div className='subtask__items'>
          <div className='subtask__column-1'>
          {
            subtasks.filter((_, index) => index % 2 === 0).map((item, index) => this.renderSubtask(item, index * 2))
          }
          </div>
          <div className='subtask__column-2'>
          {
            subtasks.filter((_, index) => index % 2 === 1).map((item, index) => this.renderSubtask(item, index * 2 + 1))
          }
          </div>
        </div>
        <div className='saveButton_row'>
          <button className='save__button' onClick={this.props.isNew ? this.handleSaveTask : this.handleUpdateTask}>Сохранить</button>
        </div>
      </div>
    )
  }
}

export default Task
