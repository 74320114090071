import React from "react";
import {IconButton, withStyles} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import './Image.scss'

const CloseButton = withStyles({
    root: {
        backgroundColor: '#999',
        position: 'absolute',
        top: '10px',
        right: '10px'
    }
})(IconButton);

const CloseIcon = withStyles({
    root: {
        color: 'white'
    }
})(Close);

const FullScreenImage = ({ image, onClose, open }) => {
  return (
    <Modal onClose={onClose} open={open} disableAutoFocus>
      <div className='image-fullScreen__wrapper'>
        <img src={image} className='image-fullScreen__image' />
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </div>
    </Modal>
  );
};

export default FullScreenImage;
