import React from 'react'
import classNames from "classnames";
import {
  Fade,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import { Link } from "react-router-dom";

//styles
import "./buttonTheme.scss";
import {currentClientUrl} from "../../api";

// component

//            ! ALL PROPS REQUIRED
//  ............................................
//  :   classes:                 <Function>    :
//  :   icon:                    <Component>   :
//  :   label:                   <String>      :
//  :   value:                   <String>      :
//  :   active:                  <Bool>        :
//  :   textHidden:              <Bool>        :
//  ............................................
//                  ! STATE
//  ............................................
//  :   anchorEl:                <DOM obj>     :
//  :   open:                    <Bool>        :
//  ............................................
//                ! COMPONENT
//  ............................................
//  :   handleClick:             <Method>      :
//  ............................................

const ListButton = ({
  classes,
  icon,
  label,
  active,
  value,
  textHidden,
  handleClick,
  toolTipMessage
}) => {
  return (
    value.includes('COURSE_LIST') || value.includes('TRAINING')
    ? <a className='sidebar' href={currentClientUrl}>
        <ListItem
          className={`${active ? 'active ' : ''}button`}
          onClick={handleClick}
          button
        >
          <div className={`icon `}>
            {icon}
          </div>
          <ListItemText>
            {textHidden ? (
              <Fade in={textHidden}>
                <Typography variant="body1" className={'text'}>
                  {label}
                </Typography>
              </Fade>
            ) : <Typography className='toolTipMessage'>{toolTipMessage}</Typography>
            }
          </ListItemText>
        </ListItem>
      </a>
    : <Link to={`/${value.toLowerCase()}`} className='sidebar' style={{ textDecoration: "none" }}>
        <ListItem
          className={`${active ? 'active ' : ''}button`}
          onClick={handleClick}
          button
        >
          <div className={`icon `}>
            {icon}
          </div>
          <ListItemText>
            {textHidden ? (
              <Fade in={textHidden}>
                <Typography variant="body1" className={'text'}>
                  {label}
                </Typography>
              </Fade>
            ) : <Typography className='toolTipMessage'>{toolTipMessage}</Typography>
            }
          </ListItemText>
        </ListItem>
      </Link>
  );
};

export default ListButton;
