import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { tasksActions, metaActions, geoActions, usersActions, drugsActions, courseActions } from '../actions'
import { Task } from '../pages'
import { Loader } from '../component'
import { withRouter } from 'react-router'

@withRouter
class TaskContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { id: null, isNew: false }
  }

  componentDidMount = () => {
    const { metaActions: { updateNavi }, tasksActions: { getTask, clearTask }, match: { params: { id, taskId } } } = this.props
    if (this.props.match.path === '/projects/:id/tasks/:taskId') {
      this.setState({id: taskId, isNew: false})
      getTask(taskId)
      updateNavi([
        { title: 'Задачи проекта', to: `/projects/${id}` },
        { title: '', to: `/projects/${id}/tasks/${taskId}` }
      ])
    } else {
      clearTask()
      this.setState({ isNew: true })
      updateNavi([
        { title: 'Задачи проекта', to: `/projects/${id}` },
        { title: 'Новая задача', to: `/` }
      ])
    }
  }

  render() {
    const { task: { isRequesting, item } } = this.props
    return (!this.state.isNew && (isRequesting || item === null)) ? <Loader /> : <Task {...this.props} id={this.state.id} isNew={this.props.match.path !== '/projects/:id/tasks/:taskId'}/>
  }
}

const mapStateToProps = state => ({
  task: state.task,
  geo: state.geo,
  users: state.users,
  drugs: state.drugs,
  courses: state.courses
})
const mapDispatchToProps = dispatch => ({
  tasksActions: bindActionCreators(tasksActions, dispatch),
  geoActions: bindActionCreators(geoActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch),
  drugsActions: bindActionCreators(drugsActions, dispatch),
  courseActions: bindActionCreators(courseActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskContainer)
