import React, { Fragment } from "react";
import { tickets } from "../../constants";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import {
  CLOSED,
  IN_WORK,
  NEEDS_ADDITIONAL_INFO,
  OPEN
} from "../../constants/tickets";
import { ReactComponent as ClosedIcon } from "../../icons/svg/closed_status.svg";
import { ReactComponent as InWorkIcon } from "../../icons/svg/in_work_status.svg";
import { ReactComponent as OpenIcon } from "../../icons/svg/open_status.svg";
import { ReactComponent as NeedsAdditionalInfoIcon } from "../../icons/svg/needs_additional_info_status.svg";

const TicketComment = ({ comment, user, onImageClick }) => {
  const getChangeStatusMessage = (status, author, message) => {
    let messageTail = null;
    let icon = null;
    switch (status) {
      case CLOSED:
        icon = <ClosedIcon />;
        messageTail = (
          <Fragment>
            <b>закрыл(-а)</b> обращение
          </Fragment>
        );
        break;
      case IN_WORK:
        icon = <InWorkIcon />;
        messageTail = (
          <Fragment>изменил(-a) статус: {<b>"{message}"</b>}</Fragment>
        );
        break;
      case OPEN:
        icon = <OpenIcon />;
        messageTail = (
          <Fragment>изменил(-a) статус: {<b>"{message}"</b>}</Fragment>
        );
        break;
      case NEEDS_ADDITIONAL_INFO:
        icon = <NeedsAdditionalInfoIcon />;
        messageTail = (
          <Fragment>изменил(-a) статус: {<b>"{message}"</b>}</Fragment>
        );
        break;
    }
    return (
      <Fragment>
        <div className="status-card__icon">{icon}</div>
        <Typography variant="body2">
          {author} {messageTail}
        </Typography>
      </Fragment>
    );
  };

  const { changedToStatus, postedAt, author, text, attachments } = comment;
  const statusChangeMessage = tickets.translateChangedStatus(changedToStatus);
  const images = attachments.items.filter(({ mimeType }) =>
    mimeType.includes("image")
  );
  const files = attachments.items.filter(
    ({ mimeType }) => !mimeType.includes("image")
  );
  return (
    <>
      {
        <Typography
          style={{ textAlign: "center", marginTop: "5px" }}
          variant="body2"
        >
          {new Date(postedAt).toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Typography>
      }
      {statusChangeMessage && (
        <div className="card__status status-card__change">
          {getChangeStatusMessage(
            changedToStatus,
            author.fullName,
            statusChangeMessage
          )}
        </div>
      )}
      <Card className={`card ${user.self === author.self ? "cardAnswer" : ""}`}>
        <CardContent>
          <div className="personInfo">
            <div
              className="avatar"
              style={{
                backgroundImage: author.avatar
                  ? `url(${author.avatar.url})`
                  : "none"
              }}
            />
            <div className="text__block">
              <Typography variant="body1">{author.fullName}</Typography>
              <Typography variant="body2">Сотрудник поддержки</Typography>
            </div>
          </div>
          <Typography variant="body1" className="message">
            {text}
          </Typography>
          <div
            className={`attachments__list attachments__list_${
              attachments.items.length
            }`}
          >
            {images.map(image => (
              <img
                key={image.id}
                onClick={() => onImageClick(image.url)}
                src={image.url}
                className="attachments__item"
              />
            ))}
          </div>
          {files.map(file => (
            <Typography variant="body1" className="attachment">
              Вложение:{" "}
              <a target="_blank" href={file.url}>
                скачать
              </a>
            </Typography>
          ))}
        </CardContent>
        <CardActions style={{ justifyContent: "space-between" }}>
          <Typography variant="body2">
            {new Date(postedAt).toLocaleTimeString("ru-RU")}
          </Typography>
        </CardActions>
      </Card>
    </>
  );
};

export default TicketComment;
