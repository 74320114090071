import { workPlacesTypes } from '../types'

const initialState = {
    items: [],
    isRequesting: false,
    isRequestingDelete: false,
    isRequestingCreate: false,
    isError: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case workPlacesTypes.GET_WORK_PLACES: return {
            ...state,
            isRequesting: true,
            isError: false
        }
        case workPlacesTypes.GET_WORK_PLACES_SUCCESS: return {
            ...state,
            isRequesting: false,
            items:payload.items
        }
        case workPlacesTypes.GET_WORK_PLACES_FAIL: return {
            ...state,
            isRequesting: false,
            isError: true
        }
        case workPlacesTypes.CREATE_EMPLOYEE: return {
            ...state,
            isRequestingCreate: true,
        }
        case workPlacesTypes.CREATE_EMPLOYEE_FAIL:
        case workPlacesTypes.CREATE_EMPLOYEE_SUCCESS: return {
            ...state,
            isRequestingCreate: false,
        }
        case workPlacesTypes.DELETE_EMPLOYEE: return {
            ...state,
            isRequestingDelete: true,
        }
        case workPlacesTypes.DELETE_EMPLOYEE_FAIL:
        case workPlacesTypes.DELETE_EMPLOYEE_SUCCESS: return {
            ...state,
            isRequestingDelete: false,
        }
        default:
            return state
    }
}
