// apiEntities

const CONTRACTS = "contracts";
const PROJECTS = "projects";
const PROJECT_DOCUMENTS = "project-documents";
const ROJECT_TASKS = "project-tasks";
const SUBTASKS = "subtask-creation-requests";
const EPORTS = "reports";
const DRUGS = "drugs";
const MEDICAL_INSTITUTIONS = "medical-institutions";
const USERS = "users";
const MANUFACTURERS = "manufacturers";
const INTERNAL_COMPANIES = "internal-companies";
const STATIC_FILES = "static-files";

export {
  CONTRACTS,
  PROJECTS,
  PROJECT_DOCUMENTS,
  ROJECT_TASKS,
  SUBTASKS,
  EPORTS,
  INTERNAL_COMPANIES,
  DRUGS,
  MEDICAL_INSTITUTIONS,
  USERS,
  MANUFACTURERS,
  STATIC_FILES
};
