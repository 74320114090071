import React, { Component } from "react";
import YMap from "./map.jsx";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { geoActions } from "../../actions";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

// console.log(x, y, lat, lng, event);
class SimpleMapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { geo: { coords: [], title: "", isRequesting: false } };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.geo !== this.props.geo) {
      this.props.onChange(this.props.geo);
      this.setState({ geo: this.props.geo });
      // this.props.handleGetTitleMap(this.props.geo.title);
    }
  }

  handleClick = coordinates => {
    return this.props.getPlace({ coords: coordinates });
  };

  render() {
    return (
      <YMap
        handleClick={this.handleClick}
        handleSelect={this.props.handleSelect}
        handleClose={this.props.handleClose}
        geo={this.state.geo}
      />
    );
  }
}

const mapStateToProps = state => ({ geo: state.geo });
const mapDispatchToProps = dispatch => ({
  getPlace: bindActionCreators(geoActions.getPlace, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleMapContainer);
