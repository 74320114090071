export const GET_MANUFACTURERS__REQUEST = 'MANUFACTURER_TYPE__GET_MANUFACTURERS__REQUEST';
export const GET_MANUFACTURERS__FAILURE = 'MANUFACTURER_TYPE__GET_MANUFACTURERS__FAILURE';
export const GET_MANUFACTURERS__SUCCESS = 'MANUFACTURER_TYPE__GET_MANUFACTURERS__SUCCESS';
export const GET_MANUFACTURER__REQUEST = 'MANUFACTURER_TYPE__GET_MANUFACTURER__REQUEST';
export const GET_MANUFACTURER__FAILURE = 'MANUFACTURER_TYPE__GET_MANUFACTURER__FAILURE';
export const GET_MANUFACTURER__SUCCESS = 'MANUFACTURER_TYPE__GET_MANUFACTURER__SUCCESS';
export const CREATE_MANUFACTURER__REQUEST = 'MANUFACTURER_TYPE__CREATE_MANUFACTURER__REQUEST';
export const CREATE_MANUFACTURER__FAILURE = 'MANUFACTURER_TYPE__CREATE_MANUFACTURER__FAILURE';
export const CREATE_MANUFACTURER__SUCCESS = 'MANUFACTURER_TYPE__CREATE_MANUFACTURER__SUCCESS';
export const UPDATE_MANUFACTURER__REQUEST = 'MANUFACTURER_TYPE__UPDATE_MANUFACTURER__REQUEST';
export const UPDATE_MANUFACTURER__SUCCESS = 'MANUFACTURER_TYPE__UPDATE_MANUFACTURER__SUCCESS';
export const UPDATE_MANUFACTURER__FAILURE = 'MANUFACTURER_TYPE__UPDATE_MANUFACTURER__FAILURE';
