import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pharmaciesActions } from "../actions";
import { Links } from "../pages";

class LinksContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrollMax: false,
      count: 20
    };
  }

  componentDidMount = () => {
    const { pharmaciesActions: { getInvitesForPharm }, match: { params: { courseId, pharmId } } } = this.props
    getInvitesForPharm({ courseId: `/v1/courses/${courseId}`, pharmId: `/v1/medical-institutions/${pharmId}`}, { offset: 0, count: this.state.count })
    document.getElementById('linksWrapper').onscroll = e => {
      if ((80 * this.state.count - document.getElementById('linksWrapper').offsetHeight) === e.target.scrollTop) {
        this.setState(state => ({
          count: state.count++,
          isScrollMax: true
        }))
      }
    }
  }
  componentDidUpdate = (_, prevState) => {
    const { pharmaciesActions: { getInvitesForPharm }, match: { params: { courseId, pharmId } } } = this.props
    if (prevState.isScrollMax === false && this.state.isScrollMax === true) {
      this.setState(
        state => ({
          count: state.count + 20,
          isScrollMax: false
        }),
        () => getInvitesForPharm({ courseId: `/v1/courses/${courseId}`, pharmId: `/v1/medical-institutions/${pharmId}`}, { offset: 0, count: this.state.count })
      )
    }
  }
  componentWillUnmount = () => {
    document.getElementById('linksWrapper').onscroll = null
  }

  render() {
    return <Links { ...this.props } isRequesting={this.state.isScrollMax} />;
  }
}

const mapStateToProps = state => ({
  invites: state.invites,
  pharmacy: state.pharmacy,
  course: state.course
});
const mapDispatchToProps = dispatch => ({
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinksContainer);
