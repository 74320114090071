import { pharmaciesGroupsTypes } from '../types'
import { getList } from '../api'

export const getPharmaciesGroups = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: pharmaciesGroupsTypes.GET_PHARMACIES_GROUPS__REQUEST }))())
    getList(getState().meta.resourses['pharmacy-groups']).then(data => dispatch((() => ({
      type: pharmaciesGroupsTypes.GET_PHARMACIES_GROUPS__SUCCESS,
      payload: { items: data.items.map(item =>  ({ ...item, company: item.company.name })) }
    }))()))
  }
