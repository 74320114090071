import { internalCompaniesTypes } from "../types";
import { get, post, remove, currentApiUrl } from "../api";
import { setQueryString } from "../utils";

export const getCompanies = filter => dispatch => {
  dispatch((() => ({ type: internalCompaniesTypes.GET_COMPANIES__REQUEST }))());
  const query = setQueryString(filter);
  get(`${currentApiUrl}/v1/internal-companies${query}`).then(_companies => {
    dispatch(
      (() => ({
        type: internalCompaniesTypes.GET_COMPANIES__SUCCESS,
        payload: { items: _companies.items }
      }))()
    );
  });
};

export const createEmployee = data => dispatch => {
  dispatch(
    (() => ({ type: internalCompaniesTypes.CREATE_EMPLOYEE__REQUEST }))()
  );
  return new Promise((res, rej) =>
    post(`${currentApiUrl}/v1/internal-company-employees`, data)
      .then(_employment => {
        dispatch(
          (() => ({
            type: internalCompaniesTypes.CREATE_EMPLOYEE__SUCCESS,
            payload: { employment: _employment }
          }))()
        );
        res(_employment);
      })
      .catch(error => {
        dispatch(
          (() => ({ type: internalCompaniesTypes.CREATE_EMPLOYEE__FAILURE }))()
        );
        rej(error);
      })
  );
};

export const deleteEmployee = employee => dispatch => {
  dispatch(
    (() => ({ type: internalCompaniesTypes.DELETE_EMPLOYEE__REQUEST }))()
  );
  return new Promise((res, rej) =>
    remove(`${currentApiUrl}/v1/internal-company-employees/${employee}`)
      .then(_employment => {
        dispatch(
          (() => ({ type: internalCompaniesTypes.DELETE_EMPLOYEE__SUCCESS }))()
        );
        res();
      })
      .catch(error => {
        dispatch(
          (() => ({ type: internalCompaniesTypes.DELETE_EMPLOYEE__FAILURE }))()
        );
        rej(error);
      })
  );
};
