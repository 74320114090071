import React, { Component } from 'react'
import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";

//styles
import "./Toolbar.scss"

class PharmaciesTableToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  render() {
    const {
      classes,
      toggleFilter,
      openContractCreateModal,
      withFilter,
      onTabClick,
      history,
      url
    } = this.props;

    return (
      <Toolbar className={`toolbar ${withFilter ? "withFilter" : null}`}>
        <button className={"button"}>
          <AddIcon />
          <Typography style={{ width: 170 }} variant="body1" id="tableTitle" onClick={() => this.props.history.push('create_pharmacy')}>
          Добавить новое
          </Typography>
        </button>
        <button className={"button"}>
          <AddIcon />
          <Typography style={{ width: 170 }} variant="body1" id="tableTitle" onClick={() => this.props.history.push('import_from_excel')}>
          Импорт из .xls
          </Typography>
        </button>

        <div className={"spacer"} />

        <div className='tabs'>
          <div onClick={() => onTabClick(0)} className={`tab ${this.props.current === 0 ? 'active' : null}`}>Медучереждения</div>
          <div onClick={() => onTabClick(1)} className={`tab ${this.props.current === 1 ? 'active' : null}`}>Группы</div>
          <div onClick={() => onTabClick(2)} className={`tab ${this.props.current === 2 ? 'active' : null}`}>Холдинги</div>
        </div>

        <div className={"spacer"} />

        <div className={"actions"}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Filter list"  style={{fontSize:"50px !important"}}>
              <IconButton onClick={toggleFilter} aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

export default PharmaciesTableToolbar;
