import React, { Component } from 'react'
import { Typography,Input } from '@material-ui/core'
import PhotoIcon from "@material-ui/icons/Photo";
import './style.scss'

class Photos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'photos',
      minimumAmount: null
    }
  }
  handleChange = (name, value) => this.setState(
    () => ({ [name]: value }),
    () => this.props.onChange(this.state)
  )
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Сделать фото</Typography>
        <div className='container'>
          <div className='icon'>
            <PhotoIcon color='primary'/>
          </div>
          <div className='content'>
            <Input placeholder='Количество фотографий' value={this.state.minimumAmount} onChange={({target: { value }}) => this.handleChange('minimumAmount', value)}></Input>
          </div>
        </div>
      </div>
    )
  }
}

export default Photos
