import { pharmaciesTypes, infoBarTypes } from '../types'

const initialState = {
  isOpen: false,
  isEnd: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case infoBarTypes.CLOSE_BAR: return {
      ...state,
      isEnd: false,
      isOpen: false
    }
    case pharmaciesTypes.IMPORT_PHARMACIES__REQUEST: return {
      ...state,
      isOpen: true,
      isEnd: false
    }
    case pharmaciesTypes.IMPORT_PHARMACIES__SUCCESS: return {
      ...state,
      isOpen: true,
      isEnd: true
    }
    default: return state
  }
}
