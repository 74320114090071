import React, { Component, Fragment, useState } from "react";
import "./DocumentEditor.scss";
import { Loader } from '../../component';
import { AttachFileV2, FullDesc, CancelIconV2, VariantsIcon } from '../../static/imgs'
import AddIcon from "@material-ui/icons/Add";
import { Tabs, Tab, Button, TextField, InputAdornment } from "@material-ui/core";
import { documentKinds } from "../../constants/documentKinds"

class DocumentEditor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        description: '',
        kind: null,
        url: '',
      }
    }
    componentDidMount() {
      if (!this.props.isNew) {
        this.setState({
          description: this.props.externalDocument.item.description,
          kind: this.props.externalDocument.item.kind,
          url: this.props.externalDocument.item.url,
        })
      }
    }
    handleChange =  (target, value) => this.setState({ [target]: value })
    handleDocumentSave = () => this.props.externalDocumentsActions.createDocument(this.state, this.props.profile.item.self)
    handleDocumentUpdate = () => this.props.externalDocumentsActions.updateDocument(this.state, this.props.externalDocument.item.self)
    validateForm = () => {
      const { description, kind, url } = this.state
      return !(description !== '' && kind !== null && url !== '')
    }
    render() {
      const { description, kind, url } = this.state
      const { isNew } = this.props
      return (
          <Fragment>
                  <div className='documentEditor__wrapper'>
                    <form>
                      <div className='row'>
                        <AttachFileV2 />
                        <input
                          placeholder="Ссылка на документ"
                          value={url}
                          onChange={({ target: {value} }) => this.handleChange('url', value)}
                          variant="outlined"
                          fullWidth={true}
                          disabled={!isNew}
                        />
                        {/* <CancelIconV2 className='cancelIcon'/> */}
                      </div>
                      <div className='row'>
                        <FullDesc />
                        <textarea
                          placeholder="Комментарий"
                          value={description}
                          onChange={({ target: {value} }) => this.handleChange('description', value)}
                          variant="outlined"
                          rows={3}
                          multiline={true}
                          fullWidth={true}
                        />
                        {/* <CancelIconV2 className='cancelIcon'/> */}
                      </div>
                      <div className='row'>
                        <VariantsIcon />
                        <div className='documentKinds__wrapper'>
                          {
                            Object
                            .keys(documentKinds)
                            .map((_kind, _key) =>
                              <div
                              key={_key}
                              className={`kind ${kind === _kind ? 'selected' : ''}`}
                              onClick={isNew ? () => this.handleChange('kind', _kind) : () => null}
                              >{documentKinds[_kind]}</div>)
                          }
                        </div>
                      </div>
                      <div className='row'>
                        <Button variant='outlined' color='inherit'>Отмена</Button>
                        <Button
                        disabled={this.validateForm()}
                        variant='contained'
                        color='secondary'
                        onClick={isNew ? this.handleDocumentSave : this.handleDocumentUpdate}
                        >{
                          isNew
                          ? this.props.profile.isRequestingCreateDocument ? <Loader small /> : 'Добавить документ'
                          : this.props.externalDocument.isRequestingUpdate ? <Loader small /> : 'Редактировать документ'
                        }
                        </Button>
                      </div>
                    </form>
                </div>
          </Fragment>
      );
    }
  }

  export default DocumentEditor;
