import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {DebounceInput} from 'react-debounce-input';

import './style.scss';

const DebounceSearchInput = ({ onChange, icon, time, placeholder, onFocus, value }) => {

    const handleChange = ({ target: { value } }) => {
        onChange(value)
    }

    return (
        <div className='search__wrapper'>
            <div className='search__icon'>
                {icon || <SearchIcon />}
            </div>
            <DebounceInput
                value={value || ''}
                placeholder={placeholder || 'Поиск'}
                onChange={handleChange}
                debounceTimeout={time || 500}
                className='search__content'
                onFocus={onFocus}
                // classes={{
                //     root: 'inputRoot',
                //     input: 'inputInput'
                // }}
            />
        </div>
    )
}

export default DebounceSearchInput;

