import { rootTypes, metaTypes } from '../types'

const initialState = {
  resourses: {},
  navi: [
    {
      title: '',
      to: ''
    }, {
      title: '',
      to: ''
    }
  ],
  naviTitleHelper: '',
  requestingResourses: true,
  isError: false,
  token: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case metaTypes.PROVIDE_TITLE: return {
      ...state,
      naviTitleHelper: action.payload.title
    }
    case metaTypes.UPDATE_NAVI: return {
      ...state,
      navi: action.payload.navi
    }
    case rootTypes.GET_RESOURSES__REQUEST: return {
      ...state,
      requestingResourses: true,
      isError: false
    }
    case rootTypes.GET_RESOURSES__FAILURE: return {
      ...state,
      requestingResourses: false,
      isError: true
    }
    case rootTypes.GET_RESOURSES__SUCCESS: return {
      ...state,
      resourses: action.payload.resourses,
      requestingResourses: false,
      isError: false
    }
    default: return state
  }
}
