import { geoTypes } from '../types'

const initialState = {
  coords: [],
  title: '',
  isRequesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case geoTypes.GET_PLACE__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case geoTypes.GET_PLACE__SUCCESS: return {
      ...state,
      isRequesting: false,
      coords: action.payload.coords,
      title: action.payload.title
    }
    default: return state
  }
}
