import React, { Component } from "react";
import { Link } from 'react-router-dom'
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography/Typography";

const TableCellWrapper = ({ nameCell }) => (
  <TableCell component="th" scope="row">
    <Typography variant="body1" id="tableTitle">
      {nameCell}
    </Typography>
  </TableCell>
);

class EnhancedTableBody extends Component {
  render() {
    const { contractsData, onClick } = this.props;

    return (
      <TableBody style={{ height: "calc(100vh - 64px)" }}>
        {
          contractsData.map((contract, row) => {
          return (
            <TableRow
              hover
              onClick={() => onClick(contract.id, contract.title)}
              tabIndex={-1}
              key={row}
            >
              {
                Object.keys(contract)
                .filter(key => key !== "id")
                .sort((a, b) => a - b)
                .map((item, col) => (
                  <TableCellWrapper
                    nameCell={contract[item]}
                    key={col}
                  />
                ))}
            </TableRow>
          )
        })
        }
      </TableBody>
    );
  }
}

export default EnhancedTableBody;
