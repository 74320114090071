import React, { Component, Fragment } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { filesActions, metaActions, usersActions } from '../actions'
import { Files, FileInfo, FileUpload } from '../pages'

class FilesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { filesActions: { getFiles }, usersActions: { getUsers },  metaActions: { updateNavi }, shared: { item: { file } } } = this.props
    if (this.props.match.path === '/upload') {
      updateNavi([
        { title: "Акции", to: "/files" },
        { title: 'Загрузить файл', to: `/upload` }
      ]);
      getUsers();
    } else if (this.props.match.path === '/files') {
      updateNavi([{ title: 'Акции', to: '/files' }])
      getFiles()
    } else {
      getFiles()
    }
  }

  render() {
    const { match: { path, isExact } } = this.props
    //
    // return path === '/files'
    // ? <Files {...this.props} />
    // : <Fragment>
    //   <Route path='/upload' component={() => <FileUpload { ...this.props } />}/>
    //   <Route path='/files/:shareId' component={() => <FileInfo { ...this.props } />}/>
    // </Fragment>
    return path === '/files'
      ? <Files {...this.props} />
      : path === '/upload'
        ? <FileUpload  { ...this.props } />
        : <FileInfo  { ...this.props } />
  }
}

const mapStateToProps = state => ({
  files: state.files,
  users: state.users,
  shared: state.shared,
  auth: state.auth
})
const mapDispatchToProps = dispatch => ({
  filesActions: bindActionCreators(filesActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesContainer)
