import {Map, YMaps} from "react-yandex-maps";
import React from "react";

const Header = ({ pharm, current, onTabClick, toggleFilter }) => (
    <div className='header'>
        <div className='drugstoreInfo__wrapper'>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className='drugstoreInfo'>
                    <div className='drugstore__title'>
                        <div className='title__icon'></div>
                        <p className='title__legalEntity'>{ pharm.title }</p>
                        <p className='title__taxID'>(ИНН { pharm.company.split('/').pop() })</p>
                    </div>
                    <div className='drugstore__description'>
                        <div className='description__icon'></div>
                        <p className='description__text'>{ pharm.description }</p>
                    </div>
                </div>
                <div className='drugstoreStatus'>
                    <div className='workCondition'>
                        <div className='whiteRightArrow__icon'> <div className='arrow'></div> </div>
                        <p>Работает</p>
                        <div className='blackDownArrow__icon'></div>
                    </div>
                    <div className='seniorStaff'>
                        <p>{/* TODO: talk about it  */}</p>
                    </div>
                </div>
            </div>
            <div className='drugstore__contacts'>
                <a href='#'>{ pharm.website }</a>
                <a href='#'>{ pharm.phones[0] }</a>
                <a href='#'>{ pharm.emails[0] }</a>
            </div>
        </div>
        <div className='drugstoreMap'>
            <YMaps>
                <Map
                    width={"100%"}
                    height={"210px"}
                    defaultState={{ center: [pharm.coordinates.lat, pharm.coordinates.lon], zoom: 15 }}
                />
            </YMaps>
        </div>
    </div>
)

export default Header