import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { Loader } from '../component'
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { SearchInput } from '../component/search'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import DoneIcon from '@material-ui/icons/Done';

import { Add, Contacts, Diplomat, FullDesc, ShortDesc, Person, Portico, Star, TagWithLike,
  TrafficLight, Pharmacies } from "../static/imgs"
import './Pharmacy.scss'

const loyaltyTypes = [
  { value: 'loyal', label: 'Лояльное отношение' },
  { value: 'negative', label: 'Негативное отношение' },
  { value: 'neutral', label: 'Нейтральное отношение' },
  { value: 'not_loyal', label: 'Не лояльное отношение' },
  { value: 'very_loyal', label: 'Очень лояльное отношение' }
]
const clinicTypes = [
  { value: 'dental', label: 'Стоматология' },
  { value: 'generic', label: 'Общего назначения' }
]
const statuses = [
  { value: 'closed', label: 'Закрыта' },
  { value: 'open', label: 'Открыта' },
  { value: 'opens_soon', label: 'Скоро открытие' },
  { value: 'repairing', label: 'Ремонт' },
  { value: 'temporally_closed', label: 'Временно закрыта' },
]

const patchKeys = [ 'address', 'company', 'coordinates', 'description', 'emails', 'loyalty', 'manager', 'phones', 'status', 'title', 'website']
const postKeys = [ 'type', 'category', 'isRelatedToVesna', 'purpose', 'address', 'company', 'coordinates', 'description', 'emails', 'loyalty', 'manager', 'phones', 'status', 'title', 'website']

@withRouter
class Pharmacy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      category: '',
      company: '',
      coordinates: {
        lat: '',
        lon: ''
      },
      description: '',
      isRelatedToVesna: false,
      category: 'a',
      emails: [],
      loyalty: '',
      manager: '',
      phones: [],
      pharmacyGroup: '',
      status: '',
      title: '',
      type: '',
      website: '',
      isRelatedToVesna: false
    }
  }
  componentDidMount () {
    const { pharmacy: { item } } = this.props
    if (this.props.isEditing) {
      this.setState({
        address: item.address,
        category: item.category,
        company: item.company.split('/').pop(),
        coordinates: item.coordinates,
        description: item.description,
        emails: item.emails,
        isRelatedToVesna: item.isRelatedToVesna,
        loyalty: item.loyalty,
        manager: item.manager,
        pharmacyGroup: item.pharmacyGroup,
        phones: item.phones,
        status: item.status,
        title: item.title,
        type: item.type,
        website: item.website
      })
    }
    const { geoActions: { getPlace } } = this.props
    getPlace({ coords: [item.coordinates.lat, item.coordinates.lon] })
  }
  componentWillUpdate = nextProps => {
    if (nextProps.geo.title !== this.state.address) {
      this.setState({ address: nextProps.geo.title })
    }
  }
  handleMapClick = coords => {
    const { geoActions: { getPlace } } = this.props
    getPlace({ coords })
    this.setState({ coordinates: { lat: coords[0], lon: coords[1] } })
  }
  handleFieldChange = (name, value) => this.setState({
    [name]: value
  })
  handleUpdateClick = () => {
    const { pharmaciesActions: { updatePharm }, match } = this.props
    updatePharm((obj => patchKeys.map(key => { obj[key] = this.state[key] }) && obj)({}), match.params.pharmId)
  }
  handleSaveNewClick = () => {
    const { pharmaciesActions: { createPharmacy }, match } = this.props
    createPharmacy((obj => postKeys.map(key => { obj[key] = this.state[key] }) && obj)({}))
  }

  render() {
    const { pharmacy: { item }, isEditing } = this.props
    const _pharm = this.state,
          _phone = this.state.phones[0] || '',
          _email = this.state.emails[0] || '';

    return (
      <div className='pharmacy__wrapper'>
        <p className="tipRow">Поля, обозначенные <span className="starSymbol">*</span> обязательны к заполнению.</p>
        {
          !isEditing
          && <RadioGroup className="radioGroup" onChange={({ target: { value } }) => this.handleFieldChange('type', value)} value={_pharm.type}>
            <FormControlLabel
              value="pharmacy"
              control={<Radio />}
              label="Аптека"
              />
            <FormControlLabel
              value="clinic"
              control={<Radio />}
              label="Клиника"
              />
            <FormControlLabel
              value="cosmetology_clinic"
              control={<Radio />}
              label="Косметология"
              />
          </RadioGroup>
        }
        <div className="row">
          <ShortDesc className="listIcon" style={{marginRight: '17px'}}/>
          <TextField
            fullWidth={true}
            label="Название"
            value={_pharm.title}
            onChange={({ target: { value } }) => this.handleFieldChange('title', value)}
            type="text"
            name="from"
            margin="normal"
            variant="outlined"
            InputProps = {{classes: {focused: "focusedColor" }}}
          />
        </div>
        <div className="row">
          <FullDesc className="listIcon" style={{marginRight: '17px'}} />
          <TextField
            fullWidth={true}
            label="Описание"
            value={_pharm.description}
            onChange={({ target: { value } }) => this.handleFieldChange('description', value)}
            type="text"
            name="from"
            margin="normal"
            variant="outlined"
            InputProps = {{classes: {focused: "focusedColor" }}}
          />
        </div>
        <div>
          <div className="row">
            <Pharmacies className="listIcon"/>
            <TextField
              fullWidth={true}
              label="Адрес *"
              value={_pharm.address}
              type="text"
              onChange={({ target: { value } }) => this.handleFieldChange('address', value)}
              name="from"
              margin="normal"
              variant="outlined"
              InputProps = {{classes: {focused: "focusedColor" }}}
              />
          </div>
          <div className='content map'>
            <YMaps>
              <Map
                width={"100%"}
                height={"100%"}
                defaultState={{ center: [_pharm.coordinates.lat, _pharm.coordinates.lon], zoom: 13 }}
                onClick={e => this.handleMapClick(e.get('coords'))}
                >
                <Placemark geometry={[_pharm.coordinates.lat, _pharm.coordinates.lon]} />
              </Map>
            </YMaps>
          </div>
        </div>
        <div className="row">
          <Contacts className="listIcon"/>
          <div className="row contactsRow">
            <TextField
              fullWidth={true}
              label="Телефон"
              value={_phone}
              onChange={({ target: { value } }) => this.handleFieldChange('phones', [value])}
              type="text"
              name="from"
              margin="normal"
              variant="outlined"
              InputProps = {{classes: {focused: "focusedColor" }}}
            />
            <TextField
              fullWidth={true}
              label="Веб-сайт"
              value={_pharm.website}
              type="text"
              name="from"
              onChange={({ target: { value } }) => this.handleFieldChange('website', value)}
              margin="normal"
              variant="outlined"
              InputProps = {{classes: { focused: "focusedColor" }}}
            />
            <TextField
              fullWidth={true}
              label="Email"
              onChange={({ target: { value } }) => this.handleFieldChange('emails', [value])}
              value={_email}
              type="text"
              name="from"
              margin="normal"
              variant="outlined"
              InputProps = {{classes: {focused: "focusedColor" }}}
            />
          </div>
        </div>
        <div className="row">
          <Diplomat className="listIcon"/>
            <TextField
              fullWidth={true}
              label="Компания"
              onChange={({ target: { value } }) => this.handleFieldChange('company', [value])}
              value={_pharm.company}
              type="text"
              name="from"
              margin="normal"
              variant="outlined"
              InputProps = {{classes: {focused: "focusedColor" }}}
            />
        </div>
        <div className="row">
          <TagWithLike className="listIcon"/>
          <TextField
            fullWidth={true}
            select
            label="Лояльность"
            onChange={({ target: { value } }) => this.handleFieldChange('loyalty', value)}
            name="from"
            value={_pharm.loyalty || ''}
            margin="normal"
            variant="outlined"
            InputProps = {{classes: {focused: "focusedColor" }}}
          >
            {loyaltyTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
        </div>
        {
          _pharm.type === 'clinic'
          && <div className="row">
            <Portico className="listIcon"/>
            <TextField
              fullWidth={true}
              label="Тип клиники"
              select
              value={_pharm.purpose || ''}
              onChange={({ target: { value } }) => this.handleFieldChange('purpose', value)}
              name="from"
              margin="normal"
              variant="outlined"
              InputProps = {{classes: {focused: "focusedColor" }}}
              >
              {clinicTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        }
        <div className="row">
          <TrafficLight className="listIcon"/>
          <TextField
            fullWidth={true}
            label="Статус"
            select
            value={_pharm.status}
            name="from"
            onChange={({ target: { value } }) => this.handleFieldChange('status', value)}
            margin="normal"
            variant="outlined"
            InputProps = {{classes: {focused: "focusedColor" }}}
          >
            {statuses.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
        </div>
        <div className="row">
          <Person className="listIcon"/>
          <SearchInput
            placeholder='Медпредставитель'
            onSelect={user => this.handleFieldChange('manager', user.self)}
            value={_pharm.manager}
            data='users'
            dataKey='fullName'
          />
          <div className="validateStaff"><Checkbox onChange={(_, value) => this.handleFieldChange('isRelatedToVesna', value)} className="icon"/><span>Относится к весне</span></div>
        </div>
        {
          _pharm.type === 'pharmacy'
          && (
            <div className="row">
              <Add className="listIcon"/>
              <SearchInput
                placeholder='Группа аптек'
                onSelect={group => this.handleFieldChange('pharmacyGroup', group.self)}
                value={_pharm.pharmacyGroup}
                data='pharmaciesGroups'
                dataKey='company'
              />
            </div>
            )
        }
        <div className="row">
          <Star className="listIcon"/>
          <div className="chipsRow">
            <Chip
              color="secondary"
              className={`radioChip ${_pharm.category === 'A' ? 'active' : 'notActive'}`}
              onClick={() => this.handleFieldChange('category', 'A')}
              label="A"
              icon={_pharm.category === 'A' && <DoneIcon />}
            />
            <Chip
              color="secondary"
              className={`radioChip ${_pharm.category === 'B' ? 'active' : 'notActive'}`}
              onClick={() => this.handleFieldChange('category', 'B')}
              icon={_pharm.category === 'B' && <DoneIcon />}
              label="B"
            />
            <Chip
              color="secondary"
              className={`radioChip ${_pharm.category === 'C' ? 'active' : 'notActive'}`}
              onClick={() => this.handleFieldChange('category', 'C')}
              icon={_pharm.category === 'C' && <DoneIcon />}
              label="C"
            />
            <Chip
              color="secondary"
              className={`radioChip ${_pharm.category === 'D' ? 'active' : 'notActive'}`}
              onClick={() => this.handleFieldChange('category', 'D')}
              icon={_pharm.category === 'D' && <DoneIcon />}
              label="D"
            />
          </div>
        </div>
        <div className="row buttonsRow">
          <Button onClick={this.handleCancelClick} variant='outlined' className="cancelButton">
            Отмена
          </Button>
          <Button onClick={!isEditing ? this.handleSaveNewClick : this.handleUpdateClick} variant='outlined' color='secondary' className="addButton">
            Сохранить медучереждения
          </Button>
        </div>
      </div>
    )
  }
}

export default Pharmacy
