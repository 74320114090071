export const pharmKeys = {
	address: 'Адрес',
	company: 'Компания',
	description: 'Описание',
	emails: 'Email-адреса',
	employees: 'Сотрудники',
	isRelatedToVesna: 'Принадлежит холдингу "Весна"',
	lat: 'Координаты (широта)',
	lon: 'Координаты (долгота)',
	legacyAddressCode: 'Код адреса*',
	legacyBuildingCode: 'Код здания*',
	legacyCompanyCode: 'Код компании*',
	loyalty: 'Лояльность',
	manager: 'Менеджер',
	phones: 'Телефоны',
	purpose: 'Профиль клиники',
	status: 'Статус',
	title: 'Название',
	type: 'Тип',
	website: 'Сайт',
}

