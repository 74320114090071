import { Toolbar, Typography, Tooltip, Tabs, Tab } from '@material-ui/core'
import IconButton from "@material-ui/core/IconButton/IconButton";
import ImportIcon from "@material-ui/icons/Publish";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";

//styles
import "./Toolbar.scss"
import "../../styles/variables.scss"

class MaterialsToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
    };
  }

  render() {
    return (
      <Toolbar className={`toolbar`}>
        <button className={"button"}>
          <ImportIcon style={{ marginRight: 10, marginLeft: 10 }}/>
          <Typography style={{ whiteSpace: 'nowrap', color: '#00796B' }} variant="body1" id="tableTitle" onClick={this.props.onCreateClick}>
            Создать статью
          </Typography>
        </button>
        <div className={"actions"}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Filter list"  style={{fontSize:"50px !important"}}>
              <IconButton aria-label="Filter list">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="More action">
              <IconButton aria-label="More action">
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

export default MaterialsToolbar;
