export default class CRUD {
  constructor(_token) {
    this._config =
      {
        headers: {
          Accept: "application/problem+json",
          Authorization: `Bearer ${_token}`
        }
      } || null;
    this._apiBase = process.env.BASE_API_URL;
  }

  async getResource(url) {
    let data = await fetch(`${this._apiBase}${url}`, {
      method: "GET",
      ...this._config
    });
    return data.json();
  }

  postResource(url, data) {
    return fetch(`${this._apiBase}${url}`, {
      method: "POST",
      ...this._config,
      body: JSON.stringify(data)
    }).then(data => data.json());
  }

  patchResource(url, data) {
    return fetch(`${this._apiBase}${url}`, {
      method: "PATCH",
      ...this._config,
      body: JSON.stringify(data)
    });
  }

  deleteResource(url) {
    return fetch(`${this._apiBase}${url}`, {
      method: "DELETE",
      ...this._config
    });
  }

  postFile(url, data) {
    return fetch(`${this._apiBase}${url}`, {
      method: "POST",
      ...this._config,
      body: data
    }).then(data => data.json());
  }
}
