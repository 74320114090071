import { projectTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: true,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case projectTypes.GET_PROJECTS__REQUEST: return {
      ...state,
      isRequesting: true,
      isError: false
    }
    case projectTypes.GET_PROJECTS__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: true,
        items: action.payload.projects.items
      }
    default: return state
  }
}
