import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActions, metaActions } from "../actions";
import { Projects } from "../pages";
import { changeStatusProject } from "../actions/project/changeStatusProject";

class ProjectsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([{ title: "Проекты", to: "/projects" }]);
  };

  render() {
    const modifiedProps = this.props.projects.items.filter((obj) => obj.manager === this.props.auth.user.self )
    return <Projects {...this.props} modifiedProps={modifiedProps} />;
  }
}

const mapStateToProps = state => ({
  projects: state.projects,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  projectActions: bindActionCreators(projectActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  changeStatusProject: bindActionCreators(changeStatusProject, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsContainer);
