import SimpleChip from "../../component/chip/chip";
import MessageIcon from "@material-ui/icons/Message";
import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import ListItem from "@material-ui/core/ListItem";


export let SubtaskMessage = ({
  input: { name, onChange, value, ...restInput }
}) => {
  onChange(true);
  return (
      <SimpleChip
        icon={<MessageIcon />}
        deleteIcom={<DoneIcon />}
        label={"Выбран тип отчета: Сообщение"}
      />

  );
};
