import apiService from "../../api/apiService";
import { PROJECTS } from "../../constants/entities";
import { getProjects } from "../project";
import { getContract } from "../contract";

export const changeStatusProject = (id, status, idContract) => {
  return (dispatch, getState) => {
    (async () => {
      let update = await new apiService(PROJECTS).update(id, {
        status: status
      });

      idContract
        ? dispatch(getContract({ id: idContract }))
        : dispatch(getProjects());
      
    })();
  };
};
