import React, {Component} from 'react'
import './dropList.scss'

class DropList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [
        { name: 10, id: 1 },
        { name: 50, id: 2 },
      ]
    }
  }

  onDropdownSelected = (e) => {
    this.props.onChange(e)
}

  render() {
    return (
      <select onChange={this.onDropdownSelected}>
        {this.state.rows.map((row, index) => (<option key={index} value={row.name}>{row.name}</option>))}
      </select>
    )
  }
}

export default DropList
