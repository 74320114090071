import React from "react";
import { Form } from "react-final-form";
import StepperModalCreateProject from "../../component/stepper/stepperModalCreateProject";
import Button from "@material-ui/core/Button/Button";
import arrayMutators from "final-form-arrays";
import AddIcon from "@material-ui/icons/Add";
import "./wizard.scss";
const ControlWizard = props => {
  return (
    <div className="control">
      <div className="buttons">
        <Button type="button" onClick={props.onClose}>
          Отмена
        </Button>
      </div>
      <StepperModalCreateProject
        activeStep={props.activeStep}
        className="stepper"
      />
      <div className="buttons">
        {props.activeStep > 0 && (
          <Button type="button" onClick={props.onPrevious}>
            Назад
          </Button>
        )}
        {!props.lastPage && (
          <Button type="submit" onClick={props.onNext}>
            Вперед
          </Button>
        )}
        {props.lastPage && (
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={props.disabled}
          >
            Отправить
          </Button>
        )}
      </div>
    </div>
  );
};

export default class createWizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {tasks:[{}]}
    };
  }
  onNext = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1)
      // values
    }));

  onPrevious = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      this.next(values);
    }
  };

  handleCreateTask = (push, values) => {
    push("tasks", {
      id: `${values.tasks === undefined ? 0 : values.tasks.length}`
    });
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];

    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        onSubmit={this.handleSubmit}
        mutators={{ ...arrayMutators }}
      >
        {({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          mutators: { push, pop, insert },
          handleCopyTask
        }) => (
          <form onSubmit={handleSubmit} className="wizard">
            {activePage}
            {page === 1 && (
              <div className="controlEpic">
                <Button
                  color="secondary"
                  className="button"
                  onClick={() => this.handleCreateTask(push, values)}
                >
                  <AddIcon />
                  Добавить задачу
                </Button>
              </div>
            )}
            <ControlWizard
              onClose={this.props.handleClose}
              activeStep={page}
              onPrevious={this.onPrevious}
              lastPage={isLastPage}
              onNext={this.onNext}
              disabled={submitting}
            />
            {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
          </form>
        )}
      </Form>
    );
  }
}
