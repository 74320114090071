import React, { Component, Fragment } from 'react';
import { Loader, List } from '../../component'
import "./Drugstore.scss";
import { LinksContainer } from '../../containers'
import { Switch, Route } from 'react-router'
import Header from "./Header";
import Main from "./Main";

class Drugstore extends Component {
  componentDidMount = () => {
    const { pharmaciesActions: { getPharmacies }, metaActions: { updateNavi }, match } = this.props
    if (match.path === '/drugstore/:pharmId') {
      updateNavi([
        { title: 'Мои аптеки', to: '/drugstore' },
        { title: 'Аптека', to: `/drugstore/${match.params.pharmId}` }
      ])
    }
    if (match.path === '/drugstore/:pharmId/:courseId') {
      updateNavi([
        { title: 'Мои аптеки', to: '/drugstore' },
        { title: 'Аптека', to: `/drugstore/${match.params.pharmId}` },
        { title: 'Курс', to: `/drugstore/${match.params.pharmId}/courses/${match.params.courseId}` }
      ])
    }
  }
  componentDidUpdate = (prevProps) => {
    const { metaActions: { updateNavi }, match, pharmacy, course } = this.props
    if (
      (prevProps.pharmacy.isRequesting !== pharmacy.isRequesting)
      || (prevProps.course.isRequesting !== course.isRequesting)
    ) {
      if (match.path === '/drugstore/:pharmId') {
        updateNavi([
          { title: 'Мои аптеки', to: '/drugstore' },
          { title: pharmacy.item.title, to: `/drugstore/${match.params.pharmId}` }
        ])
      }
      if (match.path === '/drugstore/:pharmId/courses/:courseId') {
        updateNavi([
          { title: 'Мои аптеки', to: '/drugstore' },
          { title: pharmacy.item.title, to: `/drugstore/${match.params.pharmId}` },
          { title: course.item.title, to: `/drugstore/${match.params.pharmId}/courses/${match.params.courseId}` }
        ])
      }
    }
  }
  handleTabClick = view => this.setState({ view })
  handleToggleFilter = () => this.setState({ isFilterOpened: !this.state.isFilterOpened })
  renderList = () => {
    const { pharmacies: { items } } = this.props
    return <List items={items.map(item => ({...item, address: item.address.short, manager: 'test man', group: 'Test group'}))}
            keys={['title', 'address', 'type', 'group', 'manager']}
            headers={['Название', 'Адрес', 'Тип', 'Сеть', 'Ответственный']}/>
  }

  render() {
    const { pharmacy: { isRequesting, item }, pharmaciesActions } = this.props

    return isRequesting
    ? <Loader />
    : (
      <div className='drugstore__wrapper'>
        <Header pharm={item} />
        <div className='moreInfo'>
          <div className='moreInfo__button'>
            <div className='downArrow'></div>
          </div>
          <div className='moreInfo__menu'></div>
        </div>
        {/*
        <div className='tabs'>
          <div onClick={() => onTabClick(0)} className={`tab ${current === 0 ? 'active' : null}`}>Контракты</div>
          <div onClick={() => onTabClick(1)} className={`tab ${current === 1 ? 'active' : null}`}>Проекты</div>
          <div onClick={() => onTabClick(1)} className={`tab ${current === 1 ? 'active' : null}`}>Статистика</div>
          <div onClick={() => onTabClick(1)} className={`tab ${current === 1 ? 'active' : null}`}>Сотрудники</div>
          <div onClick={() => onTabClick(1)} className={`tab ${current === 1 ? 'active' : null}`}>Обучение</div>
        </div>
        */}

          <Switch>
            <Route
              path={'/drugstore/:pharmId/courses/:courseId'}
              component={() => <LinksContainer withCourseName match={this.props.match} />}
              />
            <Route
                exact
                path={`${this.props.match.path}`}
                component={(props) => <Main item={item} pharmaciesActions={pharmaciesActions} {...props} />}
            />
          </Switch>
        </div>
    )
  }
}

export default Drugstore
