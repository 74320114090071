import { courseTypes } from '../types'

const initialState = {
  item: {},
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case courseTypes.GET_COURSE__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case courseTypes.GET_COURSE__SUCCESS: return {
      ...state,
      isRequesting: false,
      item: action.payload.item
    }
    default:
      return state
  }
}
