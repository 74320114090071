import React, { Component } from "react";
import Course from "./Course";
import CourseListHeader from "./CourseListHeader";
import * as PropTypes from "prop-types";

class CourseList extends Component {
  state = {
    filter: {}
  };

  handleStatsClick = statLink => {
    const accessesLink = document.createElement("a");
    accessesLink.setAttribute("href", statLink);
    accessesLink.setAttribute(
      "download",
      "Статистика про пройденным ссылкам.csv"
    );
    document.body.appendChild(accessesLink);
    accessesLink.click();
  };

  handleLinksClick = course => {
    const { history, match } = this.props;
    history.push(match.url + "/courses/" + course);
  };

    handleToggleFilter = (name, value) => {
        this.setState(({filter}) => {
            if (!value) {
                const oldAllFilter = {...filter};
                delete oldAllFilter[name];
                return {
                    filter: {...oldAllFilter}
                }
            }
            return {
                filter: {...filter, [name]: value}
            }
        })
    }

    filterCourses(courses) {
        const { filter } = this.state;
        if (courses)
            return courses.filter(course => {
                return !!((!filter.title || course.title.toLowerCase().indexOf(filter.title.toLowerCase()) !== -1) &&
                    (!filter.status || course.availability.status === filter.status));
            })
        return [];
    }

  render() {
    const { item, match, pharmaciesActions } = this.props;
    const { filter } = this.state;
    return (
      <div className="course-list__wrapper">
        <CourseListHeader
            filter={filter}
          pharmId={match.params.pharmId}
          pharmaciesActions={pharmaciesActions}
            handleToggleFilter={this.handleToggleFilter}
        />
        <div className="verticalCards__wrapper">
          {this.filterCourses(item.courses).map(
            (_course, key) =>
              console.log(_course) || (
                <Course
                  onAccessStatsClick={() =>
                    this.handleStatsClick(
                      _course.courseStatisticsByAccessesCsv.url
                    )
                  }
                  onInvitesStatsClick={() =>
                    this.handleStatsClick(
                      _course.courseStatisticsByInvitesWithoutAccessesCsv.url
                    )
                  }
                  onLinksClick={() => this.handleLinksClick(_course.id)}
                  course={_course}
                  key={key}
                />
              )
          )}
        </div>
      </div>
    );
  }
}

CourseList.propTypes = {
  item: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  pharmaciesActions: PropTypes.any
};

export default CourseList;
