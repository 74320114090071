import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {manufacturersActions, metaActions, usersActions} from '../actions'
import { Manufacturer } from '../pages'
import {Loader} from "../component";

const manufacturerKeys = [
  "title",
  "country",
  "phone",
  "email",
  "company",
  "manager",
];

class CreateManufacturerContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    const { metaActions: {updateNavi} } = this.props;
    updateNavi([{title: 'Производители', to: '/manufacturers'}])
  };

  submitForm = (state) => {
    const { manufacturersActions: { createManufacturer } } = this.props;

    createManufacturer((obj => manufacturerKeys.map(key => {
      obj[key] = state[key] }) && obj)({}),
      () => this.props.history.push('/manufacturers')
    )
  };

  render() {
    const { isRequesting } = this.props.manufacturers;
    return (
      <>
        {
          isRequesting
            ? <Loader />
            : <Manufacturer submitForm={this.submitForm} isNew {...this.props}/>
        }
      </>
    )
  }
}
const mapStateToProps = state => ({
  manufacturers: state.manufacturers,
  meta: state.meta,
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  manufacturersActions: bindActionCreators(manufacturersActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateManufacturerContainer)
