import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pharmaciesActions, metaActions } from '../actions'
import { Pharmacies } from '../pages'

class PharmaciesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  // componentDidMount = () => {
  //   const { contractActions: { getContracts } } = this.props
  //   getContracts()
  // }

  render() {
    return <Pharmacies {...this.props} />
  }
}

const mapStateToProps = state => ({
  pharmacies: state.pharmacies,
  meta: state.meta,
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PharmaciesContainer)
