import React from "react";

import { FormControl, FormHelperText, InputLabel } from "@material-ui/core";

import { InlineDateTimePicker as DateTimePickerX } from "material-ui-pickers";
import { Field } from "react-final-form";

export const DateTimePicker = ({
  input: { name, onChange, onBlur, value, ...restInput },
  meta,
  children,
  label,
  fullWidth,
  margin,
  InputLabelProps,
  ...rest
}) => {
  let date = value || onChange(new Date());

  return (
    <FormControl variant={"outlined"} fullWidth={fullWidth} margin={margin}>
      <DateTimePickerX
        {...rest}
        label={label}
        format="Do MMMM YYYY, h:mm"
        name={name}
        error={meta.error && meta.touched}
        // helperText={meta.touched ? meta.error : undefined}
        inputProps={restInput}
        onChange={onChange}
        onBlur={onBlur}
        value={date}
        variant={"outlined"}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};
