import React, { Component } from 'react'
import Map from '../../component/map/mapContainer'
import './style.scss'

class Geo extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className='subtask map'>
        <Map
          handleSelect={() => null}
          handleClose={() => null}
          onChange={this.props.onChange}
          />
      </div>
    )
  }
}

export default Geo
