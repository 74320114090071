import React, { Component } from "react";
import ListButton from "../../button/ListButton";
import { List, Typography } from "@material-ui/core";
import "./Navigate.scss"
import { withRouter } from 'react-router'

import { MedicalIcon,
  ContractsIcon,
  DrugsIcon,
  DrugstoreIcon,
  MaterialsIcon,
  ProjectsIcon,
  SupportIcon,
  CoursesIcon,
  UsersIcon,
  TrainingIcon,
  Pharmacies
} from "../../../static/imgs";

import {
  MEDICAL_FACILITIES_SIDEBAR,
  DRUGSTORE_SIDEBAR,
  CONTRACTS_SIDEBAR,
  ADMIN_CONTRACTS_SIDEBAR,
  PROJECTS_SIDEBAR,
  ADMIN_PROJECTS_SIDEBAR,
  USERS_SIDEBAR,
  DRUGS_SIDEBAR,
  MATERIALS_SIDEBAR,
  FILES_SIDEBAR,
  SUPPORT_SIDEBAR,
  TRAINING_SIDEBAR,
  COURSE_LIST_SIDEBAR, MANUFACTURERS,
} from "../../../constants/string"

let items = [
  {
    icon: <TrainingIcon />,
    label: TRAINING_SIDEBAR.label,
    action: TRAINING_SIDEBAR.action,
    toolTipMessage: 'Мое обучение'
  },  {
    icon: <ContractsIcon />,
    label: CONTRACTS_SIDEBAR.label,
    action: CONTRACTS_SIDEBAR.action,
    toolTipMessage: 'Мои контракты'
  }, {
    icon: <ProjectsIcon />,
    label: PROJECTS_SIDEBAR.label,
    action: PROJECTS_SIDEBAR.action,
    toolTipMessage: 'Мои проекты'
  }, {
    icon: <DrugstoreIcon />,
    label: DRUGSTORE_SIDEBAR.label,
    action: DRUGSTORE_SIDEBAR.action,
    toolTipMessage: 'Аптеки'
  }, {
    icon: <CoursesIcon />,
    label: FILES_SIDEBAR.label,
    action: FILES_SIDEBAR.action,
    toolTipMessage: 'Акции'
  }, {
    icon: <MaterialsIcon />,
    label: MATERIALS_SIDEBAR.label,
    action: MATERIALS_SIDEBAR.action,
    toolTipMessage: 'Материалы'
  }, {
    icon: <SupportIcon />,
    label: SUPPORT_SIDEBAR.label,
    action: SUPPORT_SIDEBAR.action,
    toolTipMessage: 'Техподдержка'
  },
];
let adminItems = [
  {
    icon: <TrainingIcon />,
    label: COURSE_LIST_SIDEBAR.label,
    action: COURSE_LIST_SIDEBAR.action,
    toolTipMessage: 'Список курсов'
  },  {
    icon: <ContractsIcon />,
    label: ADMIN_CONTRACTS_SIDEBAR.label,
    action: ADMIN_CONTRACTS_SIDEBAR.action,
    toolTipMessage: 'Контракты'
  },
  {
    icon: <ProjectsIcon />,
    label: ADMIN_PROJECTS_SIDEBAR.label,
    action: ADMIN_PROJECTS_SIDEBAR.action,
    toolTipMessage: 'Проекты'
  }, {
    icon: <MedicalIcon />,
    label: MEDICAL_FACILITIES_SIDEBAR.label,
    action: MEDICAL_FACILITIES_SIDEBAR.action,
    toolTipMessage: 'Медучереждения'
  }, {
    icon: <DrugsIcon />,
    label: DRUGS_SIDEBAR.label,
    action: DRUGS_SIDEBAR.action,
    toolTipMessage: 'Лекарства'
  }, {
    icon: <UsersIcon />,
    label: USERS_SIDEBAR.label,
    action: USERS_SIDEBAR.action,
    toolTipMessage: 'Сотрудники'
  },  {
    icon: <Pharmacies />,
    label: MANUFACTURERS.label,
    action: MANUFACTURERS.action,
    toolTipMessage: 'Производители'
  },
];

@withRouter
class SideBar extends Component {
  render() {
    const { opened, toggleSideBar, user, sidebarOpened, roles } = this.props;
    return (
      <div className={`drawerPaper ${!opened ? 'closed' : ''}`}>
        <div className='common sidebar__menu'>
          <List
              component="nav"
              className={'ListComponent'}
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                height: "100%"
              }}
          >
            {items.map((item, id) => (
                <ListButton
                    key={id}
                    icon={item.icon}
                    toolTipMessage={item.toolTipMessage}
                    label={item.label}
                    value={item.action}
                    active={this.props.location.pathname === `/${item.action.toLowerCase()}`}
                    textHidden={opened}
                />
            ))}
          </List>
        </div>
        {  this.props.roles.includes('ROLE_MANAGER')
        || this.props.roles.includes('ROLE_MEDICAL_REPRESENTATIVE')
        || this.props.roles.includes('ROLE_DEVELOPER')
        ?  <div className='admin sidebar__menu'>
            <Typography className='adminTitle'>Административное меню:</Typography>
            <List
                component="nav"
                className={'ListComponent'}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  height: "100%"
                }}
            >
              {adminItems.map((item, id) => (
                  <ListButton
                      key={id}
                      icon={item.icon}
                      label={item.label}
                      value={item.action}
                      active={this.props.location.pathname === `/${item.action.toLowerCase()}`}
                      textHidden={opened}
                  />
              ))}
            </List>
          </div>
        : ''}
      </div>
    );
  }
}

export default SideBar;
