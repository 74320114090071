import { courseTypes } from '../types'
import { getList, get, currentApiUrl } from '../api'

export const getCourses = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: courseTypes.GET_COURSES__REQUEST }))())
    const resourses = getState().meta.resourses
    getList(resourses['courses'])
    .then(data => dispatch((() => ({
      type: courseTypes.GET_COURSES__SUCCESS,
      payload: { items: data.items }
    }))()))
  }

export const getCoursesByQuery = query =>
  dispatch => {
    dispatch((() => ({ type: courseTypes.GET_COURSES__REQUEST }))())
    get(`${currentApiUrl}/v1/courses?title=${query}`).then(_courses => dispatch((() => ({
      type: courseTypes.GET_COURSES__SUCCESS,
      payload: { items: _courses.items }
    }))()))
  }


export const getCourse = course =>
  dispatch => {
    dispatch((() => ({ type: courseTypes.GET_COURSE__REQUEST }))())
    get(`${currentApiUrl}/v1/courses/${course}`).then(_course => {
      dispatch((() => ({
        type: courseTypes.GET_COURSE__SUCCESS,
        payload: { item: _course }
      }))())
    })
  }
