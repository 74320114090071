import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { withRouter } from "react-router";
import { MaterialsToolbar } from '../component/toolbar'
import { Loader, List } from '../component'
import "react-mde/lib/styles/css/react-mde-all.css";
import ModalSourceEditor from "../component/modal/ModalSourceEditor";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


//styles
import "../component/dashboard/Dashboard.scss";
import "./Materials.scss";
import "../component/imageExplorer/imageExplorer.scss";
// import "./Projects.scss";

const theme = createMuiTheme({
  palette: {
    primary: { main: '#D72638' }
  }
});

@withRouter
class Materials extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi },
      materialsActions: { getMaterials }
    } = this.props;
    updateNavi([{ title: "Материалы", to: "/materials" }]);
    getMaterials()
  }

  loadPreview = (pic) => (pic !== '' && pic != undefined) ? URL.createObjectURL(pic) : false
  handleMaterialClick = material => {
    this.props.history.push(`/materials/${material.id}`)
  }
  handleNewMaterialClick = () => {
    this.props.history.push('/materials/new')
  }

  render() {
    const { materials: { isRequesting, items } } = this.props

    return isRequesting
    ? <Loader />
    : <Paper className="materials" style={{ width: '89%' }}>
      <MaterialsToolbar
        onCreateClick={this.handleNewMaterialClick}
        />
      <List
        items={items.map(item => ({
          ...item,
          image: <img className='materialsPreview' src={item.preview.url} />,
          type: item.type === 'news' ? 'Новость' : 'Статья',
          publishedAt: new Date(item.publishedAt).toLocaleString('ru-RU')
        }))}
        onItemClick={this.handleMaterialClick}
        keys={['image', 'title', 'description', 'publishedAt', 'type']}
        headers={['', 'Название', 'Описание', 'Дата', 'Тип']}
        />
    </Paper>
  }
}

export default Materials;
