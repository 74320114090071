import { courseTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case courseTypes.GET_COURSES__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case courseTypes.GET_COURSES__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.items
    }
    default:
      return state
  }
}
