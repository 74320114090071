import { tasksTypes } from '../types'

const initialState = {
  item: null,
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case tasksTypes.CREATE_TASK__REQUEST: return {
      ...state,
      isRequesting: true,
    }
    case tasksTypes.CREATE_TASK__SUCCESS: return {
      ...state,
      isRequesting: false
    }
    case tasksTypes.GET_TASK__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case tasksTypes.GET_TASK__SUCCESS: return {
      ...state,
      item: action.payload.item,
      isRequesting: false
    }
    case tasksTypes.CLEAR_TASK: return {
      ...state,
      item: null
    }
    default: return state
  }
}
