const initialState = {
  filterMenuOpen: false
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    // case FILTER_TOGGLE:
    //   return {
    //     ...state,
    //     filterMenuOpen: !state.filterMenuOpen
    //   };

    default:
      return state;
  }
}
