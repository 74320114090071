import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { modalContract } from "../../constants/form";

import "./Contract.scss";
import FormCreate from "../form/AltFormCreate";
import FieldsCreate from "../form/FieldsCreate";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { submitContractsActions } from "../../actions/modal/createContract";
import Paper from "@material-ui/core/es/Paper/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { toggleModalCreateContract } from "../../actions/modal/toggleModal";
import Typography from "@material-ui/core/Typography";

class ModalCreateContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleOnSubmit = values => {
    this.props.submitContractsActions(values);
    this.props.handleOpenModal();
  };

  render() {
    let { isSubmiting, isOpenModalCreateContract } = this.props;
    return (
      <Modal className="modal_wrapper" open={isOpenModalCreateContract}>
        <div className="modal">
          <div className="title">
            <IconButton className="close" aria-label="Delete">
              <CloseIcon
                fontSize="small"
                onClick={this.props.handleOpenModal}
              />
            </IconButton>
            <Typography variant="h6">{`Создание нового контракта`}</Typography>
          </div>
          {isSubmiting && <LinearProgress />}
          <FormCreate handleOnSubmit={this.handleOnSubmit}>
            <FieldsCreate fields={modalContract} />
          </FormCreate>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitContractsActions,
      toggleModalCreateContract
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    isSubmiting: state.modal.isSubmiting,
    isError: state.modal.isError,
    isOpenModalCreateContract: state.modal.isOpenModalCreateContract
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreateContract);
