import { contractTypes, commentsTypes } from '../types'

const initialState = {
  item: null,
  isRequesting: true,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case commentsTypes.SEND_COMMENT__REQUEST:
    case contractTypes.GET_CONTRACT__REQUEST: return {
      ...state,
      isError: false,
      isRequesting: true
    }
    case contractTypes.GET_CONTRACT__SUCCESS: return {
      ...state,
      isRequesting: false,
      isError: false,
      item: {
        ...state.item,
        ...action.payload,
      }
    }
    case contractTypes.GET_CONTRACT__FAILURE: return {
      ...state,
      isRequesting: false,
      isError: true
    }
    default: return state
  }
}
