import React, {Component} from 'react';
import DebounceSearchInput from "../../component/search/DebounceSearch";
import Filter from "../../component/filters";

const courseStatuses = {
    'active': 'Активные',
    'finished': 'Завершенные',
    'not_started_yet': 'Не начавшиеся'
}

class CourseListHeader extends Component {

    render() {
        const { filter, handleToggleFilter } = this.props;
        return (
            <div className='course-list__header'>
                <div className='course-list__search'>
                    <DebounceSearchInput
                        value={filter.title || ''}
                        onChange={(value) => handleToggleFilter('title', value)}
                    />
                </div>
                <div className='course-list__filter'>
                    <Filter
                        value={filter.status}
                        onClick={(value) => handleToggleFilter('status', value)}
                        values={courseStatuses}
                    />
                </div>
            </div>
        );
    }
}

export default CourseListHeader;