import React, { Component } from 'react'
import { Typography,  } from '@material-ui/core'
import { SearchInput } from '../../component/search'
import './style.scss'

class Course extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'personal_training',
      course: '',
      trainee: ''
    }
  }
  handleChange = (name, value) => this.setState(
    () => ({ [name]: value }),
    () => this.props.onChange(this.state)
  )
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Прохождение курса</Typography>
        <div className='container'>
          <div className='icon course'></div>
          <div className='content'>
            <SearchInput data='users' dataKey='fullName' placeholder='Выберите человека' onSelect={item => this.handleChange('trainee', item.self)} />
            <SearchInput data='courses' dataKey='title' placeholder='Выберите курс' onSelect={item => this.handleChange('course', item.self)} />
          </div>
        </div>
      </div>
    )
  }
}

export default Course
