import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { metaActions, currentManufacturerActions } from "../actions";
import ManufacturerPage from "../pages/Profile/ManufacturerPage";
import { Loader } from '../component'

class CurrentManufacturerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi },
      currentManufacturerActions: { getCurrentManufacturer },
      match: { path, params: { userId } }
    } = this.props;
    getCurrentManufacturer(this.props.match.params.id)  
    updateNavi([{ title: "Ваш профиль", to: "/profile" }]);
  };

  render() {
    
    return <ManufacturerPage employee={this.props.match.path === '/users/:userId'} {...this.props} />;
  }
}

const mapStateToProps = state => ({
  currentManufacturer: state.currentManufacturer,
});
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  currentManufacturerActions: bindActionCreators(currentManufacturerActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentManufacturerContainer);
