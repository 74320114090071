import React from "react";
import {createPortal} from "react-dom";
import Modal from "../../../component/modal/Modal";
import {Button, IconButton, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import './style.scss'

function ModalConfirm({closeModal, callback, message}) {
    const handleConfirm = () => {
        callback();
        closeModal();
    }

    return createPortal(
        <Modal className="modal-confirm">
            <div className="modal-confirm__title">
                <Typography variant="h5">Подтвердите действие</Typography>
                <IconButton className="close" onClick={closeModal}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </div>
            <p className="modal-confirm__message">{message}</p>
            <div className="modal-confirm__group">
                <Button variant="contained" onClick={closeModal}>
                    Отменить
                </Button>
                <Button variant="contained" color="primary" onClick={handleConfirm}>
                    Подтвердить
                </Button>
            </div>
        </Modal>,
        document.querySelector("#modal-dom")
    );
}

export default ModalConfirm;
