import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { Loader, List } from "../../component";
import "../Pharmacies.scss";
import { USERS_SIDEBAR } from "../../constants/string";
import UsersTableToolbar from "../../component/toolbar/UsersTableToolbar";
import { userRoles } from "../../constants";
import moment from "moment";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import Block from "@material-ui/icons/Block";
import Check from "@material-ui/icons/Check";
import ModalConfirm from "../../component/modal/ModalConfirm/ModalConfirm";
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      roleFilter: [userRoles.ROLE_MEDICAL_REPRESENTATIVE],
      itemsOnPage: 20,
      pageNumber: 1,
      isOpenModal: false,
      banMessage: "",
      actionUser: {},
      callbackBanUser: () => {}
    };
  }
  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([{ title: USERS_SIDEBAR.label, to: "/users" }]);
    this.getUsers();
  };

  getUsers() {
    const {
      usersActions: { getUsers }
    } = this.props;

    getUsers(
      {
        "pagination[offset]":
          this.state.itemsOnPage * (this.state.pageNumber - 1),
        "pagination[count]": this.state.itemsOnPage,
        roles: this.state.roleFilter
      },
      {
        withWorkPlaces: true
      }
    );
  }

  handleOpenConfirmBanUser = (e, user) => {
    e.stopPropagation();
    this.setState({
      isOpenModal: true,
      actionUser: user,
      banMessage: `Подтвердите ${
        user.isBanned ? "разблокировку" : "блокировку"
      } пользователя ${user.fullName}`,
      callbackBanUser: user.isBanned
        ? () => this.handleUnBanUser(user)
        : () => this.handleBanUser(user)
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
      banMessage: "",
      callbackBanUser: () => {}
    });
  };

  handleBanUser = user => {
    const {
      usersActions: { banUser }
    } = this.props;
    banUser(user);
  };

  handleUnBanUser = user => {
    const {
      usersActions: { unbanUser }
    } = this.props;
    unbanUser(user);
  };

  componentDidUpdate = (_, prevState) => {
    if (
      prevState.itemsOnPage !== this.state.itemsOnPage ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.roleFilter !== this.state.roleFilter
    ) {
      this.getUsers();
    }
  };
  handleTabClick = value => this.setState({ roleFilter: [value] });

  handleTableControlClick = data =>
    this.setState({
      itemsOnPage: data.itemsOnPage,
      pageNumber: data.pageNumber
    });

  render() {
    const {
      users: { items, isRequesting, isRequestingAction }
    } = this.props;
    const { isOpenModal, callbackBanUser, banMessage, actionUser } = this.state;
    return (
      <Paper className="pharmacies">
        <UsersTableToolbar
          onTabClick={this.handleTabClick}
          current={this.state.roleFilter}
        />

        <List
          isRequesting={isRequesting}
          onTableControlChange={this.handleTableControlClick}
          onItemClick={_user => this.props.history.push(`/users/${_user.id}`)}
          items={items.map(item => ({
            ...item,
            specializations: item.specializations.items
              .map(({ specialization: { title } }) => title)
              .join(", "),
            employment: item.employment.items
              .map(({ medicalInstitution: { title } }) => title)
              .join(", "),
            registeredAt: moment(new Date(item.registeredAt)).format(
              "DD.MM.YYYY"
            ),
            action: isRequestingAction && actionUser.id === item.id ? (
                <div className='user__loader'>
                  <Loader small />
                </div>
            ) : (
              <Tooltip
                title={item.isBanned ? "Разблокировать" : "Заблокировать"}
              >
                <IconButton
                  onClick={e => this.handleOpenConfirmBanUser(e, item)}
                >
                  {item.isBanned ? (
                    <Check />
                  ) : (
                    <Block />
                  )}
                </IconButton>
              </Tooltip>
            )
          }))}
          keys={[
            "fullName",
            "specializations",
            "employment",
            "registeredAt",
            "rewardPoints",
            "action"
          ]}
          headers={[
            "Имя",
            "Специализация",
            "Место работы",
            "Дата регистрации",
            "Баллы",
            "Действие"
          ]}
        />
        {isOpenModal && (
          <ModalConfirm
            message={banMessage}
            callback={callbackBanUser}
            closeModal={this.handleCloseModal}
          />
        )}
      </Paper>
    );
  }
}

export default Users;
