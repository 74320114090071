import React from 'react'
import { Form } from 'react-final-form'
import Button from "@material-ui/core/Button";


const AltFormCreate = (props) => (
    <Form
      onSubmit={props.handleOnSubmit}
      initialValues={{ stooge: 'larry', employed: false }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="form">
          {props.children}
          {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
          <Button
            style={{ margin: " 20px 20px 20px auto " }}
            color={"secondary"}
            variant="contained"
            type="submit"
            // disabled={props.disabled}
          >
            Отправить
          </Button>
        </form>
      )}
    />
)

export default AltFormCreate