import React from "react";
import { Typography, Button } from '@material-ui/core';
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
//styles
import "./MaterialsEditor.scss"

class MaterialsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }
  loadPreview = () => {
    const { material: { preview } } = this.props
    return preview.url ? preview.url : (preview !== '' && preview != undefined && preview.size) ? URL.createObjectURL(preview) : null
  }

  render() {
    const { material: { body, title, categories }} = this.props;

    return (
      <div className='preview'>
        <img src={this.loadPreview()} />
        <div className='categories'>
          {
            categories.map((category, index) => <Typography key={index} className='colorTitle'>{category.title || category}</Typography>)
          }
        </div>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body2'>Дата публикации</Typography>
        <ReactMde
          selectedTab='preview'
          onChange={this.handleValueChange}
          value={(() => body)()}
          generateMarkdownPreview={markdown =>
            Promise.resolve(this.converter.makeHtml(markdown))
          }
        />
        <div className='buttonRow'>
          <Button variant="outlined" style={{color: '#D72638', borderColor: '#D72638'}}>
            Отмена
          </Button>
          <Button variant="contained" color="secondary" style={{whiteSpace: 'nowrap'}}>
            Создать статью
          </Button>
        </div>
      </div>
    );
  }
}

export default MaterialsEditor;
