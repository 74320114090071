import { manufacturersTypes } from '../types'

const initialState = {
  isEditing: false,
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case manufacturersTypes.GET_MANUFACTURERS__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case manufacturersTypes.GET_MANUFACTURERS__SUCCESS: return {
      ...state,
      isRequesting: false,
      ...action.payload,
    };
    case manufacturersTypes.GET_MANUFACTURER__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case manufacturersTypes.GET_MANUFACTURER__SUCCESS: return {
      ...state,
      isRequesting: false,
      ...action.payload,
    };
    case manufacturersTypes.CREATE_MANUFACTURER__REQUEST: return {
      ...state,
      isRequesting: true,
    };
    case manufacturersTypes.CREATE_MANUFACTURER__SUCCESS: return {
      ...state,
      isRequesting: false,
    };

    default:
      return state
  }
}
