export const GET_WORK_PLACES = "GET_WORK_PLACES";
export const GET_WORK_PLACES_SUCCESS = "GET_WORK_PLACES_SUCCESS";
export const GET_WORK_PLACES_FAIL = "GET_WORK_PLACES_FAIL";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAIL = "CREATE_EMPLOYEE_FAIL";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL";