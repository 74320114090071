import CRUD from "./api";
import { createAction, createError, getID } from "../utils/classActionCreator";
import {
    USERS,
    DRUGS,
    MEDICAL_INSTITUTIONS,
    INTERNAL_COMPANIES,
    MANUFACTURERS,
} from "../constants/entities";
// import { checkCollection } from "../utils/validate";

let renderUser = ({ items }) =>
  items.map(item => ({
    render: {
      label: item.fullName,
      id: item.itn
    },
    item: item
  }));

let renderMedicalInstitutions = ({ items }) =>
  items.map(item => ({
    render: {
      label: item.title,
      id: item.id
    },
    item: item
  }));

let renderDrugs = ({ items }) =>
  items.map(item => ({
    render: {
      label: item.title,
      id: item.id
    },
    item: item
  }));

let renderInternalCompany = ({ items }) =>
  items.map(item => ({
    render: {
      label: item.legalEntity.state === 'not_loaded_yet' ? item.legalEntity.itn : item.legalEntity.name,
      id: item.itn
    },
    item: item
  }));

let renderManufacturers = ({ items }) =>
    items.map(item => ({
        render: {
            label: item.title,
            id: item.itn
        },
        item: item
    }));

export default class ApiService {
  constructor(_entity, _search, _withoutFilteringOnBackend) {
    this._entity = _entity || null;
    this._token = localStorage.getItem("bearer_token") || null;
    this._search = _search || [];
    this._withoutFilteringOnBackend = _withoutFilteringOnBackend || false;
  }

  static _entity;
  static _token;
  static _search;

  getAll() {
    return this.get("");
  }

  get(linkOrUUID) {
    return new CRUD(this._token)
      .getResource(`/v1/${this._entity}${getID(linkOrUUID)}`)
      .then(res => (!res.status ? res : createError(res)));
  }

  post(data) {
    return new CRUD(this._token)
      .postResource(`/v1/${this._entity}`, data)
      .then(res => res)
      .catch(err => createError(err));
  }

  search = title => {
    return new CRUD(this._token)
      .getResource(this._withoutFilteringOnBackend ? `/v1/${this._entity}` : `/v1/${this._entity}?${this._search}=${title}`)
      .then(res => {
        if (this._withoutFilteringOnBackend) {
          res = {
            ...res,
            items: res.items.filter(item => ({ ...item.legalEntity }))
          }
        }
        switch (this._entity) {
          case INTERNAL_COMPANIES:   return renderInternalCompany(res);
          case USERS:                return renderUser(res);
          case DRUGS:                return renderDrugs(res);
          case MEDICAL_INSTITUTIONS: return renderMedicalInstitutions(res);
          case MANUFACTURERS:        return renderManufacturers(res);
        }
      })
      .catch(err => []);
  };


  postFile(data, type) {
    let bodyData = new FormData();
    bodyData.append("file", data);
    bodyData.append("type", type);

    return new CRUD(this._token)
      .postFile(`/v1/${this._entity}`, bodyData)
      .then(res => res)
      .catch(err => createError(err));
  }

  // .catch(err => createError(err));

  update = (id, newValue) => {
    return new CRUD(this._token).patchResource(
      `/v1/${this._entity}/${id}`,
      newValue
    );
  };
}
