import React, { Component } from 'react'
import { Typography, Input, Button } from '@material-ui/core'
import './style.scss'
import PlayListAddIcon from "@material-ui/icons/PlaylistAdd";


class Poll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'poll',
      questionText: '',
      expectedAnswer: null,
      answers: [],
      _answer: ''
    }
  }
  handleChange = (name, value) => this.setState(
    () => ({ [name]: value }),
    () => this.props.onChange(((_old, _new) =>
        Object.keys(_old).filter(key =>
          key[0] !== '_').map(key => {
            _new[key] = _old[key]
          })
        && _new)(this.state, {}))
  )
  handleDeleteAnswer = index => this.setState(state => ({
    answers: state.answers.filter((_, _index) => index !== _index)
  }))
  handleAddAnswer = () => this.setState(state => ({
    answers: [...state.answers, state._answer],
    _answer: ''
  }))
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Провести тест</Typography>
        <div className='container'>
          <div className='icon'>
            <PlayListAddIcon color={'primary'}/>
          </div>
          <div className='content'>
            <Input placeholder='Текст вопроса' onChange={({ target: { value } }) => this.handleChange('questionText', value)}/>
            <Input placeholder='Правильный ответ' onChange={({ target: { value } }) => this.handleChange('expectedAnswer', value)}/>
            <div className='line'>
              <Input value={this.state._answer} onChange={({ target: { value } }) => this.handleChange('_answer', value)} placeholder='Текст ответа' />
              <Button onClick={this.handleAddAnswer}>Добавить ответ</Button>
            </div>
            <div className='poll-answers'>
              {
                this.state.answers.map((a, key) =>
                  <div className='answer'>
                    <Typography>{a}</Typography>
                    <div className='delete' onClick={() => this.handleDeleteAnswer(key)}></div>
                  </div>)
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Poll
