import React, { Component, Fragment } from 'react'
import DropList from '../dropList/dropList.jsx'
import { Loader } from '../'
import './style.scss'

//TODO если высота tableInfo будет большой отступа снизу таблицы, то верстка поедет

const Item = ({ item, onCheck, onItemClick, keys }) => {
    return (
    <div className="item" onClick={onItemClick}>
      {
        onCheck ? <input type='checkbox' onClick={onCheck} /> : null
      }
      {
        keys.map((key, index) => <span key={index}>{item[key]}</span>)
      }
    </div>

  )
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: [],
      itemsOnPage: 10,
      pageNumber: 1
    }
  }


  handleCheck = id => {
    const { checked } = this.state,
      { onCheck } = this.props
    if (checked.includes(id)) {
      const updated = checked.filter(item => item !== id)
      this.setState(
        () => ({ checked: updated }),
        () => onCheck(this.state.checked)
      )
    } else {
      const updated = [...checked, id]
      this.setState(
        () => ({ checked: updated }),
        () => onCheck(this.state.checked)
      )
    }
  }
  changeRowsValue = event => {
    this.setState(
      { itemsOnPage: event.target.value, pageNumber: 1 },
      () => this.props.onTableControlChange && this.props.onTableControlChange(this.state)
    )
  }
  handleNextPageClick = () => {
    this.setState(
      state => ({ pageNumber: state.pageNumber + 1 }),
      () => this.props.onTableControlChange && this.props.onTableControlChange(this.state)
    )
    if (this.state.pageNumber >= Math.ceil(this.props.items.length / this.state.itemsOnPage)) {
      return this.setState({ pageNumber: this.state.pageNumber })
    }
  }
  handlePrevPageClick = () => {
    this.setState(
      state => ({ pageNumber: state.pageNumber - 1 }),
      () => this.props.onTableControlChange && this.props.onTableControlChange(this.state)
    )
    if (this.state.pageNumber == 1) {
      return this.setState({ pageNumber: this.state.pageNumber })
    }
  }


  render() {
    const { isRequesting, items, headers, onCheck, keys, onItemClick } = this.props;
    const {pageNumber} = this.state;
    console.log(this.state)
    console.log(this.props)
      return (
      <div className='table'>
        <div className="item headline_text">
          {onCheck ? <span></span> : null}
          {
            headers.map((item, index) => <span key={index}>{item}</span>)
          }
        </div>
        <div className='items'>
          {
            isRequesting
            ? <Loader />
          : items
          .filter((item, index) => index >= (this.state.pageNumber - 1) * this.state.itemsOnPage &&
          index < this.state.pageNumber * this.state.itemsOnPage)
          .map((item, index) => <Item onCheck={onCheck
            ? () => this.handleCheck(item.id)
            : null}
            onItemClick={
              onItemClick
              ? () => onItemClick(item)
              : () => null
            }
            item={item}
            key={index}
            keys={keys || []}
            />)
          }
        </div>
        <div className="tableInfo">
          {/*<div>
            <span>{this.state.pageNumber + ' из '}</span>
            <span>{Math.ceil(items.length / this.state.itemsOnPage)}</span>
          </div>*/}
          <div>
            <span>{'Строк на страницу: '}</span>
            <DropList onChange={this.changeRowsValue} />
          </div>
          <div className="previousPage" onClick={this.handlePrevPageClick}></div>
            <span className='current'>{pageNumber}</span>
          <div className="nextPage" onClick={this.handleNextPageClick}></div>
        </div>
      </div>
    )
  }
}

export default List
