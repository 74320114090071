import React, { Component } from "react";
import "./dropBox.scss";
import Dropzone from "react-dropzone";
import AddImageIcon from "@material-ui/icons/AddAPhoto";
import apiService from "../../api/apiService";
import { STATIC_FILES } from "../../constants/entities";
import Badge from "@material-ui/core/Badge";
import CloseIcon from "@material-ui/icons/Close";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap"
};
const thumb = {
  display: "inline-flex"
};

const img = {
  display: "block",
  width: "auto",
  height: "130px"
};

class DropBox extends React.Component {
  constructor() {
    super();
    this.state = {
      files: []
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    return prevState !== this.state
      ? this.state.files.length !== 0 ? this.setData(this.state.files, "task_preview") : null
      : null;
  }
  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  setData = async files => {
    let {
      input: { onChange }
    } = this.props;
    let newPreview = await new apiService(STATIC_FILES).postFile(
      files[0],
      "task_preview"
    );
    await onChange(newPreview.id);
  };

  onDrop(files, rejectedFiles, e) {
    this.setState(() => ({
      files: files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      ),
      filesWereDropped: !e.target.files || e.target.files.length === 0
    }));
  }

  onCancel() {
    this.setState(() => ({
      files: []
    }));
  }

  render() {
    const { files } = this.state;

    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <img src={file.preview} alt={file.preview} style={img} />
        {/*<div className="div"> {file.name}</div>*/}
      </div>
    ));
    let disabled = this.state.files.length !== 0;
    return (
      <Dropzone
        accept="image/*"
        onDrop={this.onDrop.bind(this)}
        onFileDialogCancel={this.onCancel.bind(this)}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropbox-container" {...getRootProps()}>

              <div className="dropbox" id="dropbox">
                <input {...getInputProps()} />
                <div className="dropbox-info">
                  {!disabled ? <AddImageIcon /> : null}

                  <div className="image-container">
                    <Badge
                      color="secondary"
                      badgeContent={disabled ? <CloseIcon /> : null}
                      onClick={() => this.onCancel()}
                    >
                    <aside style={thumbsContainer}>{thumbs}</aside>
                    </Badge>
                  </div>
                </div>
              </div>

          </div>
        )}
      </Dropzone>
    );
  }
}

export default DropBox;
