import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { metaActions, ticketsActions } from '../actions'
import { Ticket } from '../pages'
import { Loader } from '../component'

class TicketContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketId: null
    }
  }

  componentDidMount = () => {
    const { metaActions: { updateNavi }, ticketsActions: { getTicket }, match: { params: { ticketId } } } = this.props
    this.setState({ticketId})
    getTicket(ticketId)
    updateNavi([{ title: 'Обращения', to: '/support' }, { title: 'Имя тикета', to: `/support/${ticketId}` }])
  }

  render() {
    return this.props.ticket.isRequesting ? <Loader /> : <Ticket {...this.props} id={this.state.ticketId} />
  }
}

const mapStateToProps = state => ({
  ticket: state.ticket,
  meta: state.meta,
  user: state.auth.user,
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  ticketsActions: bindActionCreators(ticketsActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketContainer)
