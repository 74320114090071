import { usersTypes } from '../types'
import {getList, get, currentApiUrl, post} from '../api'
import {setQueryString} from "../utils";

export const getUsers = (filter, options) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: usersTypes.GET_USERS__REQUEST }))());
    getList(`${getState().meta.resourses.users}${setQueryString(filter)}`)
    .then(data => {
            if (options && options.withWorkPlaces)
                return Promise.all(data.items.map(user =>
                    get(`${process.env.BASE_API_URL}${user.employment}`)
                        .then(_employment => ({...user, employment: _employment}))
                ))
            return data.items
        }
    )
    .then(data => dispatch((() => ({
      type: usersTypes.GET_USERS__SUCCESS,
      payload: { items: data }
    }))()))
  }

export const getUsersByQuery = query => 
	dispatch => {
		dispatch((() => ({ type: usersTypes.GET_USERS__REQUEST }))())
		get(`${currentApiUrl}/v1/users?query=${query}`).then(_users => {
			dispatch((() => ({
				type: usersTypes.GET_USERS__SUCCESS,
      			payload: { items: _users.items }
			}))())
		})
	}

export const getUser = user => 
  dispatch => {
    dispatch((() => ({ type: usersTypes.GET_USER__REQUEST }))())
    get(`${currentApiUrl}/v1/users/${user}`).then(_user => {
      get(`${currentApiUrl}${_user.courseAccesses}`).then(_accesses => {
        Promise.all(_accesses.items.map(_access => get(`${currentApiUrl}${_access.course}`))).then(_courses => {
          const _accessesWithCourse = _accesses.items.map((__access, __index) => ({ ...__access, course: _courses[__index] }))
          get(`${currentApiUrl}${_user.managedMedicalInstitutions}`).then(_institutions => {
            get(`${currentApiUrl}${_user.employment}`).then(_workPlaces => {
              if (_user.internalCompanyEmployment) {
                get(`${currentApiUrl}${_user.internalCompanyEmployment}`).then(_internalCompany => {
                  dispatch((() => ({
                    type: usersTypes.GET_USER__SUCCESS,
                    payload: { 
                      user: {
                        ..._user, 
                        courseAccesses: { items: _accessesWithCourse },
                        managedMedicalInstitutions: _institutions,
                        internalCompanyEmployment: _internalCompany,
                        workPlaces: _workPlaces
                      } 
                    }
                  }))())
                })
              } else {
                dispatch((() => ({
                  type: usersTypes.GET_USER__SUCCESS,
                  payload: { 
                    user: {
                      ..._user, 
                      courseAccesses: { items: _accessesWithCourse },
                      managedMedicalInstitutions: _institutions,
                      workPlaces: _workPlaces
                    } 
                  }
                }))())
              }
            })
          })
        })
      })
    })
  }

export const banUser = user =>
    dispatch => {
        dispatch((() => ({ type: usersTypes.BAN_USER_REQUEST }))())
        post(`${currentApiUrl}/v1/ban-user-requests`, { user: user.self })
            .then((data) => {
                dispatch((() => ({
                    type: usersTypes.BAN_USER_SUCCESS,
                    payload: { id: user.id }
                }))())
            })
            .catch(error => {
                alert('Ошибка блокировки пользователя');
                dispatch((() => ({
                    type: usersTypes.BAN_USER__FAILURE
                }))())
            })
    }

export const unbanUser = user =>
    dispatch => {
        dispatch((() => ({ type: usersTypes.UNBAN_USER_REQUEST }))())
        post(`${currentApiUrl}/v1/unban-user-requests`, { user: user.self })
            .then((data) => {
                dispatch((() => ({
                    type: usersTypes.UNBAN_USER_SUCCESS,
                    payload: { id: user.id }
                }))())
            })
            .catch(error => {
                alert('Ошибка разблокировки пользователя');
                dispatch((() => ({
                    type: usersTypes.UNBAN_USER__FAILURE
                }))())
            })
    }
