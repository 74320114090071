import React, { Component } from "react";
import { Loader, List } from '../component'
import './ImportPharmacies.scss'
import { pharmKeys } from '../constants/pharmacies.js'

class ImportPharmacies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpened: false,
      file: null
    };
  }
  handleImportClick = () => this.props.pharmaciesActions.importPharmacies(this.props.pharmaciesImport.items)
  handleChangeFile = ({ target: { files } }) => this.setState({ file: files[0] }, () => this.props.pharmaciesActions.loadPharmaciesFromFile(this.state.file))
  render() {
    const { pharmaciesImport: { isRequesting, items, itemsMeta, progress } } = this.props

    return isRequesting 
    ? <Loader />
    : <div className="import_from_excel__wrapper">
        <div style={{display: "flex"}}>
          <label className='import__input'>
            <input className='import__input' type="file" accept=".xls,.xlsx" onChange={this.handleChangeFile}/>
            <div className='import__input'>+</div>
          </label>
          <button className='import__button' disabled={!this.state.file} onClick={this.handleImportClick}>Импортировать</button>
        </div>
        <div className='import_table'>
          <div className='header'>
            {
              ['__state', ...Object.keys(pharmKeys)].map((keyName, index) => <div className={keyName} key={index}>{pharmKeys[keyName]}</div>)
            }
          </div>
          {
            items.length > 0 && items.map((pharm, key) => (
              <div className='line'key={key}>
               {
                ['__state', ...Object.keys(pharmKeys)].map((keyName, index) => <div className={`${keyName} ${pharm[keyName] === undefined ? 'empty' : ''} ${keyName === '__state' ? (`${itemsMeta[key].isError ? 'error' : ''} ${itemsMeta[key].isImported ? 'imported' : ''}`) : (itemsMeta[key].isError ? (itemsMeta[key].errors.includes(keyName) ? 'error' : '') : '' )}`} key={index}>{keyName === '__state' ? '' : pharm[keyName] === undefined ? 'нет данных': pharm[keyName]}</div>)
               }
              </div>))
          }
        </div>
      </div>
  }
}

export default ImportPharmacies;
