import React, { Component, Fragment } from "react";
import "./contract.scss";
import { ProjectCard } from "../component/card";
import GroupIcon from "@material-ui/icons/Group";
import EventIcon from "@material-ui/icons/Event";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { CommentsContainer } from "../containers";
import NoteIcon from "@material-ui/icons/Note";
import GeneralContract from "../component/modal/GeneralContract";
import { Loader } from "../component";
import { Typography, Avatar, Button, Input, Menu, MenuItem, TextField } from '@material-ui/core'
import "@material-ui/icons/RadioButtonUncheckedRounded";
import "@material-ui/icons/SwapVerticalCircle";
import "@material-ui/icons/AccessTime";
import "@material-ui/icons/PlayCircleFilled";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import { Edit } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import ShopingCard from "@material-ui/icons/ShoppingCart";
import ModalCreateProject from "../component/modal/ModalCreateProject";
import { EditProject } from "../component/modal";

const actions = [
  { icon: <ShopingCard />, name: "Создать проект маркетинга" },
  // { icon: <FileCopyIcon />, name: "Копировать" },
  // { icon: <SaveIcon />, name: "Сохранить" },
  // { icon: <DeleteIcon />, name: "Удалить" }
];

const ContractInfo = ({
  contract
}) => {
  console.log(contract);
  return (
    <div className="info_container">
      <div className="info">
        <div className="row">
          <GroupIcon className="icon" />
          <Typography className="text" variant="body2">
            {`Договор ${contract.contractNumber}`}
          </Typography>
        </div>
        <div className="row">
          <EventIcon className="icon" />
          <Typography className="text" variant="body2">
            {`${new Date(contract.signDate).toLocaleDateString('ru-RU', {year: 'numeric', month: 'long', day: 'numeric'})} — ${new Date(contract.expirationDate).toLocaleDateString('ru-RU', {year: 'numeric', month: 'long', day: 'numeric'})}`}
          </Typography>
        </div>
      </div>
      <div className="info_status">
        <div className="row status item">
          {/*<div className="label">Действующий</div>*/}
        </div>
        <div className="row manager item">
          <Avatar className="avatar" alt="Remy Sharp">
            {
              contract.manager.avatar
              ? <img  src={contract.manager.avatar.url} />
              : contract.manager.fullName.split(' ').map(word => word[0]).join('')
            }
          </Avatar>
          <div className="label"><Typography>{contract.manager.fullName} (Руководитель проекта)</Typography></div>
        </div>
      </div>
    </div>
  );
};

class ProjectControl extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div className="control">
        <Button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {/*<Typography className="text" variant="h6">*/}
          Проекты
          {/*</Typography>*/}
          <ArrowDropDown />
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <Typography className="text" variant="h6">
              Проекты
            </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <Typography className="text" variant="body1">
              ...
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

class LogsControl extends Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div className="control">
        <Button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Логи и комментарии
          <ArrowDropDown />
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <Typography className="text" variant="h6">
              Проекты
            </Typography>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <Typography className="text" variant="body1">
              ...
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const ContractProjects = ({
  projects,
  onCheck,
  onClick,
  changeStatusProject,
  contractID
}) => (
  <div
    className={`project ${projects.length === 0 ? "without_projects" : null}`}
  >
    {projects.length > 0 && <ProjectControl />}
    {projects.length > 0 ? (
      projects.map((project, key) => (
        <ProjectCard
          onCheck={onCheck}
          onClick={onClick}
          project={project}
          key={key}
          contractID={contractID}
          changeStatus={changeStatusProject}
        />
      ))
    ) : (
      <div>
        <NoteIcon classes={{ fontSizeLarge: "icon" }} fontSize="large" />У
        контракта пока нет проектов
      </div>
    )}
  </div>
);

const ContractLogs = props => (
  <div className="logs">
    <LogsControl />
    <CommentsContainer
      type='contract'
      resourse={props.contract}
      />
    {props.menuType === "add" ? (
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className="fab"
        style={{ backgroundColor: "rgba(#00796b , 0.01)" }}
        hidden={props.hidden}
        icon={<SpeedDialIcon />}
        onBlur={props.handleClose}
        onClick={props.handleClick}
        onClose={props.handleClose}
        onFocus={props.handleOpen}
        onMouseEnter={props.handleOpen}
        onMouseLeave={props.handleClose}
        open={props.open}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={props.handleOpenModal}
          />
        ))}
      </SpeedDial>
    ) : (
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className="fab"
        style={{ backgroundColor: "rgba(#00796b , 0.01)" }}
        hidden={props.hidden}
        icon={<Edit />}
        onBlur={props.handleClose}
        onClick={props.handleProjectEditClick}
        onClose={props.handleClose}
        onFocus={props.handleOpen}
        onMouseEnter={props.handleOpen}
        onMouseLeave={props.handleClose}
        open={props.open}
      >
        {null}
      </SpeedDial>
    )}
    {/*<Fab onClick={props.handleOpenModal} >*/}
    {/*<EditIcon />*/}
    {/*</Fab>*/}
  </div>
);

class Contract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      modalOpen: false,
      selected: [],
      isModalOpen: false,
      isEditProjectModalOpened: false
    };
  }

  componentDidMount = () => {
    const {
      id,
      contractActions: { getContract },
      metaActions: { updateNavi },
      meta: { naviTitleHelper }
    } = this.props;
    getContract({ id });
    updateNavi([
      { title: "Контракты", to: "/contracts" },
      { title: naviTitleHelper, to: `/contracts/${id}` }
    ]);
  };
  componentWillUpdate = (prevProps, prevState) => {
    prevProps.contract.isRequesting !== this.props.contract.isRequesting && this.setState({ isEditProjectModalOpened: false, selected: [] })
  }

  handleVisibility = () => {
    this.setState(state => ({
      open: false,
      hidden: !state.hidden
    }));
  };

  handleClickL = name => {
    this.setState(state => ({
      open: !state.open
    }));
    if (name === "Создать проект маркетинга") {
      this.setState(state => ({
        modalOpen: !state.modalOpen
      }));
    }
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleProjectCheck = ({ id, checked }) => {
    const { selected } = this.state;
    if (selected.filter(item => item.id === id).length === 0) {
      this.setState({ selected: [...selected, { id, checked }] });
    } else {
      this.setState({ selected: selected.filter(item => item.id !== id) });
    }
  };
  handleProjectClick = id => {
    this.props.history.push(`/projects/${id}`);
  };
  handleOpenModal = () =>
    this.setState(() => ({ isModalOpen: !this.state.isModalOpen }));

  handleProjectEditClick = () => {
    this.setState({ isEditProjectModalOpened: true });
  };
  handleProjectsSave = ({ ...data }) => {
    const { selected } = this.state,
      {
        projectActions: { editProjects },
        contract
      } = this.props;

    editProjects(selected.map(item => item.id), this.props.id, {
      manager: data.id
    });
  };
  handleProjectModalClose = () => {
    this.setState({ isEditProjectModalOpened: false });
  };

  render() {
    const {
      contract: { item }
    } = this.props;

    return this.props.contract.isRequesting ? (
      <Loader />
    ) : (
      <div className="contract">
        <ModalCreateProject
          contractId={this.props.id}
          handleOpenCreateProjectWizard={this.props.toggleModalWizzard}
        />
        <ContractInfo contract={item} resourses={item} />
        <ContractProjects
          projects={item && item.projects}
          changeStatusProject={this.props.changeStatusProject}
          onCheck={this.handleProjectCheck}
          onClick={this.handleProjectClick}
          contractID={this.props.id}
        />
        <ContractLogs
          contract={item.self}
          handleOpenModal={this.props.toggleModalWizzard}
          handleVisibility={this.handleVisibility}
          handleClick={this.handleClick}
          handleClickL={this.handleClickL}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          handleProjectEditClick={this.handleProjectEditClick}
          open={this.state.open}
          hidden={this.state.hidden}
          menuType={this.state.selected.length === 0 ? "add" : "edit"}
        />
        <GeneralContract
          open={this.state.isModalOpen}
          handleOpenModal={this.handleOpenModal}
        />
        <EditProject
          projects={
            item
              ? this.state.selected.map(
                  one =>
                    item.projects.filter(
                      project => project.data.id === one.id
                    )[0].data.title
                )
              : []
          }
          onClose={this.handleProjectModalClose}
          opened={this.state.isEditProjectModalOpened}
          onSubmit={this.handleProjectsSave}
        />
      </div>
    );
  }
}

export default Contract;
