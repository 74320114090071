import React, { Component } from "react";
import Table from "../component/table/table/TablePaperWrapper";
import Paper from "@material-ui/core/Paper";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";
import TablePagination from "../component/table/";
import { withRouter } from "react-router";
import ContractTableToolbar from "../component/toolbar/ContractTableToolbar";
import { Loader } from "../component";
import "./Contracts.scss";
import ModalCreateContract from "../component/modal/ModalCreateContract";
import DropBox from "../component/card/dropBox";

@withRouter
class Contracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterOpened: false,
      modalOpen:false
    };
  }
  handleContractClick = (id, title) => {
    const {
      metaActions: { provideTitle }
    } = this.props;
    provideTitle(title);
    this.props.history.push(`/contracts/${id}`);
  };
  handleFilterChange = filter => {
    this.props.contractActions.changeFilter({ filter });
  };
  handleToggleFilter = () =>
    this.setState({ isFilterOpened: !this.state.isFilterOpened });



  render() {
    const {
      contracts: { items, isRequesting, filter }
    } = this.props;

    return isRequesting ? (
      <Loader />
    ) : (
      <Paper className="contracts">

        <ModalCreateContract  handleOpenModal={this.props.toggleModalCreateContract} />
        {/*<DropBox/>*/}
        <ContractTableToolbar
          toggleFilter={this.handleToggleFilter}
          withFilter={this.state.isFilterOpened}
          handleOpenModal={this.props.toggleModalCreateContract}
        />
        {
          this.props.isAdmin
          ? <Table
              data={
                filter.length === 0
                  ? items
                  : items.filter(
                  item =>
                    filter.includes(item.manufacturer) ||
                    filter.includes(item.internalCompany)
                  )
              }
              onItemClick={this.handleContractClick}
              sidebarOpen={false}
            />
          : <Table
              data={
                filter.length === 0
                  ? this.props.modifiedProps
                  : this.props.modifiedProps.filter(
                  item =>
                    filter.includes(item.manufacturer) ||
                    filter.includes(item.internalCompany)
                  )
              }
              onItemClick={this.handleContractClick}
              sidebarOpen={false}
            />
        }
        <FilterSideBar
          items={(parthners =>
            Array.from(new Set(items.map(item => item.int))).map(id =>
              parthners.find(item => item.int === id)
            ))(
            items
              .map(item => [
                item.internalCompany,
                item.manufacturer
              ])
              .flat()
          )}
          onChange={this.handleFilterChange}
          filterMenuOpen={"123"}
          opened={this.state.isFilterOpened}
          nameKey={"name"}
          filterName={"Контрагент"}
        />
      </Paper>
    );
  }
}

export default Contracts;
