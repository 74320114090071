import { submitTasks } from "../../utils/submiting";
import { toggleModalCreateTask } from "../../actions/modal/toggleModal";
import { getProjects } from "../../actions/project";

export function submitTasksActions({ id, tasks }) {
  return (dispatch, getState) => {
    (async () => {
      dispatch({ type: "SUBMITING_FORM" });

      let submitTask = await submitTasks(id, tasks);

      dispatch({ type: "SUBMITED_FORM" });
      dispatch(toggleModalCreateTask());
      dispatch(getProjects({ id }))
    })();
  };
}
