import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";

//            ! ALL PROPS REQUIRED
//  ............................................
//  :   name:                    <String>      :
//  :   suggestions:             <Array>       :
//  :   onChange:                <Function>    :
//  :   onSelect:                <Function>    :
//  :   onRemove:                <Function>    :
//  :   canMultiplySelect:       <Boolean>     :
//  ............................................
const styles = theme => ({
  root: { maxHeight: "56px" },
  marginNormal: { margin: 0 },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.primary.dark
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.primary.dark
    }
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.dark
    }
  },
  notchedOutline: {}
});

function DropItem({ label, onClick }) {
  return (
    <MenuItem key={label} onClick={onClick}>
      {label}
    </MenuItem>
  );
}

class BestSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      value: "",
      anchor: null,
      menuAnchorElement: null,
      suggestions: [],
      countValue: 0
    };

    this.callApi = _.debounce(this.callApi, 250, { maxWait: 1000 });
  }


  callApi = async () => {
    let { api } = this.props;
    let { value } = this.state;
    let updaterSuggestions = value => ({ suggestions: value });

    let res = await api(value);

    this.setState(() => updaterSuggestions(res));
  };

  handleChange = e => {
    let updater = value => ({ value: value });

    this.setState(updater(e.target.value));

    this.callApi();
  };

  handleSelect = async item => {
    const { select } = this.props;
    let onChange = this.props.onChange || this.props.input.onChange;
    const { selected } = this.state;
    const updated = item =>
      select
        ? { selected: [...selected, item], value: item.render.label }
        : { selected: [item], value: item.render.label };

    // const clearSuggestions = state =>

    await this.setState(updated(item));
    await onChange(this.state.selected);
    this.handleMenuClose();
  };

  handleMenuClose = () =>
    this.setState(state => ({ menuAnchorElement: null, suggestions: [] }));
  handleInputClick = e => this.setState({ menuAnchorElement: e.target });

  render() {
    const { select, classes, canMultiplySelect, className } = this.props,
      { suggestions, selected, menuAnchorElement } = this.state;
    const open = suggestions && suggestions.length !== 0;

    return (
      <div className={`autocompletesInput ${className}`}>
        <TextField
          {...this.props}
          value={this.state.value}
          fullWidth
          label={this.props.label}
          placeholder={this.props.placeholder}
          style={{ marginBottom: "10px" }}
          disabled={canMultiplySelect ? false : selected.length > 5}
          onChange={this.handleChange}
          onClick={this.handleInputClick}
          className={`${classes.marginNormal} ${classes.root} `}
          InputLabelProps={{
            shrink: true,
            classes: { root: classes.cssLabel, focused: classes.cssFocused }
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline
            }
          }}
        />
        <div
          style={{
            position: "relative",
            backgroundColor: "white",
            boxShadow: "grey 0px 5px 30px",
            zIndex: 400
          }}
        >
          {suggestions && suggestions.length > 0
            ? suggestions
                .filter(
                  item => !selected.map(_s => _s.item.id).includes(item.id)
                )
                .map((item, key) => {
                  let label = item.render.label;
                  if (key < 5) {
                    return (
                      <DropItem
                        key={key}
                        onClick={() => this.handleSelect(item)}
                        label={label}
                      />
                    );
                  }
                })
            : null}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BestSearchInput);
