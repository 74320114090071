import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import {
  InlineDateTimePicker as DateTimePickerX,
  InlineDateTimePicker
} from "material-ui-pickers";
import Select from "@material-ui/core/Select/Select";

export let isCashType = ({
  input,
  label,
  value,
  actionKey,
  meta: { touched, error },
  classes,
  children,
  ...custom
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <TextField
        label={name}
        type={"number"}
        placeholder={`Введите сумму`}
        error={touched && error}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: "auto" }}
        InputProps={{
          endAdornment: (
            <InputAdornment label={label} position="end">
              {"рублей"}
            </InputAdornment>
          )
        }}
        {...input}
        {...custom}
      />
    </div>
  );
};
