import React, { Component } from 'react'
import { Typography, Avatar, Button, Input } from '@material-ui/core'
import "./Comment.scss";

class Comment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInputHidden: true,
      value: '',
      attachment: null
    }
  }
  handleCommentClick = () => this.setState(state => ({ isInputHidden: !state.isInputHidden }))
  handleCommentSend = e => {
    if (e.keyCode === 13 && !e.shiftKey && !e.metaKey && !e.ctrlKey) {
      this.props.onSend({
        text: this.state.value,
        parentComment: this.props.comment.self || null,
        attachment: this.state.attachment || null,
        type: this.props.type,
        [this.props.type]: this.props.resourse
      })
    }
  }
  handleInputChange = ({ target: { value } }) => this.setState(() => ({ value }))
  render() {
    const { comment, flatted } = this.props,
          { isInputHidden } = this.state

    return (
      <div className="comment">
        <div className="userAvatar_container">
          <Avatar className="userAvatar" alt="Remy Sharp">
            {/*comment.author.fullName.split(' ').map(word => word[0]).filter((_, index) => index < 2).join('')*/}
          </Avatar>
        </div>
        <div className="textInfo">
          <div className="firstRow">
            <Typography className="userName">{comment.author.fullName}</Typography>
            <Typography className="action">{comment.attachment ? 'загрузил' : 'написал'}</Typography>
          </div>
          {
            comment.attachment
            ? <div className="docLink"><a target='_blank' href={comment.attachment.url}><Typography>Документ</Typography></a></div>
            : <div className='commentBody'><Typography>{comment.text}</Typography></div>
          }
          <div className="lastRow">
            <Typography className="fullDate">{ new Date(comment.postedAt).toLocaleDateString('ru-RU', { hour: 'numeric', minute: 'numeric' }) }</Typography>
            {
              !flatted && comment.level === 0 && (isInputHidden
              ? <Button onClick={this.handleCommentClick} className="button addComment">Комментарий</Button>
              : <Button onClick={this.handleCommentClick} className="button cancel">Отмена</Button>)
            }
          </div>
          { comment.level === 0 && !isInputHidden &&
            <div className='enterComment'>
              <Input
                id={comment.self}
                autoFocus={true}
                value={this.state.value}
                onChange={this.handleInputChange}
                onKeyDown={this.handleCommentSend}
                />
              <div className='icon attach-file'></div>
              <div className='icon send'></div>
            </div>
          }
        </div>
        {
          !flatted && comment.subcomments
          && (
            <div className="sub">
              {
                comment.subcomments.map((comment, index) => <Comment resourse={this.props.resourse} onSend={this.props.onSend} type={this.props.type} comment={comment} key={index}/>)
              }
            </div>
          )
        }
      </div>
    )
  }
}

export default Comment
