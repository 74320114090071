import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { drugsActions, metaActions } from "../actions";
import { Drugs } from "../pages";
import { changeStatusProject } from "../actions/project/changeStatusProject";

class DrugsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([{ title: "Лекарства", to: "/drugs" }]);
    this.props.drugsActions.getDrugs()
  };

  render() {
    console.log(this.props);
    
    return <Drugs {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    drugs: state.drugs
  }
}
const mapDispatchToProps = dispatch => ({
  drugsActions: bindActionCreators(drugsActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrugsContainer);
