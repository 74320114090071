import { profileTypes } from '../types'
import { get, post, patch, remove, currentApiUrl } from '../api'
import { history } from '../'

export const createDocument = (doc, user) => dispatch => {
	dispatch((() => ({ type: profileTypes.CREATE_DOCUMENT__REQUEST }))())
	post(`${currentApiUrl}/v1/external-documents`, {
		...doc,
		type: 'user',
		user
	}).then(_doc => {
		history.push('/profile/documents')
		dispatch((() => ({ type: profileTypes.CREATE_DOCUMENT__SUCCESS }))())
	})
}

export const updateDocument = (data, doc) => dispatch => {
	dispatch((() => ({ type: profileTypes.UPDATE_DOCUMENT__REQUEST }))())
	patch(`${currentApiUrl}${doc}`, {
		description: data.description
	}).then(_doc => {
		dispatch((() => ({ type: profileTypes.UPDATE_DOCUMENT__SUCCESS }))())
	})
}

export const getDocument = doc => dispatch => {
	dispatch((() => ({ type: profileTypes.GET_DOCUMENT__REQUEST }))())
	get(`${currentApiUrl}/v1/external-documents/${doc}`).then(_doc => {
		dispatch((() => ({ 
			type: profileTypes.GET_DOCUMENT__SUCCESS,
			payload: { document: _doc }
		}))())
	})
}

export const deleteDocument = doc => (dispatch, getState) => {
	dispatch((() => ({ 
		type: profileTypes.DELETE_DOCUMENT__REQUEST,
		payload: { document: doc }
	}))())
	remove(`${currentApiUrl}${doc}`).then(() => {
		const _documents = getState().profile.item.externalDocuments.items.filter(_doc => _doc.self !== doc)
		dispatch((() => ({ 
			type: profileTypes.DELETE_DOCUMENT__SUCCESS,
			payload: { documents: _documents }
		}))())
	})
}
