import moment from "moment";

export const formatContract = ({
  contractNumber,
  document,
  expirationDate,
  signDate,
  internalCompany,
  manager,
  manufacturer,
  summaryPayment,
  title
}) => ({
  contractNumber,
  document: `/v1/static-files/${document}`,
  signDate: moment(new Date(signDate)).format("YYYY-MM-DDT00:00:00+00:00"),
  expirationDate: moment(new Date(expirationDate)).format(
    "YYYY-MM-DDT00:00:00+00:00"
  ),
  internalCompany: internalCompany[0].item.self,
  manufacturer: manufacturer[0].item.self,
  manager: manager[0].item.self,
  summaryPayment: {
    amount: summaryPayment == undefined ? 10000 : summaryPayment,
    currency: "rub"
  },
  title,
  type: "with_manufacturer"
});

export const formatTask = id => task => {
  console.log(task);
  return {
    preview: task.preview ? `/v1/static-files/${task.preview}` : null,
    medicalInstitution: task.medicalInstitution ? task.medicalInstitution[0].item.self : null,
    coordinates:
    {
      coordinates: task.geolocation
      ? {
        lat: task.geolocation.geo.coords[0],
        lon: task.geolocation.geo.coords[1]
      }
      : {
        lat: task.medicalInstitution[0].item.coordinates.lat,
        lon: task.medicalInstitution[0].item.coordinates.lon,
      },
      title: task.geolocation ? task.geolocation.title : task.medicalInstitution[0].item.address
    } || null,
    assignedTo: task.assignedTo[0].item.self,
    description: task.description,
    title: task.title,
    project: `/v1/projects/${id}`
  }
}

export const formatSubtask = (type, data, newTaskID) => {
  switch (type) {
    case "message":
      return { task: `/v1/project-tasks/${newTaskID}`, type };
    case "photos":
      return {
        type: type,
        minimumAmount: data,
        task: `/v1/project-tasks/${newTaskID}`
      };
    case "drugSupply":
      return {
        expectedAmount: data.countValue,
        drug: data.selected[0].item.self,
        type: "drugs_supply",
        task: `/v1/project-tasks/${newTaskID}`
      };
    case "poll":
      return {
        type,
        answers: data.pollAnswer,
        questionText: data.question,
        task: `/v1/project-tasks/${newTaskID}`
      };
    default:
      return null;
  }
};

export const formatProject = id => ({
  manager,
  signDate,
  expirationDate,
  status,
  title
}) => ({
  availability: {
    from: moment(new Date(signDate)).format("YYYY-MM-DDT00:00:00+00:00"),
    till: moment(new Date(expirationDate)).format("YYYY-MM-DDT00:00:00+00:00")
  },
  contract: `/v1/contracts/${id}`,
  manager: manager[0].item.self,
  status,
  title
});

export const formatComments = comments =>
  comments
  .filter(comment => comment.level === 0)
  .map(comment => ({
    ...comment,
    subcomments: comments.filter(_comment => _comment.parentComment === comment.self)
  }))

export const formatPharmacy = pharmacy => {
  return {
    address: pharmacy.address || null,
    category: pharmacy.category || null,
    company: pharmacy.company ? `/v1/companies/${pharmacy.company}` : null,
    emails: pharmacy.emails ? pharmacy.emails.split(",") : null,
    isRelatedToVesna: pharmacy.isRelatedToVesna,
    coordinates: {
      lat: pharmacy.lat,
      lon: pharmacy.lon
    },
    legacyAddressCode: pharmacy.legacyAddressCode || null,
    legacyBuildingCode: pharmacy.legacyBuildingCode || null,
    legacyCompanyCode: pharmacy.legacyCompanyCode || null,
    loyalty: pharmacy.loyalty || null,
    phones: pharmacy.phones 
      ? pharmacy.phones.split(",").map(_phone => {
        const _modyfied = _phone.replace(' ', '')
        return _modyfied[0] === '+' ? _modyfied : ('+' + _modyfied.toString()) 
      }) 
      : null,
    status: pharmacy.status || null,
    title: pharmacy.title || null,
    type: 'pharmacy',
    website: pharmacy.website || null,
  }
}
