import React, { Component, Fragment } from "react";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { TextFieldFF } from "../component/inputs/input.finalForm";
import BestSearchInput from "../component/inputs/bestSearchInput";
import { Autocomplete } from '../component'
import GroupIcon from "@material-ui/icons/Group";
import LocalOffer from "@material-ui/icons/LocalOfferOutlined";
import DocumentTextIcon from "@material-ui/icons/Description";
import EventIcon from "@material-ui/icons/Event";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import BusinessIcon from "@material-ui/icons/BusinessCenter";
import PersonIcon from "@material-ui/icons/Person";
import { required } from "../utils/validation";
import { isDropListType } from "../component/inputs/dropList.finalForm";
import { isFileType } from "../component/inputs/fileInput.finalForm";
import { DateTimePicker } from "../component/inputs/datePiker.finalForm";
import { MuiHoc } from "../component/hoc/mui.hoc";
import { isCashType } from "../component/inputs/inputCash.finalForm";
import { maxLength } from "../utils/validation";
import ApiService from "../api/apiService";
import {USERS, INTERNAL_COMPANIES, MANUFACTURERS} from "./entities";

let checkValid = args => args[args.length - 1];
let combineValidate = (...args) => value =>
  args.map(arg => arg(value)).filter(values => values !== undefined)
  |> checkValid;

const modalContract = [
  {
    id: "18dbf235-38c0-4304-b5ff-e5f02606bcdc",
    withIcon: true,
    // icon: <AssessmentIcon className="icon" />,
    style: { gridColumn: "1 /-1" },
    label: "Добавить название",
    row: "name",
    isRequired: true,
    inputs: [
      {
        typeInput: TextFieldFF,
        label: "Название",
        name: "title",
        placeholder: "Добавить название",
        isRequired: true,
        validation: combineValidate(required),
        fullWidth: true
      }
    ]
  },
  {
    id: "a87abe99-6ca5-483a-be71-628302e45242",
    withIcon: true,
    icon: <GroupIcon className="icon" />,
    label: "Добавить стороны контракта",
    row: "group",
    isRequired: true,
    inputs: [
      {
        typeInput: BestSearchInput,
        label: "Доч. компания DPG",
        name: "internalCompany",
        placeholder: "Добавить дочернюю компанию",
        isRequired: true,
        validation: required,
        api: new ApiService(INTERNAL_COMPANIES, "query", true).search,
        select: false
      },
      {
        typeInput: BestSearchInput,
        label: "Производитель",
        name: "manufacturer",
        placeholder: "Добавить вторую сторону",
        isRequired: true,
        validation: required,
        api: new ApiService(MANUFACTURERS, "query", true).search,
        select: false
      }
    ]
  },

  // {
  //   id: 3,
  //   text: "Добавить статус и напоминание",
  //   type: "tag",
  //   input: [
  //     // {
  //     //   type: "dropList",
  //     //   nameInput: "Статус контракта",
  //     //   actionKey: "inputCreateStatusContract",
  //     //   text: "Добавить статус контракта"
  //     // },
  //     {
  //       type: "date",
  //       nameInput: "Напоминание о смене статуса",
  //       actionKey: "inputCreateReminderChangeStatus",
  //       text: "Добавить напоминание о смете",
  //       checkbox: true
  //     }
  //   ]
  // },
  {
    id: "5eed8d39-6e2c-439a-9e99-355899a7f690",
    withIcon: true,
    icon: <DocumentTextIcon className="icon" />,
    label: "Добавить номер и файл договора",
    row: "document",
    isRequired: true,
    inputs: [
      {
        typeInput: TextFieldFF,
        label: "Номер договора",
        name: "contractNumber",
        placeholder: "Добавить номер договора",
        isRequired: true,
        validation: required
      },
      {
        typeInput: isFileType,
        label: "Файл договора",
        name: "document",
        placeholder: "",
        isRequired: true
      }
    ]
  },
  {
    id: "7acac82c-0b23-41fb-8681-33a2bd871cc6",
    withIcon: true,
    icon: <EventIcon className="icon" />,
    label: "Добавить начало и окончание действия",
    row: "date",
    isRequired: false,
    inputs: [
      {
        typeInput: MuiHoc(DateTimePicker),
        label: "Начало действия",
        name: "signDate",
        placeholder: "Добавить начало действия",
        validation: required,
        isRequired: true
      },
      {
        typeInput: MuiHoc(DateTimePicker),
        label: "Окончание действия",
        name: "expirationDate",
        placeholder: "Добавить окончание действия",
        validation: required,
        isRequired: true
      }
    ]
  },
  {
    id: "6a67cdbd-fae8-44c3-9cec-12930e891882",
    withIcon: true,
    icon: <LocalAtmIcon className="icon" />,
    label: "Добавить сумму контракта и условия оплаты",
    row: "cash",
    isRequired: false,
    inputs: [
      {
        typeInput: isCashType,
        label: "Сумма контракта",
        name: "summaryPayment",
        placeholder: "Добавить сумму контракта",
        validation: required,
        isRequired: true
      }
      // {
      //   type: "paymentCondition",
      //   nameInput: "Условия оплаты",
      //   actionKey: "inputCreateTermsOfPayment",
      //   text: "Добавить условия оплаты"
      // },
      // {
      //   type: "deferment",
      //   nameInput: "Отсрочка, дней",
      //   actionKey: "inputCreateDelayOfPayment",
      //   text: "Добавить отсрочку"
      // }
    ]
  },
  // {
  //   id: 7,
  //   text: "Добавить дату следующего контакта",
  //   type: "nextContract",
  //   input: [
  //     {
  //       type: "date",
  //       nameInput: "Дата следующего контакта",
  //       actionKey: "inputCreateDateOfNextContract",
  //       text: "Добавить дату следующего контакта",
  //       checkbox: true
  //     }
  //   ]
  // },
  {
    id: "f28bd5fc-de4f-49ea-9ca7-1e94068f0fba",
    withIcon: true,
    icon: <PersonIcon className="icon" />,
    label: "Добавить ответственное лицо",
    row: "person",
    isRequired: false,
    inputs: [
      {
        typeInput: BestSearchInput,
        name: "manager",
        label: "Ответственное лицо",
        placeholder: "Добавить ответственное лицо",
        isRequired: true,
        validation: required,
        api: new ApiService(USERS, "query").search,
        select: false
      }
    ]
  }
];

let projectForm = [
  {
    id: "963efcf2-e93c-4042-9780-9e5159ca7873",
    withIcon: true,
    // icon: <AssessmentIcon className="icon" />,
    style: { gridColumn: "1 /-1" },
    label: "Добавить название",
    row: "name",
    isRequired: true,
    inputs: [
      {
        typeInput: TextFieldFF,
        label: "Название",
        name: "title",
        placeholder: "Добавить название",
        isRequired: true,
        validation: required
        // validation:[required,maxLength150]
      }
    ]
  },
  {
    id: "7acac82c-0b23-41fb-8681-33a2bd871cc6",
    withIcon: true,
    icon: <EventIcon className="icon" />,
    label: "Добавить начало и окончание действия",
    row: "date",
    isRequired: false,
    inputs: [
      {
        typeInput: MuiHoc(DateTimePicker),
        label: "Начало действия",
        name: "signDate",
        placeholder: "Добавить начало действия",
        validation: required,
        isRequired: true
      },
      {
        typeInput: MuiHoc(DateTimePicker),
        label: "Окончание действия",
        name: "expirationDate",
        placeholder: "Добавить окончание действия",
        validation: required,
        isRequired: true
      }
    ]
  },
  {
    id: "f28bd5fc-de4f-49ea-9ca7-1e97568f0fba",
    withIcon: true,
    icon: <BusinessIcon className="icon" />,
    label: "Добавить статус проекта",
    row: "person",
    isRequired: false,
    inputs: [
      {
        typeInput: isDropListType,
        label: "Статус проекта",
        name: "status",
        placeholder: "Добавить статус",
        isRequired: true,
        validation: required,
        dropList: [
          { value: "started", title: "Запущен" },
          { value: "created", title: "Создан" },
          // { value: "canceled", title: "Отменен" },
          // { value: "ended", title: "Закончен" },
        ]
      }
    ]
  },
  {
    id: "f28bd5fc-de4f-49ea-9ca7-1e94068f0fba",
    withIcon: true,
    icon: <PersonIcon className="icon" />,
    label: "Добавить руководителя проекта",
    row: "person",
    isRequired: false,
    inputs: [
      {
        typeInput: BestSearchInput,
        name: "manager",
        label: "Руководителя проекта",
        placeholder: "Добавить руководителя проекта",
        isRequired: true,
        validation: required,
        api: new ApiService(USERS, "query").search,
        select: false
      }
    ]
  }
];

export { modalContract, projectForm };
