import { projectTypes, commentsTypes } from "../types";

const initialState = {
  item: null,
  isRequesting: true,
  isCreateTaskModalOpen: false,
  isError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case commentsTypes.SEND_COMMENT__REQUEST:
    case projectTypes.GET_PROJECT__REQUEST:
      return {
        ...state,
        isError: false,
        isRequesting: true
      };
    case projectTypes.GET_PROJECT__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        item: {
          ...state.item,
          ...action.payload
        }
      };
    case 'OPEN_TASK_POPUP': return { ...state, isCreateTaskModalOpen: true }
    case 'CLOSE_TASK_POPUP': return { ...state, isCreateTaskModalOpen: false }
    // case contractTypes.GET_PROJECT__FAILURE: return {
    //   ...state,
    //   isRequesting: false,
    //   isError: true
    // }
    default:
      return state;
  }
};
