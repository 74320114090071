import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import { ReactComponent as Logo } from "../../icons/svg/pharmPlaceLogo.svg";
import Avatar from "@material-ui/core/Avatar/Avatar";
import {Typography} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import './Header.scss';

class Header extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, toggleSideBar, user, sidebarOpened } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem className='userMenu' onClick={this.handleProfileMenuOpen}>
          <div className={'name'} >{user.name}</div>
          <div className={'email'} >{user.email}</div>
          <Button className='button' onClick={() => {( location.pathname = '/profile' )}}>Профиль</Button>
          <Button className='button' onClick={this.props.onSignout}>Выйти</Button>
        </MenuItem>
      </Menu>
    );

    return (
      <div className='header'>
        <AppBar position="static" classes={{colorPrimary:"headerColor"}}>
          <Toolbar style={{paddingLeft: '5px'}}>
            <IconButton
              className='menuButton'
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleSideBar}
            >
              {
                sidebarOpened
                ? <div className='iconContainer'><div className='backArrow'></div></div>
                : <MenuIcon />
              }
            </IconButton>
            <Logo className={'logo'} />
            {
              this.props.children || null
            }
            <Avatar
              className={'orangeAvatar'}
              src={this.props.user.avatar ? this.props.user.avatar.url : null}
              >
              {this.props.user.name[0]}
              </Avatar>
              <Typography className='headerRewardPoint'><b>{this.props.user.rewardPoints}</b><br/>баллов</Typography>
            <div className={'sectionDesktop'}>
              <IconButton color="inherit">
                <HelpIcon />
              </IconButton>
              <div className={'userBlock'}>
                <Avatar className={'orangeAvatar'}>U</Avatar>
                <div className={'menuTextBox'}>
                </div>
              </div>
            </div>
            <div className={'sectionMobile'}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

export default Header
