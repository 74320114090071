import React, { Component } from "react";
import { Paper, Typography, Button, TextField, InputAdornment } from "@material-ui/core";
import { withRouter } from "react-router";
import { Loader } from '../component'
import { getShortName } from '../utils/nameTransform'
import "./Files.scss";

@withRouter
class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      description: '',
      title: '',
      userQuery: '',
      selectedUsers: []
    };
  }
  handleAddFileClick = () => this.props.history.push('/files/upload')
  handleUploadClick = () => {
    this.props.filesActions.uploadFile({
      file: this.state.file[0],
      title: this.state.title,
      description: this.state.description,
      users: this.state.selectedUsers
    })
  }
  handleChange = ({ target: { value, name } }) => this.setState({ [name]: value })
  handleFileChange = ({ target: { files } }) => this.setState({ file: files, title: files[0].name || 'title' })
  handleSelectUser = link => {
    this.setState(state => ({
      userQuery: '',
      selectedUsers: state.selectedUsers.indexOf(link) > -1
        ? state.selectedUsers.filter(item => item !== link)
        : [...state.selectedUsers, link]
    }))
  }
  render() {
    const { users: { items, isRequesting } } = this.props,
          { userQuery, selectedUsers } = this.state

    return isRequesting ? (
      <Loader />
    ) : (
      <Paper className="files">
        <Typography>Поддерживаются акции любого формата не более 256mb</Typography>
        <input type='file' id='sharedFileInput' onChange={this.handleFileChange}/>
        <label className='fileInputLabel' htmlFor='sharedFileInput'>
          <Typography>Загрузить файл</Typography>
          {
            this.state.file && <Typography className='name'>{this.state.file[0].name}</Typography>
          }
        </label>
        <TextField
          label="Описание"
          className='description'
          name='description'
          value={this.state.description}
          onChange={this.handleChange}
          margin="normal"
          variant="outlined"
        />
      <div className='userFind'>
        <TextField
          label="Кому доступен"
          placeholder='Введите имя'
          className='description'
          name='userQuery'
          value={this.state.userQuery}
          onChange={this.handleChange}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: selectedUsers.map((link, index) =>
              <div className='shared' key={index}>
                <div className='avatar'></div>
                <Typography onClick={() => this.handleSelectUser(link)} className='name'>{getShortName(items.filter(user => user.self === link)[0].fullName)}</Typography>
              </div>
          )
          }}
          >
            {
              selectedUsers.map((item, index) => <option key={index}>{item}</option>)
            }
          </TextField>
          {
            userQuery !== '' &&
            <div className='filtered'>
              {
                items.filter(item => item.fullName.toLowerCase().indexOf(userQuery.toLowerCase()) > -1).map((item, index) =>
                  <Typography onClick={() => this.handleSelectUser(item.self)} key={index}>{item.fullName}</Typography>)
              }
            </div>
          }
        </div>
        <Button onClick={this.handleUploadClick}>Загрузить</Button>
      </Paper>
    );
  }
}

export default FileUpload;
