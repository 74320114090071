import React from "react";
import TableHead from "@material-ui/core/TableHead/TableHead";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import Typography from "@material-ui/core/Typography/Typography";
import {
  CONTRACT_NUMBER,
  EXPIRATION_DATE,
  INTERNAL_COMPANY,
  SIGN_DATE,
  TITLE,
  SUBSIDIARY
} from "../../../constants/string";

//styles
import "../table/Table.scss"

const rows = [
  CONTRACT_NUMBER,
  TITLE,
  SIGN_DATE,
  EXPIRATION_DATE,
  INTERNAL_COMPANY
  // SUBSIDIARY,
];

const styles = theme => ({
  tableHeadRow: {
    ...theme.typography.subtitle2
  }
});

/**
 *
 * @type {{
 * ignDateFormat: string,
 * name: string,
 * contractNumber: string,
 * id: string,
 * title: string,
 * expirationDateFormat: string
 * }}
 */

class EnhancedTableHead extends React.Component {
  selectRowName = RowKey => {
    switch (RowKey) {
      case "contractNumber":
        return "Номер контракта";
      case "expirationDateFormat":
        return "Дата окончания";
      case "signDateFormat":
        return "Дата начала";
      case "title":
        return "Название контракта ";
      case "name":
        return "Имя";
      default:
        return null;
    }
  };
  render() {
    const { contractsData } = this.props;
    return (
      <TableHead>
        <TableRow>
          {contractsData.length !== 0
            ? Object.keys(contractsData[0])
                .filter(key => key !== "id")
                .sort((a, b) => a - b)
                .map((row, index) => {
                  return (
                    <TableCell key={index}>
                      <Typography
                        variant="subtitle2"
                        className={'tableHeadRow'}
                      >
                        {this.selectRowName(row)}
                      </Typography>
                    </TableCell>
                  );
                })
            : null}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
