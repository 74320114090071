import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Redirect } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "material-ui-pickers/utils/moment-utils";


import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer.js";
import { RootContainer, AuthContainer } from "./containers";
import thunk from "redux-thunk";
import createBrowserHistory from 'history/createBrowserHistory';
import { checkAuth } from './utils'


// 'ERROR''API''INTERNAL' 'API'
const palette = {
  primary: {
    light: "#00796B",
    main: "#FFFFFF",
    dark: "#00796B",
    contrastText: "#263238"
  },
  secondary: {
    light: "#339388",
    main: "#00796B",
    dark: "#00544a",
    contrastText: "#fff"
  },
  accent: {
    light: "#3492ca",
    main: "#0277BD",
    dark: "#015384",
    contrastText: "#fff"
  },
  secondaryAccent: {
    light: "#950000",
    main: "#D50000",
    dark: "#dd3333",
    contrastText: "#fff"
  },
  controlAction: {
    active: "#00796B",
    disable: "#979797",
    activeBG: "#E1EFEE"
  }
};
const typography = {
  useNextVariants: true,
  fontFamily: '"Roboto" ,  sans-serif',
  fontSize: 18,
  h1: {
    fontFamily: '"Roboto", sans-serif ',
    fontStyle: "Light",
    fontSize: "9.6em",
    color: palette.primary.contrastText
  },
  h2: {
    fontFamily: '"Raleway", sans-serif',
    fontStyle: "SemiBold",
    fontWeight: "500",
    fontSize: "1.5rem",
    color: palette.primary.contrastText
  },
  h3: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "Regular",
    fontWeight: "500",
    fontSize: "3rem",
    color: palette.primary.contrastText
  },
  h4: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "Regular",
    fontSize: "2.125rem",
    color: palette.primary.contrastText
  },
  h5: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "SemiBold",
    fontSize: "1.5rem",
    color: palette.primary.contrastText
  },
  h6: {
    fontFamily: '"Raleway", sans-serif',
    fontStyle: "Light",
    fontSize: "1.25rem",
    fontWeight: 500,
    color: palette.primary.contrastText
  },
  body1: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "Regular",
    fontSize: "16px",
    color: palette.primary.contrastText
  },
  body2: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "Medium",
    fontSize: "14px",
    color: "#2632389c",
    opacity: "54"
  },
  subtitle1: {
    fontFamily: '"Roboto", sans-serif',
    fontStyle: "Regular",
    fontSize: "10px",
    color: palette.primary.contrastText
  },
  subtitle2: {
    fontFamily: '"Roboto", sans-serif ',
    fontStyle: "normal ",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.1px",
    fontSize: "0.875rem !important",
    color: "#263238"
  },
  button: {
    fontFamily: '"Raleway", sans-serif',
    fontStyle: "SemiBold",
    fontSize: "0.875rem",
    letterSpacing: "0.75px",
    color: palette.primary.contrastText
  },
  button_outline: {
    fontFamily: '"Raleway", sans-serif',
    fontStyle: "semiBold",
    fontWeight: 600,
    fontSize: "0.875rem",
    letterSpacing: "0.75px",
    color: palette.secondary.main
  },
  caption: {
    fontFamily: '"Raleway", sans-serif',
    fontStyle: "SemiBold",
    fontSize: "0.75rem"
  },
  overline: {
    fontFamily: '"Roboto". sans-serif',
    fontStyle: "Medium",
    fontSize: "0.625"
  }
};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const history = createBrowserHistory();

const session = checkAuth();

const theme = createMuiTheme({
  palette: palette,
  typography: typography
});

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <MuiThemeProvider theme={theme}>
        <Router history={history} forceRefresh={true}>
          {session ? (
            <Route path="/" component={RootContainer} />
          ) : (
            <div>
              <Route exact path="/auth" component={AuthContainer} /> 
              <Redirect to="/auth" /> 
            </div>
          )}
        </Router>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>,

  document.getElementById("root")
);
