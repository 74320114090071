import ButtonGoogleMapPopper from "../../component/popper/mapPopper";
import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core/es";
import DoneIcon from "@material-ui/icons/Done";
import StoreIcon from "@material-ui/icons/Store";
export class SubtaskGeolocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.input.value.title || "",
      geo: this.props.input.value.geo || {}
    };

    this.onChange = _.debounce(this.onChange, 150, { maxWait: 300 });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    return prevState !== this.state ? this.onChange(this.state) : null;
  }

  onChange = state => {
    let {
      input: { onChange }
    } = this.props;
    onChange(this.state);
  };

  handleChangeGeoInfo = geo => {
    let {
      input: { name, onChange, value, ...restInput }
    } = this.props;
    this.setState(state => ({ geo }));
  };

  render() {
    let {
      input: { name, onChange, value, ...restInput },
      meta,
      label,
      classes,
      placeholder,
      ...rest
    } = this.props;
    let { title, geo } = this.state;
    const showError =
      ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
      meta.touched;
    return (
      <div className="geo">
        <div className="col">
          <div className="task-label">
            <div className="icon">
              {!geo.title && (
                <StoreIcon className={`icon ${showError && "error"}`} />
              )}
              {geo.title && <DoneIcon className="icon done" />}
            </div>
            <div className="label">
              <TextField
                label={showError && "добавьте геолокацию"}
                error={showError}
                value={geo.title}
                multiline
                rows="1"
                variant={"outlined"}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="inner">
            <ButtonGoogleMapPopper
              label="Добавить геолокацию"
              onChange={this.handleChangeGeoInfo}
            />
          </div>
        </div>

        {/*<div className="col reverse">*/}
        {/*{showError && (*/}
        {/*<div style={{ color: "red" }}>добавьте геолокацию </div>*/}
        {/*)}*/}
        {/*/!*<div className="geo_title">{geo.title}</div>*!/*/}
        {/*/!*<Button className="remove">*!/*/}
        {/*/!*<DeleteIcon /> Удалить*!/*/}
        {/*/!*</Button>*!/*/}
        {/*</div>*/}
      </div>
    );
  }
}
