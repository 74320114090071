import {
  get,
  currentApiUrl,
  post,
  postFile,
  postWithoutResponse
} from "../api";
import { modalTypes, profileTypes } from "../types";
import { internalCompaniesActions, workPlacesActions } from "./index";

export const getManufacturers = () => async dispatch => {
    // await dispatch({ type: profileTypes.GET_MANUFACTURERS__REQUEST});
    const manufacturers = await get(`${currentApiUrl}/v1/manufacturers?manager=/v1/users/${localStorage.getItem("user_id")}`)
    dispatch({ type: profileTypes.GET_MANUFACTURERS,
                payload: {
                    manufacturers: {
                        ...manufacturers
                    }
                }
    });
}

export const getCurrentManufacturer = (id) => async dispatch => {
    await dispatch((() => ({ type: profileTypes.GET_CURRENT_MANUFACTURER__REQUEST }))());
    const currentManufacturer = await get(`${currentApiUrl}/v1/manufacturers/${id}?manager=/v1/users/${localStorage.getItem("user_id")}`)
    const manufacturerCourses = await get(`${currentApiUrl}${currentManufacturer.courses}`)
    const manufacturerContracts = await get(`${currentApiUrl}${currentManufacturer.contracts}`)
    dispatch((() => ({
        type: profileTypes.GET_CURRENT_MANUFACTURER__SUCCESS,
        payload: {
            currentManufacturer: {
                ...currentManufacturer,
                manufacturerCourses: manufacturerCourses,
                manufacturerContracts: manufacturerContracts,
            }
        }
    }))());
}

export const getProfile = id => dispatch => {
  dispatch((() => ({ type: profileTypes.GET_PROFILE__REQUEST }))());
  const userId = id || localStorage.getItem("user_id");
  get(`${currentApiUrl}/v1/users/${userId}`).then(_user => {
    get(`${currentApiUrl}${_user.managedMedicalInstitutions}`).then(
      _managedMedicalInstitutions => {
        if (_user.internalCompanyEmployment) {
          get(`${currentApiUrl}${_user.internalCompanyEmployment}`).then(
            _internalCompanyEmployment => {
              get(
                `${currentApiUrl}${_internalCompanyEmployment.internalCompany}`
              ).then(internalCompany =>
                get(`${currentApiUrl}${_user.employment}`).then(
                  _employments => {
                    dispatch(
                      (() => ({
                        type: profileTypes.GET_PROFILE__SUCCESS,
                        payload: {
                          user: {
                            ..._user,
                            managedMedicalInstitutions: _managedMedicalInstitutions,
                            employment: _employments,
                            internalCompanyEmployment: {
                              ...internalCompany,
                              ..._internalCompanyEmployment
                            }
                          }
                        }
                      }))()
                    );
                  }
                )
              );
            }
          );
        } else {
          get(`${currentApiUrl}${_user.employment}`).then(_employments => {
            dispatch(
              (() => ({
                type: profileTypes.GET_PROFILE__SUCCESS,
                payload: {
                  user: {
                    ..._user,
                    managedMedicalInstitutions: _managedMedicalInstitutions,
                    employment: _employments
                  }
                }
              }))()
            );
          });
        }
      }
    );
  });
};

export const editPersonalInfo = (data, id) => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.POST_PROFILE_PERSONAL_INFO }))());
  post(`${currentApiUrl}/v1/edit-personal-data-requests`, data)
    .then(res => {
      dispatch(
        (() => ({
          type: profileTypes.POST_PROFILE_PERSONAL_INFO_SUCCESS
        }))()
      );
      dispatch(getProfile(id));
    })
    .catch(error =>
      dispatch(
        (() => ({ type: profileTypes.POST_PROFILE_PERSONAL_INFO_FAILURE }))()
      )
    );
};

export const editRole = (data, id) => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.POST_PROFILE_ROLE }))());
  post(`${currentApiUrl}/v1/change-user-roles-requests`, data)
    .then(data => {
      dispatch(
        (() => ({
          type: profileTypes.POST_PROFILE_ROLE_SUCCESS
        }))()
      );
      dispatch(getProfile(id));
    })
    .catch(error =>
      dispatch((() => ({ type: profileTypes.POST_PROFILE_ROLE_FAILURE }))())
    );
};

export const uploadAvatarFile = data => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.UPLOAD_AVATAR_FILE }))());
  postFile(`${currentApiUrl}/v1/static-files`, {
    file: data,
    type: "profile_avatar"
  })
    .then(avatar => {
      dispatch(
        (() => ({
          type: profileTypes.UPLOAD_AVATAR_FILE_SUCCESS,
          payload: {
            data: avatar
          }
        }))()
      );
    })
    .catch(error =>
      dispatch((() => ({ type: profileTypes.UPLOAD_AVATAR_FILE_FAILURE }))())
    );
};

export const requestSmsCode = data => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.REQUEST_SMS_CODE }))());
  postWithoutResponse(
    `${currentApiUrl}/v1/request-sms/profile-phone-change`,
    data
  )
    .then(res => {
      dispatch(
        (() => ({
          type: profileTypes.REQUEST_SMS_CODE_SUCCESS
        }))()
      );
    })
    .catch(error => {
      dispatch((() => ({ type: profileTypes.REQUEST_SMS_CODE_FAILURE }))());
    });
};

export const confirmSmsCode = data => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.CONFIRM_SMS_CODE }))());
  postWithoutResponse(`${currentApiUrl}/v1/change-phone-requests`, data)
    .then(res => {
      dispatch(
        (() => ({
          type: modalTypes.CLOSE_MODAL
        }))()
      );
      dispatch(
        (() => ({
          type: profileTypes.CONFIRM_SMS_CODE_SUCCESS,
          payload: {
            data: data.phone
          }
        }))()
      );
    })
    .catch(error =>
      dispatch((() => ({ type: profileTypes.CONFIRM_SMS_CODE_FAILURE }))())
    );
};

export const createInternalCompanyEmployment = (
  user,
  internalCompany,
  position
) => (dispatch, getState) => {
  dispatch(
    internalCompaniesActions.createEmployee({
      internalCompany: internalCompany.self,
      user: user.self,
      position: position
    })
  )
    .then(res => {
      dispatch(
        (() => ({
          type: profileTypes.CHANGE_PROFILE_INTERNAL_COMPANY,
          payload: { ...internalCompany, ...res }
        }))()
      );
    })
    .catch(error => {
      alert("Ошибка создания места работы (в компании)");
    });
};

export const deleteInternalCompanyEmployment = employee => (
  dispatch,
  getState
) => {
  dispatch(internalCompaniesActions.deleteEmployee(employee))
    .then(res => {
      dispatch(
        (() => ({
          type: profileTypes.CHANGE_PROFILE_INTERNAL_COMPANY,
          payload: null
        }))()
      );
    })
    .catch(error => {
      alert("Ошибка удаления места работы (в компании)");
    });
};

export const createEmployee = (user, medicalInstitution, position) => (
  dispatch,
  getState
) => {
  dispatch((() => ({ type: profileTypes.CREATE_PROFILE_EMPLOYEE }))());
  dispatch(
    workPlacesActions.createEmployee({
      medicalInstitution: medicalInstitution.self,
      position: position.self,
      user: user.self
    })
  )
    .then(res => {
      get(`${currentApiUrl}${res.workPlace}`).then(data => {
        dispatch(
          (() => ({
            type: profileTypes.CREATE_PROFILE_EMPLOYEE_SUCCESS,
            payload: data
          }))()
        );
      });
    })
    .catch(error => {
      console.log(error);
      dispatch((() => ({ type: profileTypes.CREATE_PROFILE_EMPLOYEE_FAIL }))());
      alert("Ошибка создания места работы");
    });
};

export const deleteEmployee = id => dispatch => {
  dispatch((() => ({ type: profileTypes.DELETE_PROFILE_EMPLOYEE }))());
  dispatch(workPlacesActions.deleteEmployee(id))
    .then(res => {
      dispatch(
        (() => ({
          type: profileTypes.DELETE_PROFILE_EMPLOYEE_SUCCESS,
          payload: id
        }))()
      );
    })
    .catch(error => {
      console.log(error);
      dispatch((() => ({ type: profileTypes.DELETE_PROFILE_EMPLOYEE_FAIL }))());
      alert("Ошибка удаления места работы");
    });
};

export const backToChangePhone = () => (dispatch, getState) => {
  dispatch((() => ({ type: profileTypes.BACK_CHANGE_PHONE }))());
};
