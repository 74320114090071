import React, { Component, Fragment } from 'react'
import SideBar from "../component/sidebar/navigate/SideBar";
import { Header } from "../component/header";
import { Navi } from '../component'
import CustomizedSnackbars from "../component/snackbars/snackbars";
import ComponentRouter from "../routes";
import { Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import { InfoContainer } from '../containers'

@withRouter
class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpened: false
    }
  }
  componentDidMount = () => {
    const { authActions: { getCurrentUser } } = this.props;
    (this.props.location.pathname === '/' || this.props.location.pathname === '/auth') && this.props.history.push('/contracts')
    getCurrentUser()
  }
  handleMenuClick = () => {
    this.setState({ sidebarOpened: !this.state.sidebarOpened })
  }
  handleSignoutClick = () => {
    const { authActions: { signout } } = this.props
    signout()
  }
  render() {
    const { meta: { navi } } = this.props
    return(
      <Fragment>
        <CustomizedSnackbars />
        <Header
          toggleSideBar={this.handleMenuClick}
          sidebarOpened={this.state.sidebarOpened}
          user={this.props.user}
          onSignout={this.handleSignoutClick}
          />
        <SideBar
          toggleSideBar={this.handleMenuClick}
          sidebarOpened={this.state.sidebarOpened}
          opened={this.state.sidebarOpened}
          roles={this.props.user.roles}
        />
        <div className={`root__wrapper + ${this.state.sidebarOpened ? 'blurred' : ''}`}
             style={{
               height: 'fit-content',
               paddingLeft: '30px',
               background: '#FAFAFA',
             }}
             onClick={this.state.sidebarOpened ? this.handleMenuClick : ''}
        >
          <Navi items={navi} />
          <Switch>
            <ComponentRouter />
          </Switch>

        </div>
      </Fragment>
    )
  }
}

export default Root
