import TextField from "@material-ui/core/TextField/TextField";
import React, { Fragment } from "react";
import { Field } from "react-final-form";

export let TextFieldFF = ({
  input: { name, onChange, value, ...restInput },
  meta,
  label,
  classes,
  placeholder,
  className,
  style,
  ...rest
}) => {
  let errorLabel =
    meta.error && meta.touched ? `⚠️- Проверьте, пожалуйста, поле ` : label;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <TextField
      {...rest}
      className={className}
      name={name}
      placeholder={placeholder}
      label={errorLabel}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      style={{flexGrow:"1" , width:"100%"}}
    />
  );
};
