import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { contractActions, metaActions } from '../actions'
import { Contracts } from '../pages'
import {toggleModalCreateContract} from "../actions/modal/toggleModal";

class AdminContractsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdmin: true,
    }
  }

  componentDidMount = () => {
    const { contractActions: { getContracts }, metaActions: { updateNavi } } = this.props
    updateNavi([{ title: 'Контракты', to: '/contracts' }])
    getContracts()
  }

  render() {
    return <Contracts {...this.props} isAdmin={this.state.isAdmin} />
  }
}

const mapStateToProps = state => ({
  contracts: state.contracts
})
const mapDispatchToProps = dispatch => ({
  contractActions: bindActionCreators(contractActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  toggleModalCreateContract: bindActionCreators(toggleModalCreateContract, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContractsContainer)
