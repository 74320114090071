import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pharmaciesActions, metaActions, courseActions } from '../actions'
import { Drugstore } from '../pages'
import { withRouter } from 'react-router'
import { Loader } from '../component'

@withRouter
class DrugstoreContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { pharmaciesActions: { getPharm }, courseActions: { getCourse }, match: { path, params: { pharmId, courseId } } } = this.props
    if (path === '/drugstore/:pharmId') {
      getPharm(pharmId)
    }
    if (path === '/drugstore/:pharmId/courses/:courseId') {
      getPharm(pharmId)
      getCourse(courseId)
    }
  }

  render() {
    return <Drugstore {...this.props} />
  }
}

const mapStateToProps = state => ({
  pharmacy: state.pharmacy,
  meta: state.meta,
  course: state.course,
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  courseActions: bindActionCreators(courseActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrugstoreContainer)
