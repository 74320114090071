import { authTypes } from '../types'
import * as authSteps from '../constants/authSteps'

const initialState = {
  step: authSteps.SEND_PHONE,
  user: {
    email: '',
    avatar: null,
    name: '',
    self: '',
    roles: [],
    pharms: []
  },
  isRequesting: false,
  isError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case authTypes.SEND_NUMBER__REQUEST: return {
      ...state,
      isError: false,
      isRequesting: true
    }
    case authTypes.SEND_NUMBER__SUCCESS: return {
      ...state,
      step: authSteps.SEND_CODE,
      isError: false,
      isRequesting: false
    }
    case authTypes.SEND_NUMBER__FAILURE: return {
      ...state,
      isError: true,
      isRequesting: false
    }
    case authTypes.SEND_CODE__REQUEST: return {
      ...state,
      isError: false,
      isRequesting: true
    }
    case authTypes.SEND_CODE__SUCCESS: return {
      ...state,
      step: authSteps.SEND_CODE,
      isError: false,
      isRequesting: false
    }
    case authTypes.SEND_CODE__FAILURE: return {
      ...state,
      isError: true,
      isRequesting: false
    }
    case authTypes.GET_USER__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case authTypes.GET_USER__SUCCESS: return {
      ...state,
      isRequesting: false,
      user: {
        ...state.user,
        avatar: action.payload.user.avatar,
        email: action.payload.user.email,
        name: action.payload.user.fullName,
        roles: action.payload.user.roles,
        self: action.payload.user.self,
        rewardPoints: action.payload.user.rewardPoints,
        pharms: action.payload.user.managedMedicalInstitutions
      }
    }
    default: return state
  }
}
