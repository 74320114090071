import * as queryString from 'query-string'
import xlsx from 'xlsx'

Array.prototype.shuffle = function () {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [this[i], this[j]] = [this[j], this[i]];
  }
  return this
}

String.prototype.cut = function (max) {
  return this.split('').filter((_, index) => index < max).join('') + '...'
}

export const roundToPrecision = (value, precision) => 
  Number(Math.round(value + 'e' + precision) + 'e-' + precision)


export const getFileType = name => {
  const ext = name.split('.').pop()
  if (/jpe?g|png|gif|bmp/.test(ext))  return 'image'
  if (/docx?/.test(ext))              return 'doc'
  if (/xls/.test(ext))                return 'xls'
  if (/avi|mp4|mp3/.test(ext))        return 'media'
  return 'default'
}

export const filter2url = (url, filter) =>
  `${url}?`
  + Object.keys(filter)
          .map((key, index) =>
            `${key}=${filter[key]}`)
          .join('&')

export const checkAuth = () => Boolean(localStorage.getItem('bearer_token'))

export const checkInvite = location => location.href.indexOf('invites') !== -1

export const formatQuestions = questions =>
  questions.map(question => {
    return ({
      ...question,
      type: !question.answers.items[0] ? 'text' : question.answers.items[0].image ? 'image' : 'text',
      mode: 'write',
      answers: question.answers
    })
  })

export const formatTime = (seconds, { withSeconds }) =>
  `${
    Math.floor(seconds / 3600) > 0 ? `${Math.floor(seconds / 3600)} часов ` : ''
  }${
    Math.floor((seconds - (seconds * Math.floor(seconds / 3600))) / 60)
  } минут ${withSeconds ? `${
    Math.floor(seconds - Math.floor((seconds - (seconds * Math.floor(seconds / 3600))) / 60) * 60)
  } секунд` : ''}`

export const formatInviteStatus = status => {
  switch (status) {
    case 'without_access':  return 'Не приступал';
    case 'process':         return 'В процессе';
    case 'failed':          return 'Не пройден';
    case 'complete':        return 'Пройден';
    default:                return null
  }
}
export const getInviteStatus = invite => {
  if (invite.courseAccess === null) {
    return 'without_access'
  } else {
    if (invite.courseAccess.courseResults) {
      if (invite.courseAccess.courseResults.type === 'successful') {
        console.log(123);
        return 'complete'
      } else if (invite.courseAccess.courseResults.type === 'unsuccessful') {
        return 'failed'
      }
    } else {
      return 'process'
    }
  }
}
export const copyTextToClipboard = text => {
  const textArea = document.createElement("textarea");

  textArea.style.position = 'absolute';
  // textArea.style.top = -1000;
  textArea.style.top = 0;
  textArea.style.background = 'transparent';
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}

export const beautifySec = sec => {
  if (sec === 0) return '0'
  if (sec < 0.5) return '20'
  if (sec > 0.5) return '40'
  return '0'
}

export function setQueryString(filter) {
    if (filter)
        return `?${queryString.stringify(filter, {arrayFormat: 'bracket'})}`;
    return '';
}

export const calcPercentage = (length, current) => Math.ceil(current * 100 / length)

export const excel2json = file => new Promise((resolve, reject) => {
  let reader = new FileReader();
  reader.onload = function(e) {
    const _arr = []
    const data = e.target.result
    const workbook = xlsx.read(data, { type: 'binary' })
    workbook.SheetNames.map(sheetName => {
      _arr.push(xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]))
    })
    resolve(_arr)
  }
  reader.readAsBinaryString(file)
})
