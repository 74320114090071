import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { Field } from "react-final-form";
import { InlineDateTimePicker as DateTimePickerX } from "material-ui-pickers";

export const isDropListType = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  formControlProps,
  variant,
  className,
  dropList,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl
      {...formControlProps}
      variant={"outlined"}
      className={className}
      InputLabelProps={{ shrink: true }}
      error={showError}
    >
      <InputLabel style={{ backgroundColor: "white" }} shrink={true}>
        {label}
      </InputLabel>
      <Select
        {...rest}
        name={name}
        onChange={onChange}
        inputProps={restInput}
        value={value}
        input={
          <OutlinedInput
            name={name}
            labelWidth={0}
            InputLabelProps={{ shrink: true }}
            // InputLabelProps={{ shrink: true }}
          />
        }
      >
        {dropList.map(item => (
          <MenuItem value={item.value}>{item.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
