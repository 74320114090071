import React, { Component } from 'react'
import { TextField } from '@material-ui/core';

const demaskFromPhone = masked => masked.split('').filter(char => /[0-9]/.test(char)).join('')
const maskToPhone = value => value.length > 0 ? value.split('').reduce((total, char, index) => {
  const maskedChar = index === 1
    ? `(${char}`
    : index === 4
      ? `)${char}`
      : index === 7
        ? `-${char}`
        : char

  return String(total) + String(maskedChar)
}, '+') : ''
const validatePhone = phone => phone.length === 11 && /[0-9]/.test(phone)

class MaskedInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      maskedValue: '+7'
    }
  }
  handleChange = ({ target: { value } }) => {
    const { maskedValue } = this.state
    this.setState({
      maskedValue: value.length < 16 ? maskToPhone(demaskFromPhone(value)) : maskedValue
    }, () => {
      this.props.onChange({ target: {
        name: this.props.name,
        value: this.props.validate
        ? validatePhone(demaskFromPhone(this.state.maskedValue))
          ? demaskFromPhone(this.state.maskedValue)
          : false
        : demaskFromPhone(this.state.maskedValue)
      }})
    })
  }
  render() {
    return (
      <TextField
        id={this.props.id}
        label={this.props.label}
        className={this.props.className}
        value={this.state.maskedValue}
        name={this.props.name}
        fullWidth={this.props.fullWidth}
        onChange={this.handleChange}
        margin="normal"
        variant="outlined"
        type="tel"
        disabled={this.props.disabled}
        error={this.props.error}
        inputProps={this.props.inputProps}
        />
    )
  }
}

export default MaskedInput
