import { pharmaciesTypes } from '../types'

const initialState = {
  items: [],
  filter: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case pharmaciesTypes.GET_PHARMACIES__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case pharmaciesTypes.GET_PHARMACIES__SUCCESS: return {
      ...state,
      items: action.payload.items,
      isRequesting: false
    }
    // case pharmaciesTypes.FILTER: return {
    //   ...state,
    //   filter: action.payload.filter
    // }
    default: return state
  }
}
