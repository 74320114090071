import React, { Component } from "react";
import Table from "../component/table/table/TablePaperWrapper";
import { Paper, Typography, Button, Card, CardContent, CardActions, Input } from "@material-ui/core";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";
import moment from "moment";
import TablePagination from "../component/table/";
import { withRouter } from "react-router";

import "./Dashboard.scss";

const Course = ({ ...props }) => (
  <div className={`course${props.withAccess ? " accessed" : ""}`}>
    <img /*src={props.picture || props._embedded.cover.url}*/ />
    <div className='textBlock'>
      <Typography className="rewardPoints"><div className="symbol">☆</div>{props.rewardPoints} баллов</Typography>
      <Typography className="title">{/*props.title*/}</Typography>
      <Typography className="desc">{/*props.description*/}</Typography>
      <Typography variant='body2'>Тематика:</Typography>
      <Typography variant='body1'>{/*theme2name(props.theme)*/}</Typography>
      <Typography variant='body2'>Сложность:</Typography>
      <Typography variant='body1'>{/*diff2name(props.difficulty)*/}</Typography>
      <div className='textRow'>
        <Typography>Слайдов:  </Typography>
        <Typography variant='body1'>{/*props.count*/}</Typography>
      </div>
    </div>
    <div className="buttonRow">
    {props.withAccess && (
      <Button variant='button' onClick={props.withAccess ? props.onClick : null}>Начать</Button>
    )}
    {!props.onEditClick && !props.withAccess && (
      <Button variant='button' disabled='true'>Недоступен</Button>
    )}
    </div>
  </div>
);

@withRouter
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      isNameEditing: false,
      fullName: 'Саблин Семен Евгеньевич'
    };
  }

  handleFileChange = ({ target: { files } }) => this.setState({ avatar: files[0] })
  loadPreview = () => {
    return (this.state.avatar !== '' && this.state.avatar != undefined) ? URL.createObjectURL(this.state.avatar) : false
  }
  changeFieldType = () => {
    this.setState({ isNameEditing: !this.state.isNameEditing })
  }
  onChange = e => {
    console.log(e);
    this.setState({fullName: e.target.value})
  }
  render() {

    return  (
      <Paper style={{width: '100%'}}>
        <div className='personInfo'>
          <div className='avatar'>
            {
              this.state.avatar === false
              ? <div>
                      <input
                        className="photoInput"
                        type="file"
                        name="avatar"
                        onChange={this.handleFileChange}
                        accept="image/*"
                        id='file'
                      />
                      <label className='emptyField' htmlFor="file">
                      </label>
                </div>
              : <div>
                      <input
                        className="photoInput"
                        type="file"
                        name="avatar"
                        onChange={this.handleFileChange}
                        accept="image/*"
                        id='file'
                        multiple={true}
                      />
                      {
                        <label  htmlFor="file">
                          <img htmlFor="file" src={this.loadPreview()} />
                        </label>
                      }
                    </div>
            }
          </div>
          <div className='information'>
            {
              this.state.isNameEditing === false
              ? <Typography variant='h6' className='fullName'>{this.state.fullName}
                  <div onClick={this.changeFieldType} className='icon create'></div>
                </Typography>
              : <div className='fullNameInput__row'>
                  <Input onChange={this.onChange} value={this.state.fullName} variant='h6' className='fullName' />
                  <div onClick={this.changeFieldType} className='icon create'></div>
                </div>
            }
            <Typography className='sex'><div className='icon person'></div>Мужской</Typography>
            <Typography className='profession'><div className='icon tie'></div>Фармацевт, провизор</Typography>
          </div>
          <div className='contact'>
            <Typography className='address'><div className='icon map-marker'></div>Кострома, Костромская обл., Россия</Typography>
            <Typography color='secondary' className='email'><div className='icon emailIcon'></div>Sergeev@ES.es</Typography>
            <Typography className='phone'><div className='icon call'></div>+7(999)0050301</Typography>
          </div>
        </div>
        <div className='table__wrapper'>
          <Typography variant='h6' style={{margin: '24px'}}>Места работы</Typography>
          <div className='horizontalCards__wrapper'>
          {
            [1,2,3,4].map((index) =>
            <Card className='horizontalCard'>
              <CardContent style={{display: 'flex'}}>
                <div className='avatar'></div>
                <div className='textBlock'>
                  <Typography variant='h6' color='secondary' className='firm'>Denis Pharm Group</Typography>
                  <Typography variant='body1' className='position'>Фармацевт-провизор</Typography>
                  <Typography className='extra'>Главное место работы</Typography>
                  <Typography className='startedSince'>с ноября 2018</Typography>
                </div>
              </CardContent>
            </Card>)
          }
          </div>
          <Button
            color='secondary'
            style={{width: '100%', maxWidth: '100%', marginBottom: '24px',
                    textTransform: 'none',
                    background: 'linear-gradient(0deg, rgba(0, 121, 107, 0.03), rgba(0, 121, 107, 0.03)), #FFFFFF'}}>
            Больше мест работы
          </Button>
        </div>
        <div className='table__wrapper'>
          <Typography variant='h6' style={{margin: '24px'}}>Новости по подписке</Typography>
          <div className='verticalCards__wrapper'>
          {
            [1,2,3,4].map((index) =>
            <Card className='verticalCard'>
              <CardContent style={{padding: '0'}}>
                <div className='previewImage'></div>
                <div className='textBlock'>
                  <Typography className="theme">Медицина</Typography>
                  <Typography variant='h5' color='secondary' className='title'>Алгоритм IBM Watson определяет глаукому с точностью 95%</Typography>
                  <div className='createData__row'>
                    <Typography variant='body1' className='createdAt'>4 дня назад</Typography>
                    <div className='icon person'></div>
                    <Typography variant='body1' className='author'>Фадеева А. А.</Typography>
                  </div>
                  <Typography variant='body1' className='annonce'>Программы готовы заменить радиологов и самостоятельно анализировать снимки и результаты обследования.</Typography>
                </div>
                <div className='statistic__row'>
                  <div className='statistic__element'>
                    <div className='icon like'></div>
                    <Typography>13</Typography>
                  </div>
                  <div className='statistic__element'>
                    <div className='icon comment'></div>
                    <Typography>4</Typography>
                  </div>
                  <div className='statistic__element'>
                    <div className='icon visibility'></div>
                    <Typography>1500</Typography>
                  </div>
                </div>
              </CardContent>
            </Card>)
          }
          </div>
        </div>
        <div className='table__wrapper' style={{border: 'none'}}>
          <Typography variant='h6' style={{margin: '24px'}}>Текущие курсы обучения</Typography>
          <div className='verticalCards__wrapper'>
          <Course
                  /*
                  key={index}
                  withAccess={Boolean(item.accessLink)}
                  title={item.title}
                  theme={item.theme}
                  difficulty={item.difficulty}
                  status={item.status}
                  rewardPoints={item.rewardPoints}
                  maximumNumberOfAttempts={item.maximumNumberOfAttempts}
                  picture={item._embedded.cover.url}
                  description={item.description}
                  count={item._embedded.slides.length}
                  onClick={() => this.handleCourseClick(item.id)}
                  startButton={true}
                  */
                  />
          {/*
            [1,2,3,4].map((index) =>
            <Card className='verticalCard'>
              <CardContent style={{padding: '0'}}>
                <div className='previewImage'></div>
                <div className='textBlock'>
                  <Typography variant='h6' color='secondary' className='firm'>Denis Pharm Group</Typography>
                  <Typography variant='body1' className='position'>Фармацевт-провизор</Typography>
                  <Typography className='extra'>Главное место работы</Typography>
                  <Typography className='startedSince'>с ноября 2018</Typography>
                </div>
                <Button сolor='secondary'>Продолжить</Button>
              </CardContent>
            </Card>)
          */}
          </div>
        </div>
      </Paper>
    );
  }
}

export default Dashboard;
