import React, { Component } from 'react'
import { Typography, TextField } from '@material-ui/core'
import Comment from './Comment.jsx'
import './Comment.scss'

class Comments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      attachment: null
    }
  }
  handleCommentChange = ({ target: { value } }) => this.setState({
    value
  })
  handleCommentSend = e => {
    if (e.keyCode === 13 && !e.shiftKey && !e.metaKey && !e.ctrlKey) {
      this.props.onSend({
        text: this.state.value,
        parentComment: null,
        attachment: this.state.attachment || null,
        type: this.props.type,
        [this.props.type]: this.props.resourse
      })
    }
  }
  render() {
    const { comments } = this.props

    return (
      <div className='comments'>
        { comments.items.map((comment, index) => <Comment flatted={this.props.flatted} key={index} comment={comment} {...this.props}/>) }
        <div className='enterComment'>
          <TextField
            classes={{InputProps: 'inputStyle'}}
            style={{width: '75%', marginLeft: '35px'}}
            placeholder='Оставить комментарий'
            variant="outlined"
            value={this.state.value}
            onChange={this.handleCommentChange}
            onKeyDown={this.handleCommentSend}
            autoFocus={true}
            />
          <div className='icon attach-file'></div>
          <div className='icon send'></div>
        </div>
      </div>
    )
  }
}

export default Comments
