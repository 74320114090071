import {
  formatContract,
  formatProject,
  formatSubtask,
  formatTask
} from "./formating";
import ApiService from "../api/apiService";
import {
  ROJECT_TASKS,
  PROJECTS,
  SUBTASKS,
  CONTRACTS
} from "../constants/entities";
import { compact } from "lodash";

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const submitContract = contract => contract |> formatContract |> new ApiService(CONTRACTS).post;

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const submitProject = (id , project) =>
  project |> formatProject(id) |> new ApiService(PROJECTS).post;

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const submitTasks = (id, tasks) =>
  tasks |> (tasks => tasks.map(task => task |> submitTask(id)));

const log = value => {
  console.log(value);
  return value;
};

export const submitTask = id => task =>
  task
  |> formatTask(id)
  |> new ApiService(ROJECT_TASKS).post
  |> submitSubTasks(task);

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export let submitSubTasks = task => async resData => {
  let data = await resData;
  Object.keys(task)
    |> (_ => _.map(type => formatSubtask(type, task[type], data.id)))
    |> compact
    |> (_ => _.map(item => new ApiService(SUBTASKS).post(item)));
};
