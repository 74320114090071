import React, { Component, Fragment } from "react";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/es/Button/Button";
import Card from "@material-ui/core/Card/Card";
import { TextFieldFF } from "../../component/inputs/input.finalForm";
import { Field } from "react-final-form";

import { SubtaskPoll } from "../../component/card/SubtaskPoll";
import { DrugsSupply } from "../../component/card/SubtaskDrugsSupply";
import { SubtaskPhotos } from "../../component/card/SubtaskPhotos";
import { SubtaskMessage } from "../../component/card/SubtaskMessage";
import { SubtaskGeolocation } from "../../component/card/SubtaskGeolocation";

import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CopyIcon from "@material-ui/icons/FileCopy";
import "./card.scss";
// import UserService from "../state/api/User/UserService";
import ListItem from "@material-ui/core/ListItem";
import BestSearchInput from "../../component/inputs/bestSearchInput";
import DropBox from "../../component/card/dropBox";
import { required } from "../../utils/validation";
import ApiService from "../../api/apiService"; // todo: move it to actions
import { USERS } from "../../constants/entities";
import { MEDICAL_INSTITUTIONS } from "../../constants/entities";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
let dropDownList = [
  // {
  //   id: "56219157-8b52-44b9-8ecb-c9fce9691920",
  //   type: "GEOLOCATION",
  //   placeholder: "Добавить геолокацию"
  // },
  {
    id: "56219157-8b52-44b9-8ecb-c943e9691920",
    type: "MESSAGE",
    placeholder: "Добавить репорт"
  },
  {
    id: "56219157-8b52-44b9-8ecb-c9fce9691430",
    type: "PHOTOS",
    placeholder: "Добавить фото"
  },
  {
    id: "56219157-8322-44b9-8ecb-c9fce9691920",
    type: "DRUGS_SUPPLY",
    placeholder: "Добавить лекарства"
  },
  {
    id: "56219157-8b52-44b9-8ecb-c9fce9665920",
    type: "POLL",
    placeholder: "Добавть вопрос"
  }
];

const taskTypes = {
  // GEOLOCATION: "subtaskGeolocationArray",
  MESSAGE: "subtaskMessageArray",
  PHOTOS: "subtaskPhotosArray",
  DRUGS_SUPPLY: "subtaskDrugsSupplyArray",
  POLL: "subtaskPollArray"
};

const selectSubtask = (item, name) => {
  switch (item.type) {
    // case "GEOLOCATION":
    //   return null;
    // {/*<Field*/}
    //   {/*name={`${name}.geolocation`}*/}
    //   {/*component={SubtaskGeolocation}*/}
    //   {/*defaultValue={true}*/}
    // {/*/>*/}
    // );
    case "MESSAGE":
      return (
        <Field
          name={`${name}.message`}
          component={SubtaskMessage}
          defaultValue={true}
        />
      );
    case "PHOTOS":
      return <Field name={`${name}.photos`} component={SubtaskPhotos} />;
    case "POLL":
      return <Field name={`${name}.poll`} component={SubtaskPoll} />;
    case "DRUGS_SUPPLY":
      return <Field name={`${name}.drugSupply`} component={DrugsSupply} />;
    default:
      return " ";
  }
};

class Epic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subtaskArray: [],
      anchorEl: null,
      assignedTo: 0,
      listIsOpen: true,
      isGeo: "med"
    };
  }
  componentDidMount() {
    // this.props.input.onChange({ test: "test" });
    // const { fields, idEpic } = this.props;
    // const subtasks = Object.keys(fields.value[idEpic])
    //   .filter(key => key.includes("subtask"))
    //   .map(key => fields.value[idEpic][key]);
    // this.setState({ subtaskArray: subtasks });
  }
  // handleCloneTaskClick = id => this.props.onClone(id);

  handleCreateSubTask = (id, type) => {
    const subtaskArray = this.state.subtaskArray;
    this.setState(() => ({ listIsOpen: true }));
    Array.isArray(subtaskArray)
      ? this.setState({
          subtaskArray: [...subtaskArray, { id, type }]
        })
      : null;
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = (id, type) => {
    this.setState({ anchorEl: null });
    id !== null ? this.handleCreateSubTask(id, type) : null;
  };

  handleOpenList = () => {
    this.setState(state => ({ listIsOpen: !state.listIsOpen }));
  };
  handleCopyTask = () => {
    let { fields, idEpic } = this.props;
    let handleCreateTask = (push, values) => {
      push(values);
    };
    handleCreateTask(fields.push, fields.value[idEpic]);
  };
  handleChange = event => {
    this.setState({ isGeo: event.target.value });
  };

  render() {
    let { name, idEpic, fields } = this.props;
    let { subtaskArray, anchorEl, assignedTo } = this.state;

    const filtered = dropDownList.filter(
      item => !subtaskArray.map(item => item.id).includes(item.id)
    );

    return (
      <Card className="epic">
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <div className="main-data">
            <div className="col field-text">
              <Field
                component={TextFieldFF}
                name={`${name}.title`}
                className="field"
                label={"Имя задачи"}
                validate={required}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />

              <Field
                component={TextFieldFF}
                name={`${name}.description`}
                className="field"
                label={"Описание задачи"}
                validate={required}
                multiline
                rows="2"
                rowsMax="2"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div className="col field-dropbox">
              <Field
                component={DropBox}
                name={`${name}.preview`}
                className="field"
              />
            </div>
          </div>

          <Field
            component={BestSearchInput}
            name={`${name}.assignedTo`}
            className="field"
            label={"Добавить мед. представителя"}
            api={new ApiService(USERS, "query").search}
            InputLabelProps={{ shrink: true }}
            suggestionChipName={"name"}
            variant="outlined"
          />

          <RadioGroup
            aria-label="Gender"
            name="gender1"
            className="select"
            value={this.state.isGeo}
            onChange={this.handleChange}
          >
            <FormControlLabel
              value="med"
              control={<Radio />}
              label="Выбрать аптеку"
            />
            <FormControlLabel
              value="geo"
              control={<Radio />}
              label="Выбрать геолокацию"
            />
          </RadioGroup>

          {this.state.isGeo === "med" ? (
            <Field
              component={BestSearchInput}
              name={`${name}.medicalInstitution`}
              className="field"
              label={"Добавить мед. учреждения"}
              api={new ApiService(MEDICAL_INSTITUTIONS, "title").search}
              InputLabelProps={{ shrink: true }}
              validate={required}
              suggestionChipName={"name"}
              variant="outlined"
            />
          ) : (
            <Field
              name={`${name}.geolocation`}
              validate={required}
              component={SubtaskGeolocation}
              defaultValue={true}
            />
          )}

          <Card className="subtask-list">
            <List
              component="nav"
              subheader={
                <div className="control">
                  <ListSubheader component="div">
                    Список типов отчетов
                  </ListSubheader>
                  <IconButton>
                    {this.state.listIsOpen ? (
                      <ExpandLess onClick={this.handleOpenList} />
                    ) : (
                      <ExpandMore onClick={this.handleOpenList} />
                    )}
                  </IconButton>
                </div>
              }
            >
              {subtaskArray.map((item, index) => {
                return (
                  <Collapse
                    in={this.state.listIsOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <ListItem className="list">
                      {selectSubtask(item, name)}
                    </ListItem>
                  </Collapse>
                );
              })}
            </List>
          </Card>
        </CardContent>
        <CardActions className="epicControl">
          {filtered.length === 0 ? null : (
            <Button
              className="epicButton"
              aria-owns={anchorEl ? "simple-menu" : undefined}
              color="secondary"
              onClick={this.handleClick}
            >
              <AddIcon /> Добавить подзадачу
            </Button>
          )}
          {/*<Button*/}
          {/*className="epicButton"*/}
          {/*aria-owns={anchorEl ? "simple-menu" : undefined}*/}
          {/*color="secondary"*/}
          {/*onClick={this.handleClick}*/}
          {/*>*/}
          {/*<AddIcon /> Назначить медпредставителей*/}
          {/*</Button>*/}
          <Button
            color="secondary"
            className="epicButton"
            onClick={() => this.handleCopyTask()}
          >
            <CopyIcon /> Скопировать задачу
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => this.handleClose(null)}
          >
            {filtered.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => this.handleClose(item.id, item.type)}
                >
                  {item.placeholder}
                </MenuItem>
              );
            })}
          </Menu>
        </CardActions>
      </Card>
    );
  }
}

export default Epic;
