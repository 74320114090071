import React, { Component } from "react";
import "./Document.scss";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import UploadIcon from "@material-ui/icons/CloudUpload";
import RefrashIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

export class ComponentDocumentLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReplay: false,
      replyInput: ""
    };
  }

  render() {
    let comment = {
      manager: {
        fullname: "Мальцев А. Д."
      },
      message: " I HATE YOU"
    };
    return (
      <div className="document_log">
        <div className="userAvatar_container">
          <Avatar className="userAvatar" alt="Remy Sharp">
            A
          </Avatar>
        </div>
        <div className="textInfo">
          <div className="firstRow">
            <span className="userName">Name</span>
            <span className="date">Date</span>
            <span className="type">type</span>
          </div>
          <div className="description">description</div>
          <Button className="file_state">Загрузить сейчас</Button>
        </div>
        <div className="type">type</div>
        <div className="actions">
          {/*<UploadIcon />*/}
          <IconButton>
            <RefrashIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}
