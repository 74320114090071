import { currentManufacturerTypes } from '../types'

const initialState = {
  item: [],
  isRequesting: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case currentManufacturerTypes.GET_CURRENT_MANUFACTURER__REQUEST: return {
      ...state,
      ...state.currentManufacturer,
      isRequesting:true
    }
    case currentManufacturerTypes.GET_CURRENT_MANUFACTURER__SUCCESS: return {
      ...state,
      ...payload.currentManufacturer,
      isRequesting: false
    } 
    default:
      return state
  }
}
