import React, { Component } from "react";
import { Loader } from "../../component";
import Info from "./Info";
import { Add } from "@material-ui/icons";
import "./Profile.scss";
import { Route, Link } from "react-router-dom";
import DocumentEditor from "./DocumentEditor.jsx";
import { ExternalDocumentContainer } from "../../containers";
import DocumentList from "./DocumentList.jsx";
import Manufacturers from "./Manufacturers.jsx";


class Profile extends Component {
  render() {
    const { match, profile } = this.props;
    return (
      <div className="profile__wrapper">
          <div className="profileToolbar__menu">
            <div className="tabs">
              <Link className={match.url === '/profile' ? "currentTab" : ""}
                    to="/profile">
                Информация
              </Link>
              <Link className={match.url === '/profile/documents' ? "currentTab" : ""}
                    to="/profile/documents">
                Документы
              </Link>
              <Link className={match.url === '/profile/manufacturers' ? "currentTab" : ""}
                    to="/profile/manufacturers">
                Производители
              </Link>
            </div>
            {match.url === "/profile/documents" && (
                <div className="addButton__wrapper">
                  <Add/>
                  <Link to="/profile/new-document">Добавить документ</Link>
                </div>
            )}
          </div>
        {profile.isRequesting ? (
          <Loader />
        ) : (
          <div>
            <Route
              exact
              path="/profile"
              render={() => <Info employee={false} {...this.props} />}
            />
            <Route
              exact
              path="/profile/documents"
              render={() => <DocumentList {...this.props} />}
            />
            <Route
              path="/profile/new-document"
              render={() => <DocumentEditor isNew {...this.props} />}
            />
            <Route
              path="/profile/documents/:documentId"
              component={ExternalDocumentContainer}
            />
            <Route
              path="/profile/manufacturers"
              render={() => <Manufacturers {...this.props} />}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Profile;
