import React, { Component, Fragment } from "react";
import { Avatar } from "@material-ui/core";
import { Loader } from "../../component";
import { Close, Done } from "@material-ui/icons";
import "./Profile.scss";
import ChangePhoneNumberModal from "../../component/modal/changeNumber/ChangePhoneNumberModal";
import AutocompleteInput from "../../component/autocomplete";
import IconButton from "@material-ui/core/es/IconButton/IconButton";
import {
  ROLE_MEDICAL_REPRESENTATIVE,
  ROLE_TARGET_AUDIENCE
} from "../../constants/userRoles";
import { internalCompaniesActions, workPlacesActions } from "../../actions";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";

const rolesConst = {
  ROLE_DEVELOPER: "Разработчик",
  ROLE_MANAGER: "Менеджер",
  ROLE_MEDICAL_REPRESENTATIVE: "Медицинский представитель",
  ROLE_TARGET_AUDIENCE: "Целевая аудитория",
  ROLE_SUPPORT_AGENT: "Агент поддержки"
};

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.profile.item,
      searchRole: "",
      searchInternalCompany: "",
      selectInternalCompany: null,
      searchPosition: "",
      searchMedicalInstitution: "",
      searchMedicalInstitutionPosition: "",
      selectMedicalInstitution: null,
      selectMedicalInstitutionPosition: null
    };
  }

  handleChangeEvent = ({ target: { name, value } }) =>
    this.handleChange(name, value);

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleFileChange = ({ target: { files } }) => {
    const {
      profileActions: { uploadAvatarFile }
    } = this.props;
    if (files && files[0]) {
      uploadAvatarFile(files[0]);
    }
  };

  handleSelectRole = value => {
    this.setState(({ roles }) => ({
      roles: [
        ...roles,
        Object.entries(rolesConst).find(
          ([keyRole, valueRole]) => valueRole === value.role
        )[0]
      ]
    }));
  };

  handleDeleteRole = delRole => {
    this.setState(({ roles }) => ({
      roles: roles.filter(role => role !== delRole)
    }));
  };

  getSuggestionsRole = () => {
    const { searchRole, roles } = this.state;
    return Object.entries(rolesConst)
      .filter(([keyRole, valueRole]) => {
        return (
          !roles.find(role => role === keyRole) &&
          valueRole.indexOf(searchRole) !== -1
        );
      })
      .map(([keyRole, valueRole]) => ({ role: valueRole }));
  };

  getSuggestionsCompanies = () => {
    const { searchInternalCompany } = this.state;
    const { internalCompanies } = this.props;
    return internalCompanies
      ? internalCompanies.items
          .map(item => ({ ...item.legalEntity, ...item }))
          .filter(
            item =>
              item.name !== null &&
              item.name.indexOf(searchInternalCompany) !== -1
          )
      : [];
  };

  handleSelectInternalCompany = value => {
    this.setState({
      searchInternalCompany: value.name,
      selectInternalCompany: value
    });
  };

  handleAddInternalCompany = () => {
    const {
      profileActions: { createInternalCompanyEmployment },
      profile: { item }
    } = this.props;
    const { selectInternalCompany, searchPosition } = this.state;
    this.setState({
      selectInternalCompany: null
    });
    if (selectInternalCompany && searchPosition)
      createInternalCompanyEmployment(
        item,
        selectInternalCompany,
        searchPosition
      );
  };

  handleDeleteInternalCompanyEmployment = employment => {
    const {
      profileActions: { deleteInternalCompanyEmployment }
    } = this.props;
    deleteInternalCompanyEmployment(employment.id);
  };

  handleSearchMedicalInstitution = value => {
    const {
      pharmaciesActions: { getPharmacies }
    } = this.props;
    this.setState(
      {
        searchMedicalInstitution: value
      },
      () => getPharmacies({ title: value })
    );
  };

  handleSelectMedicalInstitution = value => {
    this.setState({
      searchMedicalInstitution: value.title,
      selectMedicalInstitution: value
    });
  };

  getSuggestionEmployment = () => {
    const {
      pharmacies,
      profile: { item }
    } = this.props;
    return pharmacies.items.filter(
      pharm =>
        !item.employment.items.length ||
        !item.employment.items.find(
          employee => pharm.id === employee.medicalInstitution.id
        )
    );
  };

  handleSearchMedicalInstitutionPosition = value => {
    const {
      positionsActions: { getPositions }
    } = this.props;
    this.setState(
      {
        searchMedicalInstitutionPosition: value
      },
      () => getPositions({ title: value })
    );
  };

  handleSelectMedicalInstitutionPosition = value => {
    this.setState({
      searchMedicalInstitutionPosition: value.title,
      selectMedicalInstitutionPosition: value
    });
  };

  handleAddMedicalInstitution = () => {
    const {
      profileActions: { createEmployee },
      profile: { item }
    } = this.props;
    const {
      selectMedicalInstitution,
      selectMedicalInstitutionPosition
    } = this.state;
    this.setState({
      selectMedicalInstitution: null,
      selectMedicalInstitutionPosition: null
    });
    if (selectMedicalInstitution && selectMedicalInstitutionPosition)
      createEmployee(
        item,
        selectMedicalInstitution,
        selectMedicalInstitutionPosition
      );
  };

  preparePersonalInfo = () => {
    const { about, fullName, gender } = this.state;
    const {
      profile: {
        item: { self, avatar }
      }
    } = this.props;
    return {
      avatar: avatar ? avatar.self : null,
      about,
      fullName,
      gender,
      user: self
    };
  };

  handleConfirmSmsCode = data => {
    const {
      profileActions: { confirmSmsCode },
      profile: {
        item: { self }
      }
    } = this.props;
    confirmSmsCode({ ...data, user: self });
  };

  render() {
    const {
      fullName,
      gender,
      rewardPoints,
      email,
      specializations,
      roles,
      about,
      searchInternalCompany,
      searchPosition,
      searchMedicalInstitution,
      searchMedicalInstitutionPosition,
      selectMedicalInstitution,
      selectMedicalInstitutionPosition,
      selectInternalCompany,
      managedMedicalInstitutions
    } = this.state;
    const {
      profileActions: {
        editPersonalInfo,
        requestSmsCode,
        backToChangePhone,
        deleteEmployee,
        editRole
      },
      pharmacies,
      positions,
      internalCompanies,
      modalActions: { openModal, closeModal },
      isOpenModal,
      profile: {
        item: {
          avatar,
          phone,
          self,
          id,
          employment,
          internalCompanyEmployment
        },
        step,
        isRequestingChangeNumber,
        isRequestingEmployee,
        isRequestingEditInfo,
        isRequestingUpload,
        isErrorConfirmCode,
        isErrorRequestCode
      },
      employee
    } = this.props;
    return (
      <div className="profile__wrapper">
        <div className="row">
          <div className="info__block">
            {isRequestingUpload ? (
              <Loader />
            ) : (
              <Avatar
                src={avatar ? avatar.url : null}
                style={{ width: "60px", height: "60px" }}
              >
                {fullName[0]}
              </Avatar>
            )}
            <div className="avatar_field">
              <p className="secondary_text title">Аватар</p>
              <input
                id="file"
                className="avatar__upload"
                type="file"
                accept="image/*"
                onClick={event => {
                  event.target.value = null;
                }}
                onChange={this.handleFileChange}
              />
              <label for="file" className="main__button">
                Обновить фотографию
              </label>
            </div>
          </div>
          <div className="info__block gender">
            <p className="secondary_text title">Пол</p>
            <div className="radio_group">
              <input
                type="radio"
                id="choiceGender1"
                name="gender"
                value="female"
                checked={gender === "female"}
                onChange={this.handleChangeEvent}
              />
              <label for="choiceGender1">Женский</label>
              <input
                type="radio"
                id="choiceGender2"
                name="gender"
                value="male"
                checked={gender === "male"}
                onChange={this.handleChangeEvent}
              />
              <label for="choiceGender2">Мужской</label>
            </div>
          </div>
          <div className="info__block balance">
            <p className="secondary_text title">
              {employee ? "Баланс баллов" : "Ваш баланс баллов:"}
            </p>
            <p className="secondary_text balls">
              <b style={{ color: "#404040" }}>{rewardPoints}</b>
            </p>
          </div>
        </div>
        <div className="info__block full_width_card contact">
          <p className="secondary_text">{employee ? "Имя" : "Ваше имя"}</p>
          <input
            name="fullName"
            value={fullName}
            onChange={this.handleChangeEvent}
          />
          <div />
          <p className="secondary_text">Мобильный телефон</p>
          <p>{phone}</p>
          <button className="main__button" onClick={openModal}>
            Изменить
          </button>
          <p className="secondary_text">Адрес электронной почты</p>
          <p>{email}</p>
          <button className="main__button" disabled>
            Изменить
          </button>
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">
            {employee ? "Специализации" : "Ваши специализации"}
          </p>
          {specializations.items > 0
            ? specializations.items.map(item => <p>{item.title}</p>)
            : "Не указано"}
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">
            {employee ? "Роль в системе" : "Ваша роль в системе"}
          </p>
          <AutocompleteInput
            onChange={value => this.handleChange("searchRole", value)}
            onSelect={this.handleSelectRole}
            suggestions={this.getSuggestionsRole()}
            suggestionsKey="role"
          />
          <div className="roles-profile__list">
            {roles.map(item => (
              <div key={item} className="roles-profile__item">
                <div>{rolesConst[item]}</div>
                <IconButton onClick={() => this.handleDeleteRole(item)}>
                  <Close fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
          <div className="roles-profile__control">
            <button
              className="main__button"
              onClick={() =>
                editRole(
                  {
                    roles,
                    user: self
                  },
                  id
                )
              }
            >
              Изменить
            </button>
          </div>
        </div>
        <div className="info__block full_width_card">
          <p className="secondary_text title">
            {employee ? "Интересы" : "Ваши интересы"}
          </p>
          <textarea
            placeholder="Укажите информацию о себе, о своих увлечениях..."
            rows="4"
            value={about}
            name="about"
            onChange={this.handleChangeEvent}
          />
        </div>
        {roles.find(role => role === ROLE_TARGET_AUDIENCE) && (
          <div className="info__block full_width_card">
            <p className="secondary_text title">Место работы и дожность</p>
            <div className="position-profile__row">
              <AutocompleteInput
                value={searchMedicalInstitution}
                placeholder="Укажите место работы"
                onChange={this.handleSearchMedicalInstitution}
                onSelect={this.handleSelectMedicalInstitution}
                suggestions={this.getSuggestionEmployment()}
                isRequesting={pharmacies.isRequesting}
                suggestionsKey="title"
              />
              <AutocompleteInput
                value={searchMedicalInstitutionPosition}
                placeholder="Укажите должность"
                suggestions={positions.items}
                onChange={this.handleSearchMedicalInstitutionPosition}
                onSelect={this.handleSelectMedicalInstitutionPosition}
                isRequesting={positions.isRequesting}
                suggestionsKey="title"
              />
              <Tooltip title="Добавить" aria-label="add">
                <IconButton onClick={this.handleAddMedicalInstitution}>
                  <Done fontSize="small" />
                </IconButton>
              </Tooltip>
              {selectMedicalInstitution ? (
                <div className="position-profile__notification">
                  <p>* медицинская организация выбрана</p>
                  <span>({selectMedicalInstitution.title})</span>
                </div>
              ) : (
                <p className=" position-profile__notification position-profile__notification_error">
                  * выберите медицинскую организацию
                </p>
              )}
              {selectMedicalInstitutionPosition ? (
                <div className="position-profile__notification">
                  <p>* должность выбрана</p>
                  <span>({selectMedicalInstitutionPosition.title})</span>
                </div>
              ) : (
                <p className="position-profile__notification position-profile__notification_error">
                  * выберите должность
                </p>
              )}
              <div />
            </div>
            <div className="position-profile__list">
              {isRequestingEmployee ? (
                <Loader small />
              ) : (
                employment.items &&
                employment.items.map(item => (
                  <div key={item.id} className="position-profile__row">
                    <div>{item.medicalInstitution.title}</div>
                    <div>{item.position.title}</div>
                    <Tooltip title="Удалить" aria-label="delete">
                      <IconButton onClick={() => deleteEmployee(item.id)}>
                        <Close fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
        {roles.find(role => role === ROLE_MEDICAL_REPRESENTATIVE) && (
          <div className="info__block full_width_card">
            <p className="secondary_text title">
              Место работы и дожность (в компании)
            </p>
            {internalCompanyEmployment ? (
              <div className="position-profile__list">
                <div className="position-profile__row">
                  <p>{internalCompanyEmployment.legalEntity.name}</p>
                  <p>{internalCompanyEmployment.position}</p>
                  {internalCompanies.isRequestingDeletingEmployee ? (
                    <Loader small />
                  ) : (
                    <Tooltip title="Добавить" aria-label="delete">
                      <IconButton
                        onClick={() =>
                          this.handleDeleteInternalCompanyEmployment(
                            internalCompanyEmployment
                          )
                        }
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            ) : (
              <div className="position-profile__row">
                <AutocompleteInput
                  value={searchInternalCompany}
                  placeholder="Укажите место работы"
                  onChange={value =>
                    this.handleChange("searchInternalCompany", value)
                  }
                  onSelect={this.handleSelectInternalCompany}
                  suggestions={this.getSuggestionsCompanies()}
                  suggestionsKey="name"
                />
                <input
                  value={searchPosition}
                  placeholder="Укажите должность"
                  name="searchPosition"
                  onChange={this.handleChangeEvent}
                />
                {internalCompanies.isRequestingCreatingEmployee ? (
                  <Loader small />
                ) : (
                  <Tooltip title="Добавить" aria-label="add">
                    <IconButton onClick={this.handleAddInternalCompany}>
                      <Done fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                {selectInternalCompany ? (
                  <div className="position-profile__notification">
                    <p>* компания выбрана</p>
                    <span>({selectInternalCompany.name})</span>
                  </div>
                ) : (
                  <p className=" position-profile__notification position-profile__notification_error">
                    * выберите компанию
                  </p>
                )}
              </div>
            )}
          </div>
        )}
        <div className="info__block full_width_card">
          <p className="secondary_text title">
            {employee
              ? "Управление этими медицинскими учреждениями"
              : "Вы управляете этими медицинскими учреждениями"}
          </p>
          <div className="institution">
            {managedMedicalInstitutions.items.length > 0
              ? managedMedicalInstitutions.items.map(item => (
                  <p>{item.title}</p>
                ))
              : "Не указано"}
          </div>
        </div>
        <button
          className="main__button save__button"
          onClick={() => editPersonalInfo(this.preparePersonalInfo(), id)}
        >
          {isRequestingEditInfo ? <Loader small /> : "Сохранить"}
        </button>
        {isOpenModal && (
          <ChangePhoneNumberModal
            closeModal={closeModal}
            phone={phone}
            step={step}
            isRequestingChangeNumber={isRequestingChangeNumber}
            isErrorConfirmCode={isErrorConfirmCode}
            isErrorRequestCode={isErrorRequestCode}
            requestSmsCode={requestSmsCode}
            confirmSmsCode={this.handleConfirmSmsCode}
            backToChangePhone={backToChangePhone}
          />
        )}
      </div>
    );
  }
}

export default Info;
