import React, { Component } from "react";
import Table from "../component/table/table/TablePaperWrapper";
import Paper from "@material-ui/core/Paper";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";
import moment from "moment";
import TablePagination from "../component/table/";
import { withRouter } from "react-router";
import ProjectDashboardToolbar from "../component/toolbar/ProjectDashboardToolbar";
import ProjectCard from "../component/card/ProjectCard";
import { Loader } from "../component";
import * as projectStatuses from "../constants/projectStatuses";

//styles
import "../component/dashboard/Dashboard.scss";
import "./Projects.scss";

@withRouter
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isFilterOpened: false
    };
  }
  componentDidMount = () => {
    const {
      projectActions: { getProjects }
    } = this.props;
    getProjects();
  };
  handleProjectClick = id => {
    this.props.history.push(`/projects/${id}`);
  };
  handleProjectCheck = ({ id, checked }) => {
    const { selected } = this.state;
    if (selected.filter(item => item.id === id).length === 0) {
      this.setState({ selected: [...selected, { id, checked }] });
    } else {
      this.setState({ selected: selected.filter(item => item.id !== id) });
    }
  };
  handleToggleFilter = () =>
    this.setState({ isFilterOpened: !this.state.isFilterOpened });
  render() {
    const {
      projects: { items, isRequesting }
    } = this.props;
    const { selected } = this.state;
    return isRequesting ? (
      <Loader />
    ) : (
      <Paper
        style={{}}
        className={`project_dashboard ${false ? "opened" : null}`}
      >
        <ProjectDashboardToolbar
          numSelected={selected.length}
          withFilter={this.state.isFilterOpened}
          toggleFilter={this.handleToggleFilter}
        />
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div
            className="projectDashboard"
            style={{
              flexGrow: 1,
              display: "grid",
              flexWrap: "wrap",
              justifyContent: "center"
            }}
          >
            { this.props.isAdmin
              ? items.map((project, key) => {
                  return (
                    <ProjectCard
                      key={key}
                      project={project}
                      onClick={this.handleProjectClick}
                      onCheck={this.handleProjectCheck}
                      changeStatus={this.props.changeStatusProject}
                      fromProjects
                    />
                  );
              })
              : this.props.modifiedProps.map((project, key) => {
                  return (
                    <ProjectCard
                      key={key}
                      project={project}
                      onClick={this.handleProjectClick}
                      onCheck={this.handleProjectCheck}
                      changeStatus={this.props.changeStatusProject}
                      fromProjects
                    />
                  );
                })
            }
          </div>
          <FilterSideBar
            items={Array.from(new Set(items.map(item => item.status))).map(
              item => projectStatuses[item]
            )}
            nameKey={false}
            opened={this.state.isFilterOpened}
            onChange={() => null}
            filterName={"Статус"}
          />
        </div>
      </Paper>
    );
  }
}

export default Projects;
