import { contractTypes } from '../types'

const initialState = {
  items: [],
  filter: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case contractTypes.GET_CONTRACTS__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case contractTypes.GET_CONTRACTS__SUCCESS:
      return {
        ...state,
        items: action.payload.items,
        isRequesting: false
      }
    case contractTypes.FILTER: return {
      ...state,
      filter: action.payload.filter
    }
    default: return state
  }
}
