import React, { Component } from "react";
import { Button, Card, Chip, CardContent, FormControlLabel, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Paper, Table, TableHead, TableBody, TextField, Tooltip, Typography, TableRow, TableCell, Radio, RadioGroup } from "@material-ui/core";
import FilterSideBar from "../component/sidebar/filter/FilterSideBar";
import moment from "moment";
import TablePagination from "../component/table/";
import { withRouter } from "react-router";
import ProjectDashboardToolbar from "../component/toolbar/ProjectDashboardToolbar";
import ProjectCard from "../component/card/ProjectCard";
import { Loader, List } from '../component'
import * as projectStatuses from "../constants/projectStatuses";
import ModalCreateContract from "../component/modal/ModalCreateContract";
import MaterialsDashboardToolbar from "../component/toolbar/MaterialsDashboardToolbar";
import MaterialsEditor from "../component/editor/MaterialsEditor";
import ImageExplorer from "../component/imageExplorer/imageExplorer";
import Swipeable from 'react-swipeable-views'
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import "react-mde/lib/styles/css/react-mde-all.css";
import ModalSourceEditor from "../component/modal/ModalSourceEditor";


//styles
import "../component/dashboard/Dashboard.scss";
import "./Materials.scss";
import "../component/imageExplorer/imageExplorer.scss";
// import "./Projects.scss";

@withRouter
class Material extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 0,
      tab: 0,
      newCategory: '',
      type: 'blog',
      isModalOpened: false,
      isFilterOpened: false,
      isAutocompleteOpened: false
    };
    this.converter = new Showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
  }

  componentDidMount = () => {
    const { materialsActions: { getMaterial, getCategories, clearMaterial }, match: { params: { id } }, metaActions: { updateNavi }, materials: { material: { title } } } = this.props
    if (id === 'new') {
      clearMaterial()
      getCategories()
      updateNavi([
        { title: "Материалы", to: "/materials" },
        { title: 'Создание нового материала', to: `/materials/new` }
      ]);
    } else {
      getMaterial(id)
      getCategories()
      updateNavi([
        { title: "Материалы", to: "/materials" },
        { title: '', to: `/materials/${id}` }
      ]);
    }
  }
  componentDidUpdate = nextProps => {
    const { metaActions: { updateNavi }, materials: { material: { title } }, match: { params: { id } } } = this.props

    if (nextProps.materials.material.title !== title) {
      updateNavi([
        { title: "Материалы", to: "/materials" },
        { title: title, to: `/materials/${id}` }
      ]);
    }
  }

  loadPreview = () => {
    const { materials: { material: { preview } } } = this.props
    return preview.url ? preview.url : (preview !== '' && preview != undefined && preview.size) ? URL.createObjectURL(preview) : null
  }

  handleTypeChange = ({ target: { value } }) => this.setState({ type: value })
  handleSaveNewClick = () => this.props.materialsActions.createMaterial(this.props.materials.material, this.state.type)
  handleEditMaterial = () => {
    const { materialsActions: { editMaterial }, materials: { material }, match: { params: { id } } } = this.props
    const _material = { // TODO: temporally remove edit-categories cause 500 in back-end, fix it
      title: material.title,
      description: material.description,
      preview: material.preview,
      body: material.body
    }
    editMaterial(_material, material.type, id)
  }
  handleFileChange = ({ target: { files } }) => this.props.materialsActions.changeField('preview', files[0])
  handleTabChange = tab => this.setState({ tab })
  handleChange = ({ target: { name, value } }) => this.props.materialsActions.changeField(name, value)
  handleCloseModal = () => this.setState({ isModalOpened: false })
  handleOpenModal = () => this.setState({ isModalOpened: true })
  handleCategoryEnter = ({ keyCode, target: { value } }) => {
    const { materialsActions: { changeField }, materials: { material: { categories } } } = this.props
    if (keyCode === 13) {
      changeField('categories', [...categories, value])
      this.setState({ newCategory: '' })
    }
  }
  handleCategoryChange = ({ target: { value } }) => this.setState({ newCategory: value })
  handleFocus = focus => this.setState({ isAutocompleteOpened: focus })
  handleAddCategory = (cat, e) => {
    const { materialsActions: { changeField }, materials: { material: { categories } } } = this.props
    changeField('categories', [...categories, cat])
    this.setState({ isAutocompleteOpened: false })
  }

  render() {
    const { materials: { isRequesting, material, categories, isEditing } } = this.props
    const { isAutocompleteOpened, type, newCategory } = this.state

    return isRequesting ? (
      <Loader />
    ) : (
        <Paper className="materials" style={{ width: '89%' }}>
          <MaterialsDashboardToolbar
            toggleFilter={this.handleToggleFilter}
            withFilter={this.state.isFilterOpened}
            onTabChange={this.handleTabChange}
          />
          {
            isRequesting
              ? <Loader />
              :
              <Swipeable index={this.state.tab}>
                <div className='materials__wrapper' style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='edit__wrapper'>
                    <Typography variant="body1">Поля, обозначенные <span className='highlight'>*</span> обязательны к заполнению</Typography>
                    <TextField
                      id="outlined-name"
                      label="Заголовок*"
                      name='title'
                      value={material.title}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      InputProps={{ classes: { focused: 'inputBorderColor' } }}
                    />
                  <div className={`autocompleteInput${!isAutocompleteOpened ? ' withList' : ''}`} onMouseLeave={() => this.handleFocus(false)}>
                      <TextField
                        id="outlined-name"
                        label="Категории*"
                        disabled={isEditing}
                        // className={classes.textField}
                        value={this.state.newCategory}
                        onKeyDown={this.handleCategoryEnter}
                        onChange={this.handleCategoryChange}
                        onFocus={() => this.handleFocus(true)}
                        margin="normal"
                        variant="outlined"
                        multiline={true}
                        style={{display: 'flex', flexDirection: 'column'}}
                        InputProps={{
                          classes: { root: 'chipsInput', focused: 'inputBorderColor' },
                          startAdornment:
                            <div style={{minWidth: '610px', maxWidth: '640px'}}>
                              {material.categories.map((cat, index) =>
                                <Chip style={{margin: '4px'}} key={index} label={cat.title || cat} color={isEditing ? 'primary' : 'secondary'} onDelete={this.handleDelete} />
                              )}
                            </div>
                        }}
                        />
                      <div className='autocomplete'>
                        {
                          categories.filter(category => (category.type === type) && (category.title.toLowerCase().indexOf(newCategory.toLowerCase()) >= 0)).map((item, index) => <Typography key={index} onClick={() => this.handleAddCategory(item)}>{item.title}</Typography>)
                        }
                      </div>
                    </div>
                    <div className='inputRow'>
                      <TextField
                        id="outlined-name"
                        label="Анонс"
                        value={material.description}
                        name='description'
                        onChange={this.handleChange}
                        margin="normal"
                        inputProps={{ style: { height: '50px' } }}
                        variant="outlined"
                        multiline={true}
                        style={{ width: '100%' }}
                        InputProps={{ classes: { focused: 'inputBorderColor' } }}
                      />
                      <div className='photoInput__wrapper'>
                        {
                          !(material.preview.url || material.preview.size)
                            ? <div>
                              <input
                                className="photoInput"
                                type="file"
                                name="preview"
                                onChange={this.handleFileChange}
                                accept="image/*"
                                id='file'
                                />
                              <label className='emptyField' htmlFor="file">
                                <div className='icon photoInputIcon'></div>
                                <p>*</p>
                              </label>
                            </div>
                            : <div>
                              <input
                                className="photoInput"
                                type="file"
                                name="preview"
                                onChange={this.handleFileChange}
                                accept="image/*"
                                id='file'
                                multiple={true}
                              />
                              {
                                <label htmlFor="file">
                                  <img htmlFor="file" src={this.loadPreview()} />
                                </label>
                              }
                            </div>
                        }
                      </div>
                    </div>
                    <ReactMde
                      onChange={value => this.handleChange({ target: { name: 'body', value } })}
                      value={material.body}
                      generateMarkdownPreview={markdown =>
                        Promise.resolve(this.converter.makeHtml(markdown))
                      }
                    />
                  {
                    !isEditing &&
                    <RadioGroup
                      aria-label="gender"
                      name="gender2"
                      value={this.state.type}
                      onChange={this.handleTypeChange}
                      style={{ display: 'flex', flexDirection: 'row' }}
                      >
                      <FormControlLabel
                        value="news"
                        control={<Radio />}
                        label="Новость"
                        labelPlacement="end"
                        />
                      <FormControlLabel
                        value="blog"
                        control={<Radio />}
                        label="Статья"
                        labelPlacement="end"
                        />
                    </RadioGroup>
                  }
                    {
                      /*
                      <div className='sourceRow'>
                      <div className='icon sourceIcon'></div>
                      <FormControl style={{ width: '100%' }} variant="outlined">
                      <InputLabel>Источник</InputLabel>
                      <Select
                      value={this.state.imgSource}
                      onChange={this.handleChange}
                      inputProps={{ name: 'imgSource' }}
                      style={{ width: '100%' }}
                      input={<OutlinedInput />}
                      >
                      <MenuItem value={'Commonmark.org'}>Commonmark.org</MenuItem>
                      <MenuItem value={'Mocommnark.gor'}>Mocommnark.gor</MenuItem>
                      <MenuItem value={'Kocrammnom.rog'}>Kocrammnom.rog</MenuItem>
                      </Select>
                      </FormControl>
                      <ModalSourceEditor
                      opened={this.state.isModalOpened}
                      onCloseModal={this.handleCloseModal}
                      />
                      <div className='icon createIcon' onClick={this.handleOpenModal}></div>
                      </div>
                      <div className='linkRow'>
                      <div className='icon linkIcon'></div>
                      <TextField
                      id="outlined-name"
                      label="Ссылка на страницу в источнике"
                      // className={classes.textField}
                      // value={this.state.name}
                      // onChange={this.handleChange('name')}
                      margin="normal"
                      variant="outlined"
                      style={{ width: '100%' }}
                      InputProps={{ classes: { focused: 'inputBorderColor' } }}
                      />
                      </div>
                      */
                    }
                    <div className='buttonRow'>
                      {
                        !isEditing &&
                        <Button variant="outlined" color="accent" style={{ whiteSpace: 'nowrap' }}>
                          Создать и не опубликовать
                        </Button>
                      }
                      <Button variant="outlined" color="secondary" onClick={() => this.props.history.push('/materials')}>
                        Отмена
                      </Button>
                      <Button variant="contained" color="secondary" style={{ whiteSpace: 'nowrap' }} onClick={isEditing ? this.handleEditMaterial : this.handleSaveNewClick}>
                        {isEditing ? 'Сохранить' : 'Создать и опубликовать'}
                      </Button>
                    </div>
                  </div>
                  {/*<ImageExplorer />*/}
                </div>
                {
                  this.state.tab === 1
                    ? <div className='materials__wrapper' style={{ display: 'flex', justifyContent: 'center' }}>
                      <MaterialsEditor material={material} />
                      <Card className='previewCard'>
                        <CardContent>
                          <div className='infoRow'>
                            <Typography variant='h6'>Пример предпросмотра</Typography>
                            <Tooltip title="Так новости будут отображаться в закрытом состоянии" placement="top-start">
                              <div className='icon help'></div>
                            </Tooltip>
                          </div>
                          <img src={this.loadPreview()} />
                          <div className='text'>
                            <div className='categories'>
                              {
                                material.categories.map((cat, index) => <Typography key={index} variant='body2'>{cat.title || cat}</Typography>)
                              }
                            </div>
                            <Typography variant='h6'>{material.title}</Typography>
                            <Typography variant='body2'>{material.description}</Typography>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    : null
                }
              </Swipeable>
          }
        </Paper>
      );
  }
}

export default Material;
