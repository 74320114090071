export const GET_MATERIALS__REQUEST = 'MATERIALS_TYPE__GET_MATERIALS__REQUEST'
export const GET_MATERIALS__FAILURE = 'MATERIALS_TYPE__GET_MATERIALS__FAILURE'
export const GET_MATERIALS__SUCCESS = 'MATERIALS_TYPE__GET_MATERIALS__SUCCESS'
export const GET_MATERIAL__REQUEST = 'MATERIALS_TYPE__GET_MATERIAL__REQUEST'
export const GET_MATERIAL__FAILURE = 'MATERIALS_TYPE__GET_MATERIAL__FAILURE'
export const GET_MATERIAL__SUCCESS = 'MATERIALS_TYPE__GET_MATERIAL__SUCCESS'
export const CREATE_MATERIAL__REQUEST = 'MATERIALS_TYPE__CREATE_MATERIAL__REQUEST'
export const CREATE_MATERIAL__FAILURE = 'MATERIALS_TYPE__CREATE_MATERIAL__FAILURE'
export const CREATE_MATERIAL__SUCCESS = 'MATERIALS_TYPE__CREATE_MATERIAL__SUCCESS'
export const EDIT_MATERIAL__REQUEST = 'MATERIALS_TYPE__EDIT_MATERIAL__REQUEST'
export const EDIT_MATERIAL__FAILURE = 'MATERIALS_TYPE__EDIT_MATERIAL__FAILURE'
export const EDIT_MATERIAL__SUCCESS = 'MATERIALS_TYPE__EDIT_MATERIAL__SUCCESS'
export const CHANGE_FIELD = 'MATERIALS_TYPE__CHANGE_FIELD'
export const INIT_MATERIAL_EDITOR = 'MATERIALS_TYPE__INIT_MATERIAL_EDITOR'
export const GET_CATEGORIES__REQUEST = 'MATERIALS_TYPE__GET_CATEGORIES__REQUEST'
export const GET_CATEGORIES__SUCCESS = 'MATERIALS_TYPE__GET_CATEGORIES__SUCCESS'
export const GET_CATEGORIES__FAILURE = 'MATERIALS_TYPE__GET_CATEGORIES__FAILURE'
export const CREATE_CATEGORIES__REQUEST = 'MATERIALS_TYPE__CREATE_CATEGORIES__REQUEST'
export const CREATE_CATEGORIES__SUCCESS = 'MATERIALS_TYPE__CREATE_CATEGORIES__SUCCESS'
export const CREATE_CATEGORIES__FAILURE = 'MATERIALS_TYPE__CREATE_CATEGORIES__FAILURE'
