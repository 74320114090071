import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
// new
import { dashboardContract } from "./contract/dashboard.combine.reducer";
import * as reducers from ".";
import {modal} from "./modal"

const rootReducer = combineReducers({
  ...reducers,
  dashboardContract,
  modal,
  router: routerReducer
});

export default rootReducer;
