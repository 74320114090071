import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  workPlacesActions,
  pharmaciesActions,
  profileActions,
  metaActions,
  positionsActions,
  internalCompaniesActions,
  modalActions,
  externalDocumentsActions,
    usersActions,
} from "../actions";
import { Profile } from "../pages";
import { Loader } from '../component'

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      metaActions: { updateNavi },
      internalCompaniesActions: { getCompanies },
      profileActions: { getProfile , getManufacturers },
      // positionsActions: { getPositions },
      // pharmaciesActions: { getPharmacies },
      // usersActions: { getUser },
      match: { path, params: { userId } }
    } = this.props;

    getCompanies();
    getProfile();
    getManufacturers();
    updateNavi([{ title: "Ваш профиль", to: "/profile" }]);
  };

  render() {
    return <Profile {...this.props} />;
  }
}

const mapStateToProps = state => ({
  internalCompanies: state.internalCompanies,
  profile: state.profile,
  positions: state.positions,
  externalDocument: state.externalDocument,
  isOpenModal: state.modal.isOpenModal,
  pharmacies: state.pharmacies,
  employees: state.workPlaces
});
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  // pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  profileActions: bindActionCreators(profileActions, dispatch),
  positionsActions: bindActionCreators(positionsActions, dispatch),
  internalCompaniesActions: bindActionCreators(
    internalCompaniesActions,
    dispatch
  ),
  modalActions: bindActionCreators(modalActions, dispatch),
  externalDocumentsActions: bindActionCreators(
    externalDocumentsActions,
    dispatch
  ),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  workPlacesActions: bindActionCreators(workPlacesActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer);
