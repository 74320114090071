import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { contractActions, getRootResourse, authActions, profileActions } from '../actions'
import { Root } from '../pages'
import { Loader } from '../component'

import "../styles/root.scss"


class RootContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { getRootResourse } = this.props
    getRootResourse()
  }

  render() {
    const { meta: { requestingResourses } } = this.props
    return requestingResourses ? <Loader /> : <Root {...this.props} />
  }
}

const mapStateToProps = state => ({
  meta: state.meta,
  user: state.auth.user,
})
const mapDispatchToProps = dispatch => ({
  contractActions: bindActionCreators(contractActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
  getRootResourse: bindActionCreators(getRootResourse, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer)
