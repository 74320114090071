import { pharmaciesTypes } from '../types'

const initialState = {
  item: {
    address: '',
      category: '',
      company: '',
      coordinates: {
        lat: 59.9,
        lon: 30.3
      },
      description: '',
      emails: [],
      loyalty: '',
      manager: '',
      phones: [],
      pharmacyGroup: '',
      status: '',
      title: '',
      type: '',
      website: '',
      isRelatedToVesna: false
  },
  isRequesting: true,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case pharmaciesTypes.GET_PHARM__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case pharmaciesTypes.GET_PHARM__SUCCESS: return {
      ...state,
      item: action.payload.pharm,
      isRequesting: false
    }
    // case pharmaciesTypes.FILTER: return {
    //   ...state,
    //   filter: action.payload.filter
    // }
    default: return state
  }
}
