import React from "react";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import YMap from "../../component/map/mapContainer";
// import { Field } from "react-final-form";
import Fab from "@material-ui/core/Fab/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import PlaceIcon from "@material-ui/icons/Place";
import Button from "@material-ui/core/Button";
//styles
import "./Popper.scss";
import { Field } from "react-final-form";
import Modal from "@material-ui/core/Modal";

const styles = theme => ({
  typography: {
    padding: theme.spacing.unit * 2
  },
  popper: {
    minWidth: "500px",
    minHeight: "500px",
    zIndex: "3000"
  }
});

class buttonGoogleMapPopper extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: state.anchorEl ? null : currentTarget
    }));
  };

  render() {
    const { classes, label, idEpic } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "no-transition-popper" : null;

    return (
      <div>
        <Button
          aria-label="Delete"
          onClick={this.handleClick}
          color="secondary"
          className="button"
        >
          <PlaceIcon className="icon color" />
          {label}
        </Button>
        <Modal id={id} open={open}  style={{display:"flex" , justifyContent:"center", alignItems:"center"}} className={"popper"}>
          <Paper style={{width:"70vw" , height:"80vh"}}>
            <YMap
              onChange={this.props.onChange}
              handleClose={this.handleClick}
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default buttonGoogleMapPopper;
