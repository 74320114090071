export const GET_PROFILE__REQUEST = 'PROFILE_TYPE__GET_PROFILE__REQUEST'
export const GET_PROFILE__SUCCESS = 'PROFILE_TYPE__GET_PROFILE__SUCCESS'
export const GET_PROFILE__FAILURE = 'PROFILE_TYPE__GET_PROFILE__FAILURE'

export const POST_PROFILE_PERSONAL_INFO = 'POST_PROFILE_PERSONAL_INFO'
export const POST_PROFILE_PERSONAL_INFO_SUCCESS = 'POST_PROFILE_PERSONAL_INFO_SUCCESS'
export const POST_PROFILE_PERSONAL_INFO_FAILURE = 'POST_PROFILE_PERSONAL_INFO_FAILURE'

export const POST_PROFILE_ROLE = 'POST_PROFILE_ROLE'
export const POST_PROFILE_ROLE_SUCCESS = 'POST_PROFILE_ROLE_SUCCESS'
export const POST_PROFILE_ROLE_FAILURE = 'POST_PROFILE_ROLE_FAILURE'

export const UPLOAD_AVATAR_FILE = 'UPLOAD_AVATAR_FILE'
export const UPLOAD_AVATAR_FILE_SUCCESS = 'UPLOAD_AVATAR_FILE_SUCCESS'
export const UPLOAD_AVATAR_FILE_FAILURE = 'UPLOAD_AVATAR_FILE_FAILURE'

export const REQUEST_SMS_CODE = 'REQUEST_SMS_CODE';
export const REQUEST_SMS_CODE_SUCCESS = 'REQUEST_SMS_CODE_SUCCESS';
export const REQUEST_SMS_CODE_FAILURE = 'REQUEST_SMS_CODE_FAILURE';

export const CHANGE_PROFILE_INTERNAL_COMPANY = ' CHANGE_PROFILE_INTERNAL_COMPANY';
export const CREATE_PROFILE_EMPLOYEE = ' CREATE_PROFILE_EMPLOYEE';
export const CREATE_PROFILE_EMPLOYEE_SUCCESS = ' CREATE_PROFILE_EMPLOYEE_SUCCESS';
export const CREATE_PROFILE_EMPLOYEE_FAIL = ' CREATE_PROFILE_EMPLOYEE_FAIL';
export const DELETE_PROFILE_EMPLOYEE = ' DELETE_PROFILE_EMPLOYEE';
export const DELETE_PROFILE_EMPLOYEE_SUCCESS = ' DELETE_PROFILE_EMPLOYEE_SUCCESS';
export const DELETE_PROFILE_EMPLOYEE_FAIL = ' DELETE_PROFILE_EMPLOYEE_FAIL';

export const BACK_CHANGE_PHONE = 'BACK_CHANGE_PHONE';

export const CONFIRM_SMS_CODE = 'CONFIRM_SMS_CODE';
export const CONFIRM_SMS_CODE_SUCCESS = 'CONFIRM_SMS_CODE_SUCCESS';
export const CONFIRM_SMS_CODE_FAILURE = 'CONFIRM_SMS_CODE_FAILURE';

export const CREATE_DOCUMENT__REQUEST = 'PROFILE_TYPE__CREATE_DOCUMENT__REQUEST'
export const CREATE_DOCUMENT__SUCCESS = 'PROFILE_TYPE__CREATE_DOCUMENT__SUCCESS'
export const CREATE_DOCUMENT__FAILURE = 'PROFILE_TYPE__CREATE_DOCUMENT__FAILURE'
export const UPDATE_DOCUMENT__REQUEST = 'PROFILE_TYPE__UPDATE_DOCUMENT__REQUEST'
export const UPDATE_DOCUMENT__SUCCESS = 'PROFILE_TYPE__UPDATE_DOCUMENT__SUCCESS'
export const UPDATE_DOCUMENT__FAILURE = 'PROFILE_TYPE__UPDATE_DOCUMENT__FAILURE'
export const DELETE_DOCUMENT__REQUEST = 'PROFILE_TYPE__DELETE_DOCUMENT__REQUEST'
export const DELETE_DOCUMENT__SUCCESS = 'PROFILE_TYPE__DELETE_DOCUMENT__SUCCESS'
export const DELETE_DOCUMENT__FAILURE = 'PROFILE_TYPE__DELETE_DOCUMENT__FAILURE'
export const GET_DOCUMENT__REQUEST = 'PROFILE_TYPE__GET_DOCUMENT__REQUEST'
export const GET_DOCUMENT__SUCCESS = 'PROFILE_TYPE__GET_DOCUMENT__SUCCESS'
export const GET_DOCUMENT__FAILURE = 'PROFILE_TYPE__GET_DOCUMENT__FAILURE'

export const GET_MANUFACTURERS = 'PROFILE_TYPE__GET_MANUFACTURERS'
