export const ROLE_DEVELOPER = 'ROLE_DEVELOPER';
export const ROLE_MANAGER = 'ROLE_MANAGER';
export const ROLE_MEDICAL_REPRESENTATIVE = 'ROLE_MEDICAL_REPRESENTATIVE';
export const ROLE_SUPPORT_AGENT = 'ROLE_SUPPORT_AGENT';
export const ROLE_TARGET_AUDIENCE = 'ROLE_TARGET_AUDIENCE';

export const roles = {
    ROLE_DEVELOPER: 'Разработчик',
    ROLE_MANAGER: 'Управляющий',
    ROLE_MEDICAL_REPRESENTATIVE: 'Мед. представитель',
    ROLE_SUPPORT_AGENT: '',
    ROLE_TARGET_AUDIENCE: 'Целевая аудитория'
}
