import { projectTypes, commentsTypes } from '../types'
import { getList, getOne, update, serialize, get, currentApiUrl } from '../api'
import { metaActions } from './'
import { formatComments } from '../utils/formating'
import { getContract } from './contract'
import ApiService from '../api/apiService'

export const getProjects = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: projectTypes.GET_PROJECTS__REQUEST }))())
    const resourses = getState().meta.resourses
    getList(resourses.projects)
    .then(data => dispatch((() => ({
      type: projectTypes.GET_PROJECTS__SUCCESS,
      payload: { projects: data }
    }))()))
    // .catch(err => dispatch((() => ({ type: projectTypes.GET_PROJECTS__FAILURE }))()))
  }


export const getProject = ({ id }) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: projectTypes.GET_PROJECT__REQUEST }))())
    dispatch((() => ({ type: commentsTypes.GET_COMMENTS__REQUEST }))())
    const resourses = getState().meta.resourses
    getOne(resourses.projects, { id })
    .then(data => {
      dispatch((() => ({
        type: commentsTypes.GET_COMMENTS__SUCCESS,
        payload: { items: formatComments(data.comments.items) }
      }))())
      dispatch(metaActions.provideTitle(data.title))
      dispatch(getTasksForProject(data))
    })
  }

const getTasksForProject = (project) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: projectTypes.GET_TASKS__REQUEST }))())
    const resourses = getState().meta.resourses

    get(`${currentApiUrl}${project.tasks}`).then(tasks => {
      Promise.all(tasks.items.map(task => get(`${currentApiUrl}${task.subtasks}`))).then(_subtasks => {
        const withSubtasks = tasks.items.map((task, key) => ({ ...task, subtasks: _subtasks[key] }))
        dispatch((() => ({
          type: projectTypes.GET_PROJECT__SUCCESS,
          payload: {
            ...project,
            tasks: withSubtasks
          }
        }))())
      })
    })
  }

export const editProjects = (projects, contractId, {...data}) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: projectTypes.UPDATE_PROJECT__REQUEST }))())
    const resourses = getState().meta.resourses
    Promise
      .all(projects.map(id => update(resourses.projects, { id, data })))
      .then(_ => {
        dispatch({ type: projectTypes.UPDATE_PROJECT__SUCCESS })
        dispatch(getContract({ id : contractId }))
      })
  }

export const closePopup = () => ({ type: 'CLOSE_TASK_POPUP' })
export const openPopup = () => ({ type: 'OPEN_TASK_POPUP' })
