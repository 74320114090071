// export const CLOSED = 'CLOSED';
// export const OPEN = 'OPEN';
// export const IN_WORK = 'IN_WORK';
// export const NEEDS_ADDITIONAL_INFO = 'NEEDS_ADDITIONAL_INFO';

export const CLOSED = 'closed';
export const OPEN = 'open';
export const IN_WORK = 'in_work';
export const NEEDS_ADDITIONAL_INFO = 'needs_additional_info';

export const statuses = [OPEN, CLOSED, IN_WORK, NEEDS_ADDITIONAL_INFO]

export const translateStatus = (status) => {
    switch(status) {
        case CLOSED:
            return 'Закрыт';
        case IN_WORK:
            return 'В работе';
        case OPEN:
            return 'Открыт';
        case NEEDS_ADDITIONAL_INFO:
            return 'Требуется дополнительная информация';
        default:
            return 'Нет';
    }
}

export const translateChangedStatus = (status) => {
    switch(status) {
        case CLOSED:
            return 'Закрыт';
        case IN_WORK:
            return 'В работе';
        case OPEN:
            return 'Открыт';
        case NEEDS_ADDITIONAL_INFO:
            return 'Требуется дополнительная информация';
    }
}