import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import classNames from "classnames";
import { TextField, Typography, Button, Select, MenuItem, Tooltip } from '@material-ui/core';
import React from "react";

//styles
import "./imageExplorer.scss"



class ImageExplorer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSource: 'Изображения из материалов',
    };
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {} = this.props;

    return (
      <div className='imageExplorer'>
        <div className='selectRow'>
          <Select
            value={this.state.imgSource}
            onChange={this.handleChange}
            style={{width: '85%'}}
            inputProps={{name: 'imgSource'}}
            >
            <MenuItem value={'Изображения из материалов'}>Изображения из материалов</MenuItem>
            <MenuItem value={'Изображения из обучения'}>Изображения из обучения</MenuItem>
          </Select>
          <Tooltip
            title="Вы можете повторно использовать изображения не только из других материалов, но и из раздела обучения"
            placement="top-start"
          >
            <div className='icon help'></div>
          </Tooltip>
        </div>
        <Button variant="contained" color="secondary" style={{whiteSpace: 'nowrap'}}>
          Загрузить изображение
        </Button>
        <div className='imgGrid'>
          <div className='column1'>
            <div className='imgContainer'>
              {

              }
              <img style={{visibility: 'hidden'}} src='https://sivator.com/uploads/posts/2017-03/1490362936_1490318100_16.jpg' />
              <div className='deleteRow'>
                <p>Кисуля</p>
                <div className='icon deleteIcon'></div>
              </div>
            </div>
            <img src='https://humor.in.ua/upload/002/u235/ca/2a/7f501ce0.jpeg' />
            <img src='https://cs4.pikabu.ru/post_img/big/2014/05/12/7/1399890794_1048053680.jpg' />
            <img src='http://magspace.ru/uploads/old/2008/06/1214221130_1-3.jpg' />
            <img src='https://shutkijumora.ru/wp-content/uploads/2017/08/kotiki-ulybayutsya-01.jpg' />
          </div>
          <div className='column2'>
            <img src='http://magspace.ru/uploads/old/2008/06/1214221130_1-3.jpg' />
            <img src='https://shutkijumora.ru/wp-content/uploads/2017/08/kotiki-ulybayutsya-01.jpg' />
            <img src='https://sivator.com/uploads/posts/2017-03/1490362936_1490318100_16.jpg' />
            <img src='https://humor.in.ua/upload/002/u235/ca/2a/7f501ce0.jpeg' />
            <img src='https://cs4.pikabu.ru/post_img/big/2014/05/12/7/1399890794_1048053680.jpg' />
          </div>
        </div>
      </div>
    );
  }
}

export default ImageExplorer;
