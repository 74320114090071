import { positionsTypes } from '../types'
import { get, currentApiUrl } from '../api'
import {setQueryString} from "../utils";

export const getPositions = (filter) => dispatch => {
	dispatch((() => ({ type: positionsTypes.GET_POSITIONS__REQUEST }))())
	const query = setQueryString(filter);
	get(`${currentApiUrl}/v1/working-positions${query}`).then(_positions => {
		dispatch((() => ({
			type: positionsTypes.GET_POSITIONS__SUCCESS,
			payload: { positions: _positions.items }
		}))())
	})
}
