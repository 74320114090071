import React, { Component } from "react";
import moment from "moment";
import { List } from "../../component";
import { connect } from "react-redux";
import { getWorkPlaces } from "../../actions/workPlaces";
import { withRouter } from "react-router";

@withRouter
class UserList extends Component {
  state = {
    itemsOnPage: 20,
    pageNumber: 1
  };

  componentDidMount = () => {
    this.getUsers();
  };

  getUsers() {
    const { getWorkPlaces, medicalInstitution } = this.props;
    const { itemsOnPage, pageNumber } = this.state;

    getWorkPlaces({
      "pagination[offset]": itemsOnPage * (pageNumber - 1),
      "pagination[count]": itemsOnPage,
      medicalInstitution: medicalInstitution.self
    }, { withUsers: true });
  }

  componentDidUpdate = (_, prevState) => {
    const { itemsOnPage, pageNumber } = this.state;
    if (
      prevState.itemsOnPage !== itemsOnPage ||
      prevState.pageNumber !== pageNumber
    ) {
      this.getUsers();
    }
  };

  handleTableControlClick = data =>
      this.setState({
          itemsOnPage: data.itemsOnPage,
          pageNumber: data.pageNumber
      });

  handleItemClick = user => {
    this.props.history.push(`${this.props.match.url}/users/${user.id}`)
  }

  render() {
    const {
        workPlaces: { items, isRequesting }
    } = this.props;

    return (
      <List
        isRequesting={isRequesting}
        onTableControlChange={this.handleTableControlClick}
        onItemClick={this.handleItemClick}
        items={items.map(({ user }) => ({
          ...user,
          specializations: user.specializations.items
            .map(({ specialization: { title } }) => title)
            .join(", "),
          registeredAt: moment(new Date(user.registeredAt)).format("DD.MM.YYYY")
        }))}
        keys={[
          "fullName",
          "specializations",
          "phone",
          "registeredAt",
          "rewardPoints"
        ]}
        headers={[
          "Имя",
          "Специализация",
          "Телефонный номер",
          "Дата регистрации",
          "Баллы"
        ]}
      />
    );
  }
}

export default connect(
  state => ({
    workPlaces: state.workPlaces
  }),
  { getWorkPlaces }
)(UserList);
