export const GET_PROJECTS__REQUEST = 'PROJECT_TYPE__GET_PROJECTS__REQUEST'
export const GET_PROJECTS__SUCCESS = 'PROJECT_TYPE__GET_PROJECTS__SUCCESS'
export const GET_PROJECTS__FAILURE = 'PROJECT_TYPE__GET_PROJECTS__FAILURE'
export const GET_PROJECT__REQUEST = 'PROJECT_TYPE__GET_PROJECT__REQUEST'
export const GET_PROJECT__SUCCESS = 'PROJECT_TYPE__GET_PROJECT__SUCCESS'
export const GET_PROJECT__FAILURE = 'PROJECT_TYPE__GET_PROJECT__FAILURE'
export const GET_TASKS__REQUEST = 'PROJECT_TYPE__GET_TASKS__REQUEST'
export const GET_TASKS__SUCCESS = 'PROJECT_TYPE__GET_TASKS__SUCCESS'
export const GET_TASKS__FAILURE = 'PROJECT_TYPE__GET_TASKS__FAILURE'
export const UPDATE_PROJECT__REQUEST = 'PROJECT_TYPE__UPDATE_PROJECT__REQUEST'
export const UPDATE_PROJECT__SUCCESS = 'PROJECT_TYPE__UPDATE_PROJECT__SUCCESS'
export const UPDATE_PROJECT__FAILURE = 'PROJECT_TYPE__UPDATE_PROJECT__FAILURE'
