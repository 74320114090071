import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import * as authSteps from "../constants/authSteps";
import { MaskedInput } from "../component";

import "./Auth.scss";
import "../styles/typography.scss";

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedA: false,
      checkedB: false,
      isPhoneValid: false,
      tel: "",
      code: ""
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChangeField = ({ target: { name, value } }) =>
    this.setState({
      [name]: value
    });
  handleSendPhone = () => {
    const {
      authActions: { sendPhone }
    } = this.props;
    const { tel } = this.state;
    sendPhone({ tel: `+${tel}` });
  };
  handleSendCode = () => {
    const {
      authActions: { sendCode }
    } = this.props;
    const { tel, code } = this.state;
    sendCode({ tel: `+${tel}`, code });
  };

  render() {
    const {
      auth: { step, isError, isRequesting }
    } = this.props;
    const { tel, code } = this.state;

    return (
      <div
        onKeyDown={ (event) => {
          event.key === 'Enter'
            ? step === authSteps.SEND_PHONE
              ? tel
                ? this.handleSendPhone()
                : null
              : tel && code.length > 0
              ? this.handleSendCode()
              : null
            : null
          }
        }
        className="auth">
          <Card className={"card"}>
            <CardContent className={"cardContent"}>
              <Typography gutterBottom>Вход</Typography>
              <MaskedInput
                autoComplete='tel'
                onChange={this.handleChangeField}
                id="phone-number"
                label="Номер телефона"
                className={`textField ${!tel ? "not_valid" : null}`}
                name="tel"
                margin="normal"
                validate
                variant="outlined"
                inputProps={{
                  inputmode: 'tel',
                  type: 'tel',
                }}
              />
            {step === authSteps.SEND_CODE && (
              <TextField
                id="password"
                label="Kод из СМС"
                className={"textField"}
                value={code}
                name="code"
                onChange={this.handleChangeField}
                margin="normal"
                variant="outlined"
                type="number"
                autoComplete='one-time-code'
                inputProps={{
                  inputmode: 'numeric',
                }}
              />
            )}
            {/*
            <div className="authRemember">
              <Switch
                className={"switch"}
                checked={this.state.checkedB}
                onChange={this.handleChange("checkedB")}
                value="checkedB"
              />
              <Typography className={"switchText"}>Запомнить меня</Typography>
            </div>
            */}
          </CardContent>
          <CardActions className={"cardAction"}>
            <Button
              size="small"
              onClick={
                step === authSteps.SEND_PHONE
                  ? tel
                    ? this.handleSendPhone
                    : null
                  : tel && code.length > 0
                    ? this.handleSendCode
                    : null
              }
              className={`authButton ${isRequesting ? "requesting" : null} ${
                isError ? "error" : null
              } ${
                step === authSteps.SEND_PHONE
                  ? !tel
                    ? "not_valid"
                    : null
                  : !(tel && code.length > 0)
                  ? "not_valid"
                  : null
              }`}
            >
              Войти в систему
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default Auth;
