export const documentKinds = {
  "accounting_statements_for_the_last_reporting_period": "Бухгалтерская отчетность за последний отчетный период", 
  "certificate_from_the_tax_authority": "Справка из налогового органа", 
  "certificate_of_the_average_number_of_employees": "Справка о среднесписочной численности сотрудников", 
  "company_card": "Карточка компании", 
  "document_confirming_the_authority": "Документ, подтверждающий полномочия", 
  "employee_passports": "Паспорт сотрудника", 
  "employment_agreements": "Соглашения к трудовому договору", 
  "employment_contract": "Трудовой договор", 
  "extract_from_legal_entity_registry": "Выписка из ЕГРЮЛ", 
  "license": "Лицензия", 
  "notifications": "Уведомления", 
  "passport": "Паспорт", 
  "psrn_certificate": "Свидетельство ОГРН", 
  "regulations": "Устав",
  }
