export const translate = status => {
  switch (status) {
    case "active":
      return "Активный";
    case "awaiting":
      return "В ожидании";
    case "canceled":
      return "Отмененный";
    case "done":
      return "Завершено";
    case "planning":
      return "Планиование";
  }
};

export const translateRevers = status => {
  switch (status) {
    case "Активный":
      return "active";
    case "В ожидании":
      return "awaiting";
    case "Отмененный":
      return "canceled";
    case "Завершено":
      return "done";
    case "Планиование":
      return "planning";
  }
};

export const theme2name = theme => {
	switch (theme) {
		case "general_medical":
			return "Общие медицинские";
		case "otc":
			return "OTC";
		case "rx":
			return "RX";
		case "mixed":
			return "Смешанные";
	}
};

export const diff2name = diff => {
	switch (diff) {
		case "easy":
			return "Легкая";
		case "medium":
			return "Средняя";
		case "hard":
			return "Сложная";
	}
};
