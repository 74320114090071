import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pharmaciesActions, metaActions } from "../actions";
import { ImportPharmacies } from "../pages";

class ImportPharmaciesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const {
      metaActions: { updateNavi }
    } = this.props;
    updateNavi([
      { title: "Аптеки", to: "/pharmacies" },
      { title: "Импорт из .xls", to: "/import_from_excel" },
      ]);
  };

  render() {
    return <ImportPharmacies {...this.props} />;
  }
}

const mapStateToProps = state => ({
  pharmaciesImport: state.pharmaciesImport
})

const mapDispatchToProps = dispatch => ({
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportPharmaciesContainer)