import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {manufacturersActions, metaActions, usersActions} from '../actions'
import { Manufacturer } from '../pages'
import {Loader} from "../component";

const manufacturerKeys = [
  "title",
  "country",
  "phone",
  "email",
  "company",
  "manager",
];

class EditManufacturerContainer extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    const {
      match,
      manufacturers: {manufacturer},
      manufacturersActions: {getManufacturer},
      metaActions: {updateNavi}
    } = this.props;
    const manufacturerId = match.params.manufacturerId;

    updateNavi([{title: 'Производители', to: '/manufacturers'}])

    getManufacturer(manufacturerId);
  };

  updateForm = (state) => {
    const { manufacturersActions: { updateManufacturer } } = this.props;
    updateManufacturer((obj => manufacturerKeys.map(key => {
      obj[key] = state[key] }) && obj)({}),
      this.props.match.params.manufacturerId,
      () => this.props.history.push('/manufacturers')
    )
  };

  render() {
    const { isRequesting } = this.props.manufacturers;
    return (
      <>
        {
          isRequesting
          ? <Loader />
          : <Manufacturer updateForm={this.updateForm} isEdited {...this.props}/>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  manufacturers: state.manufacturers,
  meta: state.meta,
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  manufacturersActions: bindActionCreators(manufacturersActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditManufacturerContainer);
