import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { Route } from 'react-router-dom'
import { Loader, List } from '../component'
import { CommentsContainer, TaskContainer } from '../containers'
import './Project.scss'

import Healing from "@material-ui/icons/Healing";
import StreetViewIcon from "@material-ui/icons/Streetview";
import MessageIcon from "@material-ui/icons/Message";
import PhotoIcon from "@material-ui/icons/Photo";
import PlayListAddIcon from "@material-ui/icons/PlaylistAdd";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ShopingCard from "@material-ui/icons/ShoppingCart";
import DoneIcon from "@material-ui/icons/Done";
import ModalCreateTask from "../component/modal/ModalCreateTask";

const tasksActions = [
  { icon: <ShopingCard />, title: "Создать задачу", name: 'create_task' },
  { icon: <FileCopyIcon />, title: "Создать группу задач", name: 'create_task_group' },
];
const views = {
  LIST: 'LIST',
  PHARM_SELECT: 'PHARM_SELECT'
}

const renderIcon = type => {
  switch (type) {
    case 'drugs_supply': return <Healing  color={'secondary'} />
    case 'poll': return <PlayListAddIcon  color={'secondary'} />
    case 'geolocation': return <StreetViewIcon  color={'secondary'} />
    case 'photos': return <PhotoIcon  color={'secondary'} />
    case 'message': return <MessageIcon  color={'secondary'} />
    default: return null;
  }
}

const TaskCard = ({ task, onClick }) => (
  <div className='task' onClick={onClick}>
    <div className={`status ${task.isComplete ? 'complete' : 'incomplete'}`}></div>
    <div className='content'>
      <h5>{task.title}</h5>
      <h6>{task.description}</h6>
      <div className='subtasks'>
        {
          task.subtasks.items.length === 0
          ? <p>Нет подзадач</p>
          : task.subtasks.items.map((st, index) =>
            <div key={index} className='subtask'>{ renderIcon(st.type) }</div>)
        }
      </div>
    </div>
  </div>
)

@withRouter
class Project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false,
      view: views.LIST,
      openedCreateTask: false,
      checkedList: [],
    }
  }
  componentDidMount() {
    const { id, projectActions: { getProject, openPopup }, metaActions: { updateNavi }, meta: { naviTitleHelper } } = this.props
    getProject({ id });
    updateNavi([
      { title: "Проекты", to: "/projects" },
      { title: naviTitleHelper, to: `/projects/${id}` }
    ]);
  }
  componentWillUpdate = (next) => {
    if (!next.task.isRequesting && this.props.task.isRequesting) {
      this.props.history.push(`/projects/${this.props.id}`)
    }
  }
  handleControlClick = () => this.setState({ opened: true })
  handleControlLeave = () => this.setState({ opened: false })
  handleActionClick = action => {
    const { id, metaActions: { updateNavi }, history: { push }, match: { url }} = this.props
    if (action === 'create_task_group') {
      this.setState({ view: views.PHARM_SELECT, checkedList: [] })
      updateNavi([
        { title: "Проекты", to: "/projects" },
        { title: 'Создание группы задач', to: `/projects/${id}/create-tasks-group` }
      ]);
    }
    if (action === 'create_task') {
      push(`${url}/new-task`)
      this.openPopup()
    }
  }
  handleSaveClick = () => this.setState({ openedCreateTask: true })
  handleCheckPharm = checked => this.setState({ checkedList: checked })
  handleModalClose = () => this.setState({ openedCreateTask: false })
  handleSaveTask = (task, subtasks) => this.props.tasksActions.saveTask({...task, project: `/v1/projects/${this.props.id}`}, subtasks)

  closePopup = () => this.props.projectActions.closePopup()
  openPopup = () => this.props.projectActions.openPopup()

  render() {
    const { project, match: { url, isExact } } = this.props,
          { opened, view, openedCreateTask } = this.state
    return (project.isRequesting) ? <Loader /> : (
      project.isCreateTaskModalOpen
      ? <Route path={`/projects/:id/new-task`} component={() => <TaskContainer />} />
      : (
        <Fragment>
          <div className='project'>
              <div className='tasksList'>
                {
                  project.item.tasks.length === 0
                  ? <div>У проекта пока нет задач</div>
                  : project.item.tasks.map((task, index) => <TaskCard task={task} key={index} onClick={() => this.props.history.push(`${url}/tasks/${task.id}`)} />)
                }
              </div>
              <CommentsContainer
                resourse={project.item.self}
                type='project'
                />
          <div className='controls'>
            {
              view === views.LIST
              ? <SpeedDial
                  style={{ backgroundColor: "rgba(#00796b , 0.01)" }}
                  ariaLabel="SpeedDial tooltip example"
                  icon={<SpeedDialIcon />}
                  onBlur={this.handleControlLeave}
                  onClick={this.handleControlClick}
                  onClose={this.handleControlLeave}
                  onFocus={this.handleControlClick}
                  onMouseEnter={this.handleControlClick}
                  onMouseLeave={this.handleControlLeave}
                  open={opened}
                  >
                  {
                    tasksActions.map((action, index) => (
                      <SpeedDialAction
                        key={index}
                        icon={action.icon}
                        tooltipTitle={action.title}
                        tooltipOpen
                        onClick={() => this.handleActionClick(action.name)}
                        />
                    ))
                  }
                </SpeedDial>
              : <SpeedDial
                style={{ backgroundColor: "rgba(#00796b , 0.01)" }}
                ariaLabel="SpeedDial tooltip example"
                icon={<DoneIcon />}
                onBlur={this.handleControlLeave}
                onClick={this.openPopup}
                onClose={this.handleControlLeave}
                onFocus={this.handleControlClick}
                onMouseEnter={this.handleControlClick}
                onMouseLeave={this.handleControlLeave}
                open={opened}
                />
            }
          </div>
        </div>
        </Fragment>
      )

    )
  }
}

export default Project
