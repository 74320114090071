import React, { Component, Fragment } from "react";
import { fade } from "@material-ui/core/styles/colorManipulator";
import cn from "classnames";
import {
  Button,
  InputBase,
  Typography,
  List,
  ListSubheader,
  ListItemIcon,
  ListItem,
  Avatar,
  ListItemAvatar
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SchoolIcon from "@material-ui/icons/School";
import CircularProgress from "@material-ui/core/CircularProgress";
import Language from "@material-ui/icons/Language";
import Mail from "@material-ui/icons/Mail";
import Wallpaper from "@material-ui/icons/Wallpaper";
import InsertComment from "@material-ui/icons/InsertComment";
import Healing from "@material-ui/icons/Healing";
import Done from "@material-ui/icons/Done";

const styles = theme => ({
  search: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    borderRadius: "90px",
    backgroundColor: fade("#263238", 0.15),
    "&:hover": {
      backgroundColor: fade("#263238", 0.25)
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    height: "48px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  disabledLogItem: {
    color: "#979797"
  },
  progress: {
    color: "#00695c"
  },
  reportItem: {
    padding: "0 24px 0 60px"
  },
  reportSubItem: {
    display: "flex"
  },
  reportList: {
    background: "#f3f4f5",
    paddingBottom: "25px"
  },
  mapLink: {
    marginLeft: "40px"
  },
  reportHeader: {
    margin: "10px 25px 12px 60px"
  }
});

const subtaskComponents = {
  geolocation: (report, key, classes) => (
    <ListItem key={key} className={classes.reportItem}>
      <ListItemIcon>
        <Language />
      </ListItemIcon>
      <div className={classes.reportSubItem}>
        <Typography variant="body2" className={`${classes.disabledLogItem}`}>
          {new Date(report.createdAt).toString().substring(0, 24)}
        </Typography>
        <Typography variant="body2" className={classes.mapLink}>
          <a
            target="_blank"
            href={`https://www.google.ru/maps/@${report.coordinates.lat},${
              report.coordinates.lon
            }z`}
          >
            Карта
          </a>
        </Typography>
      </div>
    </ListItem>
  ),
  poll: (report, key, classes) => (
    <ListItem key={key} className={classes.reportItem}>
      <ListItemIcon>
        <InsertComment />
      </ListItemIcon>
      <div className={classes.reportSubItem}>
        <Typography variant="body2" className={classes.disabledLogItem}>
          {new Date(report.createdAt).toString().substring(0, 24)}
        </Typography>
        {Object.keys(report)
          .filter(field => field !== "id")
          .map((field, subKey) => (
            <p key={subKey}>{field}</p>
          ))}
      </div>
    </ListItem>
  ),
  message: (report, key, classes) => (
    <ListItem key={key} className={classes.reportItem}>
      <ListItemIcon>
        <Mail />
      </ListItemIcon>
      <div className={classes.reportSubItem}>
        <Typography variant="body2" className={classes.disabledLogItem}>
          {new Date(report.createdAt).toString().substring(0, 24)}
        </Typography>
        <Typography variant="body2" className={classes.mapLink}>
          Сообщение: {report.message}
        </Typography>
      </div>
    </ListItem>
  ),
  photos: (report, key, classes) => (
    <ListItem key={key} className={classes.reportItem}>
      <ListItemIcon>
        <Wallpaper />
      </ListItemIcon>
      <div className={classes.reportSubItem}>
        <Typography variant="body2" className={classes.disabledLogItem}>
          {new Date(report.createdAt).toString().substring(0, 24)}
        </Typography>
        {Object.keys(report)
          .filter(field => field !== "id")
          .map((field, subKey) => (
            <p key={subKey}>{field}</p>
          ))}
      </div>
    </ListItem>
  ),
  drugs_supply: (report, key, classes) => (
    <ListItem key={key} className={classes.reportItem}>
      <ListItemIcon>
        <Healing />
      </ListItemIcon>
      <div className={classes.reportSubItem}>
        <Typography variant="body2" className={classes.disabledLogItem}>
          {new Date(report.createdAt).toString().substring(0, 24)}
        </Typography>
        {Object.keys(report)
          .filter(field => field !== "id")
          .map((field, subKey) => (
            <p key={subKey}>{field}</p>
          ))}
      </div>
    </ListItem>
  )
};

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = { query: "" };
  }

  handleInputChange = ({ target: { value } }) =>
    this.setState({ query: value });

  render() {
    const { classes, logs } = this.props;
    const { query } = this.state;

    const disabledLog = cn({
      [classes.disabledLogItem]: this.state.disabledLogItem
    });
    return (
      <Fragment>
        <div
          style={{ margin: "16px 24px", minHeight: "46px" }}
          className={classes.search}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Поиск по логам"
            classes={{ root: classes.inputRoot, input: classes.inputInput }}
            onChange={this.handleInputChange}
            disabled={logs.isRequesting}
          />
        </div>
        {logs.isRequesting ? (
          <div
            style={{
              width: "100%",
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress size={60} className={classes.progress} />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              margin: "0 0 0 0"
            }}
          >
            <List style={{ width: "100%" }}>
              {logs.items
                .filter(item => item.title.includes(query))
                .map((string, key) => (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      margin: "0 0 0 0"
                    }}
                  >
                    <ListItem key={key}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row"
                        }}
                      >
                        <ListItemIcon>
                          <ListItemAvatar>
                            <Avatar>
                              <SchoolIcon />
                            </Avatar>
                          </ListItemAvatar>
                        </ListItemIcon>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography variant="body1" className={disabledLog}>
                            {string.assign}
                          </Typography>
                          <Typography variant="body2" className={disabledLog}>
                            {string.date.toString().substring(0, 24)}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "0px 0px 0px 100px"
                          }}
                        >
                          <Typography variant="body1" className={disabledLog}>
                            {string.title}
                          </Typography>
                          <Typography variant="body2" className={disabledLog}>
                            {string.description}
                          </Typography>
                        </div>

                        {logs.items[key].isCompleted && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto"
                            }}
                          >
                            <Done />
                          </div>
                        )}
                      </div>
                    </ListItem>
                    {logs.items[key].reports && (
                      <List className={classes.reportList}>
                        <Typography
                          variant="body1"
                          className={classes.reportHeader}
                        >
                          Отчеты
                        </Typography>
                        {logs.items[key].reports.map((report, key) =>
                          subtaskComponents[report.type](report, key, classes)
                        )}
                      </List>
                    )}
                  </div>
                ))}
            </List>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Logs;
