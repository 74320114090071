import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import React from "react";
//styles
import "./Toolbar.scss";
import { userRoles } from "../../constants";
import Typography from "@material-ui/core/Typography";

function UsersTableToolbar(props) {
  const { onTabClick } = props;
  return (
    <Toolbar className={`toolbar`}>
          <Typography
            variant="root"
            className='title'
          >
            Список пользователей
          </Typography>
      <div className="tabs">
        <div
          onClick={() => onTabClick(userRoles.ROLE_MEDICAL_REPRESENTATIVE)}
          className={`tab ${
            props.current.find(
              role => role === userRoles.ROLE_MEDICAL_REPRESENTATIVE
            )
              ? "active"
              : null
          }`}
        >
          Медицинские представители
        </div>
        <div
          onClick={() => onTabClick(userRoles.ROLE_TARGET_AUDIENCE)}
          className={`tab ${
            props.current.find(role => role === userRoles.ROLE_TARGET_AUDIENCE)
              ? "active"
              : null
          }`}
        >
          Целевая аудитория
        </div>
      </div>
    </Toolbar>
  );
}

export default UsersTableToolbar;
