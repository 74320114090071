import React, { Component } from "react";
import { YMaps, Map } from "react-yandex-maps";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/es/Paper/Paper";
import Fade from "@material-ui/core/Fade";

class YMap extends Component {
  constructor(props) {
    super(props);
  }

  onClick = e => {
    this.props.handleClick(e.get("coords"));
  };

  render() {
    const {
      geo: { title, isRequesting }
    } = this.props;

    return (
      <YMaps style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          {title === "" ? null : (
            <Fade in={title !== ""}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 600,
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <div
                  style={{
                    margin: "20px",
                    borderRadius: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "38px",
                    transition: "all 0.5s ease"
                  }}
                >
                  <div
                    style={{
                      margin: "-10px 0",
                      padding: "0px 20px",
                      backgroundColor: "#00796b",
                      borderRadius: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "29px",
                      color: "white",
                      transition: "all 0.5s ease"
                    }}
                  >
                    {title}
                  </div>
                </div>
              </div>
            </Fade>
          )}

          <Map
            onClick={this.onClick}
            width={"100%"}
            height={"100%"}
            defaultState={{ center: [55.75, 37.57], zoom: 9 }}
          />
        </div>
      </YMaps>
    );
  }
}

export default YMap;
