import React, { Fragment } from "react";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core";
import connect from "react-redux/es/connect/connect";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#00796B",
      main: "#00796B",
      dark: "#00796B",
      contrastText: "#263238"
    },
    typography: {
      useNextVariants: true,
      fontFamily: '"Roboto" ,  sans-serif',
      fontSize: 18,
      h1: {
        fontFamily: '"Roboto", sans-serif ',
        fontStyle: "Light",
        fontSize: "9.6em"
        // color: palette.primary.contrastText
      },
      h2: {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: "SemiBold",
        fontWeight: "500",
        fontSize: "1.5rem"
        // color: palette.primary.contrastText
      },
      h3: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "Regular",
        fontWeight: "500",
        fontSize: "3rem"
        // color: palette.primary.contrastText
      },
      h4: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "Regular",
        fontSize: "2.125rem"
        // color: palette.primary.contrastText
      },
      h5: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "SemiBold",
        fontSize: "1.5rem"
        // color: palette.primary.contrastText
      },
      h6: {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: "Light",
        fontSize: "1.25rem",
        fontWeight: 500
        // color: palette.primary.contrastText
      },
      body1: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "Regular",
        fontSize: "16px"
        // color: palette.primary.contrastText
      },
      body2: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "Medium",
        fontSize: "14px",
        color: "#2632389c",
        opacity: "54"
      },
      subtitle1: {
        fontFamily: '"Roboto", sans-serif',
        fontStyle: "Regular",
        fontSize: "10px"
        // color: palette.primary.contrastText
      },
      subtitle2: {
        fontFamily: '"Roboto", sans-serif ',
        fontStyle: "normal ",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.1px",
        fontSize: "0.875rem !important",
        color: "#263238"
      },
      button: {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: "SemiBold",
        fontSize: "0.875rem",
        letterSpacing: "0.75px"
        // color: palette.primary.contrastText
      },
      button_outline: {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: "semiBold",
        fontWeight: 600,
        fontSize: "0.875rem",
        letterSpacing: "0.75px"
        // color: palette.secondary.main
      },
      caption: {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: "SemiBold",
        fontSize: "0.75rem"
      },
      overline: {
        fontFamily: '"Roboto". sans-serif',
        fontStyle: "Medium",
        fontSize: "0.625"
      }
    }
  }
});

let MuiHoc = Component => {
  class MuiThemeHocWrapper extends React.Component {
    render() {
      return (
        <MuiThemeProvider theme={theme}>
          <Component {...this.props} />
        </MuiThemeProvider>
      );
    }
  }
  return MuiThemeHocWrapper;
};

export { MuiHoc };
