import React, { Component } from 'react';
import { Loader, List } from '../../component'
import "./Manufacturers.scss";
import AddIcon from "@material-ui/icons/Add";
import {withRouter} from "react-router";



@withRouter
class  Manufacturers extends Component{
  constructor(props) {
    super(props);
  }

  createManufacturer = () => {
    this.props.history.push('/create-manufacturer')
  };

  render() {
    const {isRequesting, items} = this.props.manufacturers;

    return (
        <div className='manufacturers'>
          <div className='manufacturers__header'>
            <button onClick={this.createManufacturer} type='button' className='manufacturers__button'>
              <span className='button__content'>
                <AddIcon />
                <span>Добавить<br/>производителя</span>
              </span>
            </button>
          </div>
          {
            isRequesting
              ? <Loader />
              : items && <List
                onItemClick={(item) => { this.props.history.push(`/manufacturers/${item.id}`)}}
                isRequesting={false}
                onTableControlChange={this.handleTableControlClick}
                items={items.map( item => ({
                  title: item.title,
                  company: item.company.name ? item.company.name : item.company.itn,
                  manager: item.manager ? item.manager.fullName : 'Не указан',
                  country: item.country ? item.country : 'Не указана',
                  phone: item.phone,
                  email: item.email ? item.email : 'Не указан',
                  id: item.id
                }))}
                keys={['title', 'company', 'manager', 'country', 'phone', 'email']}
                headers={['Название', 'Компания', 'Менеджер', 'Страна', 'Номер', 'Email']}
              />
          }
        </div>
    );
  }
}

export default Manufacturers