import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { metaActions, usersActions, pharmaciesActions } from '../actions'
import { UserStatistics } from '../pages'
import { Loader } from '../component'

class UserStatisticsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: null,
      pharmId: null
    }
  }

  componentDidMount = () => {
    const { metaActions: { updateNavi }, pharmaciesActions: { getPharm },  usersActions: { getUser }, match: { params: { userId, pharmId } } } = this.props
    this.setState({ userId, pharmId })
    getPharm(pharmId)
    getUser(userId)
    updateNavi([
      { title: 'Мои аптеки', to: `/drugstore`, isRequesting: false }, 
      { title: 'Аптека', to: `/drugstore/${pharmId}`, isRequesting: true }, 
      { title: 'Пользователь', to: `/drugstore/${pharmId}/users/${userId}`, isRequesting: true}
    ])
  }

  render() {
    return (this.props.user.isRequesting || this.props.pharmacy.isRequesting) ? <Loader /> : <UserStatistics {...this.props} userId={this.state.userId} pharmId={this.state.pharmId} />
  }
}

const mapStateToProps = state => ({
  user: state.user,
  pharmacy: state.pharmacy
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  pharmaciesActions: bindActionCreators(pharmaciesActions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStatisticsContainer)
