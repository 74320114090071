import { submitContract } from "../../utils/submiting";
import { toggleModalCreateContract } from "../../actions/modal/toggleModal";
import { getContracts } from "../../actions/contract";

export function submitContractsActions(values) {
  return (dispatch, getState) => {
    (async () => {
      dispatch({ type: "SUBMITING_FORM" });

      let res = await submitContract(values);

      dispatch({ type: "SUBMITED_FORM" });
      // dispatch(toggleModalCreateContract());
      dispatch(getContracts());
    })();
  };
}
