import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { contractActions, metaActions } from '../actions'
import { Contracts } from '../pages'
import {toggleModalCreateContract} from "../actions/modal/toggleModal";

class ContractsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    const { contractActions: { getContracts }, metaActions: { updateNavi } } = this.props
    updateNavi([{ title: 'Контракты', to: '/contracts' }])
    getContracts()
  }

  render() {
    const modifiedProps = this.props.contracts.items.filter((obj) => obj.manager === this.props.auth.user.self )
    return <Contracts {...this.props} modifiedProps={modifiedProps} />
  }
}

const mapStateToProps = state => ({
  contracts: state.contracts,
  auth: state.auth
})
const mapDispatchToProps = dispatch => ({
  contractActions: bindActionCreators(contractActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  toggleModalCreateContract: bindActionCreators(toggleModalCreateContract, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractsContainer)
