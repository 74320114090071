import React, { Component } from 'react'
import { Typography, Input } from '@material-ui/core'
import './style.scss'

class Survey extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'statistical_survey',
      questionText: '',
      expectedAmount: null
    }
  }
  handleChange = (name, value) => this.setState(
    () => ({ [name]: value }),
    () => this.props.onChange(this.state)
  )
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Провести опрос</Typography>
        <div className='container'>
          <div className='icon course'></div>
          <div className='content'>
            <Input placeholder='Текст вопроса' value={this.state.questionText} onChange={({target: { value }}) => this.handleChange('questionText', value)}></Input>
            <Input type='number' placeholder='Ожидаемый ответ' value={this.state.expectedAmount} onChange={({target: { value }}) => this.handleChange('expectedAmount', value)}></Input>
          </div>
        </div>
      </div>
    )
  }
}

export default Survey
