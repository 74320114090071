import { geoTypes } from '../types'
import { geoApi } from '../api'

export const getPlace = ({ coords }) =>
  dispatch => {
    dispatch((() => ({ type: geoTypes.GET_PLACE__REQUEST }))())
    geoApi.getPlace({ coords })
          .then(data => dispatch((() => ({
            type: geoTypes.GET_PLACE__SUCCESS,
            payload: {
              coords,
              title: `${data.response.GeoObjectCollection.featureMember[0].GeoObject.description} ${data.response.GeoObjectCollection.featureMember[0].GeoObject.name}`
            }
          }))()))
  }
