import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { externalDocumentsActions, metaActions } from '../actions'
import DocumentEditor from '../pages/Profile/DocumentEditor.jsx'
import { withRouter } from 'react-router'
import { Loader } from '../component'

@withRouter
class DrugstoreContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    this.props.externalDocumentsActions.getDocument(this.props.match.params.documentId)
  }

  render() {
    return this.props.externalDocument.isRequesting ? <Loader /> : <DocumentEditor {...this.props} />
  }
}

const mapStateToProps = state => ({
  externalDocument: state.externalDocument,
})
const mapDispatchToProps = dispatch => ({
  metaActions: bindActionCreators(metaActions, dispatch),
  externalDocumentsActions: bindActionCreators(externalDocumentsActions, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrugstoreContainer)
