import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import AccountBalanceWallet from "@material-ui/icons/AccountBalance";
import Email from "@material-ui/icons/Email";
import GetApp from "@material-ui/icons/GetApp";
import SchoolIcon from "@material-ui/icons/School";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import WorkIcon from "@material-ui/icons/Work";


export const dropMenu = {
  uploadAct: {
    icon: AssignmentTurnedIn,
    text: "Загрузить акт"
  },
  uploadBill: {
    icon: AccountBalanceWallet,
    text: "Загрузить счёт"
  },
  uploadMail: {
    icon: Email,
    text: "Загрузить почту"
  },
  upload: {
    icon: GetApp,
    text: "Загрузить иное"
  },
  projectLearn: {
    icon: SchoolIcon,
    text: "Проект обучения"
  },
  ProjectOutsource: {
    icon: WorkIcon,
    text: "Проект аутсорсинга мед. представителей"
  },
  ProjectMarketing: {
    icon: ShoppingCartIcon,
    text: "Проект маркетинга"
  }
};
