import React from 'react'
import { Typography } from '@material-ui/core'
import './File.scss'

const FilePreviewLite = props => (
  <div className='file-preview'>
    {console.log(props)}
    <img className={`icon ${props.type}`} src={props.type === 'image' ? props.file.url : null} />
    <div className='info'>
      <div className='meta'>
        <a href={props.file.url} target='_blank'><Typography className='title'>{props.title}</Typography></a>
        <Typography className='meta'>{new Date(props.uploadedAt).toLocaleDateString()}</Typography>
      </div>
      <div className='author' onClick={props.onAuthorClick || null}>
        <Typography className='name'>{props.owner.fullName}</Typography>
        <Typography className='description'>{props.description}</Typography>
      </div>
    </div>
  </div>
)

export default FilePreviewLite
