import React, { Component } from 'react'
import './Links.scss'
import { Loader } from '../component'
import { Typography } from '@material-ui/core'
import { formatInviteStatus, getInviteStatus, copyTextToClipboard } from '../utils' // TODO: remove getstatus and add enum from back

const WithoutInvites = () =>
  <div className='no_content'><Typography>Для этой аптеки пока нет ссылок</Typography></div>

class Links extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copiedLink: null
    }
  }
  handleCopyLinkClick = (invite, index) => {
    this.setState({ copiedLink: index })
    copyTextToClipboard(`${process.env.BASE_TRAINING_URL}/invites?id=${invite.id}&type=${invite.type}`)
  }

  render() {
    const { course, invites, withCourseName } = this.props,
          { copiedLink } = this.state

    return (
      <div className='links' id='linksWrapper'>
        <div className='links_header'>
          фильтры
        </div>
        {
          (invites.isRequesting && invites.items.length === 0)
          ? <Loader />
          : invites.items.length === 0
            ? <WithoutInvites />
            : invites.items.map((inv, index) => (
              <div className='invite' key={index}>
                <div className='block link' onClick={() => this.handleCopyLinkClick(inv, index)}>
                  <div className='linkIcon'></div>
                  <div className='withIcon'>
                    <Typography>{ inv.id.cut(6) }</Typography>
                    <div className={`copyIcon${copiedLink === index ? ' copied' : ''}`}></div>
                  </div>
                </div>
                <Typography className='block user'>{ (inv.courseAccess && inv.courseAccess.user) ? inv.courseAccess.user.fullName : "" }</Typography>
                { withCourseName && <Typography className='block course'>{inv.course.title}</Typography> }
                {/*<Typography className='block title'>{ inv.title || 'Добавить временное описание' }</Typography>*/}
                <Typography className={`block status ${getInviteStatus(inv)}`}>{ formatInviteStatus(getInviteStatus(inv)) }</Typography>
                <Typography className='block date'>{ `Создана ${new Date(inv.createdAt).toLocaleDateString('ru-RU', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) }` }</Typography>
                  <div className='block'>
                      <a target="_blank" href={course.item.certificate && course.item.certificate.url}>
                          <div onClick={() => null} className="certificate" />
                      </a>
                  </div>
              </div>
          ))
        }
        { invites.isRequesting && <Loader line /> }
      </div>
    )
  }
}

export default Links
