import { lighten } from "@material-ui/core/styles/colorManipulator";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography/Typography";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DeleteIcon from "@material-ui/core/SvgIcon/SvgIcon";
import AddIcon from "@material-ui/icons/Add";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import Button from "@material-ui/core/Button/Button";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";

//styles
import "./Toolbar.scss"

class MaterialsDashboardToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      tab: 0
    };
  }

  handleTabClick = value => this.setState(
    () => ({ tab: value }),
    () => this.props.onTabChange(this.state.tab)
  )

  render() {
    const { numSelected, toggleFilter, withFilter } = this.props,
          { tab } = this.state
    return (
        <Toolbar
            style={{maxHeight:"53px", }}
            className={classNames('toolbar', {
                ['highlight']: numSelected > 0
            }, withFilter ? 'withFilter' : null)}
        >
            <Tabs
                style={{flexGrow:1}}
                value={tab}
                onChange={(_, tab) => this.handleTabClick(tab)}
                centered
            >
                <Tab label="Редактирование" />
                <Tab label="Предпросмотр" />
            </Tabs>
        </Toolbar>
    );
  };
}
export default MaterialsDashboardToolbar;
