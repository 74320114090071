import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { materialsActions, metaActions } from "../actions";
import { Material, Materials } from "../pages";

class MaterialsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const {
      metaActions: { updateNavi },
    } = this.props;
    updateNavi([{ title: "Материалы", to: "/materials" }]);
  };

  render() {
    const { match: { url } } = this.props
    return url === '/materials' || url === '/materials/'
    ? <Materials {...this.props} />
    : <Material {...this.props} />
  }
}

const mapStateToProps = state => {
  return {
    materials: state.materials
  }
}
const mapDispatchToProps = dispatch => ({
  materialsActions: bindActionCreators(materialsActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialsContainer);
