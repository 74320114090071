import React, { Component, Fragment } from "react";
import { Paper, Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { Loader } from '../component'
import { FilePreview, FilePreviewLite } from '../component/previews'
import FilesTableToolbar from "../component/toolbar/FilesTableToolbar";
import Swipeable from 'react-swipeable-views'

//styles
import "../component/dashboard/Dashboard.scss";
import "./Files.scss";

@withRouter
class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      isFilterOpened: false,
      tab: 0
    };
  }
  handleAddFileClick = () => this.props.history.push('/upload')
  handleTabChange = tab => this.setState({ tab })
  handleShareClick = shareId => this.props.history.push('/files/' + shareId)
  render() {
    const { files: { isRequesting, items }, auth: { user: { roles, self } } } = this.props
    const isAdmin = roles.some(role => role === 'ROLE_MANAGER')

    return <Paper className="files">
        {
          isRequesting
          ? <Loader />
          : <Fragment>
            <FilesTableToolbar
              toggleFilter={this.handleToggleFilter}
              withFilter={this.state.isFilterOpened}
              handleOpenModal={this.handleAddFileClick}
              onTabChange={this.handleTabChange}
              />
            <Swipeable index={this.state.tab}>
              <div className='files_list'>
                {
                  items.filter(file => file.owner.self === self).map((file, index) =>
                  <FilePreview {...file} key={index} onShareClick={this.handleShareClick}/>)
                }
              </div>
              <div className='files_list'>
                {
                  items
                  .filter(file => file.shares.items.some(share => share.sharedWith === self))
                  .map((file, index) =>
                    <FilePreviewLite {...file} key={index} onAuthorClick={() => this.props.history.push('/files/' + file.shares.items.filter(share => share.sharedWith === self)[0].self.split('/').pop())} />)
                }
              </div>
            </Swipeable>
          </Fragment>
        }
      </Paper>
  }
}

export default Files;
