import React, { Component } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import CourseList from "./CourseList";
import { withStyles } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import UserList from "./UserList";

const StyledTab = withStyles({
  root: {
    fontSize: "14px",
    textTransform: "none"
  }
})(Tab);

class Main extends Component {
  state = {
    currentTab: 0
  };

  handleChangeTab = (e, value) => this.setState({ currentTab: value });

  render() {
    const { currentTab } = this.state;
    const { item } = this.props;

    const tabs = [
      {
        label: "Курсы",
        component: (
          <CourseList
            {...this.props}
          />
        )
      },
      {
        label: "Сотрудники",
        component: <UserList medicalInstitution={item}/>
      }
    ];

    return (
      <section>
        <Toolbar className="drugstore__toolbar">
          <Tabs
            value={currentTab}
            onChange={this.handleChangeTab}
            indicatorColor="secondary"
            textColor="secondary"
            centered
          >
            {tabs.map(({ label }, index) => (
              <StyledTab key={index} label={label} className="drugstore__tab" />
            ))}
          </Tabs>
        </Toolbar>
        <div>{tabs[currentTab].component}</div>
      </section>
    );
  }
}

export default Main;
