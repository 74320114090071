import { materialsTypes } from '../types'
import apiService from "../api/apiService";
import { STATIC_FILES } from "../constants/entities";
import { getList, getOne, post, patch } from '../api'
import { history } from '../'

export const getMaterials = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: materialsTypes.GET_MATERIALS__REQUEST }))())

    const resourses = getState().meta.resourses

    getList(resourses['news-articles']).then(news => {
      getList(resourses['blog-articles']).then(posts => {
        dispatch((() => ({
          type: materialsTypes.GET_MATERIALS__SUCCESS,
          payload: { items: [
            ...news.items.map(item => ({ ...item, type: 'news' })),
            ...posts.items.map(item => ({ ...item, type: 'blog' })),
          ]}
        }))())
      })
    })
  }

export const getMaterial = (id) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: materialsTypes.GET_MATERIAL__REQUEST }))())

    const resourses = getState().meta.resourses
    getOne(resourses['blog-articles'], { id })
    .then(material => {
      dispatch((() => ({
        type: materialsTypes.GET_MATERIAL__SUCCESS,
        payload: { material: {
          ...material,
          type: 'blog',
          categories: material.categories.items
        } }
      }))())
    })
    .catch(() => {
      getOne(resourses['news-articles'], { id })
      .then(material => {
        dispatch((() => ({
          type: materialsTypes.GET_MATERIAL__SUCCESS,
          payload: { material: {
            ...material,
            type: 'news',
            categories: material.categories.items
          } }
        }))())
      })
    })
  }

export const getCategories = () =>
  (dispatch, getState) => {
    dispatch((() => ({ type: materialsTypes.GET_CATEGORIES__REQUEST }))())
    const resourses = getState().meta.resourses
    getList(resourses['blog-categories']).then(blogs => {
      getList(resourses['news-categories']).then(news => {
        dispatch((() => ({
          type: materialsTypes.GET_CATEGORIES__SUCCESS,
          payload: { categories: [...blogs.items, ...news.items].map(cat => ({...cat, type: cat.self.indexOf('news') > 0 ? 'news' : 'blog' })) }
        }))())
      })
    })
  }

export const clearMaterial = () => ({ type: materialsTypes.INIT_MATERIAL_EDITOR })

export const createMaterial = (material, type) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: materialsTypes.CREATE_MATERIAL__REQUEST }))())
    dispatch((() => ({ type: materialsTypes.CREATE_CATEGORIES__REQUEST }))())
    const catResourse = type === 'news'
                        ? getState().meta.resourses['news-categories']
                        : getState().meta.resourses['blog-categories'],
          resourses = getState().meta.resourses,
          materialResourse = type === 'news'
                             ? getState().meta.resourses['news-articles']
                             : getState().meta.resourses['blog-articles']

    const createdCats = material.categories.filter(cat => typeof cat !== 'string').map(cat => cat.self)

    Promise
    .all(material.categories.filter(cat => typeof cat === 'string').map(cat => post(catResourse, { title: cat })))
    .then(cats => {
      console.log();
      new apiService(STATIC_FILES).postFile(
        material.preview,
        "article_preview"
      ).then(file => {
        post(materialResourse, {
          title: material.title,
          description: material.description,
          body: material.body,
          categories: [...createdCats, ...cats.map(cat => cat.self)],
          preview: file.self
        }).then(data => {
          history.push('/materials')
          dispatch((() => ({
            type: materialsTypes.CREATE_MATERIAL__SUCCESS
          }))())
        })
      })
    })
  }
export const editMaterial = (material, type, id) =>
  (dispatch, getState) => {
    dispatch((() => ({ type: materialsTypes.EDIT_MATERIAL__REQUEST }))())
    dispatch((() => ({ type: materialsTypes.CREATE_CATEGORIES__REQUEST }))())
    const catResourse = type === 'news'
                        ? getState().meta.resourses['news-categories']
                        : getState().meta.resourses['blog-categories'],
          resourses = getState().meta.resourses,
          materialResourse = type === 'news'
                             ? getState().meta.resourses['news-articles']
                             : getState().meta.resourses['blog-articles']

    // const createdCats = material.categories.filter(cat => typeof cat !== 'string').map(cat => cat.self)
    //
    // Promise
    // .all(material.categories.filter(cat => typeof cat === 'string').map(cat => post(catResourse, { title: cat })))
    // .then(cats => {
      if (material.preview.self) {
        patch(`${materialResourse}/${id}`, {
          title: material.title,
          description: material.description,
          body: material.body,
          // categories: [...createdCats, ...cats.map(cat => cat.self)]
        }).then(data => {
          history.push('/materials')
          dispatch((() => ({
            type: materialsTypes.EDIT_MATERIAL__SUCCESS
          }))())
        })
      } else {
        new apiService(STATIC_FILES).postFile(
          material.preview,
          "article_preview"
        ).then(file => {
          patch(`${materialResourse}/${id}`, {
            title: material.title,
            description: material.description,
            body: material.body,
            // categories: [...createdCats, ...cats.map(cat => cat.self)],
            preview: file.self
          }).then(data => {
            history.push('/materials')
            dispatch((() => ({
              type: materialsTypes.EDIT_MATERIAL__SUCCESS
            }))())
          })
        })
      }
    // })
  }

export const changeField = (target, value) => ({
  type: materialsTypes.CHANGE_FIELD,
  payload: { target, value }
})
