import React from "react";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";

//styles
import "./Snackbars.scss"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

let MySnackbarContent = ({
  classes,
  className,
  message,
  onClose,
  variant,
  ...other
}) => {
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={'message'}>
          <Icon className={classNames('icon', 'iconVariant')} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={'icon'} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

const MySnackbarContentWrapper = MySnackbarContent;

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit
  }
});

// @actionsSnackbars
class CustomizedSnackbars extends React.Component {
  componentDidMount() {
    // setTimeout(this.props.closeSnackbar(), 3000);
  }

  handleClose = (event, reason) => {
    this.props.closeSnackbar();
  };

  render() {
    const { classes, snackbars } = this.props;
    // const { snackbarsOpen, label, variant } = snackbars;

    return null
  }
}

export default CustomizedSnackbars;
