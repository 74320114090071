import { get, currentApiUrl } from "../api";
import { currentManufacturerTypes } from "../types";


export const getCurrentManufacturer = (id) => async dispatch => {
  await dispatch((() => ({ type: currentManufacturerTypes.GET_CURRENT_MANUFACTURER__REQUEST }))());
  const currentManufacturer = await get(`${currentApiUrl}/v1/manufacturers/${id}?manager=/v1/users/${localStorage.getItem("user_id")}`)
  const manufacturerCourses = await get(`${currentApiUrl}${currentManufacturer.courses}`)
  const manufacturerContracts = await get(`${currentApiUrl}${currentManufacturer.contracts}`)
  dispatch((() => ({ 
    type: currentManufacturerTypes.GET_CURRENT_MANUFACTURER__SUCCESS,
    payload: {
      currentManufacturer: {
        ...currentManufacturer,
        manufacturerCourses: manufacturerCourses,
        manufacturerContracts: manufacturerContracts,
      }
    }
  }))());
}


