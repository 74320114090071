import React, { Component } from 'react';
import "./Manufacturers.scss";
import {ShortDesc, Contacts, Diplomat, ContractsIcon} from "../../static/imgs";
import AutocompleteInput from "../../component/autocomplete";

class Manufacturer extends Component{
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      country: '',
      phone: '+7',
      email: '',
      company: '',
      manager: '',
      managerName: '',
    }
  }

  componentDidMount = () => {
    const isEdited = this.props.isEdited;

    if (isEdited) {
      const manufacturer = this.props.manufacturers.manufacturer;

      this.setState(() => ({
        title: manufacturer.title,
        country: manufacturer.country,
        phone: manufacturer.phone,
        email: manufacturer.email,
        company: manufacturer.company.itn,
        manager: manufacturer.manager ? manufacturer.manager.self : '',
        managerName: manufacturer.manager ? manufacturer.manager.fullName : '',
      }))
    }
  };

  toFormatted = value => value.replace(/\D/g, "");

  handleManagerChange = value => {
    this.setState(() => ({ managerName: value}));
    value !== '' && this.props.usersActions.getUsersByQuery(value)
  }

  handleSelectManager = value => {
    this.setState({
      manager: value.self,
      managerName: value.fullName,
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch(name) {
      case 'phone':
        return this.setState({[name]: `+${this.toFormatted(value)}` });
      case 'company':
        return this.setState({[name]: this.toFormatted(value)});
      default:
        return this.setState({[name]: value});
    }
  };

  labelToUp = (e) => { e.target.nextElementSibling.classList.add('input-label__top') };

  labelToDown = (e) => { e.target.nextElementSibling.classList.remove('input-label__top') };

  handleCancel = () => { this.props.history.push('/manufacturers') };

  render() {
    const { title, country, phone, email, company, manager, managerName } = this.state;
    const { isNew } = this.props;

    return (
       <div className='create-manufacturer'>
          <span className='create-manufacturer__hint'>
            Поля, обозначенные <span className='hint__color-selection'>*</span> обязательны к заполнению
          </span>
          <div className='create-manufacturer__form'>
            <div className='form__row'>
              <ShortDesc className="listIcon" />
              <div className='input-group'>
                <input
                  name='title'
                  onChange={this.handleChange}
                  value={title}
                  id='inputField'
                  className='input-area'
                />
                <label for='inputField' className={`input-label ${title ? 'input-label__top' : ''}`}>Название*</label>
              </div>
            </div>
            <div className='form__row'>
              <ShortDesc className="listIcon" />
              <div className='input-group'>
                <input
                  name='country'
                  onChange={this.handleChange}
                  value={country}
                  id='inputField'
                  className='input-area'
                />
                <label htmlFor='inputField' className={`input-label ${country ? 'input-label__top' : ''}`}>Страна</label>
              </div>
            </div>
            <div className='form__row'>
              <Contacts className="listIcon" style={{width: '55px', marginLeft: '5px'}}/>
              <div className='input-group' style={{marginLeft: '8px'}}>
                <input
                  name='phone'
                  onChange={this.handleChange}
                  value={phone}
                  maxLength='12'
                  id='inputField'
                  className='input-area'
                />
                <label htmlFor='inputField' className={`input-label ${phone ? 'input-label__top' : ''}`}>Телефон*</label>
              </div>
              <div className='input-group' style={{marginLeft: '15px'}}>
                <input
                  name='email'
                  onChange={this.handleChange}
                  value={email}
                  id='inputField'
                  className='input-area'
                />
                <label htmlFor='inputField' className={`input-label ${email ? 'input-label__top' : ''}`}>E-mail</label>
              </div>
            </div>
            <div className='form__row'>
              <Diplomat className="listIcon" />
              <div className='input-group'>
                <input
                  name='company'
                  onChange={this.handleChange}
                  value={company}
                  maxLength='10'
                  id='inputField'
                  className='input-area'
                />
                <label htmlFor='inputField' className={`input-label ${company ? 'input-label__top' : ''}`}>Компания (ИНН)*</label>
              </div>
            </div>
            <div className='form__row'>
              <ContractsIcon className="listIcon" />
              <div className='input-group'>
                <AutocompleteInput
                  value={managerName}
                  id='inputField'
                  onChange={this.handleManagerChange}
                  onSelect={this.handleSelectManager}
                  suggestions={this.props.users.items}
                  isRequesting={this.props.users.isRequesting}
                  suggestionsKey="fullName"
                />
                <label htmlFor='inputField' className={`input-label ${managerName ? 'input-label__top' : ''}`}>Менеджер</label>
              </div>
            </div>
            <div className='form__row'>
              <button onClick={this.handleCancel} className='form__button form__cancel-button'>Отмена</button>
              {
                isNew
                ? <button onClick={ () => this.props.submitForm(this.state) } className='form__button form__save-button'>Создать производителя</button>
                : <button onClick={ () => this.props.updateForm(this.state) } className='form__button form__save-button'>Редактировать производителя</button>
              }
            </div>
          </div>
        </div>
    );
  }
}

export default Manufacturer
