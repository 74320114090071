import { usersTypes } from "../types";

const initialState = {
  items: [],
  isRequestingAction: false,
  isRequesting: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case usersTypes.GET_USERS__REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case usersTypes.GET_USERS__SUCCESS:
      return {
        ...state,
        isRequesting: false,
        items: payload.items
      };
    case usersTypes.UNBAN_USER_REQUEST:
    case usersTypes.BAN_USER_REQUEST:
      return {
        ...state,
        isRequestingAction: true
      };
    case usersTypes.BAN_USER_SUCCESS:
      return {
        ...state,
        isRequestingAction: false,
        items: [
          ...state.items.map(user =>
            user.id === payload.id
              ? { ...user, isBanned: true }
              : user
          )
        ]
      };
    case usersTypes.UNBAN_USER_SUCCESS:
      return {
        ...state,
        isRequestingAction: false,
        items: [
          ...state.items.map(user =>
            user.id === payload.id
              ? { ...user, isBanned: false }
              : user
          )
        ]
      };
    case usersTypes.UNBAN_USER__FAILURE:
    case usersTypes.BAN_USER__FAILURE:
      return {
        ...state,
        isRequestingAction: false
      };
    default:
      return state;
  }
};
