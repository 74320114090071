import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Typography,
  List,
  ListItem,
  Checkbox
} from "@material-ui/core";
import classNames from "classnames";
import "./FilterSideBar.scss";

class FilterSideBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: []
    }
  }

  handleCheckboxClick = ({id}) => {
    const { query } = this.state
    const { onChange } = this.props
    let temp = [...query]
    if (temp.includes(id)) {
      this.setState(
        state => ({ query: state.query.filter(item => item !== id)}),
        () => onChange(this.state.query)
      );
    } else {
      temp.push(id)
      this.setState(
        () => ({ query: temp }),
        () => onChange(this.state.query)
      )
    }
  }

  render() {
    const { filterMenuOpen, items, nameKey, opened, filterName } = this.props;

    return (
      <Drawer
        variant="permanent"
        anchor={"right"}
        classes={{
          paper: `filter ${!opened && 'closed'}`
        }}
        open={filterMenuOpen}
      >
        <List>
          <ListItem>
            <Typography variant="h6">Фильтры</Typography>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <Typography variant="h6">{filterName}</Typography>
          </ListItem>
          {
            items.map((item, key) => (
              <ListItem key={key}>
                <Checkbox onClick={() => this.handleCheckboxClick({id: item.itn})}/>
                <Typography variant="body1">{nameKey ? item[nameKey] : item}</Typography>
              </ListItem>
            ))
          }
        </List>
      </Drawer>
    );
  }
}

//Todo : container ?
const mapStateToProps = state => ({
  filterMenuOpen: state.dashboardContract.filterMenu.filterMenuOpen
});

export default (connect(mapStateToProps)(FilterSideBar));
