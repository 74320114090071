import React, { Component } from "react";
import { CommentsContainer } from '../containers'
import { FilePreviewLite } from '../component/previews'
import { Paper, Typography, Button } from "@material-ui/core";
import { withRouter } from "react-router";
import { Loader } from '../component'
import "./Files.scss";

@withRouter
class FileInfo extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    const { filesActions: { getShare }, match: { params }, shared: { item: { file } }, metaActions: { updateNavi } } = this.props
    updateNavi([
      { title: 'Акции', to: '/files' },
      { title: file.title, to: '/files' },
    ])
    getShare(params.shareId)
  }
  componentWillUpdate = (nextProps) => {
    const { metaActions: { updateNavi } } = this.props

    if (nextProps.shared.item.file !== this.props.shared.item.file) {

      updateNavi([
        { title: 'Акции', to: '/files' },
        { title: nextProps.shared.item.file.title, to: '/files' },
      ])
    }
  }
  render() {
    const { users, shared } = this.props

    console.log(shared.item.file);

    return (users.isRequesting || shared.isRequesting) ? (
      <Loader />
    ) : (
      <Paper className="files">
        <FilePreviewLite { ...shared.item.file }/>
        <CommentsContainer flatted type='personalFileShare' resourse={shared.item.self}/>
      </Paper>
    );
  }
}

export default FileInfo;
