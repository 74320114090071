import {getList} from "../../api";
import {modalTypes} from "../../types";

export const openModal = () =>
    (dispatch, getState) => {
        dispatch((() => ({type: modalTypes.OPEN_MODAL}))())
    }

export const closeModal = () =>
    (dispatch, getState) => {
        dispatch((() => ({ type: modalTypes.CLOSE_MODAL }))())
    }
