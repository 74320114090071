import React, { Component, Fragment } from "react";
import "../inputs/textFields.scss";
import { Form, Field, FormSpy } from "react-final-form";
import createDecorator from "final-form-focus";

import "./Form.scss";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: { maxHeight: "56px" },
  marginNormal: { margin: 0 },
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.primary.dark
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: theme.palette.primary.dark
    }
  },
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.dark
    }
  },
  notchedOutline: {}
});

const required = value => (value ? undefined : "Required");

const RowInput = props => {
  let dropList = props.input.dropList || [];
  return (
    <Field
      validate={props.input.validation}
      api={props.input.api}
      select={props.input.select}
      name={props.input.name}
      component={props.input.typeInput}
      placeholder={props.input.placeholder}
      label={props.input.label}
      variant={"outlined"}
      className={`${props.classes.marginNormal} ${props.classes.root}`}
      dropList={props.input.dropList || []}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: props.classes.cssLabel,
          focused: props.classes.cssFocused
        }
      }}
      InputProps={{
        classes: {
          root: props.classes.cssOutlinedInput,
          focused: props.classes.cssFocused,
          notchedOutline: props.classes.notchedOutline
        }
      }}
      // subscription={{
      //   value: true,
      //   active: true,
      //   error: true,
      //   touched: true
      // }}
    />
  );
};

export let requireCheck = ({
  input: { checked, name, onChange, value, ...restInput },
  meta,
  label,
  classes,
  inputs,
  isRequired,
  ...rest
}) => {
  return value || isRequired ? (
    inputs.map(input => <RowInput input={input} classes={classes} {...rest} />)
  ) : (
    <Button
      {...rest}
      name={name}
      inputProps={restInput}
      onClick={() => onChange(true)}
      checked={checked}
      color="secondary"
      fullWidth={true}
      className={"labelButton"}
      style={{ minWidth: "100%", textTransform: "none", textAlign: "left" }}
      // size={"medium"}
    >
      {label}
    </Button>
  );
};

let CreateFields = ({
  fields,
  classes,
  handleSubmit,
  form,
  submitting,
  pristine,
  values,
  input
}) => {
  return fields.map((item, key) => (
    <div key={key} className="row">
      {item.icon}
      {item.style ? (
        <div style={item.style}>
          <Field
            isRequired={item.isRequired}
            label={item.label}
            name={item.id}
            component={requireCheck}
            subscription={{ value: true }}
            inputs={item.inputs}
            classes={classes}
          />
        </div>
      ) : (
        <Field
          isRequired={item.isRequired}
          label={item.label}
          name={item.id}
          component={requireCheck}
          subscription={{ value: true }}
          inputs={item.inputs}
          classes={classes}
        />
      )}
    </div>
  ));
};

export default withStyles(styles)(CreateFields);
