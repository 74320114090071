import React, { Component } from "react";
import "./Log.scss";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import UploadIcon from "@material-ui/icons/CloudUpload";
import RefrashIcon from "@material-ui/icons/Refresh";
import IconButton from "@material-ui/core/IconButton";

export class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReplay: false,
      replyInput: ""
    };
  }

  render() {
    let comment = {
      manager: {
        fullname: "Мальцев А. Д."
      },
      message: " I HATE YOU"
    };
    return (
      <div className="log">
        <div className="userAvatar_container">
          <Avatar className="userAvatar" alt="Remy Sharp">
            A
          </Avatar>
          <div className="info_persona">
            <span className="userName">Name</span>
            <span className="date">date</span>
          </div>
        </div>
        <div className="info_actions">
          Info text
        </div>
        <div className="actions">
          {/*<UploadIcon />*/}
          <div className="actions_status"> Отменено </div>
          <Button className="actions_cancel"> Вернуть </Button>
          {/*<IconButton>*/}
            {/*<RefrashIcon />*/}
          {/*</IconButton>*/}
        </div>
      </div>
    );
  }
}
