import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { projectActions, contractActions, metaActions, commentsActions } from '../actions'
import { Contract } from '../pages'
import { withRouter } from 'react-router'
import { changeStatusProject } from "../actions/project/changeStatusProject";
import {toggleModalWizzard} from "../actions/modal/toggleModal"
@withRouter
class ContractContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null
    }
  }

  componentDidMount = () => {
    const { location: { pathname } } = this.props;
    this.setState({ id: pathname.split('/').slice(-1)[0] })
  }

  render() {
    return this.state.id !== null && <Contract {...this.props} id={this.state.id}/>
  }
}

const mapStateToProps = state => ({
  contract: state.contract,
  meta: state.meta,
  comments: state.comments
})
const mapDispatchToProps = dispatch => ({
  projectActions: bindActionCreators(projectActions, dispatch),
  contractActions: bindActionCreators(contractActions, dispatch),
  commentsActions: bindActionCreators(commentsActions, dispatch),
  metaActions: bindActionCreators(metaActions, dispatch),
  changeStatusProject: bindActionCreators(changeStatusProject, dispatch),
  toggleModalWizzard: bindActionCreators(toggleModalWizzard, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractContainer)
