import React, { Component } from 'react'
import './subtask.scss'
import { GeoIcon, PhotoIcon, MessageIcon, PollIcon, StatPollIcon, CourseIcon,
  PillIcon, CancelIcon, DeleteBucketIcon } from '../../static/imgs';
import { Checkbox } from '@material-ui/core'
import { Autocomplete } from '../'

class GeoSubtask extends Component {
  constructor (props) {
    super(props)
  }
  render () {
    return (
      <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
        <GeoIcon />
        <p className='title'>Геопозиция</p>
        <i />
        <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
      </div>
    )
  }
};

class PhotoSubtask extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {}
    }
  }
  componentDidMount = () => {
    this.setState({
      item: this.props.item
    })
  }
  handleIncreaseNumber = () => {
    this.setState(
      state => ({ item: { ...state.item, minimumAmount: state.item.minimumAmount + 1 }}),
      () => this.props.onChange(this.state.item)
    )
  }
  handleSubtractNumber = () => {
    this.state.item.minimumAmount > 0
    ? this.setState(
        state => ({ item: { ...state.item, minimumAmount: state.item.minimumAmount - 1 }}),
        () => this.props.onChange(this.state.item)
      )
    : null
  }

  render () {
    return (
      <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
        <div className='photoSubtask__wrapper'>
          <PhotoIcon />
          <p className='title'>Фотографии</p>
          <div className='counter__wrapper'>
            <button onClick={this.handleSubtractNumber} className='counter__button'>-</button>
            <p>{this.state.item.minimumAmount}</p>
            <button onClick={this.handleIncreaseNumber} className='counter__button'>+</button>
          </div>
          <i />
          <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
        </div>
      </div>
    )
  }
}

class MessageSubtask extends Component {
  constructor (props) {
    super(props)
  }
  render () {
    return (
      <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
        <MessageIcon />
        <p className='title'>Сообщения</p>
        <i />
        <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
      </div>
    )
  }
}

class PollSubtask extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuOpened: true,
      item: { answers: [] }
    }
  }
  componentDidMount = () => {
    this.setState({
      item: this.props.item
    })
  }
  toggleMenu = () => {
    this.setState(
      () => ({isMenuOpened: !this.state.isMenuOpened}),
    )
  }
  handleChangeField = (field, value) => {
    this.setState(
      state => ({ item: { ...state.item, [field]: value } }),
      () => this.props.onChange(this.state.item)
    )
  }
  handleChangeAnswers = (answer, index) => {
    this.setState(
      state => ({ item: { ...state.item, answers: state.item.answers.map((_answer, _index) => index === _index ? answer : _answer) } }),
      () => this.props.onChange(this.state.item)
    )
  }
  handleDeleteAnswer = index => {
    this.setState(
      state => ({ item: { ...state.item, answers: state.item.answers.filter((_, _index) => index !== _index) } }),
      () => this.props.onChange(this.state.item)
    )
  }
  handleAddAnswer = () => this.setState(
    state => ({ item: { ...state.item, answers: [...state.item.answers, ''] } })
  )
  render () {
    const { item } = this.state
    return (
      <div className={`subtask__wrapper ${this.state.isMenuOpened ? 'opened' : 'hidden'}`}>
        <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
          <PollIcon />
          <p className='title'>Опрос</p>
          <div className={`${this.state.isMenuOpened ? 'menuOpenedArrow' : 'menuClosedArrow'}`}
               onClick={this.toggleMenu}>
          </div>
          <i />
          <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
        </div>
        <div className={`additionalMenu ${this.state.isMenuOpened ? 'opened' : 'disabled'}`}>
          <input type='text' placeholder='Тема опроса' onChange={({target: {value}}) => this.handleChangeField('questionText', value)} value={item.questionText}/>
          <hr color='#E5E5E5'/>
          {
            item.answers.map((_answer, _key) => (
              <div className='answer'>
                <Checkbox checked={item.expectedAnswer === _key} onClick={() => this.handleChangeField('expectedAnswer', _key)}/>
                <input key={_key} onChange={({target: {value}}) => this.handleChangeAnswers(value, _key)} value={_answer} type='text' placeholder={`Вариант ответа ${_key + 1}`}/>
                <button onClick={() => this.handleDeleteAnswer(_key)}>-</button>
              </div>
              ))
          }
          <button onClick={this.handleAddAnswer}>+ Добавить вариант</button>
        </div>
      </div>
    )
  }
}

class StatPollSubtask extends Component {
  constructor (props) {
    super(props)
    this.state = {isMenuOpened: true, item: {}}
  }
  componentDidMount = () => {
    this.setState({
      item: this.props.item
    })
  }
  toggleMenu = () => {
    this.setState(
      () => ({isMenuOpened: !this.state.isMenuOpened}),
    )
  }
  handleChangeField = (field, value) => {
    this.setState(
      state => ({ item: { ...state.item, [field]: value } }),
      () => this.props.onChange(this.state.item)
    )
  }
  render () {
    const { item } = this.state
    return (
      <div className={`subtask__wrapper ${this.state.isMenuOpened ? 'opened' : 'hidden'}`}>
        <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
          <StatPollIcon/>
          <p className='title'>Стат. опрос</p>
          <div className={`${this.state.isMenuOpened ? 'menuOpenedArrow' : 'menuClosedArrow'}`}
               onClick={this.toggleMenu}>
          </div>
          <i />
          <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
        </div>
        <div className={`additionalMenu ${this.state.isMenuOpened ? 'opened' : 'disabled'}`}>
          <input type='text' placeholder='Тема опроса' onChange={({target: { value } }) => this.handleChangeField('questionText', value)} value={item.questionText}/>
          <hr color='#E5E5E5'/>
          <input type='number' onChange={({target: { value } }) => this.handleChangeField('expectedAmount', value)} value={item.expectedAmount} placeholder='Ответ'/>
        </div>
      </div>
    )
  }
}

class CourseSubtask extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isMenuOpened: true,
      item: { course: '', trainee: '' }
    }
  }
  componentDidMount = () => {
    this.setState({
      item: this.props.item
    })
  }
  toggleMenu = () => {
    this.setState(
      () => ({isMenuOpened: !this.state.isMenuOpened}),
    )
  }
  handleSelect = (field, value) => {
    this.setState(
      state => ({item: { ...state.item, [field]: value }}),
      () => this.props.onChange(this.state.item)
    )
  }
  render () {
    const { item } = this.state
    return (
      <div className={`subtask__wrapper ${this.state.isMenuOpened ? 'opened' : 'hidden'}`}>
        <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
          <CourseIcon/>
          <p className='title'>Курсы</p>
          <div className={`${this.state.isMenuOpened ? 'menuOpenedArrow' : 'menuClosedArrow'}`}
               onClick={this.toggleMenu}>
          </div>
          <i />
          <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
        </div>
        <div className={`additionalMenu ${this.state.isMenuOpened ? 'opened' : 'disabled'}`}>
          <div className='select__row'>
            <Autocomplete
              value={item.course.title}
              className='course-input'
              placeholder='Название курса' 
              isRequesting={this.props.courses.isRequesting}
              onChange={this.props.onCourseChange}
              onSelect={item => this.handleSelect('course', item)}
              suggestions={this.props.courses.items}
              suggestionsKey={'title'}
              />
            <Autocomplete
              value={item.trainee.fullName}
              placeholder='Имя/email проходящего' 
              className='course-input'
              isRequesting={this.props.users.isRequesting}
              onChange={this.props.onUserChange}
              onSelect={item => this.handleSelect('trainee', item)}
              suggestions={this.props.users.items}
              suggestionsKey={'fullName'}
              />
          </div>
        </div>
      </div>
    )
  }
}

class DrugsSubtask extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: { drug: {} },
      isMenuOpened: true
    }
  }
  componentDidMount = () => {
    this.setState({ item: this.props.item })
  }
  toggleMenu = () => {
    this.setState(
      () => ({isMenuOpened: !this.state.isMenuOpened}),
    )
  }
  handleIncreaseNumber = () => {
    this.setState(
      state => ({ item: { ...state.item, expectedAmount: state.item.expectedAmount + 1 } }),
      () => this.props.onChange(this.state.item)
    )
  }
  handleSubtractNumber = () => {
    this.state.item.expectedAmount > 0
    ? this.setState(
        state => ({ item: { ...state.item, expectedAmount: state.item.expectedAmount - 1 } }),
        () => this.props.onChange(this.state.item)
      )
    : null
  }
  handleSelectDrug = drug => {
    this.setState(
      state => ({item: { ...state.item, drug }}),
      () => this.props.onChange(this.state.item)
    )
  }
  render () {
    const { item } = this.state
    return (
      <div className={`subtask__wrapper ${this.state.isMenuOpened ? 'opened' : 'hidden'}`}>
        <div className={`subtask__item ${this.props.isNew ? 'new' : this.props.isModyfied ? 'modyfied' : ''}`}>
          <PillIcon/>
          <p className='title'>Препараты</p>
          <div className={`${this.state.isMenuOpened ? 'menuOpenedArrow' : 'menuClosedArrow'}`}
               onClick={this.toggleMenu}>
          </div>
          <i />
          <CancelIcon onClick={this.props.onDelete} className='cancelIcon'/>
        </div>
        <div className={`additionalMenu ${this.state.isMenuOpened ? 'opened' : 'disabled'}`}>
          <div className='courseInfo__row'>
            <Autocomplete
              value={item.drug.title}
              placeholder='Название медикамента' 
              className='drugs_input'
              isRequesting={this.props.drugs.isRequesting}
              onChange={this.props.onDrugChange}
              onSelect={item => this.handleSelectDrug(item)}
              suggestions={this.props.drugs.items}
              suggestionsKey={'title'}
              />
            <div className='counter__wrapper'>
              <button onClick={this.handleSubtractNumber} className='counter__button'>-</button>
              <p>{item.expectedAmount}</p>
              <button onClick={this.handleIncreaseNumber} className='counter__button'>+</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { GeoSubtask, PhotoSubtask, MessageSubtask, PollSubtask, StatPollSubtask, CourseSubtask,
  DrugsSubtask }
