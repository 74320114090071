import { materialsTypes } from '../types'

const initialMaterial = {
  title: '',
  preview: {  },
  body: '',
  description: '',
  categories: [],
  type: 'blog'
}
const initialState = {
  items: [],
  material: {
    ...initialMaterial
  },
  categories: [],
  isEditing: false,
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case materialsTypes.INIT_MATERIAL_EDITOR: return {
      ...state,
      isEditing: false,
      material: {
        ...initialMaterial
      }
    }
    case materialsTypes.CREATE_MATERIAL__SUCCESS:
    case materialsTypes.EDIT_MATERIAL__SUCCESS: return {
      ...state,
      isRequesting: false
    }
    case materialsTypes.EDIT_MATERIAL__REQUEST:
    case materialsTypes.CREATE_MATERIAL__REQUEST:
    case materialsTypes.GET_CATEGORIES__REQUEST:
    case materialsTypes.GET_MATERIAL__REQUEST:
    case materialsTypes.GET_MATERIALS__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case materialsTypes.GET_CATEGORIES__SUCCESS: return {
      ...state,
      isRequesting: false,
      categories: action.payload.categories
    }
    case materialsTypes.GET_MATERIAL__SUCCESS: return {
      ...state,
      isRequesting: false,
      isEditing: true,
      material: {
        ...state.material,
        ...action.payload.material
      }
    }
    case materialsTypes.GET_MATERIALS__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.items
    }
    case materialsTypes.CHANGE_FIELD: return {
      ...state,
      material: {
        ...state.material,
        [action.payload.target]: action.payload.value
      }
    }
    default:
      return state
  }
}
