import { ticketsTypes } from '../types'

const initialState = {
  item: {},
  isRequesting: true,
  isError: false,
  isRequestingTicketStatus: false,
  isRequestingTicketComment: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ticketsTypes.CREATE_TICKET_COMMENT: return {
      ...state,
      isRequestingTicketComment: true
    }
    case ticketsTypes.CREATE_TICKET_COMMENT_SUCCESS: return {
      ...state,
      isRequestingTicketComment: false
    }
    case ticketsTypes.GET_TICKET__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case ticketsTypes.GET_TICKET__SUCCESS: return {
      ...state,
      isRequesting: false,
      item: action.payload.ticket
    }
    case ticketsTypes.CHANGE_TICKET_STATUS__REQUEST: return {
      ...state,
      isRequestingTicketStatus: true
    }
    case ticketsTypes.CHANGE_TICKET_STATUS__SUCCESS: return {
      ...state,
      isRequestingTicketStatus: false
    }
    default:
      return state
  }
}
