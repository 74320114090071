export const GET_LOGS__REQUEST = 'CONTRACT_TYPE__GET_LOGS__REQUEST'
export const GET_LOGS__SUCCESS = 'CONTRACT_TYPE__GET_LOGS__SUCCESS'
export const GET_LOGS__FAILURE = 'CONTRACT_TYPE__GET_LOGS__FAILURE'
export const GET_CONTRACTS__REQUEST = 'CONTRACT_TYPE__GET_CONTRACTS__REQUEST'
export const GET_CONTRACTS__SUCCESS = 'CONTRACT_TYPE__GET_CONTRACTS__SUCCESS'
export const GET_CONTRACTS__FAILURE = 'CONTRACT_TYPE__GET_CONTRACTS__FAILURE'
export const GET_CONTRACT__REQUEST = 'CONTRACT_TYPE__GET_CONTRACT__REQUEST'
export const GET_CONTRACT__SUCCESS = 'CONTRACT_TYPE__GET_CONTRACT__SUCCESS'
export const GET_CONTRACT__FAILURE = 'CONTRACT_TYPE__GET_CONTRACT__FAILURE'
export const FILTER = 'CONTRACT_TYPE__FILTER'
