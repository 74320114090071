import { contractTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case contractTypes.GET_LOGS__REQUEST: return {
      ...state,
      isRequesting: true,
      isError: false
    }
    case contractTypes.GET_LOGS__SUCCESS: return {
      ...state,
      isRequesting: false,
      isError: false,
      items: action.payload.logs
    }
    case contractTypes.GET_LOGS__FAILURE: return {
      ...state,
      isError: true
    }
    default: return state
  }
}
