import React from 'react';
import {IconButton, Typography} from "@material-ui/core";
import { Close } from '@material-ui/icons';
import './Files.scss'

const Attachments = ({ attachments, onDelete }) => {
    return (
        <ul className='files-attachments__list'>
            {attachments.map(({ name }, index) => (
                <li className='files-attachments__item'>
                    <Typography>{name}</Typography>
                    <IconButton onClick={() => onDelete(index)}>
                        <Close fontSize='small' />
                    </IconButton>
                </li>
            ))}
        </ul>
    );
}

export default Attachments;