export const GET_PHARMACIES__REQUEST = 'PHARMACY_TYPE__GET_PHARMACIES__REQUEST'
export const GET_PHARMACIES__FAILURE = 'PHARMACY_TYPE__GET_PHARMACIES__FAILURE'
export const GET_PHARMACIES__SUCCESS = 'PHARMACY_TYPE__GET_PHARMACIES__SUCCESS'
export const CREATE_PHARMACY__REQUEST = 'PHARMACY_TYPE__CREATE_PHARMACY__REQUEST'
export const CREATE_PHARMACY__FAILURE = 'PHARMACY_TYPE__CREATE_PHARMACY__FAILURE'
export const CREATE_PHARMACY__SUCCESS = 'PHARMACY_TYPE__CREATE_PHARMACY__SUCCESS'
export const GET_PHARM__REQUEST = 'PHARMACY_TYPE__GET_PHARM__REQUEST'
export const GET_PHARM__FAILURE = 'PHARMACY_TYPE__GET_PHARM__FAILURE'
export const GET_PHARM__SUCCESS = 'PHARMACY_TYPE__GET_PHARM__SUCCESS'
export const UPDATE_PHARM__REQUEST = 'PHARMACY_TYPE__UPDATE_PHARM__REQUEST'
export const UPDATE_PHARM__SUCCESS = 'PHARMACY_TYPE__UPDATE_PHARM__SUCCESS'
export const UPDATE_PHARM__FAILURE = 'PHARMACY_TYPE__UPDATE_PHARM__FAILURE'
export const LOAD_FROM_FILE__REQUEST = 'PHARMACY_TYPE__LOAD_FROM_FILE__REQUEST'
export const LOAD_FROM_FILE__SUCCESS = 'PHARMACY_TYPE__LOAD_FROM_FILE__SUCCESS'
export const LOAD_FROM_FILE__FAILURE = 'PHARMACY_TYPE__LOAD_FROM_FILE__FAILURE'
export const IMPORT_PHARMACIES__REQUEST = 'PHARMACY_TYPE__IMPORT_PHARMACIES__REQUEST'
export const IMPORT_PHARMACIES__IN_PROGRESS = 'PHARMACY_TYPE__IMPORT_PHARMACIES__IN_PROGRESS'
export const IMPORT_PHARMACIES__SUCCESS = 'PHARMACY_TYPE__IMPORT_PHARMACIES__SUCCESS'
