import { manufacturersTypes } from "../types";
import {
  getList,
  getOne,
  post,
  serialize,
  get,
  currentApiUrl,
  patch
} from "../api";

export const getManufacturers = () => dispatch => {
  dispatch({ type: manufacturersTypes.GET_MANUFACTURERS__REQUEST });
  get(`${currentApiUrl}/v1/manufacturers`)
  .then(_manufacturers => {
    Promise.all( Array.from( new Set(
        _manufacturers.items.map(_manufacturer => _manufacturer.manager)
      ))
      .filter(_link => _link !== null)
      .map(_managerLink => get(`${currentApiUrl}${_managerLink}`))
    ).then(_managers => {
      _manufacturers.items.map(
        item =>
          (item.manager = _managers.find(
            manager => item.manager === manager.self
          ))
      );
      dispatch({
        type: manufacturersTypes.GET_MANUFACTURERS__SUCCESS,
        payload: { ..._manufacturers }
      });
    });
  })
};

export const getManufacturer = (manufacturerId) => dispatch => {
  dispatch({ type: manufacturersTypes.GET_MANUFACTURER__REQUEST });
  get(`${currentApiUrl}/v1/manufacturers/${manufacturerId}`)
  .then( _manufacturer => {
    if ( _manufacturer.manager !== null ) {
      get(`${currentApiUrl}${_manufacturer.manager}`).then( _user =>
        dispatch({
          type: manufacturersTypes.GET_MANUFACTURERS__SUCCESS,
          payload: { manufacturer: { ..._manufacturer, manager: { ..._user } } }
        })
      )
    } else {
      dispatch({
        type: manufacturersTypes.GET_MANUFACTURERS__SUCCESS,
        payload: { manufacturer: { ..._manufacturer } }
      })
    }
  })
};

export const createManufacturer = (form, push) => dispatch => {
  form.company = `/v1/companies/${form.company}`;
  dispatch({ type: manufacturersTypes.CREATE_MANUFACTURER__REQUEST });
  post(`${currentApiUrl}/v1/manufacturers`, { ...form })
  .then(() => {
    push()
    dispatch({ type: manufacturersTypes.CREATE_MANUFACTURER__REQUEST })
  });
};

export const updateManufacturer = (form, id, push) => dispatch => {
  form.company = `/v1/companies/${form.company}`;
  dispatch((() => ({ type: manufacturersTypes.UPDATE_MANUFACTURER__REQUEST }))());
  patch(`${currentApiUrl}/v1/manufacturers/${id}`, form)
  .then(() => {
    push()
    dispatch({ type: manufacturersTypes.UPDATE_MANUFACTURER__SUCCESS })
  });
};


