import { urls } from '../constants'
const API_URL_RAW =  process.env.BASE_API_URL
const REQUEST_DATA = {
  headers: {
    Accept: "application/problem+json",
    Authorization: "Bearer " + `e1c60470-ab89-4934-998e-87e641316011`
  },
  mode: 'cors'
}

// TODO: fix then()
// export const getContractProjects = ({ projectId }) =>
//   new Promise(resolve =>
//     fetch(urls.GET_CONTRACT_PROJECTS(projectId), { headers: { 'Content-Type': 'application/json' }}))
//     .then(data => resolve(data.json()))

export const getTask = ({ taskId }) =>
  new Promise(resolve =>
    fetch(
      urls.GET_TASK(taskId),
      REQUEST_DATA
    ).then(data => resolve(data.json())))

export const getPerson = ({ personUrl }) =>
  new Promise(resolve =>
    fetch(
      API_URL_RAW + personUrl,
      REQUEST_DATA
    ).then(data => resolve(data.json())))

export const getReport = ({ reportLink, taskId }) =>
  new Promise(resolve =>
    fetch(
      API_URL_RAW + reportLink,
      REQUEST_DATA
    ).then(data => data.json())
     .then(data => resolve({ report: data, taskId })))
