import React, { Component } from 'react'
import { Typography, Input } from '@material-ui/core'
import { SearchInput } from '../../component/search'
import Healing from "@material-ui/icons/Healing";
import './style.scss'

class Drugs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'drugs_supply',
      expectedAmount: null,
      drug: ''
    }
  }
  handleChange = (name, value) => this.setState(
    () => ({ [name]: value }),
    () => this.props.onChange(this.state)
  )
  render() {
    return (
      <div className='subtask'>
        <Typography className='header'>Проверить препараты</Typography>
        <div className='container'>
          <div className='icon'>
            <Healing color='primary' />
          </div>
          <div className='content'>
            <SearchInput data='drugs' dataKey='title' placeholder='Препарат' onSelect={item => this.handleChange('drug', item.self)} />
            <Input value={this.state.expectedAmount} placeholder='Количество'onChange={({target: { value }}) => this.handleChange('expectedAmount', value)}></Input>
          </div>
        </div>
      </div>
    )
  }
}

export default Drugs
