import { commentsTypes } from '../types'
import { contractActions, projectActions, filesActions } from './'
import { post } from '../api'

export const sendComment = data =>
  (dispatch, getState) => {
    dispatch((() => ({ type: commentsTypes.SEND_COMMENT__REQUEST }))())

    const preparedData = {
      ...data,
      type: data.type === 'personalFileShare' ? 'personal_file_share' : data.type
    }

    createComment(preparedData)
    .then(() => {
      console.log(data);
      switch (data.type) {
        case 'contract': return dispatch(contractActions.getContract({ id: data.contract.split('/').pop() }));
        case 'project': return dispatch(projectActions.getProject({ id: data.project.split('/').pop() }));
        case "personalFileShare": return dispatch(filesActions.getShare(data.personalFileShare.split('/').pop()));
        default: return false
      }
    })
  }

export const createComment = (data) => (
    post(`${process.env.BASE_API_URL}/v1/comments`, data)
)
