import React from "react";
import Modal from "@material-ui/core/Modal";
import './ModalMaterial.scss';

const ModalMaterial = ({ open, onClose, children }) => {
  return (
    <Modal open={open} onClose={onClose} disableAutoFocus>
      <div className="modal">
          {children}
      </div>
    </Modal>
  );
};

export default ModalMaterial;
