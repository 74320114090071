import React, { Component, Fragment } from 'react'
import { Typography, Button } from '@material-ui/core'
import './style.scss'
import { Loader } from '../'
import { currentClientUrl } from '../../api'

class Course extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInfoOpened: false
    }
  }
  handleInfoIconClick = () => this.setState({ isInfoOpened: true })
  handleInfoClick = () => this.setState({ isInfoOpened: false })
  render() {
    const { props } = this
    
    return (
      <div className={`course`}>
        <img src={props.cover.url} />
        <div className="topRow">
          <Typography className={`rewardPoints`}><div className="symbol">{'☆'}</div>{`${props.rewardPoints} баллов`}</Typography>
        </div>
        <Typography variant="body1" className="title">{props.title}</Typography>
        <Typography className="desc">{props.description}</Typography>
        <div className={`buttonRow`}>
          {/* <Button fullWidth={true} onClick={null} className="startCourseButton">Начать</Button> */}
          <a className='startCourseButton' href={currentClientUrl}>Начать</a>
        </div>
      </div>
    )
  }
}

export default Course


