import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contractActions, authActions } from "../actions";
import { Auth } from "../pages";

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      contractActions: { getLogs }
    } = this.props;
  };

  render() {
    return <Auth {...this.props} />;
  }
}

const mapStateToProps = state => ({
  logs: state.logs,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  contractActions: bindActionCreators(contractActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContainer);
