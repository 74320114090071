import React, { Component, Fragment } from "react";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "./Contract.scss";
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
// import { createContract } from "../state/contract/actions/createContract.actions";
import { FieldArray } from "react-final-form-arrays";
import Epic from "../../component/card/Epic";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "@material-ui/core/es/Button/Button";
import { submitTasksActions } from "../../actions/modal/createTask";
import { CircularProgress, Modal, LinearProgress } from "@material-ui/core";
import { toggleModalCreateTask } from "../../actions/modal/toggleModal";
import { Typography } from "@material-ui/core";

class ModalCreateTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleOnSubmit = values => {
    let { projectID } = this.props;
    this.props.submitTasks({ tasks: values.tasks, id: projectID });
    // this.props.handleOpenModal();
  };
  handleClose = () => {
    this.props.onClose(false);
  };

  render() {
    let { isSubmiting, open } = this.props;
    return (
      <Modal className="modal_wrapper" open={open}>
        <div className="modal">
          <div className="title">
            <IconButton className="close" aria-label="Delete">
              <CloseIcon fontSize="small" onClick={this.props.onClose} />
            </IconButton>
            <Typography variant="h6">{`Создание новой задачи`}</Typography>
          </div>
          {isSubmiting && <LinearProgress />}
          <Form
            onSubmit={this.handleOnSubmit}
            mutators={{
              ...arrayMutators
            }}
            initialValues={{ tasks: [{}] }}
            render={({
              handleSubmit,
              mutators: { push, pop },
              pristine,
              reset,
              submitting,
              values
            }) => {
              return (
                <form onSubmit={handleSubmit} className="modal-contract">
                  <div className="wrapper-overflow">
                    <FieldArray name="tasks">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <Epic
                            fields={fields}
                            values={values}
                            key={name}
                            name={name}
                            idEpic={index}
                          />
                        ))
                      }
                    </FieldArray>
                  </div>

                  <div className="buttons">
                    {/*<Button*/}
                    {/*className="button"*/}
                    {/*variant="outlined"*/}
                    {/*color="secondary"*/}
                    {/*type="button"*/}
                    {/*onClick={() => push("tasks", undefined)}*/}
                    {/*>*/}
                    {/*Добавить задачу*/}
                    {/*</Button>*/}
                    <div className="left">
                      <Button
                        className="button"
                        color="secondary"
                        variant="outlined"
                        type="submit"
                        disabled={submitting || pristine}
                      >
                        Отправить
                      </Button>
                      {/*<Button*/}
                      {/*className="button"*/}
                      {/*variant="outlined"*/}
                      {/*color="secondary"*/}
                      {/*type="button"*/}
                      {/*onClick={reset}*/}
                      {/*disabled={submitting || pristine}*/}
                      {/*>*/}
                      {/*Обнулить*/}
                      {/*</Button>*/}
                    </div>
                  </div>
                  {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                </form>
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitTasks: submitTasksActions,
      toggleModalCreateTask
    },
    dispatch
  );

const mapStateToProps = state => {
  return {
    isSubmiting: state.modal.isSubmiting,
    isError: state.modal.isError,
    isOpenModalCreateTask: state.modal.isOpenModalCreateTask
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCreateTask);
