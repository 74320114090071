import { profileTypes } from "../types";
import * as authSteps from "../constants/authSteps";

const initialState = {
  item: {},
  step: authSteps.SEND_PHONE,
  isRequestingUpload: false,
  isRequesting: true,
  isRequestingChangeNumber: false,
  isRequestingCreateDocument: false,
  isRequestingDeleteDocument: false,
  isRequestingEmployee: false,
  deletingDocument: null,
  isError: false,
  isErrorConfirmCode: false,
  isErrorRequestCode: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case profileTypes.GET_PROFILE__REQUEST:
      return {
        ...state,
        isRequesting: true
      };
    case profileTypes.GET_PROFILE__SUCCESS:
      return {
        ...state,
        item: payload.user,
        isRequesting: false
      };
    case profileTypes.POST_PROFILE_ROLE:
    case profileTypes.POST_PROFILE_PERSONAL_INFO:
      return {
        ...state,
        isRequesting: true
      };
    case profileTypes.POST_PROFILE_ROLE_SUCCESS:
    case profileTypes.POST_PROFILE_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        isRequesting: false
      };
    case profileTypes.POST_PROFILE_ROLE_FAILURE:
    case profileTypes.POST_PROFILE_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        isRequesting: false
      };
    case profileTypes.UPLOAD_AVATAR_FILE:
      return {
        ...state,
        isRequestingUpload: true
      };
    case profileTypes.UPLOAD_AVATAR_FILE_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          avatar: payload.data
        },
        isRequestingUpload: false
      };
    case profileTypes.UPLOAD_AVATAR_FILE_FAILURE:
      return {
        ...state,
        isRequestingUpload: false
      };
    case profileTypes.CONFIRM_SMS_CODE:
    case profileTypes.REQUEST_SMS_CODE:
      return {
        ...state,
        isErrorConfirmCode: false,
        isRequestingChangeNumber: true,
        isErrorRequestCode: false
      };
    case profileTypes.CONFIRM_SMS_CODE_SUCCESS:
      return {
        ...state,
        isRequestingChangeNumber: false,
        step: authSteps.SEND_PHONE,
        item: {
          ...state.item,
          phone: payload.data
        }
      };
    case profileTypes.REQUEST_SMS_CODE_SUCCESS:
      return {
        ...state,
        isRequestingChangeNumber: false,
        step: authSteps.SEND_CODE
      };
    case profileTypes.REQUEST_SMS_CODE_FAILURE:
      return {
        ...state,
        isRequestingChangeNumber: false,
        isErrorRequestCode: true
      };
    case profileTypes.CONFIRM_SMS_CODE_FAILURE:
      return {
        ...state,
        isErrorConfirmCode: true,
        isRequestingChangeNumber: false
      };
    case profileTypes.BACK_CHANGE_PHONE:
      return {
        ...state,
        step: authSteps.SEND_PHONE
      };
    case profileTypes.CHANGE_PROFILE_INTERNAL_COMPANY:
      return {
        ...state,
        item: {
          ...state.item,
          internalCompanyEmployment: payload
        }
      };
    case profileTypes.CREATE_PROFILE_EMPLOYEE:
    case profileTypes.DELETE_PROFILE_EMPLOYEE:
      return {
        ...state,
        isRequestingEmployee: true
      };
    case profileTypes.CREATE_PROFILE_EMPLOYEE_FAIL:
    case profileTypes.DELETE_PROFILE_EMPLOYEE_FAIL:
      return {
        ...state,
        isRequestingEmployee: false
      };
    case profileTypes.CREATE_PROFILE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isRequestingEmployee: false,
        item: {
          ...state.item,
          employment: {
            ...state.item.employment,
            items: [...state.item.employment.items, payload]
          }
        }
      };
    case profileTypes.DELETE_PROFILE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isRequestingEmployee: false,
        item: {
          ...state.item,
          employment: {
            ...state.item.employment,
            items: state.item.employment.items.filter(
              employee => employee.id !== payload
            )
          }
        }
      };
    case profileTypes.CREATE_DOCUMENT__REQUEST:
      return {
        ...state,
        isRequestingCreateDocument: true
      };
    case profileTypes.CREATE_DOCUMENT__SUCCESS:
      return {
        ...state,
        isRequestingCreateDocument: false
      };
    case profileTypes.DELETE_DOCUMENT__REQUEST:
      return {
        ...state,
        deletingDocument: payload.document,
        isRequestingDeleteDocument: true
      };
    case profileTypes.DELETE_DOCUMENT__SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          externalDocuments: {
            ...state.item.externalDocuments,
            items: payload.documents
          }
        },
        deletingDocument: null,
        isRequestingDeleteDocument: false
      };
    case profileTypes.GET_MANUFACTURERS:
      return {
        ...state,
        manufacturers: payload.manufacturers
      };
    default:
      return state;
  }
};
