import { positionsTypes } from '../types'

const initialState = {
  items: [],
  isRequesting: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case positionsTypes.GET_POSITIONS__REQUEST: return {
      ...state,
      isRequesting: true,
      isError: false
    }
    case positionsTypes.GET_POSITIONS__SUCCESS: return {
      ...state,
      isRequesting: false,
      items: action.payload.positions
    }
    default: return state
  }
}
