import { profileTypes } from '../types'

const initialState = {
  item: {
    description: '',
    kind: '',
    type: '',
    url: '',
    createdAt: ''
  },
  isRequesting: true,
  isRequestingUpdate: false,
  isError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case profileTypes.GET_DOCUMENT__REQUEST: return {
      ...state,
      isRequesting: true
    }
    case profileTypes.GET_DOCUMENT__SUCCESS: return {
      ...state,
      isRequesting: false,
      item: action.payload.document
    }
    case profileTypes.UPDATE_DOCUMENT__REQUEST: return {
      ...state,
      isRequestingUpdate: true
    }
    case profileTypes.UPDATE_DOCUMENT__SUCCESS: return {
      ...state,
      isRequestingUpdate: false
    }
    default:
      return state
  }
}
